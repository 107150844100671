import React from 'react';

export default function Product(props) {
  const { product, onAdd, setMode, SetCurProd, cartItems} = props;
  const onLinkClick = (mode) => {
      SetCurProd(product);
      setMode("detail");
      goToTop();  
};

const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
};

  return (
    <div className="card">
      <a href='#' onClick={()=>{onLinkClick("ords")}} style={{ textDecoration: 'none' }}>
      <div>
      <div className="cardBody">
        <h2 className="cardTitle">{product.name}</h2>
        <p className="cardDescription">{product.description}</p>
      </div>
      </div>
      </a>
      <button className="cardBtn" onClick={() => onAdd(product)}>담기</button>
    </div>
  );
}

//<h3 className="cardPrice">{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</h3>