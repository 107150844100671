import React, {useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth} from "../config/firebase";

const db=firebase.firestore();


const MtrDel = ({location:{state}, history }) =>{
const user = auth.currentUser;
const {
  mtrId
} = state;


const onDel = () =>{
    
  db.collection("reqs_mentoring").doc(mtrId).delete()
.then(() => {
    window.location.replace("/mentorings");
    //alert("삭제 완료되었습니다.")
})
.catch((error) => {
    console.error("Error to del document: ", error);
});

  

}

useEffect(() => {
  onDel();
}, []);

return (
    
        <div> 
            {user ? 
             (
              <>
              </>
             )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default MtrDel;

/*

to={{ pathname:`/notices`, 
      state:{
        email: aUEmail,
        uid : aUid,
        userName : aUName,
        userType : aUType,
        userState : aUState

      }}}
*/