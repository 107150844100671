import Prods from '../components/cart2/Prods';
import ProdList from '../components/cart2/ProdList';

import Basket from '../components/cart2/Basket';
import Ords from '../components/cart2/Ords';
import React, { useState, useEffect, useId } from 'react';
import "../styles/cart2.css";
import { isBrowser, isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';

import firebase, {auth} from "../config/firebase";
import { getDoc, doc, setDoc, getFirestore, getDocs, where, limit, query, orderBy, collection, addDoc, updateDoc, serverTimestamp, deleteDoc, deleteField, runTransaction, increment } from "firebase/firestore";
import { getDatabase, ref, child, get, set, update, increment as incre, orderByChild } from "firebase/database";
import { useUser, FirestoreProvider, useFirestore, useFirebaseApp, useFirestoreCollectionData, useDatabase, useDatabaseListData, useFirestoreDocData, useDatabaseObjectData } from 'reactfire';
import { useParams, useLocation } from 'react-router-dom';
import moment from "moment";

import { BsCartFill } from 'react-icons/bs';
import toast, { Toaster } from 'react-hot-toast';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const db=firebase.firestore();

const delCartMsg = (msg) => toast.error(msg, { duration: 500 });
const addCartMsg = (msg) => toast.success(msg, { duration: 500 });
const ordMaxMsg = (msg) => toast.error(msg, { duration: 500, icon: '⚠️' });


const headerStyle = {
  backgroundColor: "#fff",
  fontSize: "0.875em",
  color: "#000",
  borderBottom: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "30px",
  position: "fixed",
  left: "0",
  top: "0",
  height: "65px",
  width: "100%",
  zIndex: 99
};

const footerStyle = {
  backgroundColor: "#000",
  fontSize: "0.875em",
  color: "white",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "10px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "40px",
  width: "100%",
  zIndex: 100
};

function AppProds() {
  const [ords, setOrds] = useState([]);
  const [curProd, SetCurProd] = useState();
  const [servs, setServs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [cartItems, setCartItems] = useState([]);
  const [mode, setMode] = useState("prods");
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState(null);
  const [gid, setGid] = useState(null);




  const prodsQuery =db.collection("prods")
  let today=new Date();
  //let today = moment();
  //const [startDate, setStartDate] = useState(moment().add(7, 'days') );
  const [startDate, setStartDate] = useState(new Date(today.setDate(today.getDate()+1)));


  //const userRef = doc(firestore, 'users', uid?uid:"a");
  //const { userStatus, data: user } = useFirestoreDocData(userRef);

  //const noordsDate = Object.values(count);
  //console.log("noordsDate->", noords);
  //console.log("noordsDate array->", noordsDate);

  /*
  for (let key in noOrdsTest) {
    if(noOrdsTest[key]!="noOrdsTest"){
      console.log(key, " ", noOrdsTest[key]);
      //noOrdsTestList.push(new Date(noOrdsTest[key]));
    }
  }
  console.log("mo:",moment().format('YYYY-MM-DD'));
  //console.log(noordsList);
  console.log(noOrdsTestList);
*/
  //console.log("aa",noOrdsTest?.hasOwnProperty(moment().format('YYYY-MM-DD')));
  
  //console.log(noOrdsTest?.[moment().format('YYYY-MM-DD')]);
  //undefined or "전체" moment().add(1,'days')
  const { status, data: prods } = useDatabaseListData(prodsQuery, {
    idField: 'id',
  });
 
const onMessageHandler = async(e) => {
    const event = JSON.parse(e.data);
    window.ReactNativeWebView.postMessage(JSON.stringify({ event: event }))
    if (event.uid) {
      setUser(event);
      setUid(event.uid);
    }
  }

  useEffect(() =>{

    const tempServs=[];
    db.collection("servs")
    .orderBy("sort")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const serv = doc.data();
        tempServs.push(serv);
      });
      setServs(tempServs);
    });
   
    console.log(cartItems);
    const isUIWebView = () => {
      return navigator.userAgent
        .toLowerCase()
        .match(/\(ip.*applewebkit(?!.*(version|crios))/)
    }
    const receiver = isUIWebView() ? window : document
    receiver.addEventListener('message', onMessageHandler)
    return () => {
      receiver.removeEventListener('message', onMessageHandler)
    }
  }, [cartItems]);


  if (status === 'loading') {
    return <div style={{marginTop:50}}><span>데이터를 불러오는 중입니다. 잠시만 기다려 주세요.</span></div>;
  }

/*
  const getOrds = async(id) => {
    const tempOrds=[];
    db.collection("ords").where("uid", "==", id).orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const ords = doc.data();
        tempOrds.push(ords);
      });
      setOrds(tempOrds);
    });
  };*/

  const onAdd = (product) => {
      const exist = cartItems.find((x) => x.id === product.id);


      if (exist) {
        if((exist.qty+1) > product.ordMax){
          ordMaxMsg(product.ordMax+"개 초과 주문이 불가능합니다."); 
        }else{
          setCartItems(
            cartItems.map((x) =>
              x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
            )
          );
          addCartMsg(product.name+" 품목 +1 담기"); 
        }
      } else {
        setCartItems([...cartItems, { ...product, qty: 1 }]);
        addCartMsg(product.name+" 품목 +1 담기"); 
      } 


  };
  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
    }
    delCartMsg(product.name+" 품목 -1 빼기"); 
  };
 
  const render = {
    detail : (
      <div style={{marginBottom:60}}>
        <h2 style={{textAlign: "center", marginTop:80}}>{curProd?.name} 상세</h2>
        <img src={curProd?.image} width="100%" height="100%" style={{marginTop:30, marginBottom:30}} />
        <div style={{margin:30}}>
        
      </div>
        <br/><br/><br/><br/><br/>
      </div>
      ),
      ordComp : (
        <div style={{marginBottom:60}}>
          <h2 style={{textAlign: "center", marginTop:80}}>주문접수 완료</h2>
          <div style={{textAlign: "center"}}>
            <span>정상적으로 주문접수가 완료되었습니다.<br/>담당자 확인 후 수주 처리 진행될 예정입니다.</span>
          </div>
          <br/><br/><br/><br/><br/>
        </div>
        ),
    prods : (
    <div style={{marginBottom:60, marginTop:80}}>
      <Tabs defaultActiveKey="buyerTab" id="uncontrolled-tab-example" >
      <Tab eventKey="buyerTab" title="전체" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4, }}>
        <ProdList category="전체" setMode={setMode} SetCurProd={SetCurProd} products={prods} onAdd={onAdd}  onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} uid={uid} user={user}/>
      </Tab>
      <Tab eventKey="buyerTab1" title="공산품" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4}}>
        <ProdList category="공산품" setMode={setMode} SetCurProd={SetCurProd} products={prods} onAdd={onAdd}  onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} uid={uid} user={user}/>
      </Tab>
      <Tab eventKey="buyerTab2" title="공산" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4}}>
        <ProdList category="공산" setMode={setMode} SetCurProd={SetCurProd} products={prods} onAdd={onAdd}  onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} uid={uid} user={user}/>
      </Tab>
      <Tab eventKey="buyerTab3" title="내구매" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4}}>
        <ProdList category="my" setMode={setMode} SetCurProd={SetCurProd} products={prods} onAdd={onAdd}  onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} uid={uid} user={user}/>
      </Tab>
      </Tabs>
    </div>
    ),
    cart : (
      <div style={{marginBottom:60, marginLeft: 4, marginRight:4}}>
      <h2 style={{textAlign: "center", marginTop:80}}>장바구니</h2>
      <Basket
          cartItems={cartItems}
          onAdd={onAdd}
          onRemove={onRemove}
          setMode={setMode}
          products={prods}
      />
      </div>
      ),
    ords : (
    <div>
    <h2 style={{textAlign: "center", marginTop:80}}>신청내역</h2>
    <Ords setMode={setMode} SetCurProd={SetCurProd} ords={ords} />
    </div>
    ),
    deli : (
      <div style={{textAlign: "center"}}>
      <h2 style={{textAlign: "center", marginTop:80}}>희망배송일 선택</h2>
      <h3>{startDate.getMonth()+1}월 {startDate.getDate()}일 오전에 도착예정</h3>
      <p>날짜 선택 시 희망배송일 변경 가능</p>
      </div>
      )
  }
  

  const onLinkClick = (mode, isSave) => {
    
    if((mode=="cart" || mode=="deli" || mode=="ordComp") && cartItems.length===0){ ordMaxMsg("품목을 장바구니에 담아주세요.");}
    else{
      if(isSave){ onBtSave(); setCartItems([]);}
      setMode(mode); goToTop();
    }
};

const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
};


const onBtSave = async() => {

window.ReactNativeWebView.postMessage(JSON.stringify({ method: 'click', cartItems: cartItems, startDate : startDate }))
}

const onBtGoOrds = async() => {
  window.ReactNativeWebView.postMessage(JSON.stringify({ method: 'goOrds' }))
}

const onBtSave1 = async() => {

};



  return (
    <div> 
      <Toaster position="top-center" />
      <HeaderBar>
      <a href='#!' onClick={()=>{onLinkClick("prods", false)}} className="blackLink"  style={mode == "prods"? { textDecoration: 'none',color: "#de071a", fontWeight: "bold"}: {textDecoration: 'none' }}>품목리스트</a>{'  /  '}
      <a href='#!' onClick={()=>{onLinkClick("cart", false)}} className="blackLink" style={mode == "cart"? { textDecoration: 'none',color: "#de071a", fontWeight: "bold"}: {textDecoration: 'none' }}>
          장바구니{''}
          {cartItems.length ? (
            <button className="badge">{cartItems.length}</button>
          ) : (
            ' '
          )}
        </a>
     </HeaderBar>  
      {render[mode]}
      <BottomBar>
      {mode == "prods" && (
          <a href='#!' onClick={()=>{onLinkClick("cart", false) }} className="whiteLink" style={{ textDecoration: 'none' }}>
        <div><span>장바구니로 이동하기</span></div></a>
      )}
      {mode == "detail" && (
        <>
        <a href='#!' onClick={()=>{onLinkClick("prods", false);}} className="whiteLink" style={{ textDecoration: 'none' }}>
          <div style={{width:"50%",float:"left"}}><span>목록보기</span></div></a>
        <a href='#!' onClick={()=>{onAdd(curProd)}} className="whiteLink" style={{ textDecoration: 'none' }}>
          <div style={{width:"50%",float:"right"}}><span>장바구니 담기</span></div></a>
        </>
      )}
      {mode == "cart" && (
          <a href='#!' onClick={()=>{onLinkClick("deli", false) }} className="whiteLink" style={{ textDecoration: 'none' }}>
        <div><span>희망배송일 선택하기</span></div></a>
      )}
      {mode == "deli" && (
          <a href='#!' onClick={()=>{onLinkClick("ordComp", true)}} className="whiteLink" style={{ textDecoration: 'none' }}>
        <div><span>주문하기</span></div></a>
      )}
      {mode == "ords" && (
          <a href='#!' onClick={()=>{onLinkClick("prods", false)}} className="whiteLink" style={{ textDecoration: 'none' }}>
        <div><span>목록보기</span></div></a>
      )}
      {mode == "ordComp" && (
          <a href='#!' onClick={()=>{onBtGoOrds()}} className="whiteLink" style={{ textDecoration: 'none' }}>
        <div><span>주문내역보기</span></div></a>
      )}
    </BottomBar>  
    </div>
  );
}

function HeaderBar({ children }) { return (<div><div style={headerStyle}>{children}</div></div>);}
function BottomBar({ children }) { return (<div><div style={footerStyle}>{children}</div></div>);}
export default AppProds;

//<Prods setMode={setMode} SetCurProd={SetCurProd} products={prods} onAdd={onAdd} cartItems={cartItems}/>
      