import Prods from '../components/cart/Prods';
import Basket from '../components/cart/Basket';
import Ords from '../components/cart/Ords';
import React, { useState, useEffect } from 'react';
import "../styles/cart.css";
import firebase, {auth} from "../config/firebase";
import { isBrowser, isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';
import reactHtmlReplace from 'react-html-replace';


const headerStyle = {
  backgroundColor: "#fff",
  fontSize: "14px",
  color: "#000",
  borderBottom: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "20px",
  position: "fixed",
  left: "0",
  top: "0",
  height: "60px",
  width: "100%",
  zIndex: 99
};

const footerStyle = {
  backgroundColor: "#000",
  fontSize: "14px",
  color: "white",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
  zIndex: 100
};


const db=firebase.firestore();
function Servs() {
  const [servs, setServs] = useState([]);
  const [ords, setOrds] = useState([]);
  const [curProd, SetCurProd] = useState();

  const [cartItems, setCartItems] = useState([]);
  const [mode, setMode] = useState("servs");
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState(null);


  const handleReadData = (e) => {
    const id=e.data;
    setUid(id);

  };

  const getUser = async(id) => {
    const data = await db.collection("users").doc(id).get()
    .then(snapshot => {
      const d=snapshot.data()
      setUser(d);
      console.log(d);    
    })
  };

  const getOrds = async(id) => {
    const tempOrds=[];
    db.collection("ords").where("uid", "==", id).orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const ords = doc.data();
        tempOrds.push(ords);
      });
      setOrds(tempOrds);
    });
  };

  const onAdd = (product) => {
    if(cartItems.length>=1){ alert("방송준비과업은 1개만 선택 가능합니다.");}
    else{
      const exist = cartItems.find((x) => x.id === product.id);
      if (exist) {
        setCartItems(
          cartItems.map((x) =>
            x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
          )
        );
      } else {
        setCartItems([...cartItems, { ...product, qty: 1 }]);
      }  
    }
  };
  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
    }
  };


  const Mention = props => {
    const { children, id, name } = props;
    return (
      <span name={name} id={id} style={{ border: '1px solid #ccc' }}>
        &nbsp;{children}&nbsp;
      </span>
    );
  };

  const Br2 = props => {
    const { children } = props;
    return (
      <span>
        &nbsp;{children}&nbsp;<br/>
      </span>
    );
  };


  const Image = props => {
    const { src, id, token, width, height} = props;
    console.log(src, id, token, width, height);
    return (
      <span>
        &nbsp;{token}&nbsp;<br/>
      </span>
    );
  };

  
  const render = {
    detail : (
      <div style={{marginBottom:60}}>
        <h2 style={{textAlign: "center", marginTop:80}}>{curProd?.name} 상세</h2>
        <img src={curProd?.image} width="100%" height="100%" style={{marginTop:30, marginBottom:30}} />
        <div style={{margin:30}}>
        {reactHtmlReplace(
          `${curProd?.body}`,
          (tag, props) => {
            if (tag === 'br') {
              return <br />;
            }
            if (tag === 'bold') {
              return <b />;
            }
            if (tag === 'italic') {
              return <i />;
            }
            if (tag === 'mention') {
              const { name, id } = props;
              return <Mention name={name} id={id}></Mention>;
            }
            if (tag === 'br2') {
              const { name, id } = props;
              return <Br2></Br2>;
            }
            if (tag === 'image') {
              const { src, id, token, width, height} = props;
              return <Image src={src} id={id} token={token} width={width} height={height}></Image>;
            }
            if (tag === 'hashtag') {
              const { tag, href } = props;
              return <a href={href}>{`#${tag}`}</a>;
            }
          }
        )}
      </div>
        <br/><br/><br/><br/><br/>
      </div>
      ),
      ordComp : (
        <div style={{marginBottom:60}}>
          <h2 style={{textAlign: "center", marginTop:80}}>신청완료</h2>
          <div style={{textAlign: "center"}}>
            <span>방송준비과업이 정상적으로 신청되었습니다.<br/>담당자 확인 후 D+2일 내 가입된 연락처로 유선 연락드리겠습니다.</span>
          </div>
          <br/><br/><br/><br/><br/>
        </div>
        ),
    servs : (
    <div style={{marginBottom:60}}>
      <h2 style={{textAlign: "center", marginTop:80}}>방송준비과업(택1 신청가능)</h2>
      <Prods setMode={setMode} SetCurProd={SetCurProd} products={servs} onAdd={onAdd} cartItems={cartItems}/>
      <br/><br/><br/><br/><br/>
    </div>
    ),
    cart : (
      <div style={{marginBottom:60}}>
      <h2 style={{textAlign: "center", marginTop:80}}>장바구니</h2>
      <Basket
          cartItems={cartItems}
          onAdd={onAdd}
          onRemove={onRemove}
          setMode={setMode}
      />
      <br/><br/><br/><br/><br/>
      </div>
      ),
    ords : (
    <div>
    <h2 style={{textAlign: "center", marginTop:80}}>신청내역</h2>
    <Ords setMode={setMode} SetCurProd={SetCurProd} ords={ords} />
    <br/><br/><br/><br/><br/>
    </div>
    )
  }
  

  useEffect(() => {

    const tempServs=[];
    db.collection("servs")
    .orderBy("sort")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const serv = doc.data();
        tempServs.push(serv);
      });
      setServs(tempServs);
    });

    if(typeof uid == "string"){
      getOrds(uid);
      getUser(uid);  
    }


    if (isMobile && isIOS) {
      window.addEventListener('message', handleReadData);
      return () => {
        window.removeEventListener('message', handleReadData);
      };
      }
      if (isMobile && isAndroid) {
      document.addEventListener('message', handleReadData);
      return () => {
        document.removeEventListener('message', handleReadData);
      };  
      }    
  }, [ords, uid]);

  const onLinkClick = (mode) => {
    
    if((mode=="cart" || mode=="ordComp") && cartItems.length===0){ alert("방송준비과업을 장바구니에 담아주세요.");}
    else{      
      setMode(mode); goToTop();
      if(mode=="ordComp") onSave();
    }
};

const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
};


const onSave = () => {
  cartItems.forEach((item, index, array) => {
    db.collection("ords").add(item)
    .then((docRef) => {
      const ref = db.collection("ords").doc(docRef.id);
      return ref.update({ 
        ordId: docRef.id,
        uid : user? user.uid : "",
        email: user? user.email:"",
        bizName : user? user.profile.bizInfo.bizName:"",
        personName : user? user.profile.name:"",
        phone: user? user.profile.phone:"",
        token : user? user.token:"",
        createAt : new Date()
       })
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
  });
};



  return (
    <div> 
      <HeaderBar>
      <a href='#!' onClick={()=>{onLinkClick("servs")}} style={{ textDecoration: 'none' }}>방송준비과업</a>{'  /  '}
        <a href='#!' onClick={()=>{onLinkClick("cart")}} style={{ textDecoration: 'none' }}>
          장바구니{' '}
          {cartItems.length ? (
            <button className="badge">{cartItems.length}</button>
          ) : (
            ' '
          )}
        </a>
        {'  /  '}
        <a href='#!' onClick={()=>{onLinkClick("ords")}} style={{ textDecoration: 'none' }}>신청내역</a>
     </HeaderBar>  
      {render[mode]}
      { mode != "servs" && (
      <BottomBar>
      {mode == "detail" && (
        <>
        <a href='#!' onClick={()=>{onLinkClick("servs");}} style={{ textDecoration: 'none' }}>
          <div style={{width:"50%",float:"left"}}><span>목록보기</span></div></a>
        <a href='#!' onClick={()=>{onAdd(curProd)}} style={{ textDecoration: 'none' }}>
          <div style={{width:"50%",float:"right"}}><span>장바구니 담기</span></div></a>
        </>
      )}
      {mode == "cart" && (
          <a href='#!' onClick={()=>{onLinkClick("ordComp") }} style={{ textDecoration: 'none' }}>
        <div><span>방송준비과업 신청하기</span></div></a>
      )}
      {mode == "ords" && (
          <a href='#!' onClick={()=>{onLinkClick("servs")}} style={{ textDecoration: 'none' }}>
        <div><span>목록보기</span></div></a>
      )}
      {mode == "ordComp" && (
          <a href='#!' onClick={()=>{onLinkClick("ords")}} style={{ textDecoration: 'none' }}>
        <div><span>신청내역 보기</span></div></a>
      )}
    </BottomBar>  
      )}

    </div>
  );
}

function HeaderBar({ children }) { return (<div><div style={headerStyle}>{children}</div></div>);}
function BottomBar({ children }) { return (<div><div style={footerStyle}>{children}</div></div>);}
export default Servs;