import React from 'react';
import { Radar } from 'react-chartjs-2';
import DetailChartTableMtr from "./DetailChartTableMtr";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { useTable } from 'react-table';

const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 500px;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;

:nth-of-type(1) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;


const RadarChartMtr = ({ recomChs, chartMdDataFromMe, mdAvgLabel, mdAvgData, totalSum, totalAvg, mdComment, mtrId, loginUserType}) =>{ 
  //console.log("mdAvgData=> ",mdAvgData);
  //console.log("chartMdDataFromMe=> ",chartMdDataFromMe);

  const chartData = {
    labels: mdAvgLabel,
    datasets: [
      {
        label: '전문가진단',
        data: mdAvgData,
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },      
      {
        label: '나의 진단',
        data: chartMdDataFromMe[0],
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      }, 
    ],
  };
  
  const options = {
    scales: {
      r: {
          angleLines: {
              display: false
          },
          suggestedMin: 0,
          suggestedMax: 5
      }
  }
  };

  const chart={
    width:"350px",
  }



  const data = React.useMemo(
    () => [
      {
        col1: "퍙균 점수",
        col2: totalAvg,
      },
      {
        col1: mdAvgLabel[0],
        col2: mdAvgData[0],
      },
      {
        col1: mdAvgLabel[1],
        col2: mdAvgData[1],
      },
      {
        col1: mdAvgLabel[2],
        col2: mdAvgData[2],
      },
      {
        col1: mdAvgLabel[3],
        col2: mdAvgData[3],
      },
      {
        col1: mdAvgLabel[4],
        col2: mdAvgData[4],
      },
      {
        col1: mdAvgLabel[5],
        col2: mdAvgData[5],
      },
      {
        col1: mdAvgLabel[6],
        col2: mdAvgData[6],
      },
      {
        col1: mdAvgLabel[7],
        col2: mdAvgData[7],
      },
      {
        col1: mdAvgLabel[8],
        col2: mdAvgData[8],
      },
      {
        col1: mdAvgLabel[9],
        col2: mdAvgData[9],
      },      
    ],
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: '항목',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: '내용',
        accessor: 'col2',
      },
      {
        Header: '내용',
        accessor: 'col3',
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  

return (

    <div style={chart}>
     { mdAvgData.length != 0 ?
     ( <Radar data={chartData} options={options} /> ) :
     ( <p>전문가 진단이 미작성되었습니다</p> )

     }
    <DetailChartTableMtr recomChs={recomChs} columns={columns} mdAvgData={mdAvgData} data={data} totalSum={totalSum} totalAvg={totalAvg} mdComment={mdComment} mtrId={mtrId} loginUserType={loginUserType}/>
    </div>
)
}

export default RadarChartMtr;