import React, {useState, useEffect} from 'react';
import FlatList from 'flatlist-react';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export default function ProdList(props) {
  //const { products, onAdd, setMode, SetCurProd, cartItems } = props;
  //const { prods } = props;
  const { category, viewNo, products, onAdd, onRemove, setMode, SetCurProd, cartItems, searchTerm, setSearchTerm, uid, user, onBtGoList} = props;

  const onLinkClick = (prod) => {
    SetCurProd(prod);
    setMode("detail");
    goToTop();  
};

  const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
  };

  const onSearchInput = e =>{
    setSearchTerm(e.target.value);
  }
  
  

  async function onBtAddRow(){

  }
  const PostWrapper = ({children}) => {
    return (
        <div className="PostWrapper">
            {children}
        </div>
    );
};

  const renderProd = (prod, idx) => {

    //let isSale=prod.isSales=="일시중지" ||prod.isSales=="품절"||prod.isSales=="사용안함"||(prod.isSales=="판매"&&prod.curStock<=0)?false:true
    //let salesPrice= Math.ceil(prod.puchasPrice/(1-prod.margin)/100) * 100;

    return (
       
  <div className="Post" key={idx}>
  <div style={{paddingBottom: 10, marginBottom:10, borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
  <Row >  
    <Col xs="auto" style={{paddingRight:15, paddingLeft :15 }}>
        <a href='#' onClick={()=>{onLinkClick(prod)}} style={{ textDecoration: 'none' }}>
          <img src={prod.image} width="150px" height="100px" />
        </a>
        </Col>
          <Col>
          <a href='#' onClick={()=>{onLinkClick(prod)}} style={{ textDecoration: 'none' }}><div>
          <b><span style={{fontSize: "0.875em",}}>{prod.name }</span></b><br/>
          <span style={{fontSize: "0.875em"}}>{prod.description }</span><br/>          
          </div></a>
          <Button type="button" style={{width:"130px"}} onClick={() => onBtGoList(prod, category)} variant="outline-dark" size="sm">{category=="available"? prod.btnTitle :"이용승인요청"}</Button>
          </Col>       
      </Row>
  </div>
</div>
    );
  }
 const groupSeparator = (group, idx, groupLabel) => <div className="group-separator"><b><span style={{fontSize: "0.875em"}}>{groupLabel}</span></b></div>;

  return (
      <div>
       
    <FlatList
      list={products}
      renderItem={renderProd}
      renderWhenEmpty={() => <div><span style={{fontSize: "0.875em"}}>내역이 없습니다.</span></div>}
      filterBy={(item, index) => {
      
        if(category=="available" && item.views.includes(viewNo) ){
          return true
        }else if(category=="notyet" && !item.views.includes(viewNo)){
          return true
        }else{
          return false
        }

      }}
      /*
      filterBy={(item, index) => {
        //console.log(item.cateName); //|| category!=hideCate
        //console.log(hideCate); //|| category!=hideCate
        //console.log(category); //현재 선택된 카테고리          
        if(item.isSales!="사용안함"){

          if(category=="전체"){
            return true
          }else if(category=="my"){
            return item.puchasedCus[user?.bizName.value]
          }
          else{
            return item.cateName===category
          }
        }else{
          return false
        }
      

      }
      }*/
      //filterBy={item => item.type.includes('완')}
      //searchCaseInsensitive
      //searchTerm={searchTerm}
      //searchBy={["name","description"]}
      //searchMinCharactersCount={1}
      //groupOf={2}
      //groupSeparator={groupSeparator}
      /*
      group={{
        by: (item) => (category=="전체" || category=="my" ? item.cateName : ""),
        separator: groupSeparator,
      }}*/
    />
</div>
  );
}
//        <div className="floatRight">{prod.type}</div>
/*

 <div className="Post" key={idx}>
        <div>
        <b>{prod.name}</b><br/>
        {prod.description}
        </div>
        <div class="text-right mb-3">
      <a href="#" class="btn btn-primary">Right align</a>
    </div>
    </div>

*/
       