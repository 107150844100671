import React, { useState, useEffect }  from 'react';
import Header from "../components/admin/Header";
import Navbar from "../components/admin/Navbar";
import Navbar2 from "../components/admin/Navbar2";

import ListDiags, {SelectColumnFilter} from "../components/admin/ListDiags";
import firebase, {auth} from "../config/firebase";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";
import Navbarmenu from '../components/menu/Navbarmenu';
import Navbarmenu2 from '../components/menu/Navbarmenu2';
import '../styles/navbarmenu.css';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();




const DiagList = (props) => {
  const resultReqs = [];
  const [reqs, setReqs] = useState([]);
  const [email, setEmail] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  const getUserInfo = async() => {
     
    const getAllUsers = await db.collection("users")
    .where("userGroup", "==", 102)
    .get();
    const allUsers=[];
  
    getAllUsers.docs.map(user => { 
      allUsers.push({
        value : user.data().uid, 
       label : `${user.data().profile.name} (${user.data().profile.bizInfo.bizName}, ${user.data().email})`,
       name : user.data().profile.name,
       token : user.data().token,
       state : user.data().state,
       userType : user.data().userType,
       email : user.data().email,
       company : user.data().profile.bizInfo.bizName,
    })
  });
  
    setAllUsers(allUsers);
    //console.log("allUsers=>",allUsers);
  
  };
  const getEmail = async(uid) => {
    
    const users = await db.collection("users")
    .where("uid", "==", uid)
    .get();
    users.docs.map(user => {
      setEmail(user.data().email);
      //console.log("email=>",user.data().email)
    })
  };

  useEffect(() => {

    if(props.loginUserType==0){

    getUserInfo();
  db.collection("data")
    .where("type","==","diag")
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const req = doc.data();
        //console.log("cus_data=>", doc.data().chart_cus_data)
        getEmail(doc.data().createBy);
        //console.log("email=>",email);

        const data={
          id: doc.id,
          bizName : doc.data().bizName,
          itemName : doc.data().itemName,
          displayName : doc.data().displayName,
          cusData: doc.data().cusData,
          cusChartData: doc.data().cusChartData,
          cusChartLabel: doc.data().cusChartLabel,
          cusTitleLabel: doc.data().cusTitleLabel,
          cusQ1Sum:doc.data().cusQ1Sum,
          cusQ2Sum:doc.data().cusQ2Sum,
          cusQ3Sum:doc.data().cusQ3Sum,
          cusQ4Sum:doc.data().cusQ4Sum,
          cusQ5Sum:doc.data().cusQ5Sum,
          cusQ6: doc.data().cusQ6,
          cusQ7: doc.data().cusQ7,
          cusQ8: doc.data().cusQ8,
          cusTotalSum:doc.data().cusTotalSum,
          mdData:doc.data().mdData,
          mdChartData:doc.data().mdChartData,
          mdChartLabel:doc.data().mdChartLabel,
          mdTitleLabel:doc.data().mdTitleLabel,
          mdQ1Sum:doc.data().mdQ1Sum,
          mdQ2Sum:doc.data().mdQ2Sum,
          mdQ3Sum:doc.data().mdQ3Sum,
          mdQ4Sum:doc.data().mdQ4Sum,
          mdQ5Sum:doc.data().mdQ5Sum,
          mdTotalSum:doc.data().mdTotalSum,
          mdQ6: doc.data().mdQ6,
          mdQ7: doc.data().mdQ7,
          mdQ8: doc.data().mdQ8,
          isReplied: doc.data().isReplied,
          state : doc.data().state,
          stateName : doc.data().stateName,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          createBy: doc.data().createBy,
          repliedBy : doc.data().repliedBy,
          repliedAt : new Date(doc.data().repliedAt.seconds * 1000),
        };
        //console.log("req no=>",doc.id);
        //req.id = doc.id;
        resultReqs.push(data);
      });
      setReqs(resultReqs);
    });

    }else if(props.loginUserType==1){


      db.collection("data")
      .where("type","==","diag")
    .where("createBy", "==", props.loginUid)
    //.where("resUid", "array-contains", props.loginUid)
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const req = doc.data();
        //console.log("cus_data=>", doc.data().chart_cus_data)
        getEmail(doc.data().createBy);
        //console.log("email=>",email);

        const data={
          id: doc.id,
          bizName : doc.data().bizName,
          itemName : doc.data().itemName,
          displayName : doc.data().displayName,
          cusData: doc.data().cusData,
          cusChartData: doc.data().cusChartData,
          cusChartLabel: doc.data().cusChartLabel,
          cusTitleLabel: doc.data().cusTitleLabel,
          cusQ1Sum:doc.data().cusQ1Sum,
          cusQ2Sum:doc.data().cusQ2Sum,
          cusQ3Sum:doc.data().cusQ3Sum,
          cusQ4Sum:doc.data().cusQ4Sum,
          cusQ5Sum:doc.data().cusQ5Sum,
          cusQ6: doc.data().cusQ6,
          cusQ7: doc.data().cusQ7,
          cusQ8: doc.data().cusQ8,
          cusTotalSum:doc.data().cusTotalSum,
          mdData:doc.data().mdData,
          mdChartData:doc.data().mdChartData,
          mdChartLabel:doc.data().mdChartLabel,
          mdTitleLabel:doc.data().mdTitleLabel,
          mdQ1Sum:doc.data().mdQ1Sum,
          mdQ2Sum:doc.data().mdQ2Sum,
          mdQ3Sum:doc.data().mdQ3Sum,
          mdQ4Sum:doc.data().mdQ4Sum,
          mdQ5Sum:doc.data().mdQ5Sum,
          mdTotalSum:doc.data().mdTotalSum,
          mdQ6: doc.data().mdQ6,
          mdQ7: doc.data().mdQ7,
          mdQ8: doc.data().mdQ8,
          isReplied: doc.data().isReplied,
          state : doc.data().state,
          stateName : doc.data().stateName,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          createBy: doc.data().createBy,
          repliedBy : doc.data().repliedBy,
          repliedAt : new Date(doc.data().repliedAt.seconds * 1000),
        };
        //console.log("req no=>",doc.id);
        //req.id = doc.id;
        resultReqs.push(data);
      });
      setReqs(resultReqs);
    });

    }else{



      db.collection("data")
      .where("type","==","diag")
      //.where("repliedBy", "==", props.loginUid)
    //.where("resUid", "array-contains", props.loginUid)
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const req = doc.data();
        //console.log("cus_data=>", doc.data().chart_cus_data)
        getEmail(doc.data().createBy);
        //console.log("email=>",email);

        const data={
          id: doc.id,
          bizName : doc.data().bizName,
          itemName : doc.data().itemName,
          displayName : doc.data().displayName,
          cusData: doc.data().cusData,
          cusChartData: doc.data().cusChartData,
          cusChartLabel: doc.data().cusChartLabel,
          cusTitleLabel: doc.data().cusTitleLabel,
          cusQ1Sum:doc.data().cusQ1Sum,
          cusQ2Sum:doc.data().cusQ2Sum,
          cusQ3Sum:doc.data().cusQ3Sum,
          cusQ4Sum:doc.data().cusQ4Sum,
          cusQ5Sum:doc.data().cusQ5Sum,
          cusQ6: doc.data().cusQ6,
          cusQ7: doc.data().cusQ7,
          cusQ8: doc.data().cusQ8,
          cusTotalSum:doc.data().cusTotalSum,
          mdData:doc.data().mdData,
          mdChartData:doc.data().mdChartData,
          mdChartLabel:doc.data().mdChartLabel,
          mdTitleLabel:doc.data().mdTitleLabel,
          mdQ1Sum:doc.data().mdQ1Sum,
          mdQ2Sum:doc.data().mdQ2Sum,
          mdQ3Sum:doc.data().mdQ3Sum,
          mdQ4Sum:doc.data().mdQ4Sum,
          mdQ5Sum:doc.data().mdQ5Sum,
          mdTotalSum:doc.data().mdTotalSum,
          mdQ6: doc.data().mdQ6,
          mdQ7: doc.data().mdQ7,
          mdQ8: doc.data().mdQ8,
          isReplied: doc.data().isReplied,
          state : doc.data().state,
          stateName : doc.data().stateName,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          createBy: doc.data().createBy,
          repliedBy : doc.data().repliedBy,
          repliedAt : new Date(doc.data().repliedAt.seconds * 1000),
        };
        //console.log("req no=>",doc.id);
        //req.id = doc.id;
        resultReqs.push(data);
      });
      setReqs(resultReqs);
    });

    }

    //console.log(resultReqs);
 
  }, []);

    const columns = React.useMemo(
        () => [
          {
            Header: "번호",
            //accessor: d => d.id,
            //accessor: "prodName",
            Cell: ({row})  => row.id
          },
          {
            Header: "회사명",
            accessor: "bizName",
          },
          {
            Header: "구분",
            accessor: "displayName",
          },
          {
            Header: "희망분야",
            accessor: "cusQ7",
          },
          {
            Header: "코디선정분야",
            accessor: "mdQ7",
          },
          {
            Header: "상태",
            accessor: "stateName",
            Filter: SelectColumnFilter, 
            filter: 'includes', 
            show: window.innerWidth > 750 ? true : false
          },
          {
            Header: "자가점수",
            accessor: d => `${d.cusTotalSum.toFixed(0)}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "코디점수",
            accessor: d => `${d.mdTotalSum.toFixed(0)}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "자가진단일",
            accessor: d => `${d.createAt.getFullYear()}.${d.createAt.getMonth()+1}.${d.createAt.getDate()}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "코디진단일",
            accessor: d => `${d.repliedAt.getFullYear()}.${d.repliedAt.getMonth()+1}.${d.repliedAt.getDate()}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "상세보기",
            accessor: d => <Link to={{ pathname:`/diagList/${d.id}`, 
            state:{
              prodName : d.prodName,

              bizName : d.bizName,
              itemName : d.itemName,
              cusData: d.cusData,
              cusChartData: d.cusChartData,
              cusChartLabel: d.cusChartLabel,
              cusTitleLabel: d.cusTitleLabel,
              cusQ1Sum:d.cusQ1Sum,
              cusQ2Sum:d.cusQ2Sum,
              cusQ3Sum:d.cusQ3Sum,
              cusQ4Sum:d.cusQ4Sum,
              cusQ5Sum:d.cusQ5Sum,
              cusQ6: d.cusQ6,
              cusQ7: d.cusQ7,
              cusQ8: d.cusQ8,
              cusTotalSum:d.cusTotalSum,
              mdData:d.mdData,
              mdChartData:d.mdChartData,
              mdChartLabel:d.mdChartLabel,
              mdTitleLabel:d.mdTitleLabel,
              mdQ1Sum:d.mdQ1Sum,
              mdQ2Sum:d.mdQ2Sum,
              mdQ3Sum:d.mdQ3Sum,
              mdQ4Sum:d.mdQ4Sum,
              mdQ5Sum:d.mdQ5Sum,
              mdTotalSum:d.mdTotalSum,
              mdQ6: d.mdQ6,
              mdQ7: d.mdQ7,
              mdQ8: d.mdQ8,
              isReplied: d.isReplied,
              state : d.state,
              stateName : d.stateName,
              createAt: d.createAt,
              createBy: d.createBy,
              repliedBy : d.repliedBy,
              repliedAt : d.repliedAt,
              
            }}}>상세보기</Link>
            //accessor: d => <Link to={`/reqs/${d.id}`}>{d.prodName}</Link>,
            //accessor: d => <a href={`https://connectmd12.web.app/req/${d.id}`}>{d.id}</a>,
            //accessor: "prodName",
            //Cell: (props)  => <a href={`http://www.${props.prodName}!`}>{props.prodName}</a>
          },



          
               
        ],[]);

    //const data = React.useMemo(() => getData(), []);
    
    return (

      <div>
      { props.loginUserType == 0 ?
      ( <Navbarmenu />  ) :
      ( <Navbarmenu2 loginUserGroup={props.loginUserGroup} /> ) 
      } 
      <div tw="min-h-screen bg-gray-100 text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">

     
      <div tw="">
        <h1 tw="text-xl font-semibold">창업역량진단리스트</h1>
        </div>
        <div tw="mt-4">
        <ListDiags 
          columns={columns} 
          data={reqs}
          
        
        />
      </div>
      </main>
    </div>
    </div>
);
}

export default DiagList;

/*


        const userEmail = [];

        const user= await db.collection("users")
        .where("uid", "==", doc.data().createBy)
        .get()
        .then((user) => {


          user.forEach((u)=>{
            //console.log(u.id, "=>", u.data());
            //console.log(u.id, "=>", u.data().email);

            userEmail.push(u.data().email);

          })

          console.log("email=>",userEmail[0]);
          data.email=userEmail[0];
          //const mail = doc.data().email;
          //userEmail.push(mail);
          //console.log("email=>", mail)
        });

*/