import React from 'react';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from './NavbarElements';
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo.png";

import {
  Link,
} from "react-router-dom";


const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;
  
const Navbar2 = (props) => {
  //const { userInfo } = props;
  return (
    <>
      <Nav>
        <Bars />
        <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Dr.MD</LogoText>            
          </LogoContainer>
  
        <NavMenu>
        
          <NavLink to={{ pathname:`/dashboard` 
            /*state: { 
              loginEmail: userInfo.loginEmail,
              loginUid : userInfo.loginUid,
              loginUserName : userInfo.loginUserName,
              loginUserType : userInfo.loginUserType,
              loginUserState : userInfo.loginUserState
            }*/
          }} activeStyle>
            진단리스트
          </NavLink>
          {props.loginUserGroup == 102 && 
          (
          <NavLink to={{ pathname:`/mentorings`}} activeStyle>
            방송분석
          </NavLink>
          )}
          {/*       
          <NavLink to={{ pathname:`/notices` }} activeStyle>
            공지사항
          </NavLink>
          */}
                    
          {/*<NavLink to={{ pathname:`/notices`, 
            state:userInfo}} activeStyle>
            공지사항
          </NavLink>
          */}
          {/* Second Nav */}
          {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
        </NavMenu>
      </Nav>
    </>
  );
};
  
export default Navbar2;

/*
<NavLink to='/resps' activeStyle>
            최신 전문가진단 내역
          </NavLink>
          
         
*/

//{userInfo.email}

/*

<NavLink to={{ pathname:`/status`}} activeStyle>
            진행현황
          </NavLink>

        */
