import React, { useState, useEffect }  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import firebase, {auth} from "../config/firebase";
import Header from "../components/admin/Header";
import { useTable } from 'react-table'
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import Navbar from "../components/admin/Navbar";
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 500px;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
min-width: 170px;
padding: 12px 15px;
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();


const StateList = (props,{location, history},) =>{
/*const Notice = ({location:{state}, history}) =>{*/
  const user = auth.currentUser;
  const { userInfo } = props;
  const [allReqs, setAllReqs] = useState([]);

  const getReqs = async() => {
    
    const getAllReqs = await db.collection("reqs_min")
    .orderBy("createAt", "desc")
    .get();
    const allReqs=[];
  
    getAllReqs.docs.map(req => { 
      allReqs.push({
        reqId: req.id,
        reqCompany : req.data().reqCompany,
        reqName : req.data().reqName,
        reqEmail : req.data().reqEmail,
        selfCheck : req.data().chart_cus_data.length===0 ? "X" : "O",
        mdCheck : req.data().currResCount==0 ? "X" : "O",
        chart_cus_data : req.data().chart_cus_data

        //qDocCheck : req.data().qDocName.length===0 ? "서류미보유" : "",
      // label : `${user.data().profile.name} (${user.data().userType==1 ? "중소기업" : "전문가"})`,
    })
  });
  
  setAllReqs(allReqs);


  
  };
  useEffect(() => {
    //console.log("allUsers=>", allUsers)
  
    getReqs();

  }, []);

/*
  const {
    title,
    body,
    name,
    uid,
    email,
    userType,
    userState,
    stateName,
    createAt,
    files,
    sendTo
} = state; */

  const handleOnDel = (e)=>{
    const message="정말로 삭제하시겠습니까?"
    if (window.confirm(message)) {
      //onDel();
      history.push({
        pathname: '/noticeDel',
        //search: '?query=abc',
        state: { 
        aa :"aa"
        }        
    });

    }else{
      e.preventDefault();
    }
  }

/*
    const getEmail = async(cus, md) => {
    
      const cusUsers = await db.collection("users")
      .where('uid', 'in', [cus, md])
      //.where("uid", "==", cus)
      //.where("uid", "==", md)
      .get();
      cusUsers.docs.map(user => {
        //console.log("uid=>", user.data().uid);
        if(user.data().uid == cus){
          setCusEmail(user.data().email);
          setCusName(user.data().profile.name);
          setBizName(user.data().profile.bizInfo.bizName);
        }else{
          setMdEmail(user.data().email);
          setMdName(user.data().profile.name);

        }
      })
    
    };

    useEffect(() => {

      //getEmail(createBy, sendTo);

             
      }, []);

*/
  return(
    <div>
      <Navbar userInfo={userInfo} />

    <div tw="min-h-screen text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
      

    <div>
      <h1 tw="text-xl font-semibold">진행현황</h1>
      <StyledTable>
      <StyledTBodyTr>
          <StyledTd>uid</StyledTd>
          <StyledTd>업체명</StyledTd>
          <StyledTd>담당자명</StyledTd>
          <StyledTd>이메일</StyledTd>
          <StyledTd>자가진단</StyledTd>
          <StyledTd>전문가진단</StyledTd>
          <StyledTd>자가점수</StyledTd>
          <StyledTd>비고</StyledTd>
          


        </StyledTBodyTr>


        {allReqs.map(row => {
       return (
         <StyledTBodyTr>
           <StyledTd>{row.reqId}</StyledTd>
           <StyledTd>{row.reqCompany}</StyledTd>
           <StyledTd>{row.reqName}</StyledTd>
           <StyledTd>{row.reqEmail}</StyledTd>
           <StyledTd>{row.selfCheck}</StyledTd>
           <StyledTd>{row.mdCheck}</StyledTd>
           <StyledTd>{row.chart_cus_data}</StyledTd>

           <StyledTd>

             {
               row.selfCheck =="O" && row.mdCheck =="O" && 
             
           <Link to={{ pathname:"/noticeEdit",
      state : {
        aa:"aa"
      }}}>
      <PrimaryButton buttonRounded={true} as="a">보고서완료</PrimaryButton>
      </Link>
        }
           </StyledTd>

         </StyledTBodyTr>
       )
     })}

      </StyledTable>    
    </div>
    
    </div>
      </main>
    </div>
    </div>
  );
}
export default StateList;

/*
          
  <h3>{chart_cus_data}</h3>
      <h3>{chart_cus_label}</h3>

      <Link to={{ pathname:"/noticeDel",
      state : {
        noticeId: noticeId,
      }}}>
      <PrimaryButton buttonRounded={true} as="a">삭제</PrimaryButton>
      </Link>

*/