import React, {useRef, useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import queryString from "query-string";
import firebase, {auth} from "../../config/firebase";


import * as Survey from "survey-react";
import "survey-react/survey.css";
import {StylesManager } from "survey-react";

StylesManager.applyTheme("winterstone");

/*
import 'survey-react/modern.min.css';
// import 'survey-react/survey.min.css';
import { Survey, StylesManager, Model } from "survey-react";
StylesManager.applyTheme("modern");
*/


const db=firebase.firestore();


/*
사전진단 자가진단 : req
방송분석 자가진단 : men
창업역량 자가진단 : diag
QA멘토링 : qaMen
*/

//const Survey2 = ({location:{state}, history}) =>{
const DataAdd = ({ match, location }) => {
  const query = queryString.parse(location.search);
  const [mode, setMode] = useState(query.mode);
  const [type, setType] = useState({});
  console.log("mode->>",mode);

  useEffect(() => {

    switch (mode) {
      case "qaMen": setType(qaMen); break; 
      case "req": setType(req); break; 
      case "reqbiz": setType(reqbiz); break; 
      case "reqReply": setType(reqReply); break; 
      case "reqSurvey": setType(reqSurvey); break;
      case "qa": setType(qa); break; 
      case "qaReply": setType(qaReply); break; 
      case "qaSurvey": setType(qaSurvey); break;    
      case "men": setType(men); break;
      case "menReply": setType(menReply); break; 
      case "menSurvey": setType(menSurvey); break; 
      case "diag": setType(diag); break;
      case "prev": setType(prevDiag); break;


     // case "diagReply": setType(json4); break;
      default: setType(req);
    }
    
  }, []);
//홈인테리어, 의자->식품으로 내용 변경
  //홈쇼핑 QA 멘토링 진단
  const qaMen={
    title: "QA멘토링",
    description: "TV홈쇼핑 방송 진행 전에 상품의 품질보증을 위해 현장에서 서류 검토, 상품의 품질보증 및 평가, 보완사항 등을 종합적으로 확인하기 위한 체크리스트입니다.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    showQuestionNumbers: "off",
    elements: [
      { name: "bizName", type: "text", title: "회사명", placeHolder: "(예시: (주)회사)", isRequired: true },
      { name: "reqName", type: "text", title: "담당자명", placeHolder: "(예시: 홍길동)", isRequired: true },
      { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
      { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/잡화","식품","이미용","패션","가전","홈인테리어","유아동","건강용품","보석"]},
      { name: "subCateName", type: "dropdown", title: "세부카테고리", visibleIf: "{cateName}!=null", isRequired: true, choices: [
        //{ text: "", value: "", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        { text: "선반", value: "선반", visibleIf: "{cateName} notempty and {cateName}=='생활/잡화'"},
        { text: "헬스기구", value: "헬스기구", visibleIf: "{cateName} notempty and {cateName}=='생활/잡화'"},
        { text: "주방세제", value: "주방세제", visibleIf: "{cateName} notempty and {cateName}=='생활/잡화'"},
        { text: "화장지", value: "주방세제", visibleIf: "{cateName} notempty and {cateName}=='생활/잡화'"},
        { text: "합성세제/섬유유연제/방향제/탈취제", value: "합성세제/섬유유연제/방향제/탈취제", visibleIf: "{cateName} notempty and {cateName}=='생활/잡화'"},
        
        { text: "농산물", value: "농산물", visibleIf: "{cateName} notempty and {cateName}=='식품'"},
        { text: "축산물", value: "축산물", visibleIf: "{cateName} notempty and {cateName}=='식품'"},
        { text: "수산물", value: "수산물", visibleIf: "{cateName} notempty and {cateName}=='식품'"},
        { text: "가공식품", value: "가공식품", visibleIf: "{cateName} notempty and {cateName}=='식품'"},
        { text: "건강기능식품", value: "건강기능식품", visibleIf: "{cateName} notempty and {cateName}=='식품'"},

        { text: "책상및테이블", value: "책상및테이블", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        { text: "의자", value: "의자", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        { text: "수납가구", value: "수납가구", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        { text: "침대", value: "침대", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        { text: "씽크대", value: "씽크대", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        { text: "소파", value: "sofa", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        { text: "캐비닛", value: "캐비닛", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        { text: "주방", value: "주방", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
        
      ]},
      {
        //type: "matrix", name: "d", title: "1.서류심사", visibleIf: "{cateName} contains '홈인테리어' and {subCateName} notempty", /*isRequired: true, */ columns: [{value: 1,text: "적합"},{value: 2,text: "부적합"}],
        type: "matrix", name: "d", title: "1.서류심사", visibleIf: "{cateName} notempty and {subCateName} notempty", /*isRequired: true, */ columns: [{value: 1,text: "적합"},{value: 2,text: "부적합"}],
        rows: [ 
          {value: "q1",text: "* 원산지증명서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '농산물'"},
          {value: "q2",text: "친환경인증서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '농산물'"},
          {value: "q3",text: "검사성적서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '농산물'"},
          {value: "q4",text: "전통식품인증서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '농산물'"},
          {value: "q5",text: "지리적표시인증서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '농산물'"}, 
          {value: "q6",text: "GAP인증서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '농산물'"}, 
          {value: "q7",text: "수입신고서류(필증, 확인증)", visibleIf: "{cateName} contains '식품' and {subCateName} contains '농산물'"}, 

          {value: "q1",text: "* 품목제조보고서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '가공식품'"},
          {value: "q2",text: "* 자가품질검사성적서(의무)", visibleIf: "{cateName} contains '식품' and {subCateName} contains '가공식품'"},
          {value: "q3",text: "* HACCP인증서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '가공식품'"},
          {value: "q4",text: "(어린이기호식품)품질인증서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '가공식품'"},
          {value: "q5",text: "검사성적서", visibleIf: "{cateName} contains '식품' and {subCateName} contains '가공식품'"}, 
          {value: "q6",text: "수입신고서류(필증, 확인증)", visibleIf: "{cateName} contains '식품' and {subCateName} contains '가공식품'"}, 
          
          {value: "q1",text: "* 안전기준 성적서 : 전기용품 및 생활용품 안전관리법 – 해당품목 안전기준 적용", visibleIf: "{cateName} contains '홈인테리어' and {subCateName} notempty"},
          {value: "q2",text: "* 품목별 시험 성적서 : 재질분석, 원단시험성적서 등", visibleIf: "{cateName} contains '홈인테리어' and {subCateName} notempty"},
          {value: "q3",text: "* 샘플 : 품질확인용(최종포장 상태)", visibleIf: "{cateName} contains '홈인테리어' and {subCateName} notempty"},
          {value: "q4",text: "* 표시사항 : 법적 필수 표시기재 확인", visibleIf: "{cateName} contains '홈인테리어' and {subCateName} notempty"},
          {value: "q5",text: "기타표현입증 : 표현을 원하는 모든 문구 사전 확인 및 입증자료", visibleIf: "{cateName} contains '홈인테리어' and {subCateName} notempty"}, 
        ]
      },
      //{ type: "comment", name: "dc", title: "부적합한 항목에 대해 조치사항(또는 사유)를 입력해주세요.", visibleIf: "{d.rows} contains 1 "},
      { type: "comment", name: "c", title: "부적합한 항목에 대해 조치사항(또는 사유)를 입력해주세요.", visibleIf: "({d.q1}!=null and {d.q1}!=1) or ({d.q2}!=null and {d.q2}!=1) or ({d.q3}!=null and {d.q3}!=1) or ({d.q4}!=null and {d.q4}!=1) or ({d.q5}!=null and {d.q5}!=1)" },

      //{ type : "html", name: "info", html: "<b>2.샘플검수</b>", visibleIf: "{cateName} notempty and {subCateName} notempty"}, 
      //{ type: "checkbox", name: "slCate", title: "2.1 관계법령에서 검토할 항목을 다중 선택해주세요.", visibleIf: "{cateName} notempty and {subCateName} notempty", hasNone: false, colCount: 3,
      
      { type : "html", name: "info", html: "<b>2.샘플검수</b>", visibleIf: "{cateName} notempty and {subCateName} notempty"}, 
      { type: "checkbox", name: "slCate", title: "2.1 포장규정 및 배송테스트에서 검토할 항목을 다중 선택해주세요.", visibleIf: "{cateName} notempty and {subCateName} notempty", hasNone: false, colCount: 3,
        choices: [
          //{ text: "", value: "", visibleIf: "{cateName} notempty and {cateName}==''"},
          { text: "목재", value: "tre", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "가죽", value: "leat", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "매트리스", value: "mat", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "카페트", value: "carp", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "커버/시트", value: "sheet", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"}, 
          
          { text: "외관", value: "f1", visibleIf: "{cateName} notempty and {cateName}=='식품' and {subCateName} notempty"},
          { text: "포장", value: "f2", visibleIf: "{cateName} notempty and {cateName}=='식품' and {subCateName} notempty"},
          { text: "배송", value: "f3", visibleIf: "{cateName} notempty and {cateName}=='식품' and {subCateName} notempty"},
        ]
      },
      {
        type: "matrix", name: "sl", title: "포장규정 및 배송테스트", visibleIf: "{slCate} notempty", /*isRequired: true, */ columns: [{value: 1,text: "적합"},{value: 2,text: "부적합"}],
        rows: [ 
          {value: "f31",text: "* [외관] 마무리는 양호하며, 손 등에 장해를 주는 잡티, 젖혀지거나 Sharp-Edge 등이 이상없나요?", visibleIf: "{slCate} contains 'f1'"},
          {value: "f32",text: "* [외관] 제품과 포장 내·외면에는 이물의 혼입, 기포, 변형, 얼룩 등 변색이 없어야 합니다.", visibleIf: "{slCate} contains 'f1'"},
          {value: "f33",text: "* [외관] 상품 품질이 신선도가 떨어져서는 안됩니다.", visibleIf: "{slCate} contains 'f1'"},
          {value: "f34",text: "* [외관] 기타 상품가치를 저하시키는 결점이 없어야 합니다.", visibleIf: "{slCate} contains 'f1'"},
          {value: "f35",text: "* [포장] 내용품이 오염 파손되지 않도록 유지되고 있어야 합니다.", visibleIf: "{slCate} contains 'f2'"},
          {value: "f36",text: "* [포장] 재질은 화학적 · 물리적으로 안전해야 합니다.", visibleIf: "{slCate} contains 'f2'"},
          {value: "f37",text: "* [포장] 과대 · 과잉 포장이 없어야 합니다.", visibleIf: "{slCate} contains 'f2'"},
          {value: "f38",text: "* [포장] 자원절약 · 폐기물처리상 적정해야 합니다.", visibleIf: "{slCate} contains 'f2'"},
          {value: "f39",text: "* [포장] 겉포장, 낱개포장에는, 얼룩, 오염, 각(모서리)떨어짐, 기스(상처) 등 이상이 없어, 사람이나 사물에 대해서 안전해야 합니다.", visibleIf: "{slCate} contains 'f2'"},
          {value: "f310",text: "* [포장] 포장으로 인한 상품 품질에 떨어져서는 안됩니다.", visibleIf: "{slCate} contains 'f2'"},
          {value: "f311",text: "* [포장] 구성품의 누락 여부", visibleIf: "{slCate} contains 'f2'"},                              
          {value: "f312",text: "* [포장] 보냉박스 외관 상태 확인(파손, 테이핑 상태 등)", visibleIf: "{slCate} contains 'f2'"},
          {value: "f313",text: "* [포장] 부직포 포장된 드라이아이스를  사용하나요?", visibleIf: "{slCate} contains 'f2'"},
          {value: "f314",text: "* [포장] 드라이아이스는 32P/30kg, 950g 이상을 사용하나요?", visibleIf: "{slCate} contains 'f2'"},
          {value: "f315",text: "* [포장] 드라이아이스는 화상주의 문구가 삽입되어 있나요?", visibleIf: "{slCate} contains 'f2'"},
          {value: "f316",text: "* [배송] 1.2m , 총 6회(꼭지점 1, 모서리 2, 면 3회) 자유낙하 시 파손이 없을 것", visibleIf: "{slCate} contains 'f3'"},
          {value: "f317",text: "* [배송] 제품 표면 온도 확인(탐침/표면온도계 활용)", visibleIf: "{slCate} contains 'f3'"},
          {value: "f318",text: "* [배송] 보냉제 사용기준 준수여부 확인", visibleIf: "{slCate} contains 'f3'"},
          {value: "f319",text: "* [배송] 권장 기준온도 준수확인(냉동 -1°C 이하)", visibleIf: "{slCate} contains 'f3'"},
          {value: "f320",text: "* [배송] 해동 및 상품 변질 확인", visibleIf: "{slCate} contains 'f3'"},                    
          //{value: "q1",text: "[목재] 폼알데하이드(데시케이터법) : 1.5 mg/L 이하", visibleIf: "{slCate} contains '목재'"},
          {value: "tre1",text: "* [목재] 폼알데하이드(데시케이터법) : 1.5 mg/L 이하", visibleIf: "{slCate} contains 'tre'"},
          {value: "tre2",text: "* [목재] 폼알데하이드 방출량(소형챔버법) : 0.12mg/m2h 이하", visibleIf: "{slCate} contains 'tre'"},
          {value: "tre3",text: "* [목재] 톨루엔 방출량(소형챔버법) : 0.08mg/m2h 이하", visibleIf: "{slCate} contains 'tre'"},
          {value: "tre4",text: "* [목재] 총휘발성 유기화합물방출량 TVOC(소형챔버법) : 4 mg/m2h 이하", visibleIf: "{slCate} contains 'tre'"},
          {value: "leat1",text: "* [가죽] 폼알데하이드 (칼라별) : 300 mg/kg 이하", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat2",text: "* [가죽] 염소화페놀류(PCP) (합성가죽은 생략 가능) : 5.0 mg/kg 이하", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat3",text: "* [가죽] 6가 크로뮴 (합성가죽은 생략 가능) : 3.0 mg/kg 이하", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat4",text: "* [가죽] 다이메틸푸마레이트 (합성가죽은 생략 가능) : 0.1 mg/kg 이하", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat5",text: "* [가죽] 아릴아민 (염색 제품) : 30 mg/kg 이하", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat6",text: "* [가죽] 유기주석화합물-TBT (코팅, 프린팅(안료, 수지) ※ 나염 제외) : 1.0 mg/kg 이하", visibleIf: "{slCate} contains 'leat'"},
          {value: "mat1",text: "* [매트리스] 폼알데하이드 (표면원단, 소재/칼라별) : 75 mg/kg 이하", visibleIf: "{slCate} contains 'mat'"},
          {value: "mat2",text: "* [매트리스] 가연성 (발화원: 타 들어가는 담배) : 연소속도 (12.0 ±3.0 min/50 mm) , 발화되어서는 안됨", visibleIf: "{slCate} contains 'mat'"},
          {value: "mat3",text: "* [매트리스] 마찰견뢰도 (표면 원단) : 건식 : 4급 이상, 습식 : 3급 이상", visibleIf: "{slCate} contains 'mat'"},
          {value: "mat4",text: "[매트리스] 인장강도 (직물에 한함) : 295 N", visibleIf: "{slCate} contains 'mat'"},
          {value: "mat5",text: "[매트리스] 내구성 (스프링 매트리스) : 간격의 양 d1, d2, d3 모두 40mm 이하", visibleIf: "{slCate} contains 'mat'"},
          {value: "mat6",text: "[매트리스] 내구성 (스프링 매트리스) : 겉감의 닳음, 풀림, 찢어짐 등이 없을 것", visibleIf: "{slCate} contains 'mat'"},
          {value: "mat7",text: "[매트리스] 내구성 (스프링 매트리스) : 내용물의 이동, 뭉침, 빠짐 등이 없을 것", visibleIf: "{slCate} contains 'mat'"},
          {value: "mat8",text: "[매트리스] 내구성 (스프링 매트리스) : 용수철의 끊어짐, 빠짐 등이 없을 것", visibleIf: "{slCate} contains 'mat'"},
          {value: "mat9",text: "[매트리스] 수직하중 (스프링 매트리스) : 휨의 증가량 D1 ≤ 45 mm, D2 ≥ 5 mm", visibleIf: "{slCate} contains 'mat'"},
          {value: "carp1",text: "* [카페트] 폼알데하이드 : 300 mg/kg 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp2",text: "* [카페트] 염소화페놀류 (PCP) : 5 mg/kg 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp3",text: "[카페트]  (TeCP) : 5 mg/kg 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp4",text: "* [카페트] 아릴아민 () : 각각 30 mg/kg 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp5",text: "* [카페트] 마찰대전성 () : 2,000 V 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp6",text: "[카페트] 델드린 () : 30 mg/kg 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp7",text: "[카페트] 유기주석화합물 – TBT () : 1.0 mg/kg 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp8",text: "[카페트] 방염제 () : 사용하지 말 것", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp9",text: "[카페트] 방염성 () : 탄화길이 10cm 이하, 잔염시간 20s 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp10",text: "[카페트] 파일사 인발강도 (월턴) : 0.450 kgf 이상", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp11",text: "[카페트]  (터프티드) : 5/32 게이지 이상 : 2.5 kgf 이상, 1/8 게이지 :  2 kgf 이상, 1/10 게이지 : 1.5 kgf 이상, 5/64 게이지 이하 : 1kgf 이상", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp12",text: "[카페트]  (기타제품) : 2.0 kgf 이상", visibleIf: "{slCate} contains 'carp'"},
          {value: "sheet1",text: "* [커버/시트] PH (원단 소재, 칼라별) : 4.0 ~ 9.0", visibleIf: "{slCate} contains 'sheet'"},
          {value: "sheet2",text: "* [커버/시트] 폼알데하이드 (원단 소재, 칼라별) : 300 mg/kg이하", visibleIf: "{slCate} contains 'sheet'"},
          {value: "sheet3",text: "* [커버/시트] 아릴아민 (염색된 섬유제품) : 30 mg/kg이하", visibleIf: "{slCate} contains 'sheet'"},
          {value: "sheet4",text: "[커버/시트] 알러지성 염료 (알러지 염료로 가공) : 사용금지", visibleIf: "{slCate} contains 'sheet'"},
          {value: "sheet5",text: "[커버/시트] 유기주석 화합물 – TBT (코팅, 프린팅(안료, 수지) ※ 나염 제외) : 1.0 mg/kg이하", visibleIf: "{slCate} contains 'sheet'"},
          {value: "sheet6",text: "[커버/시트] 다이메틸푸마레이트 (피혁 및 모피소재로 되어있는 부분) : 0.1 mg/kg이하", visibleIf: "{slCate} contains 'sheet'"},
          {value: "sheet7",text: "[커버/시트] 방염제 (방염처리 된 제품) : 사용하지 말 것", visibleIf: "{slCate} contains 'sheet'"},
          {value: "sheet8",text: "[커버/시트] 니켈 용출량 (Ni) (지속적으로 접촉하는 금속,전자파 차단 제품) : 0.5 ㎍/㎠/week 이하", visibleIf: "{slCate} contains 'sheet'"},
          {value: "tre5",text: "* [원목] 수종감별서 (천연목(원목)) : 해당 원목", visibleIf: "{slCate} contains 'tre'"},
          {value: "leat1",text: "* [섬유/가죽] 섬유 혼용률 (섬유 소재/칼라별) : ±4% ※ 5의 정수배 ±5%, 100% 표기 시 -1%", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat2",text: "* [섬유/가죽] 마찰견뢰도 (섬유, 가죽 소재/칼라별) : 건식 : 4급 이상, 습식 : 3급 이상", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat3",text: "* [섬유/가죽] 땀 견뢰도 (섬유 소재/칼라별) : 3급 이상", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat4",text: "* [섬유/가죽] 가죽감별 (천연가죽, 칼라별) : 해당 가죽", visibleIf: "{slCate} contains 'leat'"},
          {value: "leat5",text: "* [섬유/가죽] 피막두께 (천연가죽, 칼라별) : 코팅 두께 0.15 mm 미만", visibleIf: "{slCate} contains 'leat'"},
          {value: "fill1",text: "* [충전재] 재질분석 및 구성비 (우레탄 폼) : 표시 기준", visibleIf: "{slCate} contains 'fill'"},
          {value: "fill2",text: "* [충전재] 재질분석 및 구성비 (라텍스) : 표시 기준", visibleIf: "{slCate} contains 'fill'"},
          {value: "fill3",text: "* [충전재] 아닐린 (라텍스) : 불검출", visibleIf: "{slCate} contains 'fill'"},
          {value: "fill4",text: "* [충전재] 조성혼합률 (우모 충전재) : KS K 2620 준용", visibleIf: "{slCate} contains 'fill'"},
          {value: "fill5",text: "* [충전재] 우모혼합률 (거위털 충전재) : 거위털 80 %이상", visibleIf: "{slCate} contains 'fill'"},
          {value: "fill6",text: "* [충전재] 우모합섬혼합률 (우모/합섬 혼합 충전재) : 표시 기준", visibleIf: "{slCate} contains 'fill'"},
          {value: "etc1",text: "* [기타] 라돈 방출량 (※ 방사능 방출 원료 사용 금지) : 148 Bq/m3 실내공기질 관리법 권고 기준", visibleIf: "{slCate} contains 'etc'"},
          {value: "carp1",text: "* [카페트] 혼용율 (5의 정수배로 표기 시, ±5% 단, 100% 표기는 -1% 허용) : ±4%", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp2",text: "* [카페트] 세탁견뢰도 드라이클리닝견뢰도 (세탁 상품드라이클리닝 상품) : 4급 이상", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp3",text: "* [카페트] 치수변화율 (세탁 상품드라이클리닝 상품) : 세탁:직물±3%, 편물±5%, 드라이:직물±2%, 편물±3%", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp4",text: "* [카페트] 일광견뢰도 (밝은 색상 필수(화이트, 아이보리 색상 계열)) : 3급 이상", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp5",text: "* [카페트] 마찰견뢰도 () : 건식 : 4급 이상, 습식 : 3급 이상", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp6",text: "[카페트] 땀견뢰도 (-) : 3급 이상", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp7",text: "[카페트] 섬도 (극세사 상품 세번수 상품(60수 등)) : 1 denier 이하 표시기준", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp8",text: "[카페트] 건조속도 (속건기능 상품) : 350분 이하", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp9",text: "[카페트] 흡수속도 (흡수기능 상품) : 1초 이내  70 mm 이상", visibleIf: "{slCate} contains 'carp'"},
          {value: "carp10",text: "[카페트] 항균도 (항균가공 상품) : 정균감소율 95 %이상", visibleIf: "{slCate} contains 'carp'"},
        ]
      },


      { type: "checkbox", name: "seCate", title: "2.2 기타테스트에서 검토할 항목을 다중 선택해주세요.", visibleIf: "{cateName} notempty and {subCateName} notempty", hasNone: false, colCount: 3,
        choices: [
          //{ text: "", value: "", visibleIf: "{cateName} notempty and {cateName}==''"},
          { text: "원목", value: "tre", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "섬유/가죽", value: "leat", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "충전재", value: "fill", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "기타", value: "etc", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "카페트", value: "carp", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},          
        ]
      },
      {
        type: "matrix", name: "se", title: "기타테스트", visibleIf: "{seCate} notempty", /*isRequired: true, */ columns: [{value: 1,text: "적합"},{value: 2,text: "부적합"}],
        rows: [ 
          {value: "tre1",text: "* [원목] 수종감별서 (천연목(원목)) : 해당 원목", visibleIf: "{seCate} contains 'tre'"},
          {value: "leat1",text: "* [섬유/가죽] 섬유 혼용률 (섬유 소재/칼라별) : ±4% ※ 5의 정수배 ±5%, 100% 표기 시 -1%", visibleIf: "{seCate} contains 'leat'"},
          {value: "leat2",text: "* [섬유/가죽] 마찰견뢰도 (섬유, 가죽 소재/칼라별) : 건식 : 4급 이상, 습식 : 3급 이상", visibleIf: "{seCate} contains 'leat'"},
          {value: "leat3",text: "* [섬유/가죽] 땀 견뢰도 (섬유 소재/칼라별) : 3급 이상", visibleIf: "{seCate} contains 'leat'"},
          {value: "leat4",text: "* [섬유/가죽] 가죽감별 (천연가죽, 칼라별) : 해당 가죽", visibleIf: "{seCate} contains 'leat'"},
          {value: "leat5",text: "* [섬유/가죽] 피막두께 (천연가죽, 칼라별) : 코팅 두께 0.15 mm 미만", visibleIf: "{seCate} contains 'leat'"},
          {value: "fill1",text: "* [충전재] 재질분석 및 구성비 (우레탄 폼) : 표시 기준", visibleIf: "{seCate} contains 'fill'"},
          {value: "fill2",text: "* [충전재] 재질분석 및 구성비 (라텍스) : 표시 기준", visibleIf: "{seCate} contains 'fill'"},
          {value: "fill3",text: "* [충전재] 아닐린 (라텍스) : 불검출", visibleIf: "{seCate} contains 'fill'"},
          {value: "fill4",text: "* [충전재] 조성혼합률 (우모 충전재) : KS K 2620 준용", visibleIf: "{seCate} contains 'fill'"},
          {value: "fill5",text: "* [충전재] 우모혼합률 (거위털 충전재) : 거위털 80 %이상", visibleIf: "{seCate} contains 'fill'"},
          {value: "fill6",text: "* [충전재] 우모합섬혼합률 (우모/합섬 혼합 충전재) : 표시 기준", visibleIf: "{seCate} contains 'fill'"},
          {value: "etc1",text: "* [기타] 라돈 방출량 (※ 방사능 방출 원료 사용 금지) : 148 Bq/m3 실내공기질 관리법 권고 기준", visibleIf: "{seCate} contains 'etc'"},
          {value: "carp1",text: "* [카페트] 혼용율 (5의 정수배로 표기 시, ±5% 단, 100% 표기는 -1% 허용) : ±4%", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp2",text: "* [카페트] 세탁견뢰도 드라이클리닝견뢰도 (세탁 상품드라이클리닝 상품) : 4급 이상", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp3",text: "* [카페트] 치수변화율 (세탁 상품드라이클리닝 상품) : 세탁:직물±3%, 편물±5%, 드라이:직물±2%, 편물±3%", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp4",text: "* [카페트] 일광견뢰도 (밝은 색상 필수 (화이트, 아이보리 색상 계열)) : 3급 이상", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp5",text: "* [카페트] 마찰견뢰도 () : 건식 : 4급 이상, 습식 : 3급 이상", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp6",text: "[카페트] 땀견뢰도 (-) : 3급 이상", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp7",text: "[카페트] 섬도 (극세사 상품 세번수 상품(60수 등)) : 1 denier 이하 표시기준", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp8",text: "[카페트] 건조속도 (속건기능 상품) : 350분 이하", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp9",text: "[카페트] 흡수속도 (흡수기능 상품) : 1초 이내  70 mm 이상", visibleIf: "{seCate} contains 'carp'"},
          {value: "carp10",text: "[카페트] 항균도 (항균가공 상품) : 정균감소율 95 %이상", visibleIf: "{seCate} contains 'carp'"},
          
        ]
      },
      { type: "checkbox", name: "mkCate", title: "3.표시검수에서 검토할 항목을 다중 선택해주세요.", visibleIf: "{cateName} notempty and {subCateName} notempty", hasNone: false, colCount: 3,
        choices: [
          //{ text: "", value: "", visibleIf: "{cateName} notempty and {cateName}==''"},
          { text: "침대", value: "bed", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "소파", value: "sofa", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "매트리스", value: "mat", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},  
          { text: "의자", value: "chair", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},  
          { text: "책상 및 테이블", value: "table", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},  
          { text: "커버/시트/카페트", value: "sheet", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},  
         
          { text: "식품등의 표시기준", value: "bed", visibleIf: "{cateName} notempty and {cateName}=='식품'"},
          { text: "축산물의 표시기준", value: "sofa", visibleIf: "{cateName} notempty and {cateName}=='식품'"},
          { text: "건강기능식품의 표시기준", value: "mat", visibleIf: "{cateName} notempty and {cateName}=='식품'"},  
          { text: "농수산물의 원산지표시에 관한 법률", value: "chair", visibleIf: "{cateName} notempty and {cateName}=='식품'"},  
          { text: "식품등의 표시광고에 관한 법률", value: "table", visibleIf: "{cateName} notempty and {cateName}=='식품'"},  
        
        ]
      },

      { type: "checkbox", name: "gdCate", title: "4.현장검수에서 검토할 항목을 다중 선택해주세요.", visibleIf: "{cateName} notempty and {subCateName} notempty", hasNone: false, colCount: 3,
        choices: [
          //{ text: "", value: "", visibleIf: "{cateName} notempty and {cateName}==''"},
          { text: "자재", value: "part", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "외관", value: "look", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},
          { text: "보관상태", value: "keep", visibleIf: "{cateName} notempty and {cateName}=='홈인테리어'"},          
        ]
      },
      



      //{ type: "comment", name: "c", title: "부적합한 항목에 대해 조치사항(또는 사유)를 입력해주세요.", visibleIf: "({s.q1}!=null and {s.q1}!=1) or ({s.q2}!=null and {s.q2}!=1) or ({s.q3}!=null and {s.q3}!=1) or ({s.q4}!=null and {s.q4}!=1)" },


      

   /*   
        { type : "html", name: "info", html: "<b>1.서류심사</b>",},
        { type: "matrix", name: "d1", isRequired: true, title: "안전기준 성적서", rows: [ {value: "q",text: "전기용품 및 생활용품 안전관리법 – 해당품목 안전기준 적용"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{d1.q}!=null and {d1.q} !=1" },
        { type: "matrix", name: "d2", isRequired: true, title: "품목별 시험 성적서", rows: [ {value: "q",text: "재질분석, 원단시험성적서 등"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{d2.q}!=null and {d2.q} !=1" },
        { type: "matrix", name: "d3", isRequired: true, title: "샘플", rows: [ {value: "q",text: "품질확인용(최종포장 상태)"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{d3.q}!=null and {d3.q} !=1" },
        { type: "matrix", name: "d4", isRequired: true, title: "표시사항", rows: [ {value: "q",text: "법적 필수 표시기재 확인"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{d4.q}!=null and {d4.q} !=1" },
        { type: "matrix", name: "d5", isRequired: true, title: "기타 표현 입증", rows: [ {value: "q",text: "표현을 원하는 모든 문구 사전 확인 및 입증자료"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{d5.q}!=null and {d5.q} !=1" },

      { type : "html", name: "info", html: "<b>2.샘플검수</b>",},   
      { type : "html", name: "info", html: "<b>2.1 관계법령</b>",},
      { type: "checkbox", name: "slCate", title: "관계법령에서 검토할 항목을 다중 선택해주세요.", choices: ["목재","가죽","매트리스","카페트","커버,시트(가정용섬유제품)"], hasNone: false, colCount: 3 },



      { type : "html", name: "info", html: "<b>2.2 기타테스트</b>",},  
      
      
        { type: "matrix", name: "sl1", title: "목재 - 폼알데하이드 방출량(데시케이터법)", rows: [ {value: "q",text: "1.5 mg/L 이하"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{sl1.q}!=null and {sl1.q} !=1" },
        { type: "matrix", name: "sl2", title: "목재 - 폼알데하이드 방출량(소형챔버법)", rows: [ {value: "q",text: "0.12mg/m2h 이하"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{sl2.q}!=null and {sl2.q} !=1" },
        { type: "matrix", name: "sl3", title: "목재 - 톨루엔 방출량(소형챔버법)", rows: [ {value: "q",text: "0.08mg/m2h 이하"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{sl3.q}!=null and {sl3.q} !=1" },
        { type: "matrix", name: "sl4", title: "목재 - 총휘발성 유기화합물방출량 TVOC(소형챔버법)", rows: [ {value: "q",text: "4 mg/m2h 이하"}], columns: [ {value: 1,text: "적합"}, {value: 2,text: "부적합"}, ]},
        { type: "comment", name: "c", title: "검토결과 무엇이 부적합한가요?", visibleIf: "{cateName}!=null and {cateName}=='홈인테리어' and {sl4.q}!=null and {sl4.q} !=1" },
*/        
        
        
        
        
    ],
  }

  //방송분석 등록
  const men={
    title: "방송분석",
    description: "방송진출이후 방송실적이 저조하여 재고소진 등에 어려움을 겪는 중소기업에게 분석 리포트와 멘토링을 제공합니다.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    showQuestionNumbers: "off",
    elements: [
      { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
      { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
      { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }] },
      { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
      { name: "stock", type: "text", inputType: "number", title: "판매수량", placeHolder: "(숫자만입력)", isRequired: true },
      { name: "revenue", type: "text", inputType: "number", title: "매출액", placeHolder: "(숫자만입력)", isRequired: true },
      { name: "unit", type: "text", inputType: "number", title: "잔여재고", placeHolder: "(숫자만입력)", isRequired: true },
      { name: "channel", type: "text", title: "방송사", placeHolder: "(예시 : GS홈쇼핑)", isRequired: true },
      { name: "salesAt", type: "text", title: "방송일", placeHolder: "(예시: 2022-05-05)", isRequired: true },
      { name: "salesTime", type: "text", title: "방송시간대", placeHolder: "(예시: AM 5:30)", isRequired: true },
    ],

  }

  //방송분석 답변
  const menReply={
    title: "방송분석",
    description: "방송진출이후 방송실적이 저조하여 재고소진 등에 어려움을 겪는 중소기업 상품의 시장성, 상품성, 사업성에 관해 분석해주세요.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    showQuestionNumbers: "off",
    elements: [
      {
        type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "1.(방송 편리성) 상품 사용이 편리합니까?"        
      },
      {
        type: "rating", name: "q2", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "2.(방송 혁신성) 상품이 지금까지와는 달리 새로운 기능이나 타 상품과의 차별화된 우월성을 표현할 수 있습니까?"        
      },
      {
        type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "3.(방송 안전성) 방송심의등 표현 및 사용이 안전합니까?"        
      },
      {
        type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "4.(방송 시연성) 방송을 방송 중에 알기 쉽게 매력적으로 시연해 보일수 있습니까?"        
      },
      { type: "checkbox", name: "q5", title: "5.상품특성상 1차적으로 진출 공략해야할 시장은 어디라고 생각합니까?(최대 3개 선택 가능)", isRequired: true, hasNone: false, colCount: 4,
        choices: ["오픈마켓","홈쇼핑","T커머스","라이브커머스","미디어커머스","종합몰","소셜커머스","모바일 쇼핑몰","폐쇄몰","해외수출","대형마트","백화점","전문점","면세점","쇼핑센터","편의점","카테고리킬러"],
        validators: [{type: "expression", expression: "{q5.length} < 4", text: "최대 3개까지 선택 가능합니다."}]
      },
      {
        type: "rating", name: "q6", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "6.(상품 가격) 이 제품의 상품가격을 어떻게 생각하십니까?"        
      },
      {
        type: "rating", name: "q7", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "7.(상품 기능) 상품기능이 우수합니까?"        
      },
      {
        type: "rating", name: "q8", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "8.(상품 디자인) 고객이 상품디자인을 보고 관심을 가질만 합니까?"        
      },
      {
        type: "rating", name: "q9", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "9.(상품 인지성) 상품의 브랜드를 알고있습니까 ? (브랜드인지도, 상품인지도 또는 서비스 인지도)"        
      },
      {
        type: "rating", name: "q10", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "10.(상품 전문성) 이 상품에 대한 회사의 전문성은 어떠합니까?"        
      },
      { type: "comment", name: "q11", title: "11.(상품 보완성) 상품성, 전문성, 인지성(3개 중 택1)에서 보완해야할 부분은 무엇입니까? (200자 이내로 기술해주세요)", isRequired: true},
      {
        type: "rating", name: "q12", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "12.(상품성) 회사의 이 상품에 대한 상품성은 어떠합니까?"        
      },
      {
        type: "rating", name: "q13", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "13.(시장호감도) 이 상품에 좋은 감정을 불러일으키는 정도는 어떠합니까?"        
      },
      {
        type: "rating", name: "q14", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "14.(시장트랜드) 이 상품에 대한 회사의 전문성은 어떠합니까?"        
      },
      {
        type: "rating", name: "q15", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "15.(시장경쟁력) 이 상품이 갖는 시장 경쟁력에 대해 어떻게 생각하십니까?"        
      },
      {
        type : "html",
        name: "info",
        html: "<b>16.(홈쇼핑 방송 분석의견) 아래 필수 포함내용 8가지 항목에 대한 분석 및 의견을 500자 이내로 기술해주세요.</b><br/>(단, 홈쇼핑 영상이 없는 건은 상품구성, 가격판매정책, 프로모션 등 3가지 항목만 작성해도 됩니다)",
      },
      { type: "comment", name: "q16", title: "※ 필수 포함 내용 : 인서트영상, 쇼호스트(멘트), Display, 게스트 출연, 자막, 상품구성, 가격판매정책, 프로모션", isRequired: true},
      {
        type: "rating", name: "q17", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "17.(MD분석 점수) 본인의 의견을 한 점수로 나타내면?"        
      },
    ],
    
  }
  /*
completedHtml: `
      <script>
        window.ReactNativeWebView.postMessage(q16)    
      </script>
  `
  */

  //전문가진단
  const reqReply={
    title: "전문가진단",
    description: "중소기업 상품의 시장성, 상품성, 사업성에 관해 진단해주세요.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      {
        type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(고객수요/시장성) 상품이 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품입니까?"        
      },
      {
        type: "matrix", name: "q2", isRequired: true, 
        title: "가격 경쟁력",
        columns: [
            {value: 10,text: "경쟁사 대비 최저가"}, 
            {value: 7,text: "경쟁사와 가격대 비슷함"}, 
            {value: 5,text: "경쟁사보다 가격이 높음"}, 
        ],
        rows: [
          {value: "sq1",text: "경쟁사 대비 가격 경쟁력이 있습니까?"},
        ]
      },
      {
        type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 디자인) 상품의 전반적인 디자인(상세페이지, BI/CI, 패키지 등)은 어떠합니까?"        
      },
      {
        type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 기능) 맛(식품) 또는 기능성(비식품)이 타상품과 차별화되어 있습니까?"        
      },
      {
        type: "rating", name: "q5", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(방송 적합성) 상품이 방송심의 표현 사용에 안전하고 방송 중에 알기 쉽게 매력적으로 시연 가능합니까?"        
      },
      {
        type: "matrix", name: "q6", isRequired: true, 
        title: "제품 생산 책임성",
        columns: [
            {value: 10,text: "제품 직접 생산(공장보유)"}, 
            {value: 7,text: "제품 OEM 생산"}, 
            {value: 5,text: "제품 판권 보유 기업 (유통기업)"}, 
        ],
        rows: [
          {value: "sq1",text: "제품을 직접 생산합니까?"},
        ]
      },
      {
        type: "matrix", name: "q7", isRequired: true, 
        title: "홈쇼핑 방송 경험 유무",
        columns: [
            {value: 10,text: "홈쇼핑 방송 1회 이상 경험"}, 
            {value: 5,text: "방송 경험 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 방송 경험이 1회 이상 있습니까?"},
        ]
      },
      {
        type: "matrix", name: "q8", isRequired: true, 
        title: "홈쇼핑 방송 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 7,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 5,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      
      {
        type: "matrix", name: "q9", isRequired: true, 
        title: "품질관리 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 5,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 1,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      {
        type: "matrix", name: "q10", isRequired: true, 
        title: "품질관리 서류",
        columns: [
            {value: 10,text: "전체 보유"}, 
            {value: 5,text: "멘토링을 통한 단기간내 가능"}, 
            {value: 1,text: "단기간 내 개선 불가"}, 
        ],
        rows: [
          {value: "sq1",text: "품질관리 서류를 보유하고 있습니까?"},
        ]
      },
      { type: "comment", name: "q13", title: "전문가로써 마지막으로 상품(또는 중소기업)에 대한 의견을 남겨주세요.", isRequired: true},


    ]
  }

  //유통판로QnA 질문등록
  const qa={
    title: "질문등록",
    description: "유통판로 진출/확대 하는 과정에서 발생되는 문제나 질문을 자유롭게 등록하면 유통채널 전문가로부터 답변을 받을 수 있는 서비스를 제공합니다",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      { name: "title", type: "text", title: "제목", placeHolder: "", isRequired: true },
      { name: "body", type: "comment", title: "본문", placeHolder: "(상세하게 입력할수록 더 상세한 답변을 받을 수 있습니다.)", isRequired: true },
      { name: "channel", type: "dropdown", title: "유통채널", isRequired: true, choices: ["오픈마켓","홈쇼핑","T커머스","라이브커머스","미디어커머스","종합몰","소셜커머스","모바일 쇼핑몰","폐쇄몰","해외수출","대형마트","백화점","전문점","면세점","쇼핑센터","편의점","카테고리킬러"]},
      { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
    ]
  }

  const qaReply={
    title: "답변등록",
    description: "유통판로 진출/확대 하는 과정에서 발생되는 문제나 질문에 대해 답변 해주세요.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      { name: "replyCom", type: "comment", title: "답변", placeHolder: "", isRequired: true },
    ] 
  }

  const qaSurvey={
    title: "답변 만족도 등록",
    description: "",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      {
        type: "rating", name: "rating", isRequired: true, rateMin: 1, rateMax: 5, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "전문가 답변에 만족하시나요?"        
      },
      {
        type: "comment",
        name: "satisCom",
        visibleIf: "{rating}!=null and {rating}>3",
        title: "어떤 점이 만족스러우셨나요?"
      }
    ] 
  }

  //자가진단
  const req={
    title: "자가진단",
    description: "내 상품의 시장성, 상품성, 사업성에 관해 직접 점검해보는 체크리스트로 컨설팅 진행에 기초 자료로 사용됩니다.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
      { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
      { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }] },
      { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
      { name: "unitItem", type: "comment", title: "상품구성", placeHolder: "(예시: 원피스 장식 포함)", isRequired: true },
      { name: "manufacturer", type: "text", title: "제조사", placeHolder: "", isRequired: true },
      { name: "url", type: "text", title: "상품정보 웹사이트", placeHolder: "https://", isRequired: false },
      //{ name: "images", type: "file", title: "사진 첨부", storeDataAsText: false, allowMultiple: true, maxSize: 5242880},
      { name: "customer", type: "text", title: "주요고객", placeHolder: "(예시: 20대 초반 여성)", isRequired: true },
      { name: "sellingPoint", type: "comment", title: "셀링포인트", placeHolder: "(상품의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요.)", isRequired: true },
      { name: "competProd", type: "comment", title: "경쟁상품 입력", placeHolder: "", isRequired: true },

      {
        type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(고객수요/시장성) 상품이 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품입니까?"        
      },
      {
        type: "matrix", name: "q2", isRequired: true, 
        title: "가격 경쟁력",
        columns: [
            {value: 10,text: "경쟁사 대비 최저가"}, 
            {value: 7,text: "경쟁사와 가격대 비슷함"}, 
            {value: 5,text: "경쟁사보다 가격이 높음"},
        ],
        rows: [
          {value: "sq1",text: "경쟁사 대비 가격 경쟁력이 있습니까?"},
        ]
      },
      {
        type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 디자인) 상품의 전반적인 디자인(상세페이지, BI/CI, 패키지 등)은 어떠합니까?"        
      },
      {
        type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 기능) 맛(식품) 또는 기능성(비식품)이 타상품과 차별화되어 있습니까?"        
      },
      {
        type: "rating", name: "q5", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(방송 적합성) 상품이 방송심의 표현 사용에 안전하고 방송 중에 알기 쉽게 매력적으로 시연 가능합니까?"        
      },
      {
        type: "matrix", name: "q6", isRequired: true, 
        title: "제품 생산 책임성",
        columns: [
            {value: 10,text: "제품 직접 생산(공장보유)"}, 
            {value: 7,text: "제품 OEM 생산"}, 
            {value: 5,text: "제품 판권 보유 기업 (유통기업)"}, 
        ],
        rows: [
          {value: "sq1",text: "제품을 직접 생산합니까?"},
        ]
      },
      {
        type: "matrix", name: "q7", isRequired: true, 
        title: "홈쇼핑 방송 경험 유무",
        columns: [
            {value: 10,text: "홈쇼핑 방송 1회 이상 경험"}, 
            {value: 5,text: "방송 경험 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 방송 경험이 1회 이상 있습니까?"},
        ]
      },
      {
        type: "matrix", name: "q8", isRequired: true, 
        title: "홈쇼핑 방송 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 7,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 5,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      
      {
        type: "matrix", name: "q9", isRequired: true, 
        title: "품질관리 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 5,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 1,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      {
        type: "matrix", name: "q10", isRequired: true, 
        title: "품질관리 서류",
        columns: [
            {value: 10,text: "전체 보유"}, 
            {value: 5,text: "멘토링을 통한 단기간내 가능"}, 
            {value: 1,text: "단기간 내 개선 불가"}, 
        ],
        rows: [
          {value: "sq1",text: "품질관리 서류를 보유하고 있습니까?"},
        ]
      },
      { type: "checkbox", name: "qDoc1", title: "(품질서류) 해당되는 인허가 관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "사업자등록증",
          "제조/수입/판매업 신고(허가)증",
          "제조품목허가서",
          "의료기기 품질관리기준적합인정서(제조, 수입)",
          "의료기기 광고사전심의",
        ]
      },
      { type: "checkbox", name: "qDoc2", title: "(품질서류) 해당되는 검사 성적서를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "원단시험성적서",
          "정수기 품질검사 성적서",
          "재질 및 용출시험",
          "재질 성분시험",
          "완제품 시험성적서",
          "기타 시험성적서",
        ]
      },
      { type: "checkbox", name: "qDoc3", title: "(품질서류) 해당되는 거래사실 확인 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "수입신고필증",
          "식품등의 수입신고필증",
          "상품 거래 사실(OEM 포함)",
          "권한사용 계약서",
        ]
      },
      { type: "checkbox", name: "qDoc4", title: "(품질서류) 해당되는 인증 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "법 강제 인증서",
          "특허관련",
          "품질관련",
          "안전관련",
          "안정성 관련",
          "생산물책임보험",
          "BSE 미감염증명서",
          "논문",
        ]
      },
      { type: "checkbox", name: "qDoc5", title: "(품질서류) 해당되는 기타 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "제품 표준서",
          "작업 지시(표준)서",
          "등기부 등본",
          "토지 대장",
          "비디오물 등급필증",
          "사용설명서 (주의사항)",
          "표시사항",
          "원산지 확약서",
        ]
      },


      { type: "checkbox", name: "qFDoc1", title: "(품질서류) 해당되는 업체관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "영업허가(신고)증",
          "건강진단결과서(건강진단서)",
          "수질검사 성적서(노로바이러스 검사성적서)",
          "제조물책임보험증권",
          "HACCP,GMP 제품표준서 및 관리기준서",
          "HACCP,GMP 심사내역(AUDIT 점검내역)",
          "방충,방서관리일지",
          "차량점검일지(냉장,냉동 차량온도기록지)",
          "위생교육 수료증 사원 위생교육일지",
          "협력사/제조원 거래계약서",
        ]
      },
      { type: "checkbox", name: "qFDoc2", title: "(품질서류) 해당되는 제품 및 공정관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "품목제조보고(신고)서",
          "자가품질검사성적서",
          "영양성분분석성적서(제품 공간 비율 성적서)",
          "수입원료,제품서류",
          "[해당시] 원부재료 원산지증명서(유기농 취급시 유기농 인증서류)",
          "원,부재료 수불일지",
          "원,부재료 입고검사일지 원부재료 상품안전성 검사 성적서",
          "생산일지 / 작업일지 / 제조기록서(공정별 생산일지 포함 및 배합일지)",
          "판매상품별 제조공정도 제품별 공정(관리)일지",
          "생산별(공정별) 제품안전성 검사일지 기구별 검/교정일지",
          "동일제품 유통 현황 [제품거래내역(3년)]",
          "완제품 출고 및 반품 관리내역",
          "냉장냉동 온도관리일지",
          "CCP 점검일지",
          "컴플레인 처리리포트",
        ]
      },
      { type: "checkbox", name: "qFDoc3", title: "(품질서류) 해당되는 원료 관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "거래명세서",
          "수입원료,제품서류",
          "GMO증빙 및 검사성적서(대두,옥수수,면화,카놀라,사탕무,알팔파)",
          "품목제조보고서",
          "시험성적서(잔류농약시험성적서 - 해당상품 취급시)",
          "도축증명서",
          "등급판정서",
          "수매확인서",
          "원료 표시사항",
        ]
      },
      { type: "checkbox", name: "qFDoc4", title: "(품질서류) 해당되는 포장 및 광고 관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "거래명세서(포장지 업체 관련 신고서류)",
          "포장제 시험성적서",
          "광고사전심의필증",
          "상품별 표시사항(포장지 한도견본)",
          "외박스 표시사항",
          "설명서,보증서 등",
          "각종 인증서[제품표시내용 등]",
          "관련 논문 및 특허",
        ]
      },
      { type: "checkbox", name: "qFDoc5", title: "(품질서류) 해당되는 기타 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "원산지확약서",
          "서류,현장 개선 계획서",
          "기타자료(원료,상품,언론이슈 자료)",
        ]
      },
    ]
  }
  const reqbiz={
    title: "자가진단",
    description: "내 상품의 시장성, 상품성, 사업성에 관해 직접 점검해보는 체크리스트로 컨설팅 진행에 기초 자료로 사용됩니다.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
      { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
      { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }] },
      { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
      { name: "unitItem", type: "comment", title: "상품구성", placeHolder: "(예시: 원피스 장식 포함)", isRequired: true },
      { name: "manufacturer", type: "text", title: "제조사", placeHolder: "", isRequired: true },
      { name: "url", type: "text", title: "상품정보 웹사이트", placeHolder: "https://", isRequired: false },
      //{ name: "images", type: "file", title: "사진 첨부", storeDataAsText: false, allowMultiple: true, maxSize: 5242880},
      { name: "customer", type: "text", title: "주요고객", placeHolder: "(예시: 20대 초반 여성)", isRequired: true },
      { name: "sellingPoint", type: "comment", title: "셀링포인트", placeHolder: "(상품의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요.)", isRequired: true },
      { name: "competProd", type: "comment", title: "경쟁상품 입력", placeHolder: "", isRequired: true },

      {
        type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(고객수요/시장성) 상품이 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품입니까?"        
      },
      {
        type: "matrix", name: "q2", isRequired: true, 
        title: "가격 경쟁력",
        columns: [
            {value: 10,text: "경쟁사 대비 최저가"}, 
            {value: 7,text: "경쟁사와 가격대 비슷함"}, 
            {value: 5,text: "경쟁사보다 가격이 높음"},
        ],
        rows: [
          {value: "sq1",text: "경쟁사 대비 가격 경쟁력이 있습니까?"},
        ]
      },
      {
        type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 디자인) 상품의 전반적인 디자인(상세페이지, BI/CI, 패키지 등)은 어떠합니까?"        
      },
      {
        type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 기능) 맛(식품) 또는 기능성(비식품)이 타상품과 차별화되어 있습니까?"        
      },
      {
        type: "rating", name: "q5", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(브랜드 인지도) 상품의 현재 브랜드 인지도는 어느 정도라고 생각하십니까?"        
      },
      {
        type: "matrix", name: "q6", isRequired: true, 
        title: "제품 생산 책임성",
        columns: [
            {value: 10,text: "제품 직접 생산(공장보유)"}, 
            {value: 7,text: "제품 OEM 생산"}, 
            {value: 5,text: "제품 판권 보유 기업 (유통기업)"}, 
        ],
        rows: [
          {value: "sq1",text: "제품을 직접 생산합니까?"},
        ]
      },
      {
        type: "matrix", name: "q8", isRequired: true, 
        title: "온라인 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 7,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 5,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "온라인 판매 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      
      {
        type: "matrix", name: "q9", isRequired: true, 
        title: "품질관리 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 5,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 1,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "품질관리 및 고객응대 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      { name: "q10", type: "checkbox", title: "컨설팅받고 싶은 MD분야를 선택해주세요.(택1)", isRequired: true, hasNone: false, colCount: 3, maxSelectedChoices:1, choices: ["오픈마켓MD","입점채널MD","마케팅MD"]}
    ]
  }



  //상품등록
  const json5={
    title: "상품 등록",
    description: "사전 진단 전 상품 정보 등록",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
      { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
      { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }] },
      { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
      { name: "unitItem", type: "text", title: "상품구성", placeHolder: "(예시: 원피스 장식 포함)", isRequired: true },
      { name: "manufacturer", type: "text", title: "제조사", placeHolder: "", isRequired: true },
      { name: "url", type: "text", title: "상품정보 웹사이트", placeHolder: "https://", isRequired: false },
      { name: "images", type: "file", title: "사진 첨부", storeDataAsText: false, allowMultiple: true, maxSize: 5242880},
      { name: "customer", type: "text", title: "주요고객", placeHolder: "(예시: 20대 초반 여성)", isRequired: true },
      { name: "sellingPoint", type: "text", title: "셀링포인트", placeHolder: "(상품의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요.)", isRequired: true },
      { name: "competProd", type: "text", title: "경쟁상품 입력", placeHolder: "", isRequired: true },

    ]
  }
  const prevDiag = {
    title: "창업가 역량 진단",
    description: "창업가(아이템)의 현재 상황에 대한 5점 척도로 평가해주세요.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      {
        type : "html",
        name: "info",
        html: "<b>창업에 필요한 역량을 스스로 점검하고, 적절한 전문 코칭 분야를 선정하는데 목적이 있습니다.</b>",
      },
     {
      type: "matrix", name: "q1", isRequired: true, 
      title: "창업목표",
      columns: [
          {value: 1,text: "매우 그렇지 않다"}, 
          {value: 2,text: "그렇지 않다"}, 
          {value: 3,text: "보통"}, 
          {value: 4,text: "그렇다"}, 
          {value: 5,text: "매우 그렇다"},
      ],
      rows: [
        {value: "sq1",text: "(경제적 목표) 창업가는 고객과 시장 환경에 기반한 향후 5년간 구체적인 경제적 목표를 가지고 있는가?"},
        {value: "sq2",text: "(개인적 목표) 창업가는 사업에 대한 명확한 비전과 목표를 가지고 있는가?"},
        {value: "sq3",text: "(사회적 목표) 창업가의 사업 목적은 환경보호(E), 사회적 기여(S), 기업지배구조(G)를 포함하고 있는가?"},
      ]
      },
      {
        type: "matrix", name: "q2", isRequired: true, 
        title: "창업아이템",
        columns: [
            {value: 1,text: "매우 그렇지 않다"}, 
            {value: 2,text: "그렇지 않다"}, 
            {value: 3,text: "보통"}, 
            {value: 4,text: "그렇다"}, 
            {value: 5,text: "매우 그렇다"},
        ],
        rows: [
          {value: "sq1",text: "(시장성) 창업 아이템은 향후 시장 확장성이 높은 아이템인가?"},
          {value: "sq2",text: "(기술성) 창업 아이템은 경쟁사에 비해 차별화된 기술 전문성을 가지고 있는가?"},
          {value: "sq3",text: "(수익성) 창업 아이템은 투자대비 수익성이 높은 아이템인가?"},
          {value: "sq4",text: "(독창성) 창업 아이템은 고객이 기대할 만한 독창적인 기능/서비스/편리성/재미를 제공하는가?"},
          {value: "sq5",text: "(사회적기여도) 창업 아이템은 인류와 자연환경을 고려한 소재, 부품, 제품 및 서비스를 포함하는가?"},
        ]
        },
        {
          type: "matrix", name: "q3", isRequired: true, 
          title: "창업자 역량",
          columns: [
              {value: 1,text: "매우 그렇지 않다"}, 
              {value: 2,text: "그렇지 않다"}, 
              {value: 3,text: "보통"}, 
              {value: 4,text: "그렇다"}, 
              {value: 5,text: "매우 그렇다"},
          ],
          rows: [
            {value: "sq1",text: "(전문성-기술,영업) 창업자는 기술사업화에 대한 개발, 영업, 관리에 대한 전문 역량을 가지고 있는가?"},
            {value: "sq2",text: "(관련지식) 창업자는 제품 개발 및 서비스 제공에 대한 전문 자격(학위, 자격증 등)을 가지고 있는가?"},
            {value: "sq3",text: "(관련경험) 창업가는 기술사업화에 대한 경력이나 경험을 가지고 있는가?"},
            {value: "sq4",text: "(기업가정신) 창업가는 성공 창업에 대한 명확한 비전과 뜨거운 열정, 강인한 실천의지를 가지고 있는가?"},
          ]
          },
          {
            type: "matrix", name: "q4", isRequired: true, 
            title: "창업자금",
            columns: [
                {value: 2,text: "매우 그렇지 않다"}, 
                {value: 4,text: "그렇지 않다"}, 
                {value: 6,text: "보통"}, 
                {value: 8,text: "그렇다"}, 
                {value: 10,text: "매우 그렇다"},
            ],
            rows: [
              {value: "sq1",text: "(필요자금) 창업가는 성공 창업을 위한 구체적인 자금 목표와 확보 계획을 마련하였는가?"},
              {value: "sq2",text: "(확보자금) 창업가는 3년 이내 사업화를 위한 필요 자금을 확보하고 있는가?"},
            ]
            },
            {
              type: "matrix", name: "q5", isRequired: true, 
              title: "창업팀",
              columns: [
                  {value: 2,text: "매우 그렇지 않다"}, 
                  {value: 4,text: "그렇지 않다"}, 
                  {value: 6,text: "보통"}, 
                  {value: 8,text: "그렇다"}, 
                  {value: 10,text: "매우 그렇다"},
              ],
              rows: [
                {value: "sq1",text: "(필요업무및인원) 창업가는 성공 창업을 위해 필요한 업무를 구체화 하고 인력을 확보하고 있는가?"},
                {value: "sq2",text: "(추가확보인원) 창업가는 성공 창업을 위해 필요한 인력 계획을 구체화 하고 확보하기 위해 노력하는가?"},
              ]
              },
              {
                type: "checkbox",
                name: "q6",
                title: "코칭받고 싶은 분야를 다중 선택해주세요.(최대 3개)",
                isRequired: true,
                hasNone: false,
                colCount: 4,
                maxSelectedChoices:3,
                choices: ["투자유치","IR피칭","경영관리","자금조달","시제품개발","인사노무","마케팅","지식재산권","디자인","일반법률","금형설계","시험인증"]
            },
            {
              type: "radiogroup",
              name: "q7",
              isRequired: true,
              visibleIf: "{q6.length} > 1",
              title: "가장 받고 싶은 1순위 코칭 분야를 선택해주세요.",
              choicesFromQuestion: "q6",
              choicesFromQuestionMode: "selected"
            },
            {
              type: "comment",
              name: "q8",
              title: "코칭 진행에 있어 바라는 점이 있다면 남겨주세요."
            }
    ],
    completedHtml: ``
   };
  //창업가 역량 진단
  const diag = {
    title: "창업가 역량 진단",
    description: "창업에 필요한 역량을 스스로 점검하고, 적절한 전문 코칭 분야를 선정하는데 목적이 있습니다.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      {
        type : "html",
        name: "info",
        html: "<b>회사 및 아이템 소개</b>",
      },
      { name: "prodName", type: "text", title: "아이템명", placeHolder: "", isRequired: true },
      { name: "prodSummary", type: "comment", title: "(아이템 소개) 아이템을 200글자 이내로 소개하시오.", placeHolder: "", isRequired: true },
      { name: "ceoSkill", type: "comment", title: "(전문성) 창업자의 기술개발 역량을 구체적으로 작성하시오.", placeHolder: "", isRequired: true },
      { name: "ceoDegree", type: "comment", title: "(관련지식) 창업아이템과 관련된 창업자의 학위(석사학위이상) 및 관련자격증을 작성하시오.", placeHolder: "", isRequired: true },
      { name: "ceoVision", type: "comment", title: "(비전과 목표) 창업아이템과 관련된 창업자의 비전과 목표를 작성하시오.", placeHolder: "", isRequired: true },
      { name: "ceoDream", type: "comment", title: "(기업가정신) 성공 창업에 대한 포부를 작성하시오.", placeHolder: "", isRequired: true },
      { name: "ceoRoleModel", type: "comment", title: "(기업가정신) 성공 창업을 위한 본인의 롤모델과 선정이유에 대해 작성하시오.", placeHolder: "", isRequired: true },
      { name: "competDiff", type: "comment", title: "(기술성) 경쟁사 대비 기술전문성(차별성)을 작성하시오.", placeHolder: "", isRequired: true },
      { name: "fundGoal", type: "comment", title: "(자금 목표) 성공 창업을 위해 얼마만큼의 자금을 목표로 하는가?", placeHolder: "", isRequired: true },
      { name: "fundPlan", type: "comment", title: "(자금 확보계획) 위 자금 목표를 달성할 자금 확보 계획을 작성하시오.", placeHolder: "", isRequired: true },
      { type : "html", name: "info", html: "<b>창업가 역량 진단 : 창업가(아이템)의 현재 상황에 대한 5점 척도로 평가해주세요.</b>",},
      { type : "html", name: "info", html: "<b>[1] 창업목표</b>", },
      {
        type: "matrix", name: "q1", isRequired: true, 
        title: "경제적 목표",
        columns: [
            {value: 1,text: "5억"}, 
            {value: 2,text: "10억"}, 
            {value: 3,text: "20억"}, 
            {value: 4,text: "50억"}, 
            {value: 5,text: "100억"},
        ],
        rows: [
          {value: "sq1",text: "창업가는 고객과 시장 환경에 기반한 향후 5년 후 매출은 어느 수준이 적당하다고 생각하는가?"},
        ]
      },
      {
        type: "matrix", name: "q2", isRequired: true, 
        title: "개인적 목표",
        columns: [
          {value: 1,text: "매우 그렇지 않다"}, 
          {value: 2,text: "그렇지 않다"}, 
          {value: 3,text: "보통"}, 
          {value: 4,text: "그렇다"}, 
          {value: 5,text: "매우 그렇다"},
        ],
        rows: [
          {value: "sq1",text: "창업가는 앞서 작성한 사업에 대한 명확한 비전과 목표를 스스로 평가하시오."},
        ]
      },
      {
        type: "matrix", name: "q3", isRequired: true, 
        title: "사회적 목표",
        columns: [
          {value: 1,text: "0~20%"}, 
          {value: 2,text: "21~40%"}, 
          {value: 3,text: "41~60%"}, 
          {value: 4,text: "61~80%"}, 
          {value: 5,text: "81~100%"},
        ],
        rows: [
          {value: "sq1",text: "창업가의 사업 목적은 환경보호(E), 사회적 기여(S), 기업지배구조(G)를 포함하고 있는가?"},
        ]
      },
      { type : "html", name: "info", html: "<b>[2] 창업아이템</b>", },
      {
        type: "matrix", name: "q4", isRequired: true, 
        title: "시장성",
        columns: [
          {value: 1,text: "0~20%"}, 
          {value: 2,text: "21~40%"}, 
          {value: 3,text: "41~60%"}, 
          {value: 4,text: "61~80%"}, 
          {value: 5,text: "81~100%"},
        ],
        rows: [
          {value: "sq1",text: "창업 아이템은 향후 시장 확장성이 높은 아이템인가?"},
        ]
      },
      {
        type: "matrix", name: "q5", isRequired: true, 
        title: "기술성",
        columns: [
          {value: 1,text: "61~70%"}, 
          {value: 2,text: "71~80%"}, 
          {value: 3,text: "81~90%"}, 
          {value: 4,text: "91~100%"}, 
          {value: 5,text: "100% 초과"},
        ],
        rows: [
          {value: "sq1",text: "앞서 작성한 경쟁사 대비 창업 아이템의 차별화된 기술 전문성을 평가하시오."},
        ]
      },
      {
        type: "matrix", name: "q6", isRequired: true, 
        title: "수익성",
        columns: [
          {value: 1,text: "1배수"}, 
          {value: 2,text: "3배수"}, 
          {value: 3,text: "5배수"}, 
          {value: 4,text: "7배수"}, 
          {value: 5,text: "10배수"},
        ],
        rows: [
          {value: "sq1",text: "창업 아이템은 투자대비 적정 수익성은 어느 수준인가?"},
        ]
      },
      {
        type: "matrix", name: "q7", isRequired: true, 
        title: "독창성",
        columns: [
          {value: 1,text: "매우 그렇지 않다"}, 
          {value: 2,text: "그렇지 않다"}, 
          {value: 3,text: "보통"}, 
          {value: 4,text: "그렇다"}, 
          {value: 5,text: "매우 그렇다"},
        ],
        rows: [
          {value: "sq1",text: "창업 아이템은 고객이 기대할 만한 독창적인 기능/서비스/편리성/흥미를 제공하는가?"},
        ]
      },
      {
        type: "matrix", name: "q8", isRequired: true, 
        title: "사회적기여도",
        columns: [
          {value: 1,text: "0~20%"}, 
          {value: 2,text: "21~40%"}, 
          {value: 3,text: "41~60%"}, 
          {value: 4,text: "61~80%"}, 
          {value: 5,text: "81~100%"},
        ],
        rows: [
          {value: "sq1",text: "창업 아이템은 인류와 자연환경을 고려한 소재, 부품, 제품 및 서비스를 포함하는가?"},
        ]
      },
      { type : "html", name: "info", html: "<b>[3] 창업자 역량</b>", },
      {
        type: "matrix", name: "q9", isRequired: true, 
        title: "전문성",
        columns: [
          {value: 2,text: "61~70%"}, 
          {value: 4,text: "71~80%"}, 
          {value: 6,text: "81~90%"}, 
          {value: 8,text: "91~100%"}, 
          {value: 10,text: "100% 초과"},
        ],
        rows: [
          {value: "sq1",text: "창업자는 기술개발 역량에 대한 전문 역량을 평가하시오."},
        ]
      },
      {
        type: "matrix", name: "q10", isRequired: true, 
        title: "관련지식",
        columns: [
          {value: 2,text: "1개"}, 
          {value: 4,text: "2개"}, 
          {value: 6,text: "3개"}, 
          {value: 8,text: "4개"}, 
          {value: 10,text: "5개 이상"},
        ],
        rows: [
          {value: "sq1",text: "창업자는 제품 개발 및 서비스 제공에 대한 전문 자격(석사학위이상, 관련자격증 등)을 몇개 가지고 있는가?"},
        ]
      },
      {
        type: "matrix", name: "q11", isRequired: true, 
        title: "관련경험",
        columns: [
          {value: 2,text: "1년 미만"}, 
          {value: 4,text: "1~3년"}, 
          {value: 6,text: "3~5년"}, 
          {value: 8,text: "5~7년"}, 
          {value: 10,text: "7년 초과"},
        ],
        rows: [
          {value: "sq1",text: "창업가의 기술사업화에 대한 경력 년수는?"},
        ]
      },
      {
        type: "matrix", name: "q12", isRequired: true, 
        title: "기업가정신",
        columns: [
          {value: 2,text: "매우 그렇지 않다"}, 
          {value: 4,text: "그렇지 않다"}, 
          {value: 6,text: "보통"}, 
          {value: 8,text: "그렇다"}, 
          {value: 10,text: "매우 그렇다"},
        ],
        rows: [
          {value: "sq1",text: "창업가는 성공 창업에 대한 명확한 비전과 뜨거운 열정, 강인한 실천의지를 가지고 있는가?"},
        ]
      },
      { type : "html", name: "info", html: "<b>[4] 창업자금</b>", },
      {
        type: "matrix", name: "q13", isRequired: true, 
        title: "현재자금",
        columns: [
            {value: 1,text: "1천 미만"}, 
            {value: 2,text: "1~5천 미만"}, 
            {value: 3,text: "5천~1억 미만"}, 
            {value: 4,text: "1억~3억 미만"}, 
            {value: 5,text: "3억 초과"},
        ],
        rows: [
          {value: "sq1",text: "창업가는 성공 창업을 위한 현재 창업자본금을 확보하고 있는가?"},
        ]
      },
      {
        type: "matrix", name: "q14", isRequired: true, 
        title: "확보자금",
        columns: [
            {value: 1,text: "1억 미만"}, 
            {value: 2,text: "1~3억 미만"}, 
            {value: 3,text: "3억~5억 미만"}, 
            {value: 4,text: "5억~10억 미만"}, 
            {value: 5,text: "10억 초과"},
        ],
        rows: [
          {value: "sq1",text: "창업가는 3년 이내 사업화를 위해 얼마만큼의 자금을 확보할 계획인가?"},
        ]
      },
      { type : "html", name: "info", html: "<b>[5] 창업팀</b>", },
      {
        type: "matrix", name: "q15", isRequired: true, 
        title: "현재인원",
        columns: [
            {value: 1,text: "0명"}, 
            {value: 2,text: "1명 미만"}, 
            {value: 3,text: "3명 미만"}, 
            {value: 4,text: "5명 미만"}, 
            {value: 5,text: "7명 이상"},
        ],
        rows: [
          {value: "sq1",text: "창업가는 현재 업무를 수행할 수 있는 인력을 확보하고 있는가?(대표 제외)"},
        ]
      },
      {
        type: "matrix", name: "q16", isRequired: true, 
        title: "추가확보인원",
        columns: [
          {value: 1,text: "1명"}, 
          {value: 2,text: "2명 미만"}, 
          {value: 3,text: "3명 미만"}, 
          {value: 4,text: "4명 미만"}, 
          {value: 5,text: "5명 이상"},
        ],
        rows: [
          {value: "sq1",text: "창업가는 사업기간 내 추가 인력을 몇 명 고용할 계획인가?"},
        ]
      },

      {
        type: "checkbox",
        name: "cusMenCates",
        title: "코칭받고 싶은 분야를 다중 선택해주세요.(최대 3개)",
        isRequired: true,
        hasNone: false,
        colCount: 3,
        maxSelectedChoices:3,
        choices: ["비즈니스모델","설계","피봇팅","시제품개발","금형설계", "지식재산권", "디자인", "마케팅", "시험인증", "인사노무", "자금조달", "경영관리", "투자유치(IR피칭)", "공장 설립", "설비 확충", "사업비 운영", "해외수출","트리즈"]
    },
    {
      type: "radiogroup",
      name: "cusMenCate",
      isRequired: true,
      visibleIf: "{cusMenCates.length} > 1",
      title: "가장 받고 싶은 1순위 코칭 분야를 선택해주세요.",
      choicesFromQuestion: "cusMenCates",
      choicesFromQuestionMode: "selected"
    },
    { name: "men", type: "comment", title: "본인이 선택한 특화 코칭 분야에서 세부적으로 코칭 받고 싶은 내용을 작성하시오.", placeHolder: "", isRequired: true },
    ],
    completedHtml: ``
   };
  
   //방송분석 멘토링 - 만족도 조사
  const menSurvey = {
    title: "방송분석 멘토링 지원사업 만족도 조사",
    description: "소상공인 TV홈쇼핑 및 T-커머스 입점 지원 멘토링지원사업 참여업체 만족도 조사",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
     {
      type: "matrix", name: "q1", isRequired: true, 
      title: "홈쇼핑 방송분석 멘토링 지원사업의 참여 과정을 고려할 때 다음의 각 항목들에 대하여 만족도를 체크해 주십시오.",
      columns: [
          {value: 1,text: "매우 불만족"}, 
          {value: 2,text: "불만족"}, 
          {value: 3,text: "보통"}, 
          {value: 4,text: "만족"}, 
          {value: 5,text: "매우 만족"},
      ],
      rows: [
        {value: "sq1",text: "① 사업에 대한 안내는 충분히 드렸습니까?"},
        {value: "sq2",text: "② 서류 제출시 불편한 점은 없으셨습니까?"},
        {value: "sq3",text: "③ 현장 진단 및 전문가 멘토링에 만족하십니까?"},
        {value: "sq4",text: "④ 판로별 전문가 비대면(zoom) 멘토링에 만족하십니까?"},
        {value: "sq5",text: "⑤ 멘토링 진행 시 불편하거나 어려운 점은 없었습니까?"},
        {value: "sq6",text: "⑥ 수행기관의 멘토링 전반에 대해 만족하십니까?"},
      ]
      },
      {
        type: "rating", name: "q2", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "「홈쇼핑 방송분석 멘토링 사업」에 대하여 전반적으로 만족하십니까?"        
    },
    { type: "checkbox", name: "q3", title: "방송분석 멘토링은 어느 부분에 도움이 되었습니까?(복수응답가능)", hasOther: true, isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{q2}>=4",
        choices: [
            "상품에 대한 정확한 분석 및 이해",
            "전문가진단을 통한 현장의견",
            "기타판로 연계",
            "방송 분석",
        ]
    },
    { type: "checkbox", name: "q4", title: "2.에서 ‘그렇지 않다’ 라고 답하신 이유는 무엇입니까?(복수응답가능)", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{q2}<=2",
        choices: [
            "생각했던 지원내용과 다르다.",
            "원하는 방송분석를 받지 못하였다.",
            "판로 연계에 도움이 되지 않았다.",
            "멘토링에 대한 결과가 만족스럽지 않다.",
        ]
    },
    
    { type: "comment", name: "q5", title: "멘토링 사업 참여 시 좋았던 점 또는 건의 사항 · 발전방안 등 귀사의 의견을 작성해 주십시오.", isRequired: false},
    ]
   };

   //방송준비 멘토링 - 만족도 조사
  const reqSurvey = {
    title: "방송준비 멘토링 지원사업 만족도 조사",
    description: "소상공인 TV홈쇼핑 및 T-커머스 입점 지원 멘토링지원사업 참여업체 만족도 조사",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
     {
      type: "matrix", name: "q1", isRequired: true, 
      title: "홈쇼핑 방송준비 멘토링 지원사업의 참여 과정을 고려할 때 다음의 각 항목들에 대하여 만족도를 체크해 주십시오.",
      columns: [
          {value: 1,text: "매우 불만족"}, 
          {value: 2,text: "불만족"}, 
          {value: 3,text: "보통"}, 
          {value: 4,text: "만족"}, 
          {value: 5,text: "매우 만족"},
      ],
      rows: [
        {value: "q11",text: "① 사업에 대한 안내는 충분히 드렸습니까?"},
        {value: "q12",text: "② 서류 제출시 불편한 점은 없으셨습니까?"},
        {value: "q13",text: "③ 현장 진단 및 전문가 멘토링에 만족하십니까?"},
        {value: "q14",text: "④ 방송 제안서 가이드에 만족하십니까?"},
        {value: "q15",text: "⑤ 상세페이지 개선에 만족하십니까?"},
        {value: "q16",text: "⑥ 8개 선택 과업(sns홍보, 패키지 개선 등)에 만족하십니까?"},
        {value: "q17",text: "⑦ 수행기관의 멘토링 전반에 대해 만족하십니까?"},
      ]
      },
      {
        type: "rating", name: "q2", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "「홈쇼핑 방송준비(역량강화) 멘토링 사업」에 대한 종합 만족도 정도를 체크해주십시오."        
    },
    { type: "radiogroup", name: "q3", title: "8대 과업 중 귀사가 선택한 과업은 무엇이었습니까?", isRequired: true, hasNone: false, colCount: 3,
        choices: [
            "SNS홍보",
            "BI/CI 개선",
            "패키지개선",
            "품질인증컨설팅",
            "특허출원",
            "라이브커머스",
            "해외온라인 입점",
            "동영상 제작",
        ]
    },
    { type: "radiogroup", name: "q4", title: "8대 과업 중 귀사가 받고 싶은 과업은 무엇입니까? ", isRequired: true, hasNone: false, colCount: 3,
        choices: [
          "SNS홍보",
          "BI/CI 개선",
          "패키지개선",
          "품질인증컨설팅",
          "특허출원",
          "라이브커머스",
          "해외온라인 입점",
          "동영상 제작",
        ]
    },
    { type: "radiogroup", name: "q5", title: "8대 과업 외 지원 받고 싶은 과업은 무엇입니까?", hasOther: true, isRequired: true, hasNone: false, colCount: 3,
        choices: [
            "상품이미지 촬영",
            "유통 전문 교육",
            "오프라인 행사",
            "유통채널 MD상담(입점 상품 판매 전략)",
            "카드뉴스 홍보"
        ]
    },
    { type: "checkbox", name: "q6", title: "멘토링 과정 중 가장 만족스러웠던 과정은 무엇이었습니까? (복수응답가능)", isRequired: true, hasNone: false, colCount: 3,
        choices: [
            "현장진단멘토링",
            "역량진단 (닥터엠디 앱)",
            "상세페이지 개선 및 제작",
            "선택과업 결과물 (8개 중 택1)",
            "홈쇼핑역량강화 ZOOM 교육"
        ]
    },
    {
      type: "rating", name: "q7", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "전혀 그렇지 않다", maxRateDescription: "매우 그렇다",
      title: "「방송준비를 위한 멘토링이 홈쇼핑 진출에 도움이 된다고 생각하십니까?"        
    },
    {
      type: "rating", name: "q8", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "전혀 그렇지 않다", maxRateDescription: "매우 그렇다",
      title: "「멘토링 지원 내용이 판로확대 등 향후 경영활동에 도움이 될 것으로 생각하십니까?"        
    },
    { type: "comment", name: "q9", title: "멘토링 사업 참여 시 좋았던 점 또는 건의 사항 · 발전방안 등 귀사의 의견을 작성해 주십시오.", isRequired: false},
    ]
   };


  const myFunction = async(data) => {
    window.ReactNativeWebView.postMessage(data)
  }

 
   const onComplete=(survey, options)=>{
    //Write survey results into database
    const data=JSON.stringify(survey.data);
    //alert("Survey results: " + JSON.stringify(survey.data));
    if(window.ReactNativeWebView) {
      myFunction(data);
    }
   }

   const model = new Survey.Model(type);
   //model.data=allSurvey;
   /*
   model.data = {
    q1: {q11 : 1, q12 : 2, q13:3,q14:4,q15:5,q16:2,q17:1},
    q2 : 5,
    q3 : "SNS홍보"
  };*/

const onValueChanged=(sender, options)=>{
  console.log("sender : ",sender);
  console.log("options: ",options);
  console.log("options.value: ",options.value);
  //options.value
}

  //{"q1":1,"q5":["대형마트"]}
  return (
    <Survey.Survey model={model} completeText={mode=="diag"?'다음':'등록'} onComplete={onComplete} onValueChanged={onValueChanged}/>
  );
};

export default DataAdd;