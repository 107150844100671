import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import Drmd from "demos/Drmd.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
import Admin from "pages/Admin.js";
import Join from "pages/Join.js";
import joinPartner from "pages/Join2.js";
import Join3 from "pages/Join3.js";
import SignUpWebView from "pages/SignUpWebView.js";

import ResetPwd from "pages/ResetPwd.js";


import DashBoard from "pages/DashBoard.js";
import Reqz from "pages/Reqz.js";

import StateList from "pages/StateList.js";
import Status from "pages/Status.js";

import Reqs from "pages/Reqs.js";
import Req from "pages/Req.js";
import ReqAdd from "pages/ReqAdd.js";
import Users from "pages/Users.js";
import UserList from "pages/UserList.js";
import User from "pages/User.js";
import UserApprove from "pages/UserApprove.js";

import Resps from "pages/Resps.js";
import RespList from "pages/RespList.js";
import Resp from "pages/Resp.js";
import RespAdd from "pages/RespAdd.js";

import Notices from "pages/Notices.js";
import NoticeList from "pages/NoticeList.js";
import Notice from "pages/Notice.js";
import NoticeAdd from "pages/NoticeAdd.js";
import NoticeEdit from "pages/NoticeEdit.js";
import NoticeDel from "pages/NoticeDel.js";

import Mtrz from "pages/Mtrz.js";
import Mtrs from "pages/Mtrs.js";
import MtrList from "pages/MtrList.js";
import Mtr from "pages/Mtr.js";
import MtrAdd from "pages/MtrAdd.js";
import MtrEdit from "pages/MtrEdit.js";
import MtrDel from "pages/MtrDel.js";
import MtrReply from "pages/MtrReply.js";

import DiagChart from "pages/DiagChart.js";
import DiagChart2 from "pages/DiagChart2.js";
import DiagChart3 from "pages/DiagChart3.js";
import DiagChart4 from "pages/DiagChart4.js";
import DiagEdit from "pages/DiagEdit.js";
import DiagReply from "pages/DiagReply.js";
import DiagEditReplied from "pages/DiagEditReplied.js";

import Diagz from "pages/Diagz.js";
import Diags from "pages/Diags.js";
import DiagList from "pages/DiagList.js";
import Diag from "pages/Diag.js";



// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
import Survey from "pages/Survey.js";
import Survey2 from "pages/Survey2.js";
import SurveyForm from "pages/SurveyForm.js";

import DataDetail from "pages/Data/DataDetail.js";
import DataAdd from "pages/Data/DataAdd.js";
import DataEdit from "pages/Data/DataEdit.js";
import DataEditReplied from "pages/Data/DataEditReplied.js";
import DataReply from "pages/Data/DataReply.js";
import MenEditReplied from "pages/Data/MenEditReplied.js";
import NoticeDetail from "pages/Data/NoticeDetail.js";

import WebAdd from "pages/Data/WebAdd.js";
import Rechallenge from "pages/Data/Rechallenge.js";


import Servs from "pages/Servs.js";
import ServsAdmin from "pages/ServsAdmin.js";
import AppHome from "pages/AppHome.js";

import AppProds from './front/AppProds';
import AppOrds from './front/AppOrds';
import AppServs from "./front/AppServs";

import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";



import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route exact path="/" component={Drmd}/>
        <Route path="/admin" component={Admin} />
        <Route path="/dashBoard" component={DashBoard} />
        <Route path="/reqz" component={Reqz} />
        <Route path="/mtrz" component={Mtrz} />
        <Route path="/reqAdd" component={ReqAdd} />
        <Route path="/status" component={Status} />
        <Route path="/StateList" component={StateList} />

        <Route path="/users" component={Users} />
        <Route path="/userApprove" component={UserApprove} />
        <Route path="/Resps" component={Resps} />
        <Route path="/RespAdd" component={RespAdd} />

        <Route path="/notices" component={Notices} />
        <Route path="/noticeAdd" component={NoticeAdd} />
        <Route path="/noticeEdit" component={NoticeEdit} />
        <Route path="/noticeDel" component={NoticeDel} />

        <Route path="/mentorings" component={Mtrs} />
        <Route path="/mentoringAdd" component={MtrAdd} />
        <Route path="/mentoringEdit" component={MtrEdit} />
        <Route path="/mentoringDel" component={MtrDel} />
        <Route path="/mentoringReply" component={MtrReply} />

        <Route path="/diagz" component={Diagz} />
        <Route path="/diags" component={Diags} />
        <Route path="/signUp" component={SignUpWebView} />
        <Route path="/join" component={Join} />
        <Route path="/join3" component={Join3} />
        <Route path="/joinPartner" component={joinPartner} />
        <Route path="/resetPwd" component={ResetPwd} />

        <Route path="/servs" component={Servs} />
        <Route path="/servsAdmin" component={ServsAdmin} />
        <Route path="/front/home" component={AppHome} />
        <Route path="/front/prods" component={AppProds} />
        <Route path="/front/ords" component={AppOrds} />
        <Route path="/front/servs" component={AppServs} />


        <Route path="/reqs" component={Reqs}>
          <Route path="/reqs/:reqId" component={Req} />        
        </Route>
        <Route path="/userList" component={UserList}>
          <Route path="/userList/:userId" component={User} />        
        </Route>
        <Route path="/respList" component={RespList}>
          <Route path="/respList/:respId" component={Resp} />        
        </Route>
        <Route path="/noticeList" component={NoticeList}>
          <Route path="/noticeList/:noticeId" component={Notice} />        
        </Route>
        <Route path="/mentoring" component={MtrList}>
          <Route path="/mentoring/:mtrId" component={Mtr} />        
        </Route>
        
        <Route path="/diagList" component={DiagList}>
          <Route path="/diagList/:diagId" component={Diag} />        
        </Route>
        
        

        <Route path="/survey/:noticeId/:uid" component={Survey} />
        <Route path="/survey2" component={Survey2} />
        <Route path="/check" component={SurveyForm} />

        <Route path="/new" component={WebAdd} />
        <Route path="/rechallenge" component={Rechallenge} />
        <Route path="/add" component={DataAdd} />
        <Route path="/edit" component={DataEdit} />
        <Route path="/detail" component={DataDetail} />
        <Route path="/reply" component={DataReply} />
        <Route path="/editReplied" component={DataEditReplied} />
        <Route path="/editRepliedMen" component={MenEditReplied} />
        <Route path="/noticeDetail" component={NoticeDetail} />


        <Route path="/diagchart" component={DiagChart} />
        <Route path="/diagchart2" component={DiagChart2} />
        <Route path="/diagchart3" component={DiagChart3} />
        <Route path="/diagchart4" component={DiagChart4} />
        <Route path="/diagEdit" component={DiagEdit} />
        <Route path="/diagEdit" component={DiagEdit} />
        <Route path="/diagReply" component={DiagReply} />
        <Route path="/diagEditReplied" component={DiagEditReplied} />

        
        <Route path="/privacy" component={PrivacyPolicyPage} />
        <Route path="/agreement" component={TermsOfServicePage} />
      </Switch>
    </Router>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
//export default AirGo;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

//export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

//export default MainLandingPage;
