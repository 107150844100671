import React, {useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth} from "../config/firebase";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { Container as ContainerBase } from "components/misc/Layouts";
import styled from "styled-components";
import ColumnSelect from 'react-column-select'

const db=firebase.firestore();

const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const errorStyle = {
  color : 'red',
  fontSize : "10px"

}

//const NoticeEdit = (history) =>{
const NoticeEdit = ({location:{state}, history }) =>{
const user = auth.currentUser;
const {
  noticeId,
  title,
  body,
  name,
  uid,
  email,
  userType,
  createAt,
  files,
  sendTo,
  allUsers,
  pushOptionNo,
  sendToValue,
  noticeType
} = state;

useEffect(() => {
  console.log("preNoticeType=>",noticeType);
  //console.log("sendToValue=>",sendToValue);
  setSelected(sendToValue);
  setPushOption(pushOptionNo);
  //console.log("allusers=>",allUsers)
}, []);
const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [result, setResult] = useState("");
  const [pushOption, setPushOption] = useState("");
  const [pushOptionValue, setPushOptionValue] = useState(3);
  const [selected, setSelected] = useState([])  
  const [allUserCopy, setAllUserCopy] = useState(allUsers);
  const [replyFrom, setReplyFrom] = useState([])
  const [noticeType1, setNoticeType1] = useState(noticeType);
  const filterItems = (query) => {
    return allUsers.filter((el) =>
      el.userType.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    );
  }


  const getNoticeInfo = async(uid) => {
    
    const noticeRef  = await db.collection("notices").doc(noticeId)
    
    noticeRef.get().then((response) => {
      const noticeData = response.data();
      setReplyFrom(noticeData.replyFrom);
    });
  
  };

const sendNotificationTo = async(tokens) => {
  console.log("token:", tokens);
  tokens.map(token => sendNotification(token))
};


const sendNotification=async()=>{ 
  console.log("-------ready to push");
  const message = {
    to: "ExponentPushToken[kezFaCFZpZ8ywHYuQ5L4w5]",
    sound: 'default',
    title: '상품/역량 진단 요청 알림',
    body: '(sss)',
    data: { someData: 'goes here' },
  };

  await fetch('https://cors-anywhere.herokuapp.com/https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
  console.log("------finish to push-----")
}

  const handleChange = (event) => {
    setPushOption(event.target.value);
    setSelected([]);
    if(event.target.value==="0"){
      setSelected(allUserCopy);
      setPushOptionValue(0);
      //setAllUserCopy([]);
      console.log(allUserCopy);
    }else if(event.target.value==="1"){
      setSelected(filterItems(1));
      setPushOptionValue(1);
      //setAllUserCopy(filterItems(2));
      console.log(filterItems(1));
    }else if(event.target.value==="2"){
      setSelected(filterItems(2));
      setPushOptionValue(2);
      //setAllUserCopy(filterItems(1));
      console.log(filterItems(2));
    }else if(event.target.value==="3"){
      setSelected(filterItems(3));
      setPushOptionValue(3);
      //setAllUserCopy(filterItems(1));
      console.log(filterItems(3));
    }else {
      event.preventDefault();
    }
    //console.log("selected=>",selected);

    //console.log("pushOption->",event.target.value);
  }

  const handleTypeChange = (e) => {
    setNoticeType1(e.target.value);
};
  const onChange = (values) => {
    setSelected(values)
    console.log("selected=>", values)
  }
  const onSubmit = async (data) => {
    console.log("data=>", data);
    //데이터 전달받기
    //console.log("title=>",data.title);    
    //setResult(JSON.stringify(data));
    //console.log(data);
    if(selected.length==0){
    //if(pushOptionValue == 3 || (pushOption =="3" && selected.length==0)){
    //if(pushOption =="default " || pushOption !="0" || pushOption !="1" || pushOption!="2" || pushOption!="3"){
        alert("푸시 수신자를 선택해주세요.");
      }else{
        let uid=[];
        let token=[];
        let company=[];
        let name=[];
        let email=[];
        let sendToValue = [];


        for ( const item of selected){

          try{

          const users = await db.collection("users")
          .where("uid", "==", item.value)
          .get(); 
          users.docs.map(user => {

            uid.push(user.data().uid);
            token.push(user.data().token);
            company.push(user.data().profile.bizInfo.bizName);
            name.push(user.data().profile.name);
            email.push(user.data().email);
            sendToValue.push(
              {
              label : `${user.data().profile.name} (${user.data().profile.bizInfo.bizName}, ${user.data().email})`,
               value : user.data().uid
              },
            );
            })        

            }catch (error){
              console.log(error);
            }
        }
        let tempUid=uid;
        replyFrom.filter(x => tempUid.includes(x));

        console.log("replyFrom", replyFrom);

        //onsole.log("uid=>", uid);
        //console.log("token=>", token);
        //console.log("company=>", company);
        //console.log("name=>", name);
        //console.log("email=>", email);
        //console.log("sendToValue=>", sendToValue);
  
      db.collection("notices").doc(noticeId).update({
      title : data.title,
      body :  data.body,
      updateAt : new Date(),
      sendTo : uid,
      pushOptionNo : pushOptionValue,
      sendToInfo : {
        company : company,
        name : name,
        email : email,
        uid : uid,
      },
      sendToValue : sendToValue,
      sendToToken : token,
      replyFrom : replyFrom,
      noticeType : noticeType1
  })
  .then(() => {
      //console.log("Document written with ID: ", docRef.id);
      history.push({
        pathname: '/notices',
        //search: '?query=abc',
    });
  })
  .catch((error) => {
      console.error("Error editing document: ", error);
  });

  

  //sendNotificationTo(token);
  //sendNotification();
  
      }
  }

return (
    
        <div> 
            {user ? 
             (
              <Content>
                <MainContainer>
                <h1 tw="text-xl font-semibold">공지사항 수정</h1>
              <FormContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
               <div>작성자 : {name} ( {email} )</div>

               <div>
        <p>&nbsp;</p>
        <label htmlFor="공지사항">
         <input
            key="a"
            id="a"
            type="radio"
            name="a"
            value={1}
            onChange={(e) => handleTypeChange(e)}
            checked={noticeType1== 1}
            style={{
              height: 15,
              width: 20,
            }}
          />
          공지사항&nbsp;&nbsp;&nbsp;
          </label>

          <label htmlFor="만족도조사">
         <input
            key="b"
            id="b"
            type="radio"
            name="b"
            value={2}
            onChange={(e) => handleTypeChange(e)}
            checked={noticeType1 == 2}
            style={{
              height: 15,
              width: 20,
            }}
          />
          만족도 조사
          </label>
          <p>&nbsp;</p>
              </div>
             <div>
               제목 <Input defaultValue={title} {...register("title", { required: true })} />
               {errors.title && <span style={errorStyle}>제목을 입력하세요.</span>}
              </div>
              <div>
             본문 <TextArea defaultValue={body} {...register("body", { required: true })} />
             {errors.body && <span style={errorStyle}>본문을 입력하세요.</span>}
             </div> 

             <div>
               푸시 수신자 선택 
               <Select defaultValue={pushOptionNo}  onChange={handleChange}>
            <option value="default">그룹 선택</option>
            <option value="default">-------</option>
            <option value="0">전체 선택</option>
            <option value="1">중소기업 전체 선택</option>
            <option value="2">전문가 전체 선택</option>
            <option value="3">직접 선택</option>
          </Select>
          {pushOption =="3"  && 
             <ColumnSelect
        options={allUserCopy}
        defaultValue={sendToValue}
        onChange={onChange}
        leftHeader='전체'
        rightHeader='선택된 수신자'
        isSearchable={true}
        searchPlaceholder="검색..."
        theme={{
          headerBgColor: '#eee',
          columnBorderColor: '#ccc',
          textColor: '#000000',
          columnBgColor: '#ccc',
          buttonBgColor: '#ccc',
          optionSelectedBgColor: '#eee',
          optionHoverBgColor: '#eee',
          searchFocusBorderColor: '#ccc',
        }}
      />
      }
               </div>


             <SubmitButton type="submit">
                  <span className="text">수정</span>
                </SubmitButton>
           </form>
           </FormContainer>
           </MainContainer>
           </Content>
             )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default NoticeEdit;

/*

to={{ pathname:`/notices`, 
      state:{
        email: aUEmail,
        uid : aUid,
        userName : aUName,
        userType : aUType,
        userState : aUState

      }}}
*/