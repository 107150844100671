import React, {useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';
import firebase, {auth} from "../../config/firebase";
import queryString from "query-string";

import * as Survey from "survey-react";
import "survey-react/survey.css";
import {StylesManager } from "survey-react";

StylesManager.applyTheme("winterstone");

/*
import 'survey-react/modern.min.css';
// import 'survey-react/survey.min.css';
import { Survey, StylesManager, Model } from "survey-react";
StylesManager.applyTheme("modern");
*/


const db=firebase.firestore();

const MenEditReplied = ({ match, location }) => {
  const query = queryString.parse(location.search);
  const [mode, setMode] = useState(query.mode);
  const [uid, setUid] = useState(query.uid||"");
  const [type, setType] = useState({});
  console.log("mode->>",mode);
  console.log("uid->>",uid);


  //방송분석 답변
  const menReply={
    title: "방송분석",
    description: "방송진출이후 방송실적이 저조하여 재고소진 등에 어려움을 겪는 중소기업 상품의 시장성, 상품성, 사업성에 관해 분석해주세요.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    showQuestionNumbers: "off",
    elements: [
      {
        type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "1.(방송 편리성) 상품 사용이 편리합니까?"        
      },
      {
        type: "rating", name: "q2", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "2.(방송 혁신성) 상품이 지금까지와는 달리 새로운 기능이나 타 상품과의 차별화된 우월성을 표현할 수 있습니까?"        
      },
      {
        type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "3.(방송 안전성) 방송심의등 표현 및 사용이 안전합니까?"        
      },
      {
        type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "4.(방송 시연성) 방송을 방송 중에 알기 쉽게 매력적으로 시연해 보일수 있습니까?"        
      },
      { type: "checkbox", name: "q5", title: "5.상품특성상 1차적으로 진출 공략해야할 시장은 어디라고 생각합니까?(최대 3개 선택 가능)", isRequired: true, hasNone: false, colCount: 4,
        choices: ["오픈마켓","홈쇼핑","T커머스","라이브커머스","미디어커머스","종합몰","소셜커머스","모바일 쇼핑몰","폐쇄몰","해외수출","대형마트","백화점","전문점","면세점","쇼핑센터","편의점","카테고리킬러"],
        validators: [{type: "expression", expression: "{q5.length} < 4", text: "최대 3개까지 선택 가능합니다."}]
      },
      {
        type: "rating", name: "q6", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "6.(상품 가격) 이 제품의 상품가격을 어떻게 생각하십니까?"        
      },
      {
        type: "rating", name: "q7", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "7.(상품 기능) 상품기능이 우수합니까?"        
      },
      {
        type: "rating", name: "q8", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "8.(상품 디자인) 고객이 상품디자인을 보고 관심을 가질만 합니까?"        
      },
      {
        type: "rating", name: "q9", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "9.(상품 인지성) 상품의 브랜드를 알고있습니까 ? (브랜드인지도, 상품인지도 또는 서비스 인지도)"        
      },
      {
        type: "rating", name: "q10", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "10.(상품 전문성) 이 상품에 대한 회사의 전문성은 어떠합니까?"        
      },
      { type: "comment", name: "q11", title: "11.(상품 보완성) 상품성, 전문성, 인지성(3개 중 택1)에서 보완해야할 부분은 무엇입니까? (200자 이내로 기술해주세요)", isRequired: true},
      {
        type: "rating", name: "q12", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "12.(상품성) 회사의 이 상품에 대한 상품성은 어떠합니까?"        
      },
      {
        type: "rating", name: "q13", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "13.(시장호감도) 이 상품에 좋은 감정을 불러일으키는 정도는 어떠합니까?"        
      },
      {
        type: "rating", name: "q14", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "14.(시장트랜드) 이 상품에 대한 회사의 전문성은 어떠합니까?"        
      },
      {
        type: "rating", name: "q15", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "15.(시장경쟁력) 이 상품이 갖는 시장 경쟁력에 대해 어떻게 생각하십니까?"        
      },
      {
        type : "html",
        name: "info",
        html: "<b>16.(홈쇼핑 방송 분석의견) 아래 필수 포함내용 8가지 항목에 대한 분석 및 의견을 500자 이내로 기술해주세요.</b><br/>(단, 홈쇼핑 영상이 없는 건은 상품구성, 가격판매정책, 프로모션 등 3가지 항목만 작성해도 됩니다)",
      },
      { type: "comment", name: "q16", title: "※ 필수 포함 내용 : 인서트영상, 쇼호스트(멘트), Display, 게스트 출연, 자막, 상품구성, 가격판매정책, 프로모션", isRequired: true},
      {
        type: "rating", name: "q17", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 그렇지 않다", maxRateDescription: "매우 그렇다",
        title: "17.(MD분석 점수) 본인의 의견을 한 점수로 나타내면?"        
      },
    ],
    
  }
  /*
completedHtml: `
      <script>
        window.ReactNativeWebView.postMessage(q16)    
      </script>
  `
  */

  //전문가진단
  const reqReply={
    title: "전문가진단",
    description: "중소기업 상품의 시장성, 상품성, 사업성에 관해 진단해주세요.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      {
        type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(고객수요/시장성) 상품이 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품입니까?"        
      },
      {
        type: "matrix", name: "q2", isRequired: true, 
        title: "가격 경쟁력",
        columns: [
            {value: 10,text: "경쟁사보다 가격이 높음"}, 
            {value: 7,text: "경쟁사와 가격대 비슷함"}, 
            {value: 5,text: "경쟁사 대비 최저가"}, 
        ],
        rows: [
          {value: "sq1",text: "경쟁사 대비 가격 경쟁력이 있습니까?"},
        ]
      },
      {
        type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 디자인) 상품의 전반적인 디자인(상세페이지, BI/CI, 패키지 등)은 어떠합니까?"        
      },
      {
        type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 기능) 맛(식품) 또는 기능성(비식품)이 타상품과 차별화되어 있습니까?"        
      },
      {
        type: "rating", name: "q5", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(방송 적합성) 상품이 방송심의 표현 사용에 안전하고 방송 중에 알기 쉽게 매력적으로 시연 가능합니까?"        
      },
      {
        type: "matrix", name: "q6", isRequired: true, 
        title: "제품 생산 책임성",
        columns: [
            {value: 10,text: "제품 직접 생산(공장보유)"}, 
            {value: 7,text: "제품 OEM 생산"}, 
            {value: 5,text: "제품 판권 보유 기업 (유통기업)"}, 
        ],
        rows: [
          {value: "sq1",text: "제품을 직접 생산합니까?"},
        ]
      },
      {
        type: "matrix", name: "q7", isRequired: true, 
        title: "홈쇼핑 방송 경험 유무",
        columns: [
            {value: 10,text: "홈쇼핑 방송 1회 이상 경험"}, 
            {value: 5,text: "방송 경험 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 방송 경험이 1회 이상 있습니까?"},
        ]
      },
      {
        type: "matrix", name: "q8", isRequired: true, 
        title: "홈쇼핑 방송 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 7,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 5,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      
      {
        type: "matrix", name: "q9", isRequired: true, 
        title: "품질관리 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 5,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 1,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      {
        type: "matrix", name: "q10", isRequired: true, 
        title: "품질관리 서류",
        columns: [
            {value: 10,text: "전체 보유"}, 
            {value: 5,text: "멘토링을 통한 단기간내 가능"}, 
            {value: 1,text: "단기간 내 개선 불가"}, 
        ],
        rows: [
          {value: "sq1",text: "품질관리 서류를 보유하고 있습니까?"},
        ]
      },
      { type: "comment", name: "q13", title: "전문가로써 마지막으로 상품(또는 중소기업)에 대한 의견을 남겨주세요.", isRequired: true},


    ]
  }


  //자가진단
  const req={
    title: "자가진단",
    description: "내 상품의 시장성, 상품성, 사업성에 관해 직접 점검해보는 체크리스트로 컨설팅 진행에 기초 자료로 사용됩니다.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
      { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
      { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }] },
      { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
      { name: "unitItem", type: "comment", title: "상품구성", placeHolder: "(예시: 원피스 장식 포함)", isRequired: true },
      { name: "manufacturer", type: "text", title: "제조사", placeHolder: "", isRequired: true },
      { name: "url", type: "text", title: "상품정보 웹사이트", placeHolder: "https://", isRequired: false },
      { name: "images", type: "file", title: "사진 첨부", storeDataAsText: false, allowMultiple: true, maxSize: 5242880},
      { name: "customer", type: "text", title: "주요고객", placeHolder: "(예시: 20대 초반 여성)", isRequired: true },
      { name: "sellingPoint", type: "comment", title: "셀링포인트", placeHolder: "(상품의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요.)", isRequired: true },
      { name: "competProd", type: "comment", title: "경쟁상품 입력", placeHolder: "", isRequired: true },

      {
        type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(고객수요/시장성) 상품이 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품입니까?"        
      },
      {
        type: "matrix", name: "q2", isRequired: true, 
        title: "가격 경쟁력",
        columns: [
            {value: 10,text: "경쟁사보다 가격이 높음"}, 
            {value: 7,text: "경쟁사와 가격대 비슷함"}, 
            {value: 5,text: "경쟁사 대비 최저가"}, 
        ],
        rows: [
          {value: "sq1",text: "경쟁사 대비 가격 경쟁력이 있습니까?"},
        ]
      },
      {
        type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 디자인) 상품의 전반적인 디자인(상세페이지, BI/CI, 패키지 등)은 어떠합니까?"        
      },
      {
        type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(상품 기능) 맛(식품) 또는 기능성(비식품)이 타상품과 차별화되어 있습니까?"        
      },
      {
        type: "rating", name: "q5", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "(방송 적합성) 상품이 방송심의 표현 사용에 안전하고 방송 중에 알기 쉽게 매력적으로 시연 가능합니까?"        
      },
      {
        type: "matrix", name: "q6", isRequired: true, 
        title: "제품 생산 책임성",
        columns: [
            {value: 10,text: "제품 직접 생산(공장보유)"}, 
            {value: 7,text: "제품 OEM 생산"}, 
            {value: 5,text: "제품 판권 보유 기업 (유통기업)"}, 
        ],
        rows: [
          {value: "sq1",text: "제품을 직접 생산합니까?"},
        ]
      },
      {
        type: "matrix", name: "q7", isRequired: true, 
        title: "홈쇼핑 방송 경험 유무",
        columns: [
            {value: 10,text: "홈쇼핑 방송 1회 이상 경험"}, 
            {value: 5,text: "방송 경험 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 방송 경험이 1회 이상 있습니까?"},
        ]
      },
      {
        type: "matrix", name: "q8", isRequired: true, 
        title: "홈쇼핑 방송 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 7,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 5,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      
      {
        type: "matrix", name: "q9", isRequired: true, 
        title: "품질관리 대응 역량",
        columns: [
            {value: 10,text: "대응가능인원 보유"}, 
            {value: 5,text: "현재 대응 불가능 하나 인력충원계획 있음"}, 
            {value: 1,text: "대응 불가능 및 향후 충원계획 없음"}, 
        ],
        rows: [
          {value: "sq1",text: "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?"},
        ]
      },
      {
        type: "matrix", name: "q10", isRequired: true, 
        title: "품질관리 서류",
        columns: [
            {value: 10,text: "전체 보유"}, 
            {value: 5,text: "멘토링을 통한 단기간내 가능"}, 
            {value: 1,text: "단기간 내 개선 불가"}, 
        ],
        rows: [
          {value: "sq1",text: "품질관리 서류를 보유하고 있습니까?"},
        ]
      },
      { type: "checkbox", name: "qDoc1", title: "(품질서류) 해당되는 인허가 관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "사업자등록증",
          "제조/수입/판매업 신고(허가)증",
          "제조품목허가서",
          "의료기기 품질관리기준적합인정서(제조, 수입)",
          "의료기기 광고사전심의",
        ]
      },
      { type: "checkbox", name: "qDoc2", title: "(품질서류) 해당되는 검사 성적서를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "원단시험성적서",
          "정수기 품질검사 성적서",
          "재질 및 용출시험",
          "재질 성분시험",
          "완제품 시험성적서",
          "기타 시험성적서",
        ]
      },
      { type: "checkbox", name: "qDoc3", title: "(품질서류) 해당되는 거래사실 확인 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "수입신고필증",
          "식품등의 수입신고필증",
          "상품 거래 사실(OEM 포함)",
          "권한사용 계약서",
        ]
      },
      { type: "checkbox", name: "qDoc4", title: "(품질서류) 해당되는 인증 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "법 강제 인증서",
          "특허관련",
          "품질관련",
          "안전관련",
          "안정성 관련",
          "생산물책임보험",
          "BSE 미감염증명서",
          "논문",
        ]
      },
      { type: "checkbox", name: "qDoc5", title: "(품질서류) 해당되는 기타 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}!=null and {cateName}!='식품'",
        choices: [
          "제품 표준서",
          "작업 지시(표준)서",
          "등기부 등본",
          "토지 대장",
          "비디오물 등급필증",
          "사용설명서 (주의사항)",
          "표시사항",
          "원산지 확약서",
        ]
      },


      { type: "checkbox", name: "qFDoc1", title: "(품질서류) 해당되는 업체관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "영업허가(신고)증",
          "건강진단결과서(건강진단서)",
          "수질검사 성적서(노로바이러스 검사성적서)",
          "제조물책임보험증권",
          "HACCP,GMP 제품표준서 및 관리기준서",
          "HACCP,GMP 심사내역(AUDIT 점검내역)",
          "방충,방서관리일지",
          "차량점검일지(냉장,냉동 차량온도기록지)",
          "위생교육 수료증 사원 위생교육일지",
          "협력사/제조원 거래계약서",
        ]
      },
      { type: "checkbox", name: "qFDoc2", title: "(품질서류) 해당되는 제품 및 공정관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "품목제조보고(신고)서",
          "자가품질검사성적서",
          "영양성분분석성적서(제품 공간 비율 성적서)",
          "수입원료,제품서류",
          "[해당시] 원부재료 원산지증명서(유기농 취급시 유기농 인증서류)",
          "원,부재료 수불일지",
          "원,부재료 입고검사일지 원부재료 상품안전성 검사 성적서",
          "생산일지 / 작업일지 / 제조기록서(공정별 생산일지 포함 및 배합일지)",
          "판매상품별 제조공정도 제품별 공정(관리)일지",
          "생산별(공정별) 제품안전성 검사일지 기구별 검/교정일지",
          "동일제품 유통 현황 [제품거래내역(3년)]",
          "완제품 출고 및 반품 관리내역",
          "냉장냉동 온도관리일지",
          "CCP 점검일지",
          "컴플레인 처리리포트",
        ]
      },
      { type: "checkbox", name: "qFDoc3", title: "(품질서류) 해당되는 원료 관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "거래명세서",
          "수입원료,제품서류",
          "GMO증빙 및 검사성적서(대두,옥수수,면화,카놀라,사탕무,알팔파)",
          "품목제조보고서",
          "시험성적서(잔류농약시험성적서 - 해당상품 취급시)",
          "도축증명서",
          "등급판정서",
          "수매확인서",
          "원료 표시사항",
        ]
      },
      { type: "checkbox", name: "qFDoc4", title: "(품질서류) 해당되는 포장 및 광고 관련 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "거래명세서(포장지 업체 관련 신고서류)",
          "포장제 시험성적서",
          "광고사전심의필증",
          "상품별 표시사항(포장지 한도견본)",
          "외박스 표시사항",
          "설명서,보증서 등",
          "각종 인증서[제품표시내용 등]",
          "관련 논문 및 특허",
        ]
      },
      { type: "checkbox", name: "qFDoc5", title: "(품질서류) 해당되는 기타 서류를 선택해주세요.", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{cateName}='식품'",
        choices: [
          "원산지확약서",
          "서류,현장 개선 계획서",
          "기타자료(원료,상품,언론이슈 자료)",
        ]
      },
    ]
  }



  //상품등록
  const json5={
    title: "상품 등록",
    description: "사전 진단 전 상품 정보 등록",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
      { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
      { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }] },
      { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
      { name: "unitItem", type: "text", title: "상품구성", placeHolder: "(예시: 원피스 장식 포함)", isRequired: true },
      { name: "manufacturer", type: "text", title: "제조사", placeHolder: "", isRequired: true },
      { name: "url", type: "text", title: "상품정보 웹사이트", placeHolder: "https://", isRequired: false },
      { name: "images", type: "file", title: "사진 첨부", storeDataAsText: false, allowMultiple: true, maxSize: 5242880},
      { name: "customer", type: "text", title: "주요고객", placeHolder: "(예시: 20대 초반 여성)", isRequired: true },
      { name: "sellingPoint", type: "text", title: "셀링포인트", placeHolder: "(상품의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요.)", isRequired: true },
      { name: "competProd", type: "text", title: "경쟁상품 입력", placeHolder: "", isRequired: true },

    ]
  }
  //창업가 역량 진단
  const diag = {
    title: "창업가 역량 진단",
    description: "창업가(아이템)의 현재 상황에 대한 5점 척도로 평가해주세요.",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
      {
        type : "html",
        name: "info",
        html: "<b>창업에 필요한 역량을 스스로 점검하고, 적절한 전문 코칭 분야를 선정하는데 목적이 있습니다.</b>",
      },
     {
      type: "matrix", name: "q1", isRequired: true, 
      title: "창업목표",
      columns: [
          {value: 1,text: "매우 그렇지 않다"}, 
          {value: 2,text: "그렇지 않다"}, 
          {value: 3,text: "보통"}, 
          {value: 4,text: "그렇다"}, 
          {value: 5,text: "매우 그렇다"},
      ],
      rows: [
        {value: "sq1",text: "(경제적 목표) 창업가는 고객과 시장 환경에 기반한 향후 5년간 구체적인 경제적 목표를 가지고 있는가?"},
        {value: "sq2",text: "(개인적 목표) 창업가는 사업에 대한 명확한 비전과 목표를 가지고 있는가?"},
        {value: "sq3",text: "(사회적 목표) 창업가의 사업 목적은 환경보호(E), 사회적 기여(S), 기업지배구조(G)를 포함하고 있는가?"},
      ]
      },
      {
        type: "matrix", name: "q2", isRequired: true, 
        title: "창업아이템",
        columns: [
            {value: 1,text: "매우 그렇지 않다"}, 
            {value: 2,text: "그렇지 않다"}, 
            {value: 3,text: "보통"}, 
            {value: 4,text: "그렇다"}, 
            {value: 5,text: "매우 그렇다"},
        ],
        rows: [
          {value: "sq1",text: "(시장성) 창업 아이템은 향후 시장 확장성이 높은 아이템인가?"},
          {value: "sq2",text: "(기술성) 창업 아이템은 경쟁사에 비해 차별화된 기술 전문성을 가지고 있는가?"},
          {value: "sq3",text: "(수익성) 창업 아이템은 투자대비 수익성이 높은 아이템인가?"},
          {value: "sq4",text: "(독창성) 창업 아이템은 고객이 기대할 만한 독창적인 기능/서비스/편리성/재미를 제공하는가?"},
          {value: "sq5",text: "(사회적기여도) 창업 아이템은 인류와 자연환경을 고려한 소재, 부품, 제품 및 서비스를 포함하는가?"},
        ]
        },
        {
          type: "matrix", name: "q3", isRequired: true, 
          title: "창업자 역량",
          columns: [
              {value: 1,text: "매우 그렇지 않다"}, 
              {value: 2,text: "그렇지 않다"}, 
              {value: 3,text: "보통"}, 
              {value: 4,text: "그렇다"}, 
              {value: 5,text: "매우 그렇다"},
          ],
          rows: [
            {value: "sq1",text: "(전문성-기술,영업) 창업자는 기술사업화에 대한 개발, 영업, 관리에 대한 전문 역량을 가지고 있는가?"},
            {value: "sq2",text: "(관련지식) 창업자는 제품 개발 및 서비스 제공에 대한 전문 자격(학위, 자격증 등)을 가지고 있는가?"},
            {value: "sq3",text: "(관련경험) 창업가는 기술사업화에 대한 경력이나 경험을 가지고 있는가?"},
            {value: "sq4",text: "(기업가정신) 창업가는 성공 창업에 대한 명확한 비전과 뜨거운 열정, 강인한 실천의지를 가지고 있는가?"},
          ]
          },
          {
            type: "matrix", name: "q4", isRequired: true, 
            title: "창업자금",
            columns: [
                {value: 2,text: "매우 그렇지 않다"}, 
                {value: 4,text: "그렇지 않다"}, 
                {value: 6,text: "보통"}, 
                {value: 8,text: "그렇다"}, 
                {value: 10,text: "매우 그렇다"},
            ],
            rows: [
              {value: "sq1",text: "(필요자금) 창업가는 성공 창업을 위한 구체적인 자금 목표와 확보 계획을 마련하였는가?"},
              {value: "sq2",text: "(확보자금) 창업가는 3년 이내 사업화를 위한 필요 자금을 확보하고 있는가?"},
            ]
            },
            {
              type: "matrix", name: "q5", isRequired: true, 
              title: "창업팀",
              columns: [
                  {value: 2,text: "매우 그렇지 않다"}, 
                  {value: 4,text: "그렇지 않다"}, 
                  {value: 6,text: "보통"}, 
                  {value: 8,text: "그렇다"}, 
                  {value: 10,text: "매우 그렇다"},
              ],
              rows: [
                {value: "sq1",text: "(필요업무및인원) 창업가는 성공 창업을 위해 필요한 업무를 구체화 하고 인력을 확보하고 있는가?"},
                {value: "sq2",text: "(추가확보인원) 창업가는 성공 창업을 위해 필요한 인력 계획을 구체화 하고 확보하기 위해 노력하는가?"},
              ]
              },
              {
                type: "checkbox",
                name: "q6",
                title: "창업가가 코칭받아야 할 분야를 다중 선택해주세요.(최대 3개)",
                isRequired: true,
                hasNone: false,
                colCount: 4,
                maxSelectedChoices:3,
                choices: ["투자유치","IR피칭","경영관리","자금조달","시제품개발","인사노무","마케팅","지식재산권","디자인","일반법률","금형설계","시험인증"]
            },
            {
              type: "radiogroup",
              name: "q7",
              isRequired: true,
              visibleIf: "{q6.length} > 1",
              title: "창업가가 가장 코칭받아야 하는 1순위 코칭 분야를 선택해주세요.",
              choicesFromQuestion: "q6",
              choicesFromQuestionMode: "selected"
            },
            {
              type: "comment",
              name: "q8",
              title: "창업가(또는 팀, 아이템 등) 전반에 대해 느낀점에 관해 남겨주세요."
            }
    ],
    completedHtml: ``
   };
  
   //방송분석 멘토링 - 만족도 조사
  const menSurvey = {
    title: "방송분석 멘토링 지원사업 만족도 조사",
    description: "소상공인 TV홈쇼핑 및 T-커머스 입점 지원 멘토링지원사업 참여업체 만족도 조사",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
     {
      type: "matrix", name: "q1", isRequired: true, 
      title: "홈쇼핑 방송분석 멘토링 지원사업의 참여 과정을 고려할 때 다음의 각 항목들에 대하여 만족도를 체크해 주십시오.",
      columns: [
          {value: 1,text: "매우 불만족"}, 
          {value: 2,text: "불만족"}, 
          {value: 3,text: "보통"}, 
          {value: 4,text: "만족"}, 
          {value: 5,text: "매우 만족"},
      ],
      rows: [
        {value: "sq1",text: "① 사업에 대한 안내는 충분히 드렸습니까?"},
        {value: "sq2",text: "② 서류 제출시 불편한 점은 없으셨습니까?"},
        {value: "sq3",text: "③ 현장 진단 및 전문가 멘토링에 만족하십니까?"},
        {value: "sq4",text: "④ 판로별 전문가 비대면(zoom) 멘토링에 만족하십니까?"},
        {value: "sq5",text: "⑤ 멘토링 진행 시 불편하거나 어려운 점은 없었습니까?"},
        {value: "sq6",text: "⑥ 수행기관의 멘토링 전반에 대해 만족하십니까?"},
      ]
      },
      {
        type: "rating", name: "q2", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "「홈쇼핑 방송분석 멘토링 사업」에 대하여 전반적으로 만족하십니까?"        
    },
    { type: "checkbox", name: "q3", title: "방송분석 멘토링은 어느 부분에 도움이 되었습니까?(복수응답가능)", hasOther: true, isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{q2}>=4",
        choices: [
            "상품에 대한 정확한 분석 및 이해",
            "전문가진단을 통한 현장의견",
            "기타판로 연계",
            "방송 분석",
        ]
    },
    { type: "checkbox", name: "q4", title: "2.에서 ‘그렇지 않다’ 라고 답하신 이유는 무엇입니까?(복수응답가능)", isRequired: true, hasNone: false, colCount: 3,
        visibleIf: "{q2}<=2",
        choices: [
            "생각했던 지원내용과 다르다.",
            "원하는 방송분석를 받지 못하였다.",
            "판로 연계에 도움이 되지 않았다.",
            "멘토링에 대한 결과가 만족스럽지 않다.",
        ]
    },
    
    { type: "comment", name: "q5", title: "멘토링 사업 참여 시 좋았던 점 또는 건의 사항 · 발전방안 등 귀사의 의견을 작성해 주십시오.", isRequired: false},
    ]
   };

   //방송준비 멘토링 - 만족도 조사
  const reqSurvey = {
    title: "방송준비 멘토링 지원사업 만족도 조사",
    description: "소상공인 TV홈쇼핑 및 T-커머스 입점 지원 멘토링지원사업 참여업체 만족도 조사",
    logo: "https://www.drmd.kr/static/media/logo.e46179e0.png",
    logoWidth: 60,
    logoHeight: 60,
    elements: [
     {
      type: "matrix", name: "q1", isRequired: true, 
      title: "홈쇼핑 방송준비 멘토링 지원사업의 참여 과정을 고려할 때 다음의 각 항목들에 대하여 만족도를 체크해 주십시오.",
      columns: [
          {value: 1,text: "매우 불만족"}, 
          {value: 2,text: "불만족"}, 
          {value: 3,text: "보통"}, 
          {value: 4,text: "만족"}, 
          {value: 5,text: "매우 만족"},
      ],
      rows: [
        {value: "q11",text: "① 사업에 대한 안내는 충분히 드렸습니까?"},
        {value: "q12",text: "② 서류 제출시 불편한 점은 없으셨습니까?"},
        {value: "q13",text: "③ 현장 진단 및 전문가 멘토링에 만족하십니까?"},
        {value: "q14",text: "④ 방송 제안서 가이드에 만족하십니까?"},
        {value: "q15",text: "⑤ 상세페이지 개선에 만족하십니까?"},
        {value: "q16",text: "⑥ 8개 선택 과업(sns홍보, 패키지 개선 등)에 만족하십니까?"},
        {value: "q17",text: "⑦ 수행기관의 멘토링 전반에 대해 만족하십니까?"},
      ]
      },
      {
        type: "rating", name: "q2", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족",
        title: "「홈쇼핑 방송준비(역량강화) 멘토링 사업」에 대한 종합 만족도 정도를 체크해주십시오."        
    },
    { type: "radiogroup", name: "q3", title: "8대 과업 중 귀사가 선택한 과업은 무엇이었습니까?", isRequired: true, hasNone: false, colCount: 3,
        choices: [
            "SNS홍보",
            "BI/CI 개선",
            "패키지개선",
            "품질인증컨설팅",
            "특허출원",
            "라이브커머스",
            "해외온라인 입점",
            "동영상 제작",
        ]
    },
    { type: "radiogroup", name: "q4", title: "8대 과업 중 귀사가 받고 싶은 과업은 무엇입니까? ", isRequired: true, hasNone: false, colCount: 3,
        choices: [
          "SNS홍보",
          "BI/CI 개선",
          "패키지개선",
          "품질인증컨설팅",
          "특허출원",
          "라이브커머스",
          "해외온라인 입점",
          "동영상 제작",
        ]
    },
    { type: "radiogroup", name: "q5", title: "8대 과업 외 지원 받고 싶은 과업은 무엇입니까?", hasOther: true, isRequired: true, hasNone: false, colCount: 3,
        choices: [
            "상품이미지 촬영",
            "유통 전문 교육",
            "오프라인 행사",
            "유통채널 MD상담(입점 상품 판매 전략)",
            "카드뉴스 홍보"
        ]
    },
    { type: "checkbox", name: "q6", title: "멘토링 과정 중 가장 만족스러웠던 과정은 무엇이었습니까? (복수응답가능)", isRequired: true, hasNone: false, colCount: 3,
        choices: [
            "현장진단멘토링",
            "역량진단 (닥터엠디 앱)",
            "상세페이지 개선 및 제작",
            "선택과업 결과물 (8개 중 택1)",
            "홈쇼핑역량강화 ZOOM 교육"
        ]
    },
    {
      type: "rating", name: "q7", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "전혀 그렇지 않다", maxRateDescription: "매우 그렇다",
      title: "「방송준비를 위한 멘토링이 홈쇼핑 진출에 도움이 된다고 생각하십니까?"        
    },
    {
      type: "rating", name: "q8", isRequired: true, rateMin: 0, rateMax: 5, minRateDescription: "전혀 그렇지 않다", maxRateDescription: "매우 그렇다",
      title: "「멘토링 지원 내용이 판로확대 등 향후 경영활동에 도움이 될 것으로 생각하십니까?"        
    },
    { type: "comment", name: "q9", title: "멘토링 사업 참여 시 좋았던 점 또는 건의 사항 · 발전방안 등 귀사의 의견을 작성해 주십시오.", isRequired: false},
    ]
   };



   const [data, setData] = useState({});


  const getData = async(id) => {

     db.collection("data").doc(id).collection("replies").doc(uid).get()
    .then(snapshot => {
      const d=snapshot.data();
      setData(d.mdData);
    })
    
  };

  const handleReadData = (e) => {
    const id=e.data;
    getData(id);
  };

  useEffect(() => {

    switch (mode) {
      case "req": setType(req); break; 
      case "reqReply": setType(reqReply); break; 
      case "reqSurvey": setType(reqSurvey); break;  
      //case "men": setType(men); break;
      case "menReply": setType(menReply); break; 
      case "menSurvey": setType(menSurvey); break; 
      case "diag": setType(diag); break; 
     // case "diagReply": setType(json4); break;
      default: setType(req);
    }

    if (isMobile && isIOS) {
      window.addEventListener('message', handleReadData);
      return () => {
        window.removeEventListener('message', handleReadData);
      };
      }
      if (isMobile && isAndroid) {
      document.addEventListener('message', handleReadData);
      return () => {
        document.removeEventListener('message', handleReadData);
      };  
      }
    //getDiag("JRfAyZkYYnUcm0492Xet")

  }, []);

  const myFunction = async(data) => {
    window.ReactNativeWebView.postMessage(data)
  }

 
   const onComplete=(survey, options)=>{
    //Write survey results into database
    const data=JSON.stringify(survey.data);
    //alert("Survey edit Data: " + JSON.stringify(survey.data));
    myFunction(data);
   }

   const model = new Survey.Model(type);
   model.data=data;
   /*
   model.data = {
    q1: {q11 : 1, q12 : 2, q13:3,q14:4,q15:5,q16:2,q17:1},
    q2 : 5,
    q3 : "SNS홍보"
  };*/

const onValueChanged=(sender, options)=>{
  console.log("sender : ",sender);
  console.log("options: ",options);
  console.log("options.value: ",options.value);
  //options.value


}

  //{"q1":1,"q5":["대형마트"]}
  return (
    <Survey.Survey model={model} onComplete={onComplete} onValueChanged={onValueChanged}/>
  );
};

export default MenEditReplied;
