import React, {useEffect } from 'react';
import { isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';
import firebase, {auth} from "../config/firebase";


const DiagChart3 = () =>{

  const handleReadData = (e) => {
    const c=JSON.parse(e.data);
    if(!!c.a){
      alert(c.a);
    }
    if(!!c.b){
      alert(c.b);
    }
    //alert(e.data);
  };

  const handleSendData = (e) => {
    window.ReactNativeWebView.postMessage("data");
  };



  useEffect(() => {
    if (isMobile && isIOS) {
      window.addEventListener('message', handleReadData);
      return () => {
        window.removeEventListener('message', handleReadData);
      };
      }
      if (isMobile && isAndroid) {
      document.addEventListener('message', handleReadData);
      return () => {
        document.removeEventListener('message', handleReadData);
      };  
      }
  }, []);

return (
    
        <div> 
          안녕<button onclick={()=>handleSendData} value="aaaa">Click me</button>
        </div>
)    
} 

export default DiagChart3;

/*
  useEffect(() => {
    window.addEventListener('message', handleReadData);
    // cleanup this component
    return () => {
      window.removeEventListener('message', handleReadData);
    };
  }, []);
*/