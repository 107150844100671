import React from 'react';
import Reqs from "./Reqs";
import { Redirect, Route, useLocation } from 'react-router-dom';

import firebase, {auth} from "../config/firebase";


const Reqz = ({location:{state}, history}) =>{
const user = auth.currentUser;
//const location = useLocation();

const {
  loginUserName,
  loginUserType,
  loginUserState,
  loginUserGroup
} = state;

return (
    
        <div> 
            {user ? 
             //<UserList userInfo={location.state}/>
             <Reqs loginUserName={loginUserName} loginUserType={loginUserType} loginUserState={loginUserState} loginUserGroup={loginUserGroup}/>
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default Reqz;