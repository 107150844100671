import React from 'react';
import firebase, {auth} from "../../config/firebase";

export default function Basket(props) {
  const { cartItems, onAdd, onRemove, setMode } = props;
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);
  const taxPrice = itemsPrice * 0.14;
  const shippingPrice = itemsPrice > 2000 ? 0 : 20;
  //const totalPrice = itemsPrice + taxPrice + shippingPrice;
  const totalPrice = itemsPrice;
  return (
<div className="container1">
  {cartItems.length === 0 ? (<div>장바구니가 비어있습니다.</div>):
  (
    <>
    {cartItems.map((item) => (
    <div key={item.id} className="grid-container">
      <div>
      <span style={{fontWeight: "bold"}}>{item.name}</span> <br />
      
      </div>
      <div>
      <button onClick={() => onRemove(item)} className="remove">삭제</button>
      </div>
    </div>
     
  ))}
  <hr style={{margin:"30px"}}/>
  
  </>
)}

</div>
  );
}
//<span style={{fontSize: "13px", color:"#aaa"}}>({item.qty} x {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원)</span>
//<span style={{fontWeight: "bold"}}>최종 신청 금액: {totalPrice>3000000? (totalPrice-3000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원</span>
//<button onClick={() => onAdd(item)} className="add">+</button>{' '}