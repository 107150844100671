import React, { useState, useEffect }  from 'react';
import Header from "../components/admin/Header";
import Navbar from "../components/admin/Navbar";

import ListNotices, {SelectColumnFilter} from "../components/admin/ListNotices";
import firebase, {auth} from "../config/firebase";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";
import Navbarmenu from '../components/menu/Navbarmenu';
import '../styles/navbarmenu.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();



const NoticeList = (props) => {
  const resultNotices = [];
  const [notices, setNotices] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const getUserInfo = async() => {
     
    const getAllUsers = await db.collection("users")
    .get();
    const allUsers=[];
  
    getAllUsers.docs.map(user => { 
      allUsers.push({
        value : user.data().uid,      
       label : `${user.data().profile.name} (${user.data().profile.bizInfo.bizName}, ${user.data().email})`,
       name : user.data().profile.name,
       token : user.data().token,
       state : user.data().state,
       userType : user.data().userType,
       email : user.data().email,
       company : user.data().profile.bizInfo.bizName
    })
  });
  
    setAllUsers(allUsers);
    //console.log("allUsers=>",allUsers);
  
  };

  useEffect(() => {

    getUserInfo();
    db.collection("notices")
  //db.collection("resp_min")
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
   
      response.forEach((doc) => {
        const notices = doc.data();
        /*
        const category="";
        if(typeof doc.data().profile.proInfo.category.name == undefined){
          category="";
        }else{
          category = doc.data().profile.proInfo.category.name;
        }
        const channel="";
        const channelCountry="";
        const channelType="";
        const yearsOfCareer="";
*/

        const data={
          id: doc.id,
          title : doc.data().title,
          body : doc.data().body,
          name : doc.data().name,
          uid : doc.data().uid,
          email : doc.data().email,
          userType : doc.data().userType,
          userState : doc.data().state,
          stateName : doc.data().stateName,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          files : doc.data().files,
          sendTo : doc.data().sendTo,
          pushOptionNo : doc.data().pushOptionNo,
          sendToInfo : doc.data().sendToInfo,
          sendToValue : doc.data().sendToValue,
          noticeType : doc.data().noticeType,
          replyFrom : doc.data().replyFrom,
        };
        //console.log("user no=>",doc.id);
        
        //req.id = doc.id;
        resultNotices.push(data);
      });
      setNotices(resultNotices);
    });

    

    //console.log(resultReqs);
 
  }, []);

    const columns = React.useMemo(
        () => [
          {
            Header: "번호",
            //accessor: d => d.id,
            //accessor: "prodName",
            Cell: ({row})  => row.id
          },
          {
            Header: "분류",
            accessor: d => `${d.noticeType==1 ? "공지" : "만족도 설문"}`,
            //accessor: "prodName",
          },
          {
            Header: "제목",
            accessor: "title",
          },
          {
            Header: "등록자",
            accessor: "name",
            show: window.innerWidth > 750 ? true : false
          },       
          {
            Header: "등록일",
            accessor: d => `${d.createAt.getFullYear()}.${d.createAt.getMonth()+1}.${d.createAt.getDate()}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "상세보기",
            accessor: d => <Link to={{ pathname:`/noticeList/${d.id}`, 
            state:{
                id: d.id,
                title : d.title,
                body : d.body,
                name : d.name,
                uid : d.uid,
                email : d.email,
                userType : d.userType,
                userState : d.userState,
                stateName : d.stateName,
                createAt : d.createAt,
                files : d.files,
                sendTo : d.sendTo,
                //allUsers : allUsers,
                pushOptionNo : d.pushOptionNo,
                sendToValue : d.sendToValue,
                noticeType : d.noticeType,
                replyFrom : d.replyFrom
            }}}>상세보기</Link>
            //accessor: d => <Link to={`/reqs/${d.id}`}>{d.prodName}</Link>,
            //accessor: d => <a href={`https://connectmd12.web.app/req/${d.id}`}>{d.id}</a>,
            //accessor: "prodName",
            //Cell: (props)  => <a href={`http://www.${props.prodName}!`}>{props.prodName}</a>
          },
          /*{
            Header: "진단요청 새창보기",
            accessor: d => <Link to={{ pathname:`/reqs/${d.idReq}`, 
            }} target="_blank" rel="noopener noreferrer">상세보기</Link>
            //accessor: d => <Link to={`/reqs/${d.id}`}>{d.prodName}</Link>,
            //accessor: d => <a href={`https://connectmd12.web.app/req/${d.id}`}>{d.id}</a>,
            //accessor: "prodName",
            //Cell: (props)  => <a href={`http://www.${props.prodName}!`}>{props.prodName}</a>
          },*/
        
        ],[]);

    //const data = React.useMemo(() => getData(), []);
    
    return (

      <div>
      <Navbarmenu />
      <div tw="min-h-screen bg-gray-100 text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
        
      <h1 tw="text-xl font-semibold">공지사항</h1>
      <Link to={{ pathname:"/noticeAdd",
        state : {
          allUsers : allUsers
        }
        
      }}>
      <PrimaryButton buttonRounded={true} as="a">신규 등록</PrimaryButton>
      </Link>
      
        </div>
        <div tw="mt-4">
        <ListNotices 
          columns={columns} 
          data={notices}
        />
      </div>
      </main>
    </div>
    </div>
);
}

export default NoticeList;

/*


        const userEmail = [];

        const user= await db.collection("users")
        .where("uid", "==", doc.data().createBy)
        .get()
        .then((user) => {


          user.forEach((u)=>{
            //console.log(u.id, "=>", u.data());
            //console.log(u.id, "=>", u.data().email);

            userEmail.push(u.data().email);

          })

          console.log("email=>",userEmail[0]);
          data.email=userEmail[0];
          //const mail = doc.data().email;
          //userEmail.push(mail);
          //console.log("email=>", mail)
        });

*/