import React, {useEffect, useState } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { isBrowser, isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';
import firebase, {auth} from "../../config/firebase";
import BarChart from "../../components/admin/BarChart";
import ReqDetail from "../../components/admin/ReqDetail";

import queryString from "query-string";
import Table from "react-bootstrap/Table";

const db=firebase.firestore();
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;

const DataDetail = ({ match, location }) => {
  const query = queryString.parse(location.search);
  const [mode, setMode] = useState(query.mode);
  console.log("mode->>",mode);

  const [data, setData] = useState("");
  const [cusChartData, setCusChartData] = useState([]);
  const [cusChartLabel, setCusChartLabel] = useState([]);
  const [mdChartData, setMdChartData] = useState([]);
  const [mdChartLabel, setMdChartLabel] = useState([]);

  //공통
  const [cusTotalSum, setCusTotalSum] = useState(0);
  const [mdTotalSum, setMdTotalSum] = useState(0);
  const [brand, setBrand] = useState("");
  const [cateName, setCateName] = useState("");
  const [price, setPrice] = useState("");
  const [prodName, setProdName] = useState("");
  const [isReplied, setIsReplied] = useState(null);
  const [channel, setChannel] = useState("");

  //req
  const [competProd, setCompetProd] = useState("");
  const [customer, setCustomer] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [unitItem, setUnitItem] = useState("");
  const [sellingPoint, setSellingPoint] = useState("");
  const [url, setUrl] = useState("");
  const [qDoc1, setQdoc1] = useState([]);
  const [qDoc2, setQdoc2] = useState([]);
  const [qDoc3, setQdoc3] = useState([]);
  const [qDoc4, setQdoc4] = useState([]);
  const [qDoc5, setQdoc5] = useState([]);

  //diag
  const [cusQ1Sum, setCusQ1Sum] = useState(0);
  const [cusQ2Sum, setCusQ2Sum] = useState(0);
  const [cusQ3Sum, setCusQ3Sum] = useState(0);
  const [cusQ4Sum, setCusQ4Sum] = useState(0);
  const [cusQ5Sum, setCusQ5Sum] = useState(0);
  const [cusQ6, setCusQ6] = useState([]);
  const [cusQ7, setCusQ7] = useState("");
  const [cusQ8, setCusQ8] = useState("");
  const [cusTitleLabel, setCusTitleLabel]=useState([]);
  const [displayName, setDisplayName] =  useState("");

  const [mdQ1Sum, setMdQ1Sum] = useState(0);
  const [mdQ2Sum, setMdQ2Sum] = useState(0);
  const [mdQ3Sum, setMdQ3Sum] = useState(0);
  const [mdQ4Sum, setMdQ4Sum] = useState(0);
  const [mdQ5Sum, setMdQ5Sum] = useState(0);
  const [mdQ6, setMdQ6] = useState([]);
  const [mdQ7, setMdQ7] = useState("");
  const [mdQ8, setMdQ8] = useState("");

  //men
  const [revenue,setRevenue] = useState(0);
  const [salesAt,setSalesAt] = useState("");
  const [salesTime,setSalesTime] = useState("");
  const [stock,setStock] = useState(0);
  const [unit,setUnit] = useState(0);
  const [mdTitleLabel,setMdTitleLabel] = useState([]);
  const [mdTotalAvg,setMdTotalAvg] = useState(null);  
  const [mdTitleChartData, setMdTitleChartData] = useState([]);
  

  //qa
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [replyCom, setReplyCom] = useState("");
  const [satisCom, setSatisCom] = useState("");
  const [rating, setRating] = useState(-1); 

  const handleReadData = (e) => {
    const id=e.data;
    getData(id);
  };
  //req
  const getData = async(id) => {
    const data = await db.collection("data").doc(id).get()
    .then(snapshot => {
      const d=snapshot.data()
      setData(d);
      console.log(d);
      
      if(d.type=="req"){
      setCusChartData(d.cusChartData);
      setCusChartLabel(d.cusChartLabel);
      setMdChartData(d.mdChartData);
      setMdChartLabel(d.mdChartLabel);
      setBrand(d.brand);
      setProdName(d.prodName);
      setCateName(d.cateName);
      setCompetProd(d.competProd);
      setCustomer(d.customer);
      setManufacturer(d.manufacturer);
      setPrice(d.price);
      setMdTotalSum(d.mdTotalSum);
      setUnitItem(d.unitItem);
      setSellingPoint(d.sellingPoint);
      setUrl(d.url);
      setQdoc1(d.qDoc1);
      setQdoc2(d.qDoc2);
      setQdoc3(d.qDoc3);
      setQdoc4(d.qDoc4);
      setQdoc5(d.qDoc5);
      
      }else if(d.type=="diag"){
      
        setCusChartData(d.cusChartData);
        setCusChartLabel(d.cusChartLabel);
        setMdChartData(d.mdChartData);
        setMdChartLabel(d.mdChartLabel);
        setCusTotalSum(d.cusTotalSum);
        setCusQ1Sum(d.cusQ1Sum);
        setCusQ2Sum(d.cusQ2Sum);
        setCusQ3Sum(d.cusQ3Sum);
        setCusQ4Sum(d.cusQ4Sum);
        setCusQ5Sum(d.cusQ5Sum);
        setMdQ1Sum(d.mdQ1Sum);
        setMdQ2Sum(d.mdQ2Sum);
        setMdQ3Sum(d.mdQ3Sum);
        setMdQ4Sum(d.mdQ4Sum);
        setMdQ5Sum(d.mdQ5Sum);
        setMdTotalSum(d.mdTotalSum);
        setCusQ6(d.cusQ6);
        setCusQ7(d.cusQ7);
        setCusQ8(d.cusQ8);
        setMdQ6(d.mdQ6);
        setMdQ7(d.mdQ7);
        setMdQ8(d.mdQ8);
        setIsReplied(d.isReplied);

        
      }else if(d.type=="men"){ 

        setMdChartData(d.mdChartData);
        setCusChartLabel(d.mdChartLabel);
        setBrand(d.brand);
        setProdName(d.prodName);
        setCateName(d.cateName);
        setPrice(d.price);
        setChannel(d.channel);
        setRevenue(d.revenue);
        setSalesAt(d.salesAt);
        setSalesTime(d.salesTime);
        setStock(d.stock);
        setUnit(d.unit);
        setMdTitleLabel(d.mdTitleLabel);
        setMdTotalAvg(d.mdTotalAvg); 
        setIsReplied(d.isReplied);
        setMdTitleChartData(d.mdTitleChartData);
        
      }else if(d.type=="qa"){ 
      
        setTitle(d.title);
        setBody(d.body);
        setCateName(d.cusData.cateName);
        setChannel(d.cusData.channel);
        setRating(d.rating);
        setReplyCom(d.replyCom);
        setSatisCom(d.satisCom);
      }else{ 
        console.log("etc");
      }
    })
  };

  const handleSendData = (e) => {
    window.ReactNativeWebView.postMessage("data");
  };



  useEffect(() => {

    //getData("gwhbvhI4RP0TBvy7vtUN");

    if (isMobile && isIOS) {
      window.addEventListener('message', handleReadData);
      return () => {
        window.removeEventListener('message', handleReadData);
      };
      }
      if (isMobile && isAndroid) {
      document.addEventListener('message', handleReadData);
      return () => {
        document.removeEventListener('message', handleReadData);
      };  
      }

  }, []);


  const chart={
    width: isBrowser ? "500px": "100%",
    marginTop: 30
  }

return (
    
<div style={{paddingLeft: 50, paddingRight: 50}}> 
  { mode == "req" &&    
  <div>
  <ReqDetail cusData={cusChartData} cusLabel={cusChartLabel} mdData={mdChartData}/>
  <Table bordered style={chart}>
  <tbody>
    <tr><th style={{width: "40%"}}>상품명</th><td  style={{width: "60%"}}>{prodName}</td></tr>
    <tr><th>브랜드</th><td>{brand}</td></tr>
    <tr><th>카테고리</th><td>{cateName}</td></tr>
    <tr><th>가격</th><td>{price}</td></tr>
    <tr><th>상품구성</th><td>{unitItem}</td></tr>
    <tr><th>제조사</th><td>{manufacturer}</td></tr>
    <tr><th>주요고객</th><td>{customer}</td></tr>
    <tr><th>상품특장점</th><td>{sellingPoint}</td></tr>
    <tr><th>경쟁상품</th><td>{competProd}</td></tr>
    <tr><th>품질서류</th><td>{qDoc1} {qDoc2} {qDoc3} {qDoc4} {qDoc5}</td></tr>
  </tbody>
  </Table>
  </div>
  }
  { mode == "men" &&  
  <>
  <div>
  <BarChart cusLabel={cusChartLabel} mdData={mdChartData}  mode={mode}/>
  <Table bordered style={chart}>
  <tbody>
    <tr><th style={{width: "40%"}}>상품명</th><td  style={{width: "60%"}}>{prodName}</td></tr>
    <tr><th>브랜드</th><td>{brand}</td></tr>
    <tr><th>카테고리</th><td>{cateName}</td></tr>
    <tr><th>가격</th><td>{price}</td></tr>
    <tr><th>판매수량</th><td>{unit}</td></tr>
    <tr><th>매출액</th><td>{revenue}</td></tr>
    <tr><th>잔여재고</th><td>{stock}</td></tr>
    <tr><th>방송사</th><td>{channel}</td></tr>
    <tr><th>방송일</th><td>{salesAt}</td></tr>
    <tr><th>방송시간</th><td>{salesTime}</td></tr>
    {isReplied && (
    <tr><th>총 점수</th><td>{mdTotalAvg}</td></tr>      
    )}

    {isReplied && (
    <tr><th>상세 점수</th><td>방송관리 : {mdTitleChartData[0]}<br/>
    상품성 : {mdTitleChartData[1]}<br/>
    상품관리 : {mdTitleChartData[2]}<br/>
    시장관리 : {mdTitleChartData[3]}<br/>
    MD분석 점수 : {mdTitleChartData[4]}<br/>
    </td></tr>
    )}
    {isReplied && (
    <tr><th>진출공략시장채널</th><td></td></tr>      
    )}
  </tbody>
  </Table>
  </div>
  </>
  }
  { mode == "diag" &&  
  <>
  <BarChart cusData={cusChartData} cusLabel={cusChartLabel} mdData={mdChartData}  mode={mode}/>
  <Table bordered style={chart}>
  <thead>
    <tr>
      <th></th>
      <th>자가진단</th>
      <th>코디진단</th>
    </tr>
  </thead>
  <tbody>
    <tr><th style={{width: "30%"}}>총점수(100점)</th><td style={{width: "35%"}}>{cusTotalSum}</td><td style={{width: "35%"}}>{mdTotalSum!=0? mdTotalSum:"미작성"}</td></tr>
    <tr><th>창업목표</th><td>{cusQ1Sum}</td><td>{mdQ1Sum!=0? mdQ1Sum:"미작성"}</td></tr>
    <tr><th>창업아이템</th><td>{cusQ2Sum}</td><td>{mdQ2Sum!=0? mdQ2Sum:"미작성"}</td></tr>
    <tr><th>창업자역량</th><td>{cusQ3Sum}</td><td>{mdQ3Sum!=0? mdQ3Sum:"미작성"}</td></tr>
    <tr><th>창업자금</th><td>{cusQ4Sum}</td><td>{mdQ4Sum!=0? mdQ4Sum:"미작성"}</td></tr>
    <tr><th>창업팀</th><td>{cusQ5Sum}</td><td>{mdQ5Sum!=0? mdQ5Sum:"미작성"}</td></tr>
    <tr><th>코칭 희망분야</th><td>1순위: {cusQ7}<br/>{cusQ6.join(", ")}</td><td>{mdQ7!=""? "1순위:"+mdQ7:""}{mdQ6.length === 0? "미작성":mdQ6.join(", ")}</td></tr>
    <tr><th>바라는점(코멘트)</th><td>{cusQ8}</td><td>{mdQ8!=""? mdQ8:"미작성"}</td></tr>
  </tbody>
  </Table>
  </>
  }
  { mode == "qa" &&    
  <div>
  <Table bordered style={chart}>
  <tbody>
    <tr><th style={{width: "40%"}}>질문 제목</th><td  style={{width: "60%"}}>{title}</td></tr>
    <tr><td colSpan='2'>{body}</td></tr>
    <tr><th style={{width: "40%"}}>채널</th><td  style={{width: "60%"}}>{channel}</td></tr>
    <tr><th style={{width: "40%"}}>카테고리</th><td  style={{width: "60%"}}>{cateName}</td></tr>
    <tr><th style={{width: "40%"}}>답변 내용</th><td>{replyCom==""?"미작성":replyCom}</td></tr>
    <tr><th style={{width: "40%"}} rowSpan="2">만족도</th><td>{rating<0?"미작성":rating+"점(5점 만점)"}</td></tr>
    <tr><td>{satisCom}</td></tr>
  </tbody>
  </Table>
  </div>
  }
</div>
)    
} 

export default DataDetail;