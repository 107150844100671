import React, {useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth} from "../config/firebase";

const db=firebase.firestore();


const UserApprove = ({location:{state}, history }) =>{
const user = auth.currentUser;
const uid=window.location.pathname.replace('/userApprove/', '');
//console.log(uid);

const onApprove = (uid) =>{
  
  db.collection('users').doc(uid).get().then((response) => {
    const user = response.data();
    const userTypeNo=user.userType;
    console.log(userTypeNo);
  let payLoad={}  
  if(userTypeNo==3){
    payLoad={
      state : 2,
      userType: 3,
      userGroup : 102,
    } 
  }else if(userTypeNo==2){

    payLoad={
      state : 2,
      userType: 2
    } 
  }else{
    

    payLoad={
      state : 2
    } 
  }
    
  db.collection("users").doc(uid).update(payLoad)
.then(() => {

  //console.log(uid);
  //console.log(payLoad);


      history.push({
        pathname: '/users',
        //search: '?query=abc',     
    });
    //window.location.replace("/users");
    //alert("삭제 완료되었습니다.")
})
.catch((error) => {
    console.error("Error to update state: ", error);
});

  });


  

  

}

useEffect(() => {
  onApprove(uid);
}, []);

return (
    
        <div> 
            {user  ? 
             (
              <>
              </>
             )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default UserApprove;

/*

to={{ pathname:`/notices`, 
      state:{
        email: aUEmail,
        uid : aUid,
        userName : aUName,
        userType : aUType,
        userState : aUState

      }}}
*/