import React, { useState, useEffect }  from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from "react-table";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import firebase, {auth} from "../../config/firebase";

const StyleImages = styled.div`
display:flex; 
height: auto;
width : 600px;
align-items: flex-start;
flex-wrap: wrap;
`;

const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #fff;
  color: #000;
  text-align: left;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(1) {
  font-weight: bold;  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;

.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;


const db=firebase.firestore();


const DetailChartTableMtr = ({ recomChs, columns, mdAvgData, data, mdComment, totalSum, totalAvg, mtrId, loginUserType}) =>{ 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })
  const [resp, setResp] = useState([]);
  const [score1, setScore1]=useState(mdAvgData[0]+mdAvgData[1]+mdAvgData[2]+mdAvgData[3]);
  const [score2, setScore2]=useState(mdAvgData[4]+mdAvgData[5]+mdAvgData[6]);
  const [score3, setScore3]=useState(mdAvgData[7]+mdAvgData[8]+mdAvgData[9]);
  const [score4, setScore4]=useState(mdAvgData[10]+mdAvgData[11]+mdAvgData[12]);
  const [score5, setScore5]=useState(totalAvg-(mdAvgData[0]+mdAvgData[1]+mdAvgData[2]+mdAvgData[3]+mdAvgData[4]+mdAvgData[5]+mdAvgData[6]+mdAvgData[7]+mdAvgData[8]+mdAvgData[9]+mdAvgData[10]+mdAvgData[11]+mdAvgData[12]));



  const user = auth.currentUser;

  const getResp = async(mtrId) => {
    const tempResp=[];
  
    let resps=null;

    if(loginUserType==0){
      resps = await db.collection("resp_mentoring")
    .where("idReq", "==", mtrId)
    .get();

    }else{
      resps = await db.collection("resp_mentoring")
      .where("idReq", "==", mtrId)
      .where("idUser", "==", user.uid)
    .get();

    }


    
    resps.docs.map(resp => {       
      tempResp.push(resp.data());
      
    })
    setResp(tempResp);

    
    //console.log("tempResp->",tempResp);
    //console.log(tempChartMdData);
  };


  useEffect(() => {
    //alert(resp);
    getResp(mtrId);
    

    /*
const tempMdCommentPoint=0;
    tempMdCommentPoint=Number(resp.data().chart_md_sum_data[13]);

    switch(tempMdCommentPoint) {
      case 1: return setMdCommentPoint(15);
      case 2: return setMdCommentPoint(20);
      case 3: return setMdCommentPoint(25);
      case 4: return setMdCommentPoint(30);
      case 5: return setMdCommentPoint(45);
    }
    */

    
    }, []);

return (

  <div>
  <StyledTable {...getTableProps()}
    style={{ 
      border: 'solid 1px #fff',
      width: "500px",
    }}>

   <tbody {...getTableBodyProps()}>   
   <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>총 점수(100점)
     </StyledTd>

     <StyledTd style={{width: "300px"}}>{totalAvg > 0 ? totalAvg+"점" : "미작성"}
     </StyledTd>
    </StyledTBodyTr>
    <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>상세 점수
     </StyledTd>
     <StyledTd style={{width: "300px"}}> 
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>방송관리 : </span>
      <span>{score1 > 0 ? score1.toFixed(2)+"점" : "미작성"} </span><br/>
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>상품성 : </span>
      <span>{score2 > 0 ? score2.toFixed(2)+"점" : "미작성"}</span><br/>
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>상품관리 : </span>
      <span>{score3 > 0 ? score3.toFixed(2)+"점" : "미작성"}</span><br/>
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>시장관리 : </span>
      <span>{score4 > 0 ? score4.toFixed(2)+"점":"미작성"}</span><br/>
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>MD분석 점수 : </span>
      <span>{score5 > 0 ? score5.toFixed(2)+"점": "미작성"}</span><br/>

     </StyledTd>
    </StyledTBodyTr>
    <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>진출공략시장채널
     </StyledTd>

     <StyledTd>{recomChs.length == 0 ? "미작성" : recomChs.join(", ")}
     </StyledTd>
    </StyledTBodyTr>

   <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>전문가 의견
     </StyledTd>
     <StyledTd colSpan="1">
       
       {resp.map(item => (
        <div key={item.id}>
          <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>{item.name}</span>
          <span style={{color: "#444", fontSize: 12}}> ( {item.channelName},</span>
          <span style={{color: "#444", fontSize: 12}}> {item.email},</span>
          <span style={{color: "#444", fontSize: 12}}> {item.bizName} )</span><br/>
          <span>- 분석의견 : {item.comment}</span><br/>
          <span>- 보완할점 : {item.disadvantage}</span><br/>

        <hr
        style={{
            color: "#ccc",
            backgroundColor: "#ccc",
            height: 0.5,
            marginTop : 10,
            marginBottom : 10
        }}
    />
        </div>
        
        ))}
     </StyledTd>
    </StyledTBodyTr>
   
     
   </tbody>
 </StyledTable>
  

</div>
);
}

export default DetailChartTableMtr;

/*
   <thead>
     {headerGroups.map(headerGroup => (
       <StyledTBodyTr {...headerGroup.getHeaderGroupProps()}>
         {headerGroup.headers.map(column => (
           <StyledTh
             {...column.getHeaderProps()}
             /*style={{
               borderBottom: 'solid 3px black',
               background: '#fff',
               color: 'black',
               fontWeight: 'bold',
             }}
             >
             {column.render('Header')}
           </StyledTh>
         ))}
       </StyledTBodyTr>
     ))}
   </thead>

*/

/*

{item.chart.data[13] == 1 && (<span>- MD분석점수 : 15점<br/></span>) }
          {item.chart.data[13] == 2 && (<span>- MD분석점수 : 20점<br/></span>) }
          {item.chart.data[13] == 3 && (<span>- MD분석점수 : 25점<br/></span>) }
          {item.chart.data[13] == 4 && (<span>- MD분석점수 : 30점<br/></span>) }
          {item.chart.data[13] == 5 && (<span>- MD분석점수 : 35점<br/></span>) }
*/



/*

 <StyledTBodyTr>
    <StyledTd colSpan="3" style={{textAlign: "center"}}>진단설명
     <p style={{color: "#ccc"}}>진단 항목별 점수차이가 나는 항목에 대해 설명합니다.</p>
     </StyledTd>
    </StyledTBodyTr>


     {rows.map(row => {
       prepareRow(row)
       return (
         <StyledTBodyTr {...row.getRowProps()}>
           {row.cells.map(cell => {
             return (
               <StyledTd
                 {...cell.getCellProps()}
                 //style={{
                   //padding: '10px',
                   //border: 'solid 1px #ccc',
                   //background: '#fff',
                   
                  
                 //}}
                 >
                 {cell.render('Cell')}
               </StyledTd>
             )
           })}
         </StyledTBodyTr>
       )
     })}
     */