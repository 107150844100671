import React, { useState, useEffect }  from 'react';
import Header from "../components/admin/Header";
import Navbar from "../components/admin/Navbar";
import Navbar2 from "../components/admin/Navbar2";


import ListReqs, {SelectColumnFilter} from "../components/admin/ListReqs";
import firebase, {auth} from "../config/firebase";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";
import Navbarmenu from '../components/menu/Navbarmenu';
import Navbarmenu2 from '../components/menu/Navbarmenu2';
import '../styles/navbarmenu.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();



const Reqs = (props) => {
  const resultReqs = [];
  const [reqs, setReqs] = useState([]);
  const [email, setEmail] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const user = auth.currentUser;

  const qDoc=[
    { 
      label: "인허가 서류>사업자등록증",
      value: 1000,
      name: "사업자등록증"
      },
      {
      label: "인허가 서류>제조/수입/판매업 신고(허가)증",
      value: 1001,
      name: "제조/수입/판매업 신고(허가)증"
      },
      {
      label: "인허가 서류>제조품목허가서",
      value: 1002,
      name: "제조품목허가서"
      },
      {
      label: "인허가 서류>의료기기 품질관리기준적합인정서(제조, 수입)",
      value: 1003,
      name: "의료기기 품질관리기준적합인정서(제조, 수입)"
      },
      {
      label: "인허가 서류>의료기기 광고사전심의",
      value: 1004,
      name: "의료기기 광고사전심의"
      },
      {
      label: "검사 성적서>원단시험성적서",
      value: 2000,
      name: "원단시험성적서"
      },
      {
      label: "검사 성적서>정수기 품질검사 성적서",
      value: 2001,
      name: "정수기 품질검사 성적서"
      },
      {
      label: "검사 성적서>재질 및 용출시험",
      value: 2002,
      name: "재질 및 용출시험"
      },
      {
      label: "검사 성적서>재질 성분시험",
      value: 2003,
      name: "재질 성분시험"
      },
      {
      label: "검사 성적서>완제품 시험성적서",
      value: 2004,
      name: "완제품 시험성적서"
      },
      {
      label: "검사 성적서>기타 시험성적서",
      value: 2005,
      name: "기타 시험성적서"
      },
      {
      label: "거래사실 확인서류>수입신고필증",
      value: 3000,
      name: "수입신고필증"
      },
      {
      label: "거래사실 확인서류>식품등의 수입신고필증",
      value: 3001,
      name: "식품등의 수입신고필증"
      },
      {
      label: "거래사실 확인서류>상품 거래 사실(OEM 포함)",
      value: 3002,
      name: "상품 거래 사실(OEM 포함)"
      },
      {
      label: "거래사실 확인서류>권한사용 계약서",
      value: 3003,
      name: "권한사용 계약서"
      },
      {
      label: "인증 서류>법 강제 인증서",
      value: 4000,
      name: "법 강제 인증서"
      },
      {
      label: "인증 서류>특허관련",
      value: 4001,
      name: "특허관련"
      },
      {
      label: "인증 서류>품질관련",
      value: 4002,
      name: "품질관련"
      },
      {
      label: "인증 서류>안전관련",
      value: 4003,
      name: "안전관련"
      },
      {
      label: "인증 서류>안정성 관련",
      value: 4004,
      name: "안정성 관련"
      },
      {
      label: "인증 서류>생산물책임보험",
      value: 4005,
      name: "생산물책임보험"
      },
      {
      label: "인증 서류>BSE 미감염증명서",
      value: 4006,
      name: "BSE 미감염증명서"
      },
      {
      label: "인증 서류>논문",
      value: 4007,
      name: "논문"
      },
      {
      label: "기타>제품 표준서",
      value: 5000,
      name: "제품 표준서"
      },
      {
      label: "기타>작업 지시(표준)서",
      value: 5001,
      name: "작업 지시(표준)서"
      },
      {
      label: "기타>등기부 등본",
      value: 5002,
      name: "등기부 등본"
      },
      {
      label: "기타>토지 대장",
      value: 5003,
      name: "토지 대장"
      },
      {
      label: "기타>비디오물 등급필증",
      value: 5004,
      name: "비디오물 등급필증"
      },
      {
      label: "기타>사용설명서 (주의사항)",
      value: 5005,
      name: "사용설명서 (주의사항)"
      },
      {
      label: "기타>표시사항",
      value: 5006,
      name: "표시사항"
      },
      {
      label: "기타>원산지 확약서",
      value: 5007,
      name: "원산지 확약서"
      }
  ];
  const foodQDoc=[
    {
      label: "업체관련>영업허가(신고)증",
      value: 100,
      name: "영업허가(신고)증"
      },
      {
      label: "업체관련>건강진단결과서(건강진단서)",
      value: 101,
      name: "건강진단결과서(건강진단서)"
      },
      {
      label: "업체관련>수질검사 성적서(노로바이러스 검사성적서)",
      value: 102,
      name: "수질검사 성적서(노로바이러스 검사성적서)"
      },
      {
      label: "업체관련>제조물책임보험증권",
      value: 103,
      name: "제조물책임보험증권"
      },
      {
      label: "업체관련>HACCP,GMP 제품표준서 및 관리기준서",
      value: 104,
      name: "HACCP,GMP 제품표준서 및 관리기준서"
      },
      {
      label: "업체관련>HACCP,GMP 심사내역(AUDIT 점검내역)",
      value: 105,
      name: "HACCP,GMP 심사내역(AUDIT 점검내역)"
      },
      {
      label: "업체관련>방충,방서관리일지",
      value: 106,
      name: "방충,방서관리일지"
      },
      {
      label: "업체관련>차량점검일지(냉장,냉동 차량온도기록지)",
      value: 107,
      name: "차량점검일지(냉장,냉동 차량온도기록지)"
      },
      {
      label: "업체관련>위생교육 수료증 사원 위생교육일지",
      value: 108,
      name: "위생교육 수료증 사원 위생교육일지"
      },
      {
      label: "업체관련>협력사/제조원 거래계약서",
      value: 109,
      name: "협력사/제조원 거래계약서"
      },
      {
      label: "제품 및 공정>품목제조보고(신고)서",
      value: 200,
      name: "품목제조보고(신고)서"
      },
      {
      label: "제품 및 공정>자가품질검사성적서",
      value: 201,
      name: "자가품질검사성적서"
      },
      {
      label: "제품 및 공정>영양성분분석성적서(제품 공간 비율 성적서)",
      value: 202,
      name: "영양성분분석성적서(제품 공간 비율 성적서)"
      },
      {
      label: "제품 및 공정>수입원료,제품서류",
      value: 203,
      name: "수입원료,제품서류"
      },
      {
      label: "제품 및 공정>[해당시] 원부재료 원산지증명서(유기농 취급시 유기농 인증서류)",
      value: 204,
      name: "[해당시] 원부재료 원산지증명서(유기농 취급시 유기농 인증서류)"
      },
      {
      label: "제품 및 공정>원,부재료 수불일지",
      value: 205,
      name: "원,부재료 수불일지"
      },
      {
      label: "제품 및 공정>원,부재료 입고검사일지 원부재료 상품안전성 검사 성적서",
      value: 206,
      name: "원,부재료 입고검사일지 원부재료 상품안전성 검사 성적서"
      },
      {
      label: "제품 및 공정>생산일지 / 작업일지 / 제조기록서(공정별 생산일지 포함 및 배합일지)",
      value: 207,
      name: "생산일지 / 작업일지 / 제조기록서(공정별 생산일지 포함 및 배합일지)"
      },
      {
      label: "제품 및 공정>판매상품별 제조공정도 제품별 공정(관리)일지",
      value: 208,
      name: "판매상품별 제조공정도 제품별 공정(관리)일지"
      },
      {
      label: "제품 및 공정>생산별(공정별) 제품안전성 검사일지 기구별 검/교정일지",
      value: 209,
      name: "생산별(공정별) 제품안전성 검사일지 기구별 검/교정일지"
      },
      {
      label: "제품 및 공정>동일제품 유통 현황 [제품거래내역(3년)]",
      value: 210,
      name: "동일제품 유통 현황 [제품거래내역(3년)]"
      },
      {
      label: "제품 및 공정>완제품 출고 및 반품 관리내역",
      value: 211,
      name: "완제품 출고 및 반품 관리내역"
      },
      {
      label: "제품 및 공정>냉장냉동 온도관리일지",
      value: 212,
      name: "냉장냉동 온도관리일지"
      },
      {
      label: "제품 및 공정>CCP 점검일지",
      value: 213,
      name: "CCP 점검일지"
      },
      {
      label: "제품 및 공정>컴플레인 처리리포트",
      value: 214,
      name: "컴플레인 처리리포트"
      },
      {
      label: "원료관련>거래명세서",
      value: 300,
      name: "거래명세서"
      },
      {
      label: "원료관련>수입원료,제품서류",
      value: 301,
      name: "수입원료,제품서류"
      },
      {
      label: "원료관련>GMO증빙 및 검사성적서(대두,옥수수,면화,카놀라,사탕무,알팔파)",
      value: 302,
      name: "GMO증빙 및 검사성적서(대두,옥수수,면화,카놀라,사탕무,알팔파)"
      },
      {
      label: "원료관련>품목제조보고서",
      value: 303,
      name: "품목제조보고서"
      },
      {
      label: "원료관련>시험성적서(잔류농약시험성적서 - 해당상품 취급시)",
      value: 304,
      name: "시험성적서(잔류농약시험성적서 - 해당상품 취급시)"
      },
      {
      label: "원료관련>도축증명서",
      value: 305,
      name: "도축증명서"
      },
      {
      label: "원료관련>등급판정서",
      value: 306,
      name: "등급판정서"
      },
      {
      label: "원료관련>수매확인서",
      value: 307,
      name: "수매확인서"
      },
      {
      label: "원료관련>원료 표시사항",
      value: 308,
      name: "원료 표시사항"
      },
      {
      label: "포장 및 광고>거래명세서(포장지 업체 관련 신고서류)",
      value: 400,
      name: "거래명세서(포장지 업체 관련 신고서류)"
      },
      {
      label: "포장 및 광고>포장제 시험성적서",
      value: 401,
      name: "포장제 시험성적서"
      },
      {
      label: "포장 및 광고>광고사전심의필증",
      value: 402,
      name: "광고사전심의필증"
      },
      {
      label: "포장 및 광고>상품별 표시사항(포장지 한도견본)",
      value: 403,
      name: "상품별 표시사항(포장지 한도견본)"
      },
      {
      label: "포장 및 광고>외박스 표시사항",
      value: 404,
      name: "외박스 표시사항"
      },
      {
      label: "포장 및 광고>설명서,보증서 등",
      value: 405,
      name: "설명서,보증서 등"
      },
      {
      label: "포장 및 광고>각종 인증서[제품표시내용 등]",
      value: 406,
      name: "각종 인증서[제품표시내용 등]"
      },
      {
      label: "포장 및 광고>관련 논문 및 특허",
      value: 407,
      name: "관련 논문 및 특허"
      },
      {
      label: "기타(공통)>원산지확약서",
      value: 500,
      name: "원산지확약서"
      },
      {
      label: "기타(공통)>서류,현장 개선 계획서",
      value: 501,
      name: "서류,현장 개선 계획서"
      },
      {
      label: "기타(공통)>기타자료(원료,상품,언론이슈 자료)",
      value: 502,
      name: "기타자료(원료,상품,언론이슈 자료)"
      }
  ];

  const getUserInfo = async() => {
     
    const getAllUsers = await db.collection("users")
    .where("userType", "==",2)
    //.orderBy("creatAt", "desc")
    .get();
    const allUsers=[];
  
    getAllUsers.docs.map(user => { 
      allUsers.push({
        value : user.data().uid,      
       label : `${user.data().profile.name} (${user.data().profile.bizInfo.bizName}, ${user.data().email})`,
       name : user.data().profile.name,
       token : user.data().token,
       state : user.data().state,
       userType : user.data().userType,
       email : user.data().email,
       company : user.data().profile.bizInfo.bizName
    })
  });
  
    setAllUsers(allUsers);
    //console.log("allUsers=>",allUsers);
  
  };


  const getEmail = async(uid) => {
    
    const users = await db.collection("users")
    .where("uid", "==", uid)
    .get();
    users.docs.map(user => {
      setEmail(user.data().email);
      //console.log("email=>",user.data().email)
    })
  };



  useEffect(() => {
    //getUserInfo();
      if(props.loginUserType==0){
        getUserInfo();
    
        db.collection("reqs_min")
        .orderBy("createAt", "desc")
        .get()
        .then((response) => {
          response.forEach((doc) => {
            const req = doc.data();
            //console.log("cus_data=>", doc.data().chart_cus_data)
            getEmail(doc.data().createBy);
            //console.log("email=>",email);
    
            const data={
              id: doc.id,
              prodName : doc.data().prodName,
              brand : doc.data().brand,
              cateNo : doc.data().cateNo,
              cateName : doc.data().cateName,
              manufacturer : doc.data().manufacturer,
              stateName : doc.data().stateName,
              competProd : doc.data().competProd,
              price : doc.data().price,
              sellingPoint : doc.data().sellingPoint,
              unitItem : doc.data().unitItem,
              customer : doc.data().customer,
              chart_cus_data : doc.data().chart_cus_data,
              chart_cus_label : doc.data().chart_cus_label,
              images : doc.data().images,
              createAt : new Date(doc.data().createAt.seconds * 1000),
              createBy : doc.data().createBy,
              sendTo : doc.data().sendTo,
              reqCompany : doc.data().reqCompany,
              reqEmail : doc.data().reqEmail,
              reqName : doc.data().reqName,
              qDocName : doc.data().qDocName,
              filedCount : Object.keys(req).length
    
            };
            //console.log("req no=>",doc.id);
            //req.id = doc.id;
            resultReqs.push(data);
          });
          setReqs(resultReqs);
        });
    
      }else if(props.loginUserType==1){
        getUserInfo();
        db.collection("reqs_min")
        .where("createBy", "==", user.uid)
        .orderBy("createAt", "desc")
        .get()
        .then((response) => {
          response.forEach((doc) => {
            const req = doc.data();
            //console.log("cus_data=>", doc.data().chart_cus_data)
            getEmail(doc.data().createBy);
            //console.log("email=>",email);
    
            const data={
              id: doc.id,
              prodName : doc.data().prodName,
              brand : doc.data().brand,
              cateNo : doc.data().cateNo,
              cateName : doc.data().cateName,
              manufacturer : doc.data().manufacturer,
              stateName : doc.data().stateName,
              competProd : doc.data().competProd,
              price : doc.data().price,
              sellingPoint : doc.data().sellingPoint,
              unitItem : doc.data().unitItem,
              customer : doc.data().customer,
              chart_cus_data : doc.data().chart_cus_data,
              chart_cus_label : doc.data().chart_cus_label,
              images : doc.data().images,
              createAt : new Date(doc.data().createAt.seconds * 1000),
              createBy : doc.data().createBy,
              sendTo : doc.data().sendTo,
              reqCompany : doc.data().reqCompany,
              reqEmail : doc.data().reqEmail,
              reqName : doc.data().reqName,
              qDocName : doc.data().qDocName,
              filedCount : Object.keys(req).length
            };
            //console.log("req no=>",doc.id);
            //req.id = doc.id;
            resultReqs.push(data);
          });
          setReqs(resultReqs);
        });
        
      }else{
    
        db.collection("reqs_min")
        .where("sendTo", "==",user.uid)
        .orderBy("createAt", "desc")
        .get()
        .then((response) => {
          response.forEach((doc) => {
            const req = doc.data();
            //console.log("cus_data=>", doc.data().chart_cus_data)
            getEmail(doc.data().createBy);
            //console.log("email=>",email);
    
            const data={
              id: doc.id,
              prodName : doc.data().prodName,
              brand : doc.data().brand,
              cateNo : doc.data().cateNo,
              cateName : doc.data().cateName,
              manufacturer : doc.data().manufacturer,
              stateName : doc.data().stateName,
              competProd : doc.data().competProd,
              price : doc.data().price,
              sellingPoint : doc.data().sellingPoint,
              unitItem : doc.data().unitItem,
              customer : doc.data().customer,
              chart_cus_data : doc.data().chart_cus_data,
              chart_cus_label : doc.data().chart_cus_label,
              images : doc.data().images,
              createAt : new Date(doc.data().createAt.seconds * 1000),
              createBy : doc.data().createBy,
              sendTo : doc.data().sendTo,
              reqCompany : doc.data().reqCompany,
              reqEmail : doc.data().reqEmail,
              reqName : doc.data().reqName,
              qDocName : doc.data().qDocName,
              filedCount : Object.keys(req).length
            };
            //console.log("req no=>",doc.id);
            //req.id = doc.id;
            resultReqs.push(data);
          });
          setReqs(resultReqs);
        });
    
    
    
      }

    //console.log(resultReqs);
 
  }, []);

    const columns = React.useMemo(
        () => [
          {
            Header: "번호",
            //accessor: d => d.id,
            //accessor: "prodName",
            Cell: ({row})  => row.id,
          },
          {
            Header: "상품명",
            accessor: "prodName",
          },
          {
            Header: "카테고리",
            accessor: "cateName",
            show: window.innerWidth > 750 ? true : false
          },
          
          {
            Header: "회사명",
            accessor: "reqCompany",
          },
          {
            Header: "담당자",
            accessor: "reqName",
            show: window.innerWidth > 750 ? true : false
          },
          {
            Header: "상태",
            accessor: "stateName",
            Filter: SelectColumnFilter, 
            filter: 'includes', 
            show: window.innerWidth > 750 ? true : false
          },
          {
            Header: "진단요청일",
            accessor: d => `${d.createAt.getFullYear()}.${d.createAt.getMonth()+1}.${d.createAt.getDate()}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "자가진단",
            accessor: d => `${d.chart_cus_data.length ===0 ? "X" : "O"}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "상세보기",
            accessor: d => <Link to={{ pathname:`/reqs/${d.id}`, 
            state:{
              prodName : d.prodName,
              competProd : d.competProd,
              brand : d.brand,
              cateName : d.cateName,
              manufacturer : d.manufacturer,
              stateName : d.stateName,
              competProd : d.competProd,
              price : d.price,
              sellingPoint : d.sellingPoint,
              unitItem : d.unitItem,
              customer : d.customer,
              chart_cus_data : d.chart_cus_data,
              chart_cus_label : d.chart_cus_label,
              images : d.images,
              createAt : d.createAt,
              createBy : d.createBy,
              sendTo : d.sendTo,
              reqCompany : d.reqCompany,
              reqEmail : d.reqEmail,
              reqName : d.reqName,
              qDocName : d.qDocName,
              loginEmail: user.email,
              loginUid : user.uid,
              loginUserName : props.loginUserName,
              loginUserType : props.loginUserType,
              loginUserState : props.loginUserState,
              loginUserGroup : props.loginUserGroup,
              idReq: d.id,
              cateNo : d.cateNo,
              allQDocs :  d.cateNo==2 ? foodQDoc : qDoc
            }}}>상세보기</Link>
            //accessor: d => <Link to={`/reqs/${d.id}`}>{d.prodName}</Link>,
            //accessor: d => <a href={`https://connectmd12.web.app/req/${d.id}`}>{d.id}</a>,
            //accessor: "prodName",
            //Cell: (props)  => <a href={`http://www.${props.prodName}!`}>{props.prodName}</a>
          },
                         
        ],[]);

    //const data = React.useMemo(() => getData(), []);
    
    return (

      <div>
      { props.loginUserType == 0 ?
      ( <Navbarmenu />  ) :
      ( <Navbarmenu2 loginUserGroup={props.loginUserGroup} /> ) 
      }  
  

      <div tw="min-h-screen bg-gray-100 text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
        
        <h1 tw="text-xl font-semibold">진단리스트</h1>

      { props.loginUserType == 1 &&  
<Link to={{ pathname:"/reqAdd",
state : {
  allUsers : allUsers
}

}}>
      <PrimaryButton buttonRounded={true} as="a">진단 요청</PrimaryButton>
      </Link>
}
      
        </div>
        <div tw="mt-4">
        <ListReqs 
          columns={columns} 
          data={reqs}

        
        />
      </div>
      </main>
    </div>
    </div>
);
}

export default Reqs;

/*


        const userEmail = [];

        const user= await db.collection("users")
        .where("uid", "==", doc.data().createBy)
        .get()
        .then((user) => {


          user.forEach((u)=>{
            //console.log(u.id, "=>", u.data());
            //console.log(u.id, "=>", u.data().email);

            userEmail.push(u.data().email);

          })

          console.log("email=>",userEmail[0]);
          data.email=userEmail[0];
          //const mail = doc.data().email;
          //userEmail.push(mail);
          //console.log("email=>", mail)
        });

*/



/*

useEffect(() => {
  
  if(userInfo.loginUserType==0){
    getUserInfo();

    db.collection("reqs_min")
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const req = doc.data();
        //console.log("cus_data=>", doc.data().chart_cus_data)
        getEmail(doc.data().createBy);
        //console.log("email=>",email);

        const data={
          id: doc.id,
          prodName : doc.data().prodName,
          brand : doc.data().brand,
          cateName : doc.data().cateName,
          manufacturer : doc.data().manufacturer,
          stateName : doc.data().stateName,
          competProd : doc.data().competProd,
          price : doc.data().price,
          sellingPoint : doc.data().sellingPoint,
          unitItem : doc.data().unitItem,
          customer : doc.data().customer,
          chart_cus_data : doc.data().chart_cus_data,
          chart_cus_label : doc.data().chart_cus_label,
          images : doc.data().images,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          createBy : doc.data().createBy,
          sendTo : doc.data().sendTo,
          reqCompany : doc.data().reqCompany,
          reqEmail : doc.data().reqEmail,
          reqName : doc.data().reqName,
          qDocName : doc.data().qDocName

        };
        //console.log("req no=>",doc.id);
        //req.id = doc.id;
        resultReqs.push(data);
      });
      setReqs(resultReqs);
    });

  }else if(userInfo.loginUserType==1){
    getUserInfo();
    db.collection("reqs_min")
    .where("createBy", "==", userInfo.loginUid)
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const req = doc.data();
        //console.log("cus_data=>", doc.data().chart_cus_data)
        getEmail(doc.data().createBy);
        //console.log("email=>",email);

        const data={
          id: doc.id,
          prodName : doc.data().prodName,
          brand : doc.data().brand,
          cateName : doc.data().cateName,
          manufacturer : doc.data().manufacturer,
          stateName : doc.data().stateName,
          competProd : doc.data().competProd,
          price : doc.data().price,
          sellingPoint : doc.data().sellingPoint,
          unitItem : doc.data().unitItem,
          customer : doc.data().customer,
          chart_cus_data : doc.data().chart_cus_data,
          chart_cus_label : doc.data().chart_cus_label,
          images : doc.data().images,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          createBy : doc.data().createBy,
          sendTo : doc.data().sendTo,
          reqCompany : doc.data().reqCompany,
          reqEmail : doc.data().reqEmail,
          reqName : doc.data().reqName,
          qDocName : doc.data().qDocName

        };
        //console.log("req no=>",doc.id);
        //req.id = doc.id;
        resultReqs.push(data);
      });
      setReqs(resultReqs);
    });
    
  }else{

    db.collection("reqs_min")
    .where("sendTo", "==",userInfo.loginUid)
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const req = doc.data();
        //console.log("cus_data=>", doc.data().chart_cus_data)
        getEmail(doc.data().createBy);
        //console.log("email=>",email);

        const data={
          id: doc.id,
          prodName : doc.data().prodName,
          brand : doc.data().brand,
          cateName : doc.data().cateName,
          manufacturer : doc.data().manufacturer,
          stateName : doc.data().stateName,
          competProd : doc.data().competProd,
          price : doc.data().price,
          sellingPoint : doc.data().sellingPoint,
          unitItem : doc.data().unitItem,
          customer : doc.data().customer,
          chart_cus_data : doc.data().chart_cus_data,
          chart_cus_label : doc.data().chart_cus_label,
          images : doc.data().images,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          createBy : doc.data().createBy,
          sendTo : doc.data().sendTo,
          reqCompany : doc.data().reqCompany,
          reqEmail : doc.data().reqEmail,
          reqName : doc.data().reqName,
          qDocName : doc.data().qDocName

        };
        //console.log("req no=>",doc.id);
        //req.id = doc.id;
        resultReqs.push(data);
      });
      setReqs(resultReqs);
    });



  }


  

    //console.log(resultReqs);
 
  }, []);

*/