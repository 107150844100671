import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import firebase, {auth} from "../config/firebase";
import Select from 'react-select'

const db=firebase.firestore();
const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const MonoSelect = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;


const Join3 = ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "중소기업 회원가입",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign In With Google",
      url: "https://google.com"
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign In With Twitter",
      url: "https://twitter.com"
    }
  ],
  submitButtonText = "중소기업 회원가입",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "resetPwd",
  signupUrl = "/admin",
  history
}) => {

  const [formData, setFormData] = useState(defaultFormValue());

  const checkEmail=(str)=>{                                                 
    var reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if(!reg_email.test(str)) {return false;}
    else {return true;}
}

  const onSubmit=(e)=>{
    e.preventDefault();
    if (formData.email=="" || formData.password=="") {
      alert("이메일과 비밀번호를 필수 입력해주세요.");
    }else if(!checkEmail(formData.email)){
      alert("올바른 이메일 주소가 아닙니다.");
    }else if (formData.password !=formData.repeatPassword) {
      alert("입력한 비밀번호가 일치하지 않습니다.");
    } else if (formData.password.length < 6) {
      alert("비밀번호는 최소 6글자 이상이어야 합니다.");
    }else if(formData.bizName==""){
      alert("회사명을 입력해주세요.");
    }else if(formData.bizNum==""){
      alert("사업자번호를 입력해주세요.");
    }else if(formData.profit==""){
      alert("전년도 매출액을 입력해주세요.");
    }else if(formData.name==""){
      alert("담당자명을 입력해주세요.");
    }else if(formData.phone==""){
      alert("전화번호를 입력해주세요.");
    
    }else {

      auth
        .createUserWithEmailAndPassword(formData.email, formData.password)
        .then((u) => {
          db.collection('users').doc(u.user.uid).set({
            email : formData.email,
            uid : u.user.uid,
            avatar : "",
            displayName : formData.name,
            creatAt : new Date(),
            updateAt : new Date(),
            argeeAt : new Date(),
            adAt : new Date(),
            privatAt : new Date(),
            approveAt : new Date(),
            userType : 1,
            userGroup : 12,
            state : 1,
            profile : {
              total_req_count : 0,
              name : formData.name,
              phone : formData.phone,
              bizInfo : {
                bizNum : formData.bizNum,
                bizName: formData.bizName,
                name : "",
                licensePhoto : "",
                profit : formData.profit
              },
              respondent : {
                email : "",
                uid : "",
                token : ""
              }
            },
            token : "webSignUpUser",
          })
          alert("중소기업으로 회원가입이 완료되었습니다. 영업일+2일 내에 담당자 확인 후 승인처리됩니다.");
          history.push({
            pathname: '/admin',
          });
        })
        .catch(() => {
          alert("이미 사용중인 이메일입니다. 다른 이메일을 입력해주세요.");
        });
      
      
    
    }
  };
  
  const onChange = (e, type) => {
    if(type=="bizNum" || type=="phone"){
      const value = e.target.value.replace(/\+|-/ig, '');
      setFormData({ ...formData, [type]: value });
    }else{
      setFormData({ ...formData, [type]: e.target.value });
    }


  };

  return (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              
              <Form>
                
                <Input 
                  type="text" 
                  placeholder="이메일"
                  onChange={(e) => onChange(e, "email")}
                  autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호" 
                onChange={(e) => onChange(e, "password")}
                autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호 재입력" 
                onChange={(e) => onChange(e, "repeatPassword")}
                autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="회사명 입력"
                  onChange={(e) => onChange(e, "bizName")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number"
                  placeholder="사업자번호 입력 (숫자만 입력)"
                  onChange={(e) => onChange(e, "bizNum")}
                  autoCapitalize='none'
                />
                <Input 
                  type="text"
                  placeholder="전년도 매출액 (숫자, 한글 모두 가능)"
                  onChange={(e) => onChange(e, "profit")}
                  autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="담당자명"
                  onChange={(e) => onChange(e, "name")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number" 
                  placeholder="전화번호 입력 (예: 01012345678)"
                  onChange={(e) => onChange(e, "phone")}
                  autoCapitalize='none'
                />
                <SubmitButton type="submit" onClick={onSubmit}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              </Form>
              <p tw="mt-6 text-xs text-gray-600 text-center">
                <a href={forgotPasswordUrl} tw="border-b border-gray-500 border-dotted">
                  비밀번호를 잊어버렸나요?
                </a>
              </p>
              <p tw="mt-8 text-sm text-gray-600 text-center">
                로그인하시겠어요?{" "}
                <a href={signupUrl} tw="border-b border-gray-500 border-dotted">
                  로그인
                </a>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
  );
}

function defaultFormValue() {
  return {
    email: "",
    password: "",
  };
}

export default Join3;