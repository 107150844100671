import React from 'react';
import firebase, {auth} from "../../config/firebase";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const db=firebase.firestore();

export default function Basket(props) {
  const { cartItems, onAdd, onRemove, setMode, products } = props;
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * Math.ceil(c.puchasPrice/(1-c.margin)/100) * 100, 0);
  const taxPrice = itemsPrice * 0.14;
  const shippingPrice = itemsPrice > 2000 ? 0 : 20;
  //const totalPrice = itemsPrice + taxPrice + shippingPrice;
  const totalPrice = itemsPrice;
  
  return (
<div>
  {cartItems.length === 0 ? (<div><span style={{fontSize: "0.875em"}}>장바구니가 비어있습니다.</span></div>):
  (
    <>
    {cartItems.map((prod) => {
    //console.log(prod);
    //console.log(products);
    const p = products.find(function(item, index, arr){
      return item.value == prod.value
    });
    console.log(p);
    
    let isSale=p.isSales=="일시중지" ||p.isSales=="품절"||p.isSales=="사용안함"||(p.isSales=="판매"&&p.curStock<=0)?false:true
    let salesPrice= Math.ceil(p.puchasPrice/(1-p.margin)/100) * 100;
    
    return(
  <div className="Post" key={p.id}>
    <div>
    <Row>
      <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>
            {p.isSales=="시장사입" ? (
              <>
            <span className="pointLabel">시장사입</span> &nbsp;&nbsp;
            <span style={isSale? {textDecoration: "none", fontSize: "0.875em"}:{textDecoration: "line-through", fontSize: "0.875em"}}>{p.name }</span><br/>
            <span style={isSale? {textDecoration: "none", fontSize: "0.875em"}:{textDecoration: "line-through", fontSize: "0.875em"}}>{p.description }</span>
            </>
            ):(
            <>
            <span style={isSale? {textDecoration: "none", fontSize: "0.875em"}:{textDecoration: "line-through", fontSize: "0.875em"}}>{p.name }</span><br/>
            <span style={isSale? {textDecoration: "none", fontSize: "0.875em"}:{textDecoration: "line-through", fontSize: "0.875em"}}>{p.description }</span>
            </>
            )}
      </Col>
      {isSale ? (
          <Col xs="auto" style={{paddingRight:0, paddingLeft :0, fontSize: "0.875em" }}>{p.isSales=="시장사입" && (<span className="pointLabel" style={{fontSize: "0.875em"}}>시장가 변동가능</span>)}
          &nbsp;&nbsp;{prod.qty} x {salesPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원&nbsp;&nbsp;
          <ButtonGroup>
            <Button type="button" onClick={() => onRemove(prod)} variant="dark" disabled={cartItems.find((x) => x.id === prod.id)==undefined?true:false}>-</Button>
            <Button type="button" onClick={() => onAdd(prod)} variant="danger"> +</Button>
          </ButtonGroup>
          </Col>
          ):(
            <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>
              <span style={{fontSize: "0.875em"}}>{p.isSales!="판매"? p.isSales:"일시 재고부족"}</span>
            </Col>
  
          )}
    </Row>

    </div>
  </div>   
  )
  })}
  <div  className="Post" style={{alignContent:"right"}}>
    <Row>
  <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>
  <span style={{marginTop: "30px", fontWeight: "bold", fontSize: "0.875em"}}>최종 주문 예정 금액:</span>
  </Col>
  <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>  <span style={{fontSize: "0.875em"}}>
   {totalPrice? totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0} 원</span>
   </Col>
   </Row>

  </div>
  </>
)}

</div>
  );
}


/*

    <div key={item.id} className="grid-container">
      <div>
      <span style={{fontWeight: "bold"}}>{item.label}</span> <br />
      <span style={{fontSize: "13px", color:"#aaa"}}>({item.qty} x {item.puchasPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원)</span>
      </div>
      <div>
      <button onClick={() => onAdd(item)} className="add">+</button>{' '}
      <button onClick={() => onRemove(item)} className="remove">-</button>
      </div>
    </div>

*/