import React, { useState, useEffect }  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import firebase, {auth} from "../config/firebase";
import Header from "../components/admin/Header";
import { useTable } from 'react-table'
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(odd) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const db=firebase.firestore();


const Resp = ({location:{state}}) =>{
    let { respId } = useParams();
    const {
        chart_label,
        chart_data,
        comment,
        idReq,
        idUser,
        nickName,
        createAt,
  } = state;

  const sumChartData = chart_data.reduce(function add(sum, currValue) {
    return sum + currValue;
  }, 0);


/*
    const getEmail = async(cus, md) => {
    
      const cusUsers = await db.collection("users")
      .where('uid', 'in', [cus, md])
      //.where("uid", "==", cus)
      //.where("uid", "==", md)
      .get();
      cusUsers.docs.map(user => {
        //console.log("uid=>", user.data().uid);
        if(user.data().uid == cus){
          setCusEmail(user.data().email);
          setCusName(user.data().profile.name);
          setBizName(user.data().profile.bizInfo.bizName);
        }else{
          setMdEmail(user.data().email);
          setMdName(user.data().profile.name);

        }
      })
    
    };

    useEffect(() => {

      //getEmail(createBy, sendTo);

             
      }, []);

*/
  return(

    <div tw="min-h-screen text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
      

    <div>
      <h1 tw="text-xl font-semibold">전문가 진단</h1>
      <StyledTable>
      {chart_label.map((label, i) =>      
        <StyledTBodyTr>
          <StyledTd>{label}</StyledTd>
          <StyledTd>{chart_data[i]} 점</StyledTd>
        </StyledTBodyTr>
      )}
      <StyledTBodyTr>
          <StyledTd>합계</StyledTd>
          <StyledTd>{sumChartData} 점</StyledTd>
        </StyledTBodyTr>
      <StyledTBodyTr>
          <StyledTd>전문가 의견</StyledTd>
          <StyledTd>{comment}</StyledTd>
        </StyledTBodyTr>

      </StyledTable> 

      <h1 tw="text-xl font-semibold">기본 정보</h1>
      <StyledTable>
        
        <StyledTBodyTr>
          <StyledTd>전문가</StyledTd>
          <StyledTd>{nickName}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>idUser</StyledTd>
          <StyledTd>{idUser}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>idReq</StyledTd>
          <StyledTd>{idReq}</StyledTd>
        </StyledTBodyTr>      
        <StyledTBodyTr>
          <StyledTd>등록일시</StyledTd>
          <StyledTd>{createAt.getFullYear()}.{createAt.getMonth() + 1}.{createAt.getDate()} {createAt.getHours()}:
              {createAt.getMinutes() < 10 ? "0" : ""}
              {createAt.getMinutes()}</StyledTd>
        </StyledTBodyTr>
      </StyledTable>      
    </div>
    </div>
      </main>
    </div>
  );
}
export default Resp;

/*
          
  <h3>{chart_cus_data}</h3>
      <h3>{chart_cus_label}</h3>
*/