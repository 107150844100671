import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Radar } from 'react-chartjs-2';

import Table from "react-bootstrap/Table";

import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 500px;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;

:nth-of-type(1) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;


const BarChart = ({ cusData, cusLabel, mdData, mode, data}) =>{ 
  console.log("mdData=> ",mdData);



  const chartData6 = {
    labels: cusLabel,
    datasets: [
      {
        label: '전문가진단',
        data: mdData,
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData,
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartData7 = {
    labels: cusLabel,
    datasets: [
      {
        label: '전문가진단',
        data: mdData,
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
    ],
  };
  
  
  const options = {
    scales: {
      r: {
          angleLines: {
              display: false
          },
          suggestedMin: 0,
          suggestedMax: 8
      }
  }
  };

  const chart={
    width: isBrowser ? "500px": "100%",
  }


return (

    <div style={chart}>
     { ((mode=="diag" && cusData.length != 0) || (mode=="men" && mdData.length != 0)) ?
     ( <div>
       <h1 tw="text-xl font-semibold" style={{marginBottom:30}}>전체결과</h1>
       {mode=="men" &&(
         <>
          <Radar data={chartData7} options={options} />  
         </>
       )}


       {mode=="diag" &&(
         <>
          <Radar data={chartData6} options={options} />  
       
         </>
       )}
       
       </div> ) :
     ( <p>로딩 중입니다</p> )

     }
    </div>
)
}

export default BarChart;


/*
  <h1 tw="text-xl font-semibold" style={{marginBottom:30}}>창업목표</h1>
       <Bar data={chartData1} options={options} />
       <h1 tw="text-xl font-semibold" style={{marginBottom:30, marginTop:60}}>창업아이템</h1>
       <Bar data={chartData2} options={options} />
       <h1 tw="text-xl font-semibold" style={{marginBottom:30, marginTop:60}}>창업자 역량</h1>
       <Bar data={chartData3} options={options} />
       <h1 tw="text-xl font-semibold" style={{marginBottom:30, marginTop:60}}>창업자금</h1>
       <Bar data={chartData4} options={options} />
       <h1 tw="text-xl font-semibold" style={{marginBottom:30, marginTop:60}}>창업팀</h1>
       <Bar data={chartData5} options={options} />
*/


/*

  const chartData = {
    labels: cusLabel,
    datasets: [
      {
        label: '전문가진단',
        data: mdData,
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData,
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
      
    ],
  };

  const chartData1 = {
    labels: cusLabel.slice(0,3),
    datasets: [
      {
        label: '전문가진단',
        data: mdData.slice(0,3),
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData.slice(0,3),
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartData2 = {
    labels: cusLabel.slice(3,8),
    datasets: [
      {
        label: '전문가진단',
        data: mdData.slice(3,8),
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData.slice(3,8),
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartData3 = {
    labels: cusLabel.slice(8,12),
    datasets: [
      {
        label: '전문가진단',
        data: mdData.slice(8,12),
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData.slice(8,12),
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartData4 = {
    labels: cusLabel.slice(12,14),
    datasets: [
      {
        label: '전문가진단',
        data: mdData.slice(12,14),
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData.slice(12,14),
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartData5 = {
    labels: cusLabel.slice(14),
    datasets: [
      {
        label: '전문가진단',
        data: mdData.slice(14),
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData.slice(14),
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
    ],
  };
*/