import React, {useState, useEffect} from 'react';
import NoticeList from "./NoticeList";
import { Redirect, Route } from 'react-router-dom';

import firebase, {auth} from "../config/firebase";


//const Notices = ({location:{state}}) =>{
const Notices = () =>{


  //const query = queryString.parse(location.pathname);
  //const query=location.pathname;

  //console.log("search",search)
  //console.log("pathname=>",pathname);

  const user = auth.currentUser;


/*
const {
  email,
  uid,
  userName,
  userType,
  userState
} = state;
*/
return (
    
        <div> 
           <NoticeList />
            
        </div>
)    
} 

export default Notices;

/*

{user || query.source=="notice" ? 
             <NoticeList />
             /*<NoticeList aUEmail={email} aUid={uid} aUName={userName} aUType={userType} aUState={userState} />
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }

*/