import React from 'react';

export default function Ord(props) {
  const { ord, setMode, SetCurProd} = props;
  const ordDate=new Date(ord.createAt.seconds * 1000);
  console.log(ordDate);
  const onLinkClick = (mode) => {
    SetCurProd(ord);
    setMode("detail");
    goToTop();

  };

const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
};
  return (
    <div className="card">
      <a href='#' onClick={()=>{onLinkClick("ords")}} style={{ textDecoration: 'none' }}>
      <div>
      <img src={ord.image} className="cardImg" />
      <div className="cardBody">
        <h2 className="cardTitle">{ord.name}</h2>
        <p className="cardDescription">{ord.bizName} / {ord.personName}</p>
        <p className="cardDescription">{ord.email} / {ord.phone}</p>
        <p className="cardDescription">신청일 : {ordDate.getFullYear()}.{ordDate.getMonth()+1}.{ordDate.getDate()}</p>
      </div>
      </div>
      </a>
    </div>
  );
}

/*
      <div className="cardBody">
        <h2 className="cardTitle">{ord.name}</h2>
        <p className="cardDescription">{ord.bizName} / {ord.personName}</p>
        <p className="cardDescription">{ord.email} / {ord.phone}</p>
        <p className="cardDescription">신청일 : {ord.createAt.getFullYear()}.{ord.createAt.getMonth()+1}.{ord.createAt.getDate()}</p>
      </div>


*/