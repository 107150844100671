import React, { useState, useEffect }  from 'react';
import Header from "../components/admin/Header";
import Navbar from "../components/admin/Navbar";
import Navbar2 from "../components/admin/Navbar2";

import ListReqs, {SelectColumnFilter} from "../components/admin/ListReqs";
import firebase, {auth} from "../config/firebase";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";
import Navbarmenu from '../components/menu/Navbarmenu';
import Navbarmenu2 from '../components/menu/Navbarmenu2';
import '../styles/navbarmenu.css';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();




const MtrList = (props) => {
  const resultReqs = [];
  const [reqs, setReqs] = useState([]);
  const [email, setEmail] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  const getUserInfo = async() => {
     
    const getAllUsers = await db.collection("users")
    .where("userGroup", "==", 102)
    .get();
    const allUsers=[];
  
    getAllUsers.docs.map(user => { 
      allUsers.push({
        value : user.data().uid, 
       label : `${user.data().profile.name} (${user.data().profile.bizInfo.bizName}, ${user.data().email})`,
       name : user.data().profile.name,
       token : user.data().token,
       state : user.data().state,
       userType : user.data().userType,
       email : user.data().email,
       company : user.data().profile.bizInfo.bizName,
    })
  });
  
    setAllUsers(allUsers);
    //console.log("allUsers=>",allUsers);
  
  };
  const getEmail = async(uid) => {
    
    const users = await db.collection("users")
    .where("uid", "==", uid)
    .get();
    users.docs.map(user => {
      setEmail(user.data().email);
      //console.log("email=>",user.data().email)
    })
  };

  useEffect(() => {

    if(props.loginUserType==0){

    getUserInfo();
  db.collection("reqs_mentoring")
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const req = doc.data();
        //console.log("cus_data=>", doc.data().chart_cus_data)
        getEmail(doc.data().createBy);
        //console.log("email=>",email);

        const data={
          id: doc.id,
          prodName : doc.data().prodName,
          brand : doc.data().brand,
          cateName : doc.data().cateName,
          cateNo : doc.data().cateNo,
          reqCompany : doc.data().reqCompany,
          reqEmail : doc.data().reqEmail,
          reqName : doc.data().reqName,
          video : doc.data().video,
          files : doc.data().files,
          price : doc.data().price,
          unit : doc.data().unit,
          revenue : doc.data().revenue,
          stock : doc.data().stock,
          channel : doc.data().channel,
          salesAt : doc.data().salesAt,
          salesTime : doc.data().salesTime,
          email : doc.data().email,
          uid : doc.data().uid,
          name : doc.data().name,
          userType : doc.data().userType,
          state : doc.data().state,
          stateName : doc.data().stateName,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          createBy : doc.data().createBy,
          sendTo : doc.data().sendTo,
          sendToInfo : doc.data().sendToInfo,
          chart_md_avg_label : doc.data().chart_md_avg_label,
          chart_md_sum_data : doc.data().chart_md_sum_data,
          chart_md_avg_data : doc.data().chart_md_avg_data,
          resCount	: doc.data().resCount,
          currResCount : doc.data().currResCount,
          isChart : doc.data().isChart,
          totalSum : doc.data().totalSum,
          totalAvg : doc.data().totalAvg,
          pushOptionNo : doc.data().pushOptionNo,
          sendToValue : doc.data().sendToValue,
          replyFrom : doc.data().replyFrom,
          recomCh : doc.data().recomCh
        };
        //console.log("req no=>",doc.id);
        //req.id = doc.id;
        resultReqs.push(data);
      });
      setReqs(resultReqs);
    });

    }else{

      db.collection("reqs_mentoring")
    .where("sendTo", "array-contains", props.loginUid)
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const req = doc.data();
        //console.log("cus_data=>", doc.data().chart_cus_data)
        getEmail(doc.data().createBy);
        //console.log("email=>",email);

        const data={
          id: doc.id,
          prodName : doc.data().prodName,
          brand : doc.data().brand,
          cateName : doc.data().cateName,
          cateNo : doc.data().cateNo,
          reqCompany : doc.data().reqCompany,
          reqEmail : doc.data().reqEmail,
          reqName : doc.data().reqName,
          video : doc.data().video,
          files : doc.data().files,
          price : doc.data().price,
          unit : doc.data().unit,
          revenue : doc.data().revenue,
          stock : doc.data().stock,
          channel : doc.data().channel,
          salesAt : doc.data().salesAt,
          salesTime : doc.data().salesTime,
          email : doc.data().email,
          uid : doc.data().uid,
          name : doc.data().name,
          userType : doc.data().userType,
          state : doc.data().state,
          stateName : doc.data().stateName,
          createAt : new Date(doc.data().createAt.seconds * 1000),
          createBy : doc.data().createBy,
          sendTo : doc.data().sendTo,
          sendToInfo : doc.data().sendToInfo,
          chart_md_avg_label : doc.data().chart_md_avg_label,
          chart_md_sum_data : doc.data().chart_md_sum_data,
          chart_md_avg_data : doc.data().chart_md_avg_data,
          resCount	: doc.data().resCount,
          currResCount : doc.data().currResCount,
          isChart : doc.data().isChart,
          totalSum : doc.data().totalSum,
          totalAvg : doc.data().totalAvg,
          pushOptionNo : doc.data().pushOptionNo,
          sendToValue : doc.data().sendToValue,
          replyFrom : doc.data().replyFrom,
          recomCh : doc.data().recomCh
        };
        //console.log("req no=>",doc.id);
        //req.id = doc.id;
        resultReqs.push(data);
      });
      setReqs(resultReqs);
    });

    }

    //console.log(resultReqs);
 
  }, []);

    const columns = React.useMemo(
        () => [
          {
            Header: "번호",
            //accessor: d => d.id,
            //accessor: "prodName",
            Cell: ({row})  => row.id
          },
          {
            Header: "상품명",
            accessor: "prodName",
          },
          {
            Header: "카테고리",
            accessor: "cateName",
            show: window.innerWidth > 750 ? true : false
          },
          
          {
            Header: "회사명",
            accessor: "reqCompany",
          },
          {
            Header: "담당자",
            accessor: "reqName",
            show: window.innerWidth > 750 ? true : false
          },
          {
            Header: "상태",
            accessor: "stateName",
            Filter: SelectColumnFilter, 
            filter: 'includes', 
            show: window.innerWidth > 750 ? true : false
          },
          {
            Header: "평균",
            accessor: d => `${d.totalAvg.toFixed(2)}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "분석요청일",
            accessor: d => `${d.createAt.getFullYear()}.${d.createAt.getMonth()+1}.${d.createAt.getDate()}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "상세보기",
            accessor: d => <Link to={{ pathname:`/mentoring/${d.id}`, 
            state:{
              prodName : d.prodName,
              brand : d.brand,
              cateName : d.cateName,
              cateNo : d.cateNo,
              reqCompany : d.reqCompany,
              reqEmail : d.reqEmail,
              reqName : d.reqName,
              video : d.video,
              files : d.files,
              price : d.price,
              unit : d.unit,
              revenue : d.revenue,
              stock : d.stock,
              channel : d.channel,
              salesAt : d.salesAt,
              salesTime : d.salesTime,
              email : d.email,
              uid : d.uid,
              name : d.name,
              userType : d.userType,
              state : d.state,
              stateName : d.stateName,
              createAt : d.createAt,
              createBy : d.createBy,
              sendTo : d.sendTo,
              sendToInfo : d.sendToInfo,
              chart_md_avg_label : d.chart_md_avg_label,
              chart_md_avg_data : d.chart_md_avg_data,
              resCount	: d.resCount,
              currResCount : d.currResCount,
              isChart : d.isChart,
              totalSum : d.totalSum,
              totalAvg : d.totalAvg,
              pushOptionNo : d.pushOptionNo,
              sendToValue : d.sendToValue,
              loginEmail: props.loginEmail,
              loginUid : props.loginUid,
              loginUserName : props.loginUserName,
              loginUserType : props.loginUserType,
              loginUserState : props.loginUserState,
              loginUserGroup : props.loginUserGroup,
              idReq: d.id,
              replyFrom : d.replyFrom,
              recomCh :d.recomCh
            }}}>상세보기</Link>
            //accessor: d => <Link to={`/reqs/${d.id}`}>{d.prodName}</Link>,
            //accessor: d => <a href={`https://connectmd12.web.app/req/${d.id}`}>{d.id}</a>,
            //accessor: "prodName",
            //Cell: (props)  => <a href={`http://www.${props.prodName}!`}>{props.prodName}</a>
          },



          
               
        ],[]);

    //const data = React.useMemo(() => getData(), []);
    
    return (

      <div>
      { props.loginUserType == 0 ?
      ( <Navbarmenu />  ) :
      ( <Navbarmenu2 loginUserGroup={props.loginUserGroup} /> ) 
      } 
      <div tw="min-h-screen bg-gray-100 text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">

     
      <div tw="">
        <h1 tw="text-xl font-semibold">방송분석</h1>
        
        { props.loginUserType == 0 && (
        <Link to={{ pathname:"/mentoringAdd",
        state : {
          allUsers : allUsers
        }
        
      }}>
      <PrimaryButton buttonRounded={true} as="a">신규 등록</PrimaryButton>
      </Link>
        )}
        </div>
        <div tw="mt-4">
        <ListReqs 
          columns={columns} 
          data={reqs}
          
        
        />
      </div>
      </main>
    </div>
    </div>
);
}

export default MtrList;

/*


        const userEmail = [];

        const user= await db.collection("users")
        .where("uid", "==", doc.data().createBy)
        .get()
        .then((user) => {


          user.forEach((u)=>{
            //console.log(u.id, "=>", u.data());
            //console.log(u.id, "=>", u.data().email);

            userEmail.push(u.data().email);

          })

          console.log("email=>",userEmail[0]);
          data.email=userEmail[0];
          //const mail = doc.data().email;
          //userEmail.push(mail);
          //console.log("email=>", mail)
        });

*/