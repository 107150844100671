import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import "firebase/compat/auth"
import 'firebase/compat/storage'
import 'firebase/app-check';

//dev firebase config
/*
const firebaseConfig = {
  apiKey: "AIzaSyD3cqGZDIkCFVb-cB1HVdSF0BUenOyibfw",
  authDomain: "dev01-48acc.firebaseapp.com",
  projectId: "dev01-48acc",
  storageBucket: "dev01-48acc.appspot.com",
  messagingSenderId: "484481903966",
  appId: "1:484481903966:web:3bab4cd7650d3a5d0d73cf"
};
*/


//firebase config
const firebaseConfig = {
    apiKey: "AIzaSyCBSrEfPY_X44iOaWNRbeLnEA-0Oi1qBYg",
    authDomain: "connectmd12.firebaseapp.com",
    databaseURL: "https://connectmd12.firebaseio.com",
    projectId: "connectmd12",
    storageBucket: "connectmd12.appspot.com",
    messagingSenderId: "984873279849",
    appId: "1:984873279849:web:e005b0ba3cb8471c42ddf8",
    measurementId: "G-6VCCQ7VBN1"
  };

export default firebase.initializeApp(firebaseConfig);
//export const firebaseApp=firebase.initializeApp(firebaseConfig);
//export const db=firebase.firestore(firebaseApp);

//export const auth = firebase.auth;
//export const db = firebase.database();

export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
