import React from 'react';
import Ord from './Ord';

export default function Ords(props) {
  const { ords, setMode, SetCurProd} = props;
  return (
      <div className="wrapper">
        {ords.length === 0 ? (<div><span style={{fontSize: "0.875em"}}>신청내역이 없습니다.</span></div>):
        (
        ords.map((ord) => (
          <Ord key={ord.ordId} setMode={setMode} SetCurProd={SetCurProd} ord={ord} />
        ))
        )}
      </div>
  );
}