import React from 'react';
import UserList from "./UserList";
import { Redirect, Route, useLocation } from 'react-router-dom';

import firebase, {auth} from "../config/firebase";


const Users = () =>{
const user = auth.currentUser;
//const location = useLocation();

return (
    
        <div> 
            {user ? 
             //<UserList userInfo={location.state}/>
             <UserList/>
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default Users;