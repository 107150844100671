import React, {useState, useEffect, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth, storage} from "../config/firebase";
import { set, useForm } from "react-hook-form";
import tw from "twin.macro";
import { Container as ContainerBase } from "components/misc/Layouts";
import styled from "styled-components";
import ColumnSelect from 'react-column-select'
import { v4 as uuidv4 } from "uuid"
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";
import { map } from "lodash";

const db=firebase.firestore();
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const SubHeading = tw.h1`text-xl xl:text-xl font-semibold`;

const FileUploadBtn = styled.button` background-color: black; border: 1px solid black; color: #fff;padding:8px;margin-top:16px;margin-bottom:32px;margin-right:16px;font-size:11px;`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const errorStyle = {
  color : 'red',
  fontSize : "10px"

}

const noticeStyle = {
  color : 'black',
  fontSize : "10px"

}

//const NoticeAdd = (history) =>{
const MtrEdit = ({location:{state}, history }) =>{
  
const user = auth.currentUser;

const {
  mtrId,
  prodName,
  brand,
  cateName,
  cateNo,
  reqCompany,
  reqEmail,
  reqName,
  video,
  files,
  price,
  unit,
  revenue,
  stock,
  channel,
  salesAt,
  salesTime,
  email,
  uid,
  name,
  userType,
  stateName,
  createAt,
  createBy,
  sendTo,
  sendToInfo,
  chart_md_avg_label,
  chart_md_avg_data,
  cjart_md_sum_data,
  resCount,
  currResCount,
  isChart,
  totalSum,
  totalAvg,
  pushOptionNo,
  sendToValue,
  allUsers,
} = state; 


useEffect(() => {

  setSelected(sendToValue);
}, []);

const resetFileInput=React.useRef();

const filterItems = (query) => {
  return allUsers.filter((el) =>
    el.userType.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
  );
}

const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [result, setResult] = useState("");
  const [pushOption, setPushOption] = useState("");
  const [pushOptionValue, setPushOptionValue] = useState(""); 
  const [selected, setSelected] = useState([])  
  const [allUserCopy, setAllUserCopy] = useState(allUsers);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [file, setFile] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [cateName2, setCateName2] = useState("");
  const [cateNo2, setCateNo2] = useState("");


  const [token, setToken] = useState("");


  const onFileChange = (event) => {
    setSelectedFiles(event.target.files);
    for (let i = 0; i < selectedFiles.length; i++) {
      //formData.append("myfile", selectedFiles[i], selectedFiles[i].name);
      console.log(selectedFiles[i]+" pdf =>"+selectedFiles[i].name)
    }

    const {target:{file, value}} = event;
    const theFile = file[0];
    const reader = new FileReader();
    setFile(value)
    reader.onloadend = (finishedEvent) => {
      const { currentTarget: {result}} = finishedEvent
      setAttachment(result)
    }
    reader.readAsDataURL(theFile);
  }
  const onClearAttachment = () => {
    setAttachment([]);
    setFile([]);
    resetFileInput.current.value="";
  };


  const onFileUpload = async (event) => {
    event.preventDefault();
    let attachmentUrl = "";
    if (attachment !== "") {
      const attachmentRef = storage.ref().child(`mentorings/${uuidv4()}`);
      const response = await attachmentRef.putString(attachment, "data_url")
      attachmentUrl = await response.ref.getDownloadURL()
    }
    const postObj = {
        text: "aaa",
        createdAt: Date.now(),
        creatorId: user.uid,
        attachmentUrl,
    }
    await db.collection("mentorings").add(postObj);
    setAttachment('');
    setFile('')
  };


  const handleFilesChange = (e) => {
    e.preventDefault();
    // files 배열 자체를 담을 것이므로 index 뺌.
    setSelectedFiles(e.target.files);

    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("myfile", e.target.files[i], e.target.files[i].name);
      console.log(e.target.files[i]+" pdf =>"+e.target.files[i].name)
    }
    setAttachment(formData.getAll("myfile"));
    //console.log(formData.getAll("myfile")[0].name);
    //console.log(formData.getAll("myfile")[1].name);
    
    //console.log(attachment[1].name);



  };

  const handleFilesUpload = async(event) => {
    event.preventDefault();
    //console.log(attachment[0].name);
    let fileName=[];
    let pdfBlob=[];
    for (let i = 0; i < attachment.length; i++) {
      let id=uuidv4();
      console.log(attachment[i].name)

      console.log(id);
      //console.log(attachment[i].name)

      
      try {
        await storage.ref("mentorings").child(id).put(attachment[i])
        .then((result) => {
           storage
          .ref(`mentorings/${id}`)
          .getDownloadURL()
          .then((fileUrl) => {
            pdfBlob.push(fileUrl);
          });
        });
      } catch (error) {
          console.log("error", error);
      }
    }
    setFileUrl(pdfBlob);
    console.log(pdfBlob);
    alert("파일이 성공적으로 업로드 되었습니다.");
    /*
    uploadFileStorage().then((response) => {
      setFileUrl(response);
      alert("파일이 성공적으로 업로드 되었습니다.");
    });

*/


  



  };

  const uploadFileStorage = async () => {
    const pdfBlob = [];

    await Promise.all(
      map(attachment, async (f) => {
        const response = await fetch(f);
        console.log(response);

        const blob = await response.blob();
        console.log("blob=>",blob);
        const ref = storage.ref("mentorings").child(uuidv4());
        await ref.put(blob).then(async (result) => {
          await storage
            .ref(`mentorings/${result.metadata.name}`)
            .getDownloadURL()
            .then((fileUrl) => {
              pdfBlob.push(fileUrl);
            });
        });
      })
    );

    return pdfBlob;
  };

  const selectCateName=(value)=>{

    switch(value) {
      case '1':
        return "생활/건강";
      case '2':
        return "식품";
      case '3':
        return "이미용";
      case '4':
        return "패션";
      case '5':
        return "가전";
      case '6':
        return "홈인테리어";
      case '7':
        return "유아동";
      case '8':
        return "스포츠/레저";
      case '9':
        return "기타";
      default:
        return 'default';
    }

  }

  const handleCategoryChange = (event) => {
    
    //console.log(event.target.value);
    setCateNo2(Number(event.target.value));
    const name=selectCateName(event.target.value);
    setCateName2(name);
    //console.log(name);
    event.preventDefault();

  }

 
  const handleChange = (event) => {
    setPushOption(event.target.value);
    setSelected([]);
    if(event.target.value==="0"){
      setSelected(allUserCopy);
      setPushOptionValue(0);
      //setAllUserCopy([]);
      console.log(allUserCopy);
    }else if(event.target.value==="1"){
      setSelected(filterItems(1));
      setPushOptionValue(1);
      //setAllUserCopy(filterItems(2));
      console.log(filterItems(1));
    }else if(event.target.value==="2"){
      setSelected(filterItems(2));
      setPushOptionValue(2);
      //setAllUserCopy(filterItems(1));
      console.log(filterItems(2));
    }else if(event.target.value==="3"){
      setSelected(filterItems(3));
      setPushOptionValue(3);
      //setAllUserCopy(filterItems(1));
      console.log(filterItems(3));
    }else {
      event.preventDefault();
    }
    //console.log("selected=>",selected);

    //console.log("pushOption->",event.target.value);
  }

  const onChange = (values) => {
    setSelected(values)
    console.log("selected=>", values)
  }
  const onSubmit = async (data) => {
    //데이터 전달받기
    //console.log("title=>",data.title);    
    //setResult(JSON.stringify(data));
    //console.log(data);
    if(selected.length==0){
    //if(pushOptionValue == 3 || (pushOption =="3" && selected.length==0)){
    //if(pushOption =="default " || pushOption !="0" || pushOption !="1" || pushOption!="2" || pushOption!="3"){
      alert("푸시 수신자를 선택해주세요.");
    }else{

      console.log(selected);
      let uid=[];
      let token=[];
      let company=[];
      let name=[];
      let email=[];
      let sendToValue = [];
     
      for ( const item of selected){
        try{

          const users = await db.collection("users")
          .where("uid", "==", item.value)
          .get(); 
          users.docs.map(user => {

            uid.push(user.data().uid);
            token.push(user.data().token);
            company.push(user.data().profile.bizInfo.bizName);
            name.push(user.data().profile.name);
            email.push(user.data().email);
            sendToValue.push(
              {
              label : `${user.data().profile.name} (${user.data().profile.bizInfo.bizName}, ${user.data().email})`,
               value : user.data().uid
              },
            );  
            }) 

         
        }catch (error){
          console.log(error);
        }

      };
      
      //console.log("uid=>", uid)
      //console.log("company=>", company)
      //console.log("email=>", email)

      await db.collection("reqs_mentoring").doc(mtrId).update({
        prodName : data.prodName,
        brand : data.brand,
        cateName : cateName,
        cateNo : cateNo,
        reqCompany : data.reqCompany,
        reqEmail : data.reqEmail,
        reqName : data.reqName,
        video : data.youtubeCode,
        files : (files!="" && fileUrl=="") ? files : fileUrl,
        price : data.price,
        unit : data.unit,
        revenue : data.revenue,
        stock : data.stock,
        channel : data.channel,
        salesAt : data.salesAt,
        salesTime : data.salesTime,
        updateAt : new Date(),
        sendTo : uid,
        sendToToken : token,
        sendToInfo : {
          company : company,
          name : name,
          email : email,
          uid : uid,
          token : token
        },
        sendToValue : sendToValue,
        resCount	: uid.length,
        replyFrom : [],
       
   
    })
    .then((docRef) => {
      
        history.push({
          pathname: '/mentorings',
          //search: '?query=abc',
      });
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
    });

    
    }
  }

  const uploadedFile = () => {
    const result = [];
 
    for (let i = 0; i < attachment.length; i++) {
      result.push(<span style={noticeStyle} key={i}>{attachment[i].name}<br/></span>);
    }
    return result;
  };

return (
    
        <div> 
            {user ? 
             (
             
              <Content>
                <MainContainer>
                <Heading>방송분석 수정</Heading>
                <SubHeading>기본정보</SubHeading>
              <FormContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
             <div>
               작성자 : {name} ( {email} )
              </div>

              <div>
               카테고리
               <Select value={cateNo} onChange={handleCategoryChange}>
               <option value="default">카테고리 선택</option>
                  <option value="1" label="생활/건강">생활/건강</option>
                  <option value="2">식품</option>
                  <option value="3">이미용</option>
                  <option value="4">패션</option>
                  <option value="5">가전</option>
                  <option value="6">홈인테리어</option>
                  <option value="7">유아동</option>
                  <option value="8">스포츠/레저</option>
                  <option value="9">기타</option>
          </Select>
              </div>
             <div>
               상품명 <Input defaultValue={prodName} {...register("prodName", { required: true })} />
               {errors.title && <span style={errorStyle}>상품명을 입력하세요.</span>}
              </div>
              <div>
               브랜드 <Input defaultValue={brand} {...register("brand", { required: true })} />
               {errors.title && <span style={errorStyle}>브랜드를 입력하세요.</span>}
              </div>
              
              <div>
               중소기업 회사명 <Input defaultValue={reqCompany} {...register("reqCompany", { required: true })} />
               {errors.title && <span style={errorStyle}>중소기업의 회사명을 입력하세요.</span>}
              </div>
              <div>
               중소기업 이메일 <Input defaultValue={reqEmail} {...register("reqEmail", { required: true })} />
               {errors.title && <span style={errorStyle}>중소기업의 이메일주소를 입력하세요.</span>}
              </div>
              <div>
               중소기업 담당자명 <Input defaultValue={reqName} {...register("reqName", { required: true })} />
               {errors.title && <span style={errorStyle}>중소기업의 담당자를 입력하세요.</span>}
              </div>

              <div>
                파일 업로드
                <span style={noticeStyle}> Ctrl 키로 파일 다중 선택이 가능합니다.</span>
                <Input type="file" onChange={handleFilesChange} accept="application/pdf, application/vnd.ms-excel" multiple ref={resetFileInput}/> 
                
                {uploadedFile()}
                <FileUploadBtn onClick={handleFilesUpload}>전체 업로드</FileUploadBtn>
                <FileUploadBtn onClick={onClearAttachment}>전체 삭제</FileUploadBtn>
            
              </div>
              <div>
               유튜브 영상코드
               <span style={noticeStyle}> 공유&#62;퍼가기&#62;소스 내 11자리의 동영상 코드를 입력해주세요.</span>
               <Input defaultValue={video} {...register("youtubeCode", { required: false })} />
               {errors.title && <span style={errorStyle}>유튜브 영상코드를 입력하세요.</span>}
              </div>
          
              <div>
               푸시 수신자 선택 
               
             <ColumnSelect
        options={allUserCopy}
        defaultValue={sendToValue}
        onChange={onChange}
        leftHeader='전체'
        rightHeader='선택된 수신자'
        isSearchable={true}
        searchPlaceholder="검색..."
        theme={{
          headerBgColor: '#eee',
          columnBorderColor: '#ccc',
          textColor: '#000000',
          columnBgColor: '#ccc',
          buttonBgColor: '#ccc',
          optionSelectedBgColor: '#eee',
          optionHoverBgColor: '#eee',
          searchFocusBorderColor: '#ccc',
        }}
      />
      
               </div>
            
               <SubHeading>판매정보</SubHeading>
               <div>
               판매가 <Input type="number" defaultValue={price} {...register("price", { required: true })} />
               {errors.title && <span style={errorStyle}>판매가를 입력하세요.</span>}
              </div>
              <div>
               판매수량 <Input type="number" defaultValue={unit} {...register("unit", { required: true })} />
               {errors.title && <span style={errorStyle}>판매수량을 입력하세요.</span>}
              </div>
              <div>
               매출액 <Input type="number" defaultValue={revenue} {...register("revenue", { required: true })} />
               {errors.title && <span style={errorStyle}>매출금액을 입력하세요.</span>}
              </div>
              <div>
               잔여재고 <Input type="number" defaultValue={stock} {...register("stock", { required: true })} />
               {errors.title && <span style={errorStyle}>잔여재고를 입력하세요.</span>}
              </div>
              <div>
               방송사 <Input defaultValue={channel} {...register("channel", { required: true })} />
               {errors.title && <span style={errorStyle}>방송사를 입력하세요.</span>}
              </div>
              <div>
               방송일 <Input defaultValue={salesAt} {...register("salesAt", { required: true })} />
               {errors.title && <span style={errorStyle}>방송일을 입력하세요.</span>}
              </div>
              <div>
               방송시간대 <Input defaultValue={salesTime} {...register("salesTime", { required: true })} />
               {errors.title && <span style={errorStyle}>방송시간대를 입력하세요.</span>}
              </div>



             <SubmitButton type="submit">
                  <span className="text">수정</span>
                </SubmitButton>
           </form>
           </FormContainer>
           </MainContainer>
           </Content>
          
             )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default MtrEdit;

/*

to={{ pathname:`/notices`, 
      state:{
        email: aUEmail,
        uid : aUid,
        userName : aUName,
        userType : aUType,
        userState : aUState

      }}}
*/

/*
<Select value={pushOption} onChange={handleChange}>
            <option value="default">그룹 선택</option>
            <option value="default">-------</option>
            <option value="2">방송분석 전문가 전체 선택</option>
            <option value="3">직접 선택</option>
          </Select>

*/