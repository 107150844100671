import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import {FiAlignRight,FiXCircle,FiChevronDown } from "react-icons/fi";
import logo from "../../images/logo32.png";
import tw from "twin.macro";
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
  } from '../admin/NavbarElements';

const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;


const Navbarmenu = () => {

    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const toggleClass = () => {
      setisMenu(isMenu === false ? true : false);
      setResponsiveclose(isResponsiveclose === false ? true : false);
  };

    let boxClass = ["main-menu menu-right menuq1"];
    if(isMenu) {
        boxClass.push('menuq2');
    }else{
        boxClass.push('');
    }




    const [isSubProd, setIsSubProd] = useState(false);
    const [isSubStock, setIsSubStock] = useState(false);
    const [isSubPurch, setIsSubPurch] = useState(false);
    const [isSubUnitP, setIsSubUnitP] = useState(false);
    const [isSubSale, setIsSubSale] = useState(false);
    const [isSubOpp, setIsSubOpp] = useState(false);
    const [isSubUser,setIsSubUser] = useState(false);
    const [isSubDiag,setIsSubDiag] = useState(false);


      
    const toggleSubmenu = (n) => {
        switch(n) {
            case 1: return setIsSubProd(isSubProd === false ? true : false);
            case 2: return setIsSubStock(isSubStock === false ? true : false);
            case 3: return setIsSubPurch(isSubPurch === false ? true : false);
            case 4: return setIsSubUnitP(isSubUnitP === false ? true : false);
            case 5: return setIsSubSale(isSubSale === false ? true : false);
            case 6: return setIsSubOpp(isSubOpp === false ? true : false);
            case 7: return setIsSubUser(isSubUser === false ? true : false);
            case 8: return setIsSubDiag(isSubDiag === false ? true : false);
            default: return 0;
          }

        
      };

    

    let boxClassSubProd = ["sub__menus"];
    let boxClassSubStock = ["sub__menus"];
    let boxClassSubPurch = ["sub__menus"];
    let boxClassSubUnitP = ["sub__menus"];
    let boxClassSubSale = ["sub__menus"];
    let boxClassSubOpp = ["sub__menus"];
    let boxClassSubUser = ["sub__menus"];
    let boxClassSubDiag = ["sub__menus"];

    
    if(isSubProd) {
        boxClassSubProd.push('sub__menus__Active');
    }else if(isSubStock) {
        boxClassSubStock.push('sub__menus__Active');
    }else if(isSubPurch) {
        boxClassSubPurch.push('sub__menus__Active');
    }else if(isSubUnitP) {
        boxClassSubUnitP.push('sub__menus__Active');
    }else if(isSubSale) {
        boxClassSubSale.push('sub__menus__Active');
    }else if(isSubOpp) {
        boxClassSubOpp.push('sub__menus__Active');
    }else if(isSubUser){
        boxClassSubUser.push('sub__menus__Active');
    }else if(isSubDiag){
        boxClassSubDiag.push('sub__menus__Active');
    }


   

    return (
        <>
        <Nav>
          <LogoContainer>
              <LogoImg src={logo} />
              <LogoText>Dr.MD</LogoText>
              
            </LogoContainer>
    
        
          <div className="header__middle__menus">
                    <nav className="main-nav " >

                    {isResponsiveclose === true ? <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                    </> : <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignRight />   </span>
                    </>}


                    <ul className={boxClass.join(' ')}>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/dashboard`}>홈쇼핑역량진단</NavLink> </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/mentorings`}>홈쇼핑방송분석</NavLink> </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/diags`}>창업역량진단</NavLink> </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/users`}>회원</NavLink> </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/notices`}>공지사항</NavLink> </li>
                    </ul>


                    </nav>     
                </div> 
        </Nav>
      </>
    )
}

export default Navbarmenu

/*

             <li  className="menu-item" >
                        <NavLink exact activeClassName='is-active' onClick={toggleClass} to={`/`}> Home </NavLink> 
                    </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/About`}> About </NavLink> </li>
                    <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="#"> Shop <FiChevronDown /> </Link>
                        <ul className={boxClassSubMenu.join(' ')} > 
                            <li> <NavLink onClick={toggleClass} activeClassName='is-active'  to={`/Online`}> Online Shop </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Offline`}> Offline Shop </NavLink> </li>
                        </ul>
                    </li>
                    
                    


                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Contact`}> Contact </NavLink> </li>
                    

*/


/*

return (
    <header className="header__middle">
        <div className="container">
            <div className="row">

                <div className="header__middle__logo">
                        
                        <LogoImg src={logo} />
                 </div>

                <div className="header__middle__menus">
                    <nav className="main-nav " >

                    {isResponsiveclose === true ? <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                    </> : <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignRight />   </span>
                    </>}


                    <ul className={boxClass.join(' ')}>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/dashboard`}>진단리스트</NavLink> </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/mentorings`}>방송분석</NavLink> </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/users`}>회원리스트</NavLink> </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/notices`}>공지사항</NavLink> </li>
                    


       
                    </ul>


                    </nav>     
                </div>   

            </div>
	    </div>
    </header>
    )
}

*/