import Prods from '../components/cart2/Prods';
import OrdList from '../components/cart2/OrdList';

import CancelBasket from '../components/cart2/CancelBasket';
import Ords from '../components/cart2/Ords';
import React, { useState, useEffect, useId } from 'react';
import "../styles/cart2.css";
import { isBrowser, isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';

import firebase, {auth} from "../config/firebase";
import { getDoc, doc, setDoc, getFirestore, getDocs, where, limit, query, orderBy, collection, addDoc, updateDoc, serverTimestamp, deleteDoc, deleteField, runTransaction, increment } from "firebase/firestore";
import { getDatabase, ref, child, get, set, update, increment as incre, orderByChild } from "firebase/database";
import { useUser, FirestoreProvider, useFirestore, useFirebaseApp, useFirestoreCollectionData, useDatabase, useDatabaseListData, useFirestoreDocData, useDatabaseObjectData } from 'reactfire';
import { useParams, useLocation } from 'react-router-dom';
import moment from "moment";
import { BsCartFill } from 'react-icons/bs';
import toast, { Toaster } from 'react-hot-toast';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const db=firebase.firestore();

const delCartMsg = (msg) => toast.error(msg, { duration: 500 });
const addCartMsg = (msg) => toast.success(msg, { duration: 500 });
const ordMaxMsg = (msg) => toast.error(msg, { duration: 500, icon: '⚠️' });
const addCancelCartMsg = (msg) => toast.success(msg, { duration: 800 });


const headerStyle = {
  backgroundColor: "#fff",
  fontSize: "0.875em",
  color: "#000",
  borderBottom: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "30px",
  position: "fixed",
  left: "0",
  top: "0",
  height: "65px",
  width: "100%",
  zIndex: 99
};

const footerStyle = {
  backgroundColor: "#000",
  fontSize: "0.875em",
  color: "white",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "10px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "40px",
  width: "100%",
  zIndex: 100
};

function AppOrds() {
  const [curProd, SetCurProd] = useState();

  const [searchTerm, setSearchTerm] = useState("");

  const [cartItems, setCartItems] = useState([]);
  const [mode, setMode] = useState("ords");
  const [ords, setOrds] = useState([]);
  const [uid, setUid] = useState(null);
  const [gid, setGid] = useState(null);


  const database = useDatabase();
  const firestore = useFirestore();
  const prodsRef = ref(database, 'prods');
  const prodsQuery = query(prodsRef);
  let today=new Date();
  //let today = moment();
  //const [startDate, setStartDate] = useState(moment().add(7, 'days') );
  const [startDate, setStartDate] = useState(new Date(today.setDate(today.getDate()+1)));

  const { status, data: prods } = useDatabaseListData(prodsQuery, {
    idField: 'id',
  });

 
const onMessageHandler = async(e) => {
    const event = JSON.parse(e.data);
    window.ReactNativeWebView.postMessage(JSON.stringify({ event: event }))
    if(event.type=="ords"){
      setOrds(event.orders);
      //alert(event.type);
      //setOrds(event.orders);
      //setUid(event.uid);
      //alert(event.orders[1].prodName);
    }
    
  }

  useEffect(() =>{
    const isUIWebView = () => {
      return navigator.userAgent
        .toLowerCase()
        .match(/\(ip.*applewebkit(?!.*(version|crios))/)
    }
    const receiver = isUIWebView() ? window : document
    receiver.addEventListener('message', onMessageHandler)
    return () => {
      receiver.removeEventListener('message', onMessageHandler)
    }
  }, [ords]);


  if (status === 'loading') {
    return <div style={{marginTop:50}}><span>데이터를 불러오는 중입니다. 잠시만 기다려 주세요.</span></div>;
  }

/*
  const getOrds = async(id) => {
    const tempOrds=[];
    db.collection("ords").where("uid", "==", id).orderBy("createAt", "desc")
    .get()
    .then((response) => {
      response.forEach((doc) => {
        const ords = doc.data();
        tempOrds.push(ords);
      });
      setOrds(tempOrds);
    });
  };*/

  const onAddGroup = (label) => {
    new Promise(function(resolve, reject){
      let temp=ords.filter(function(e) { return e.deliDateDash == label }); 
      resolve(temp);
    })
    .then(function(result) {
      let addTemp=[];
      for (const [index, product] of result.entries()) {
        try {
        const exist = cartItems.find((x) => x.id === product.id);
        if (exist) {
          //ordMaxMsg(product.prodName+" 품목은 이미 주문취소신청 내역에 있습니다.")
        }else{   
          addTemp.push(product);
        }      
        } catch (error) { console.log(error); }
      }
      return addTemp;
    })
    .then(function(result) {
      setCartItems([...cartItems, { ...result }]); 
      addCancelCartMsg("주문취소신청 화면으로 이동해주세요.");    
    });    
  
  };

  const onAdd = (product) => {   
    const exist = cartItems.find((x) => x.id === product.id);

    if (exist) {
      ordMaxMsg("이미 주문취소신청 내역에 있습니다.")
    }else{   
      setCartItems([...cartItems, { ...product }]);
      addCancelCartMsg("주문취소신청 화면으로 이동해주세요."); 
    }
  };
  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
      addCancelCartMsg("주문 건을 삭제하였습니다."); 
    }else{
      delCartMsg("일시적인 오류로 주문건을 삭제할 수 없습니다."); 
    }

  };


function User({ user }) {
  return (
    <div>
      {user.prodName} ({user.prodId})
    </div>
  );
}
 
  const render = {
    detail : (
      <div style={{marginBottom:60}}>
        <h2 style={{textAlign: "center", marginTop:80}}>{curProd?.name} 상세</h2>
        <img src={curProd?.image} width="100%" height="100%" style={{marginTop:30, marginBottom:30}} />
        <div style={{margin:30}}>
        
      </div>
        <br/><br/><br/><br/><br/>
      </div>
      ),
      cancelComp : (
        <div style={{marginBottom:60}}>
          <h2 style={{textAlign: "center", marginTop:80}}>주문취소 완료</h2>
          <div style={{textAlign: "center"}}>
            <span>정상적으로 주문취소가 완료되었습니다.</span>
          </div>
          <br/><br/><br/><br/><br/>
        </div>
        ),
      ordComp : (
        <div style={{marginBottom:60}}>
          <h2 style={{textAlign: "center", marginTop:80}}>주문접수 완료</h2>
          <div style={{textAlign: "center"}}>
            <span>정상적으로 주문접수가 완료되었습니다.<br/>담당자 확인 후 수주 처리 진행될 예정입니다.</span>
          </div>
          <br/><br/><br/><br/><br/>
        </div>
        ),
    prods : (
    <div style={{marginBottom:60, marginTop:80}}>
       {ords.map(user => (
            <User user={user} />
          ))}
    </div>
    ),
    cart : (
      <div style={{marginBottom:60, marginLeft: 4, marginRight:4}}>
      <h2 style={{textAlign: "center", marginTop:80}}>주문취소 신청</h2>
      <CancelBasket
          cartItems={cartItems}
          onAdd={onAdd}
          onRemove={onRemove}
          setMode={setMode}
          products={prods}
      />
      </div>
      ),
    ords : (
      <div style={{marginBottom:60, marginTop:80}}>
      <Tabs defaultActiveKey="buyerTab" id="uncontrolled-tab-example" >
      <Tab eventKey="buyerTab" title="배송예정" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4, }}>
        <OrdList category="배송예정" setMode={setMode} SetCurProd={SetCurProd} products={ords} onAdd={onAdd}  onAddGroup={onAddGroup} onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Tab>
      <Tab eventKey="buyerTab1" title="배송완료" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4}}>
        <OrdList category="배송완료" setMode={setMode} SetCurProd={SetCurProd} products={ords} onAdd={onAdd}  onAddGroup={onAddGroup} onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Tab>
      </Tabs>
    </div>
    ),
    deli : (
      <div style={{textAlign: "center"}}>
      <h2 style={{textAlign: "center", marginTop:80}}>희망배송일 선택</h2>
      <h3>{startDate.getMonth()+1}월 {startDate.getDate()}일 오전에 도착예정</h3>
      <p>날짜 선택 시 희망배송일 변경 가능</p>

      </div>
      )
  }
  

  const onLinkClick = (mode, isSave) => {
    
    if((mode=="cart" || mode=="deli" || mode=="cancelComp") && cartItems.length===0){ ordMaxMsg("주문취소할 품목을 선택해주세요.");}
    else{
      if(isSave){ onBtDel(); setCartItems([]);}
        setMode(mode); goToTop();
    }
};

const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
};

const onRemoveConfirm = () => {
  if(cartItems.length<=0){
    ordMaxMsg("주문취소할 품목이 없습니다.");
  }else if (window.confirm(`총 ${cartItems.length}건의 주문을 취소하시겠습니까?`)) {
    onLinkClick("cancelComp", true);
  } else {
    console.log("취소했습니다.");
  }
};

const onBtDel = async() => {

  window.ReactNativeWebView.postMessage(JSON.stringify({ method: 'click', cartItems: cartItems }))
  }

  const onBtGoOrds = async() => {
    setMode(mode); goToTop();
    window.location.reload();
  }

  return (
    <div> 
      <Toaster position="top-center" />
      <HeaderBar>
      <a href='#!' onClick={()=>{onLinkClick("ords", false)}} className="blackLink"  style={mode == "ords"? { textDecoration: 'none',color: "#de071a", fontWeight: "bold"}: {textDecoration: 'none' }}>주문내역</a>{'  /  '}
      <a href='#!' onClick={()=>{onLinkClick("cart", false)}} className="blackLink" style={mode == "cart"? { textDecoration: 'none',color: "#de071a", fontWeight: "bold"}: {textDecoration: 'none' }}>
          주문취소신청{''}
          {cartItems.length ? (
            <button className="badge">{cartItems.length}</button>
          ) : (
            ' '
          )}
        </a>
     </HeaderBar>  
      {render[mode]}
      {mode != "ords" && (
         <BottomBar>
         {mode == "prods" && (
             <a href='#!' onClick={()=>{onLinkClick("cart", false) }} className="whiteLink" style={{ textDecoration: 'none' }}>
           <div><span>장바구니로 이동하기</span></div></a>
         )}
         {mode == "detail" && (
           <>
           <a href='#!' onClick={()=>{onLinkClick("prods", false);}} className="whiteLink" style={{ textDecoration: 'none' }}>
             <div style={{width:"50%",float:"left"}}><span>목록보기</span></div></a>
           <a href='#!' onClick={()=>{onAdd(curProd)}} className="whiteLink" style={{ textDecoration: 'none' }}>
             <div style={{width:"50%",float:"right"}}><span>장바구니 담기</span></div></a>
           </>
         )}
         {mode == "cart" && (
             <a href='#!' onClick={()=>{onRemoveConfirm() }} className="whiteLink" style={{ textDecoration: 'none' }}>
           <div><span>주문취소하기</span></div></a>
         )}
         {mode == "deli" && (
             <a href='#!' onClick={()=>{onLinkClick("ordComp", true)}} className="whiteLink" style={{ textDecoration: 'none' }}>
           <div><span>주문하기</span></div></a>
         )}
         {mode == "ordComp" && (
             <a href='#!' onClick={()=>{console.log("aa")}} className="whiteLink" style={{ textDecoration: 'none' }}>
           <div><span>주문내역보기</span></div></a>
         )}
         {mode == "cancelComp" && (
             <a href='#!' onClick={()=>{onBtGoOrds("ords")}} className="whiteLink" style={{ textDecoration: 'none' }}>
           <div><span>주문내역보기</span></div></a>
         )}
       </BottomBar> 
      )}      
    </div>
  );
}

function HeaderBar({ children }) { return (<div><div style={headerStyle}>{children}</div></div>);}
function BottomBar({ children }) { return (<div><div style={footerStyle}>{children}</div></div>);}
export default AppOrds;

//<Prods setMode={setMode} SetCurProd={SetCurProd} products={prods} onAdd={onAdd} cartItems={cartItems}/>
      

/*

<div style={{marginBottom:60, marginTop:80}}>
      <Tabs defaultActiveKey="buyerTab" id="uncontrolled-tab-example" >
      <Tab eventKey="buyerTab" title="전체" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4, }}>
        <OrdList category="전체" hideCate={noOrdsTest?.[moment().format('YYYY-MM-DD')]} setMode={setMode} SetCurProd={SetCurProd} products={ords} onAdd={onAdd}  onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Tab>
      <Tab eventKey="buyerTab1" title="공산품" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4}}>
        <OrdList category="공산품" hideCate={noOrdsTest?.[moment().format('YYYY-MM-DD')]} setMode={setMode} SetCurProd={SetCurProd} products={ords} onAdd={onAdd}  onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Tab>
      <Tab eventKey="buyerTab2" title="공산" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4}}>
        <OrdList category="공산" hideCate={noOrdsTest?.[moment().format('YYYY-MM-DD')]} setMode={setMode} SetCurProd={SetCurProd} products={ords} onAdd={onAdd}  onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Tab>
      <Tab eventKey="buyerTab3" title="내구매" className="blackLink" style={{marginTop:30, marginLeft: 4, marginRight:4}}>
        <OrdList category="my" hideCate={noOrdsTest?.[moment().format('YYYY-MM-DD')]} setMode={setMode} SetCurProd={SetCurProd} products={ords} onAdd={onAdd}  onRemove={onRemove} cartItems={cartItems} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Tab>
      </Tabs>
    </div>
*/


/*

<div>
    <h2 style={{textAlign: "center", marginTop:80}}>신청내역</h2>
    <Ords setMode={setMode} SetCurProd={SetCurProd} ords={ords} />
    </div>
    
*/