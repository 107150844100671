import React from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from "react-table";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line

const StyleImages = styled.div`
display:flex; 
height: auto;
width : 600px;
align-items: flex-start;
flex-wrap: wrap;
`;

const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #fff;
  color: #000;
  text-align: left;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(1) {
  font-weight: bold;  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;

.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const DetailChartTable = ({ columns, data, mdComment, sumCusData, sumMdData, cusData, mdData}) =>{ 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

const a=(n)=>{
  let tempCusData=[];
  let tempCusLastValue=null;
  let tempMdData=[];
  let tempMdLastValue=null;


  if(n==1){
    tempCusData = cusData;
    tempCusLastValue =cusData[9];
    console.log("cus 1=>", cusData);
    tempCusData.splice(-1,1); //마지막 요소 제거
    console.log("2=>", tempCusData);
    tempCusData .splice(7, 0, tempCusLastValue);
    console.log("3=>", tempCusData);
    return tempCusData.join(", ");
  }else{
    tempMdData = mdData;
    tempMdLastValue =mdData[9];
    console.log("md 1=>", mdData);
    tempMdData.splice(-1,1); //마지막 요소 제거
    console.log("2=>", tempMdData);
    tempMdData .splice(7, 0, tempMdLastValue);
    console.log("3=>", tempMdData);
    return tempMdData.join(", ");
  }
 }

return (

  <div>
  <StyledTable {...getTableProps()}
    style={{ 
      border: 'solid 1px #fff',
      width: "100%",
    }}>

   <tbody {...getTableBodyProps()}>   
   <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>총 점수
     </StyledTd>

     <StyledTd >자가진단 : {sumCusData > 0 ? sumCusData+"점" : "미작성"}
     </StyledTd>

     <StyledTd >전문가진단 : {sumMdData > 0 ? sumMdData+"점" : "미작성"}
     </StyledTd>
    </StyledTBodyTr>

    <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>항목별 점수
     </StyledTd>

     <StyledTd >{cusData.length > 0 ? a(1) : "-"}
     </StyledTd>

     <StyledTd >{mdData.length > 0 ? a(2) : "-"}
     </StyledTd>
    </StyledTBodyTr>
    
    { mdData.length != 0 &&
   <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>전문가 의견
     </StyledTd>
     <StyledTd colSpan="2" >{mdComment}
     </StyledTd>
    </StyledTBodyTr>
  }  
     
   </tbody>
 </StyledTable>
  

</div>
);
}

export default DetailChartTable;

/*
   <thead>
     {headerGroups.map(headerGroup => (
       <StyledTBodyTr {...headerGroup.getHeaderGroupProps()}>
         {headerGroup.headers.map(column => (
           <StyledTh
             {...column.getHeaderProps()}
             /*style={{
               borderBottom: 'solid 3px black',
               background: '#fff',
               color: 'black',
               fontWeight: 'bold',
             }}
             >
             {column.render('Header')}
           </StyledTh>
         ))}
       </StyledTBodyTr>
     ))}
   </thead>

*/



/*

 <StyledTBodyTr>
    <StyledTd colSpan="3" style={{textAlign: "center"}}>진단설명
     <p style={{color: "#ccc"}}>진단 항목별 점수차이가 나는 항목에 대해 설명합니다.</p>
     </StyledTd>
    </StyledTBodyTr>


     {rows.map(row => {
       prepareRow(row)
       return (
         <StyledTBodyTr {...row.getRowProps()}>
           {row.cells.map(cell => {
             return (
               <StyledTd
                 {...cell.getCellProps()}
                 //style={{
                   //padding: '10px',
                   //border: 'solid 1px #ccc',
                   //background: '#fff',
                   
                  
                 //}}
                 >
                 {cell.render('Cell')}
               </StyledTd>
             )
           })}
         </StyledTBodyTr>
       )
     })}
     */