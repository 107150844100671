import React from 'react';
import Reqs from "./Reqs";
import { Redirect, Route, useLocation } from 'react-router-dom';

import firebase, {auth} from "../config/firebase";
import MtrList from './MtrList';


const Mtrz = ({location:{state}, history}) =>{
const user = auth.currentUser;
//const location = useLocation();
const {
  loginEmail,
  loginUid,
  loginUserName,
  loginUserType,
  loginUserState,
  loginUserGroup
} = state;

return (
    
        <div> 
            {user ? 
             //<UserList userInfo={location.state}/>
             <MtrList loginEmail={loginEmail} loginUid={loginUid} loginUserName={loginUserName} loginUserType={loginUserType} loginUserState={loginUserState} loginUserGroup={loginUserGroup}/>
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default Mtrz;