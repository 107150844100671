import React from 'react';
import { Radar } from 'react-chartjs-2';
import DetailChartTable from "./DetailChartTable";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { useTable } from 'react-table';

const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 500px;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;

:nth-of-type(1) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;


const RadarChart = ({ cusData, cusLabel, mdData, mdComment}) =>{ 
  console.log("mdData=> ",mdData);

  const sumCusData = cusData.reduce(function add(sum, currValue) {
    return sum + currValue;
  }, 0);
  const sumMdData = mdData.reduce(function add(sum, currValue) {
    return sum + currValue;
  }, 0);

  const chartData = {
    labels: cusLabel,
    datasets: [
      {
        label: '전문가진단',
        data: mdData,
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData,
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
      
    ],
  };
  
  const options = {
    scales: {
      r: {
          angleLines: {
              display: false
          },
          suggestedMin: 0,
          suggestedMax: 10
      }
  }
  };

  const chart={
    width:"350px",
  }



  const data = React.useMemo(
    () => [
      {
        col1: "총 점수",
        col2: sumCusData,
        col3: sumMdData
      },
      {
        col1: cusLabel[0],
        col2: cusData[0],
        col3: mdData[0],
      },
      {
        col1: cusLabel[1],
        col2: cusData[1],
        col3: mdData[1],
      },
      {
        col1: cusLabel[2],
        col2: cusData[2],
        col3: mdData[2],
      },
      {
        col1: cusLabel[3],
        col2: cusData[3],
        col3: mdData[3],
      },
      {
        col1: cusLabel[4],
        col2: cusData[4],
        col3: mdData[4],
      },
    
      {
        col1: cusLabel[5],
        col2: cusData[5],
        col3: mdData[5],
      },
      {
        col1: cusLabel[6],
        col2: cusData[6],
        col3: mdData[6],
      },
      {
        col1: cusLabel[7],
        col2: cusData[7],
        col3: mdData[7],
      },
      {
        col1: cusLabel[8],
        col2: cusData[8],
        col3: mdData[8],
      },      
    ],
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: '항목',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: '내용',
        accessor: 'col2',
      },
      {
        Header: '내용',
        accessor: 'col3',
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  

return (

    <div style={chart}>
     { cusData.length != 0 ?
     ( <Radar data={chartData} options={options} /> ) :
     ( <p>자가진단 또는 전문가 진단이 미작성되었습니다</p> )

     }
    
    <DetailChartTable columns={columns} data={data} mdComment={mdComment} sumCusData={sumCusData} sumMdData={sumMdData} cusData={cusData} mdData={mdData}/>
    </div>
)
}

export default RadarChart;