import React, {useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth} from "../config/firebase";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import styled from "styled-components";
import ColumnSelect from 'react-column-select'

const db=firebase.firestore();
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const SubHeading = tw.h1`text-xl xl:text-xl font-semibold`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

//const RespAdd = () => (
const RespAdd = ({location:{state}, history }) =>{
  const user = auth.currentUser;
  const {
    loginEmail,
    loginUid,
    loginUserName,
    loginUserType,
    loginUserState,
    idReq,         
    cateNo,
    allQDocs
  }=state;


  const [resCount, setResCount] = useState(0);
  const [currResCount, setCurrResCount] = useState(0);
  const [respState, setRespState] = useState(0);
  const [token, setToken] = useState("");
  const [allQDoc, setAllQDoc] = useState(allQDocs);
  const [selected, setSelected] = useState([]);

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
  const bodyTextStyle = {
    color : 'black',
    fontSize : "15px"
  }
  const errorStyle = {
    color : 'red',
    fontSize : "10px"
  }

  const questions=[
    { 
      no: 1,
      title: "고객수요/시장성",
      body : "상품이 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품입니까?",
      type : "radio",
      points : [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
    },
    {
      no: 2,
      title: "가격 경쟁력",
      body : "경쟁사 대비 가격 경쟁력이 있습니까?",
      type : "radio",
      label : ["경쟁사 대비 최저가", "경쟁사와 가격대 비슷함", "경쟁사보다 가격이 높음"],
      points : [10, 7, 5]
    },
    {
      no: 3,
      title: "상품 디자인",
      body : "상품의 전반적인 디자인(상세페이지, bici , 패키지 등)은 어떠합니까? ",
      type : "radio",
      points : [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
    },
    {
      no: 4,
      title: "상품 기능",
      body : "맛(식품) 또는 기능성(비식품)이 타상품과 차별화되어 있습니까?",
      type : "radio",
      points : [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
    },
    {
      no: 5,
      title: "방송 적합성",
      body : "상품이 방송심의 표현 사용에 안전하고 방송 중에 알기 쉽게 매력적으로 시연 가능합니까?",
      type : "radio",
      points : [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
    },
    {
      no: 6,
      title: "제품 생산 책임성",
      body : "제품을 직접 생산합니까?",
      type : "radio",
      label : ["제품 직접 생산(공장보유)", "제품 OEM 생산", "제품 판권 보유 기업 (유통기업)"],
      points : [10, 7, 5]
    },
    {
      no: 7,
      title: "품질관리 서류 보유 유무",
      body : "해당되는 품질 관련 서류를 선택 후 답변해주세요.",
      type : "select",
      label : ["전체 보유", "멘토링을 통한 단기간내 가능", "단기간 내 개선 불가"],
      points : [10, 5, 1]
    },
    {
      no: 9,
      title: "홈쇼핑 방송 경험 유무",
      body : "홈쇼핑 방송 경험이 1회 이상 있습니까?",
      type : "radio",
      label : ["홈쇼핑 방송 1회 이상 경험","방송 경험 없음"],
      points : [10, 5]
    },
    {
      no: 10,
      title: "홈쇼핑 방송 대응 역량",
      body : "홈쇼핑 진행 시 대응 가능한 담당자가 있습니까?",
      type : "radio",
      label : ["대응가능인원 보유", "현재 대응 불가능 하나 인력충원계획 있음", "대응 불가능 및 향후 충원계획 없음"],
      points : [10, 7, 5]
    },
    {
      no: 11,
      title: "전문가 진단 의견",
      body : "전문가로써 마지막으로 상품(또는 중소기업)에 대한 의견을 남겨주세요.",
      type : "input",
      points : []
    },
    {
      no: 8,
      title: "품질관리 대응 역량",
      body : "품질 서류 준비를 대응 가능한 담당자가 있습니까?",
      type : "radio",
      label : ["대응가능인원 보유", "현재 대응 불가능 하나 인력충원계획 있음", "대응 불가능 및 향후 충원계획 없음"],
      points : [10, 5, 1]
    },

  ];
  const [Q0, setQ0] = useState(""); 
  const [Q1, setQ1] = useState(""); 
  const [Q2, setQ2] = useState(""); 
  const [Q3, setQ3] = useState(""); 
  const [Q4, setQ4] = useState("");
  const [Q5, setQ5] = useState(""); 
  const [Q6, setQ6] = useState(""); 
  const [Q7, setQ7] = useState(""); 
  const [Q8, setQ8] = useState("");
  const [Q10, setQ10] = useState(""); 


  useEffect(() => {
    
    const reqRef = db.collection("reqs_min").doc(idReq);
      reqRef.get().then((doc) => {
      //reqRef.onSnapshot((doc) => {
        setResCount(doc.data().resCount);
        setCurrResCount(doc.data().currResCount);
        setRespState(doc.data().state);
        setToken(doc.data().token);
      });
   
  }, []);

  const handleChange = (e, q) => {
    switch(q) {
      case 0: return setQ0(e.target.value);
      case 1: return setQ1(e.target.value);
      case 2: return setQ2(e.target.value);
      case 3: return setQ3(e.target.value);
      case 4: return setQ4(e.target.value);
      case 5: return setQ5(e.target.value);
      case 6: return setQ6(e.target.value);
      case 7: return setQ7(e.target.value);
      case 8: return setQ8(e.target.value);
      case 10: return setQ10(e.target.value);
    }
  };

  const onSubmit = async (data) => {
    if(Q6==1){
      setQ6(0);
    }
    if(Q10==1){
      setQ10(0);
    }

    if( loginUserType == 1 && selected.length==0){
      //if(pushOptionValue == 3 || (pushOption =="3" && selected.length==0)){
      //if(pushOption =="default" || pushOption !="0" || pushOption !="1" || pushOption!="2" || pushOption!="3"){
        alert("품질서류를 선택해주세요.");
    }else if(Q0==""){
        alert("1번 질문에 답변을 선택해주세요.");
    }else if(Q1==""){
      alert("2번 질문에 답변을 선택해주세요.");
    }else if(Q2==""){
      alert("3번 질문에 답변을 선택해주세요.");
    }else if(Q3==""){
      alert("4번 질문에 답변을 선택해주세요.");
    }else if(Q4==""){
      alert("5번 질문에 답변을 선택해주세요.");
    }else if(Q5==""){
      alert("6번 질문에 답변을 선택해주세요.");
    }else if(Q6==""){
      alert("7번 질문에 답변을 선택해주세요.");
    }else if(Q10==""){
      alert("8번 질문에 답변을 선택해주세요.");
    }else if(Q7==""){
      alert("9번 질문에 답변을 선택해주세요.");
    }else if(Q8==""){
      alert("10번 질문에 답변을 선택해주세요.");
   
    }else{
      let qDocName=[];
      let qDocNo=[];
      let qDocs=[];


      for ( const item of selected){
        try{
          qDocNo.push(item.value);
          qDocName.push(item.name);
          qDocs.push(
            {
            label : item.label,
             value : item.value,
             name : item.name
            },
          );     
        }catch (error){
          console.log(error);
        }

      };

    if(resCount==currResCount){
      //alert("실시간 진단 진행 마감되어 답변이 불가능합니다.");
      alert("실시간 진단 진행 마감되어 답변 이 불가능합니다. 뒤로가기를 눌러 다른 요청 건을 확인해주세요.");
    } else {
      const chart = {
          label : [
            questions[0].title,
            questions[1].title,
            questions[2].title,
            questions[3].title,
            questions[4].title,
            questions[5].title,
            questions[6].title,
            questions[7].title,
            questions[8].title,
            questions[10].title

          ],
          data : [
            parseFloat(Q0),
            parseFloat(Q1),
            parseFloat(Q2),
            parseFloat(Q3),
            parseFloat(Q4),
            parseFloat(Q5),
            parseFloat(Q6),
            parseFloat(Q7),
            parseFloat(Q8),
            parseFloat(Q10)
          ],
        };

      const docRef = db.collection("users").doc(user.uid);
      await docRef.get().then((doc) => {
          if (doc.exists) {
              //console.log("Document data:", doc.data());
              //setDisplayName(doc.data().displayName);
              //setIntro(doc.data().profile.intro);
              //console.log("profile Phone:", doc.data().profile.phone);
              //console.log("nickName Phone:", doc.data().displyName);

              const paylod = {
                idUser: user.uid,
                avatarUser: user.photoURL,
                idReq: idReq,
                createAt: new Date(),
                nickName: doc.data().displayName,
                //intro : doc.data().profile.intro,
                chart : chart, 
                comment : data.comment,
                qDocNo : qDocNo,
                qDocName : qDocName,
                qDocs : qDocs
              };
              if(loginUserType==2){

                db.collection("resp_min")
                .add(paylod)
                .then((docRef) => {
                  //updateReq(user.uid, chart, docRef);
                  updateReq(user.uid, chart, data.comment, qDocNo, qDocName, qDocs);
                  updateChartsData(idReq);

                })
                .catch(() => {
                  alert("답변 등록이 실패하였습니다. 다시 시도해 주세요.");
                });

              }else{
                //updateReq(user.uid, chart, data.comment);
                updateReq(user.uid, chart, data.comment, qDocNo, qDocName, qDocs);
              }
              

          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });

    
      
    }
  }
  }

  const updateChartsData = (idReq) => {

    let docId="";
    db.collection("charts")
      .where("idReq", "==", idReq)
      .get()
      .then((response) => {
        response.forEach((doc) => {
          //console.log("Document data:", doc.data());
          const result = [];
          result.push(doc.data());
          //console.log("result=>", result[0].chartsData.data);
          const dataArray = [];
          docId=doc.id;

          result[0].chartsData.map((d)=>{
            //console.log("d data: ",d.data);
            dataArray.push(d.data);
        });


        if(Q0==10) result[0].data1[0]+=1;
        else if(Q0==9) result[0].data1[1]+=1;
        else if(Q0==8) result[0].data1[2]+=1;
        else if(Q0==7) result[0].data1[3]+=1;
        else if(Q0==6) result[0].data1[4]+=1;
        else if(Q0==5) result[0].data1[5]+=1;
        else if(Q0==4) result[0].data1[6]+=1;
        else if(Q0==3) result[0].data1[7]+=1;
        else if(Q0==2) result[0].data1[8]+=1;
        else result[0].data1[9]+=1;
  
        if(Q1==10) result[0].data2[0]+=1;
        else if(Q1==7) result[0].data2[1]+=1;
        else result[0].data2[2]+=1;
  
        if(Q2==10) result[0].data3[0]+=1;
        else if(Q2==9) result[0].data3[1]+=1;
        else if(Q2==8) result[0].data3[2]+=1;
        else if(Q2==7) result[0].data3[3]+=1;
        else if(Q2==6) result[0].data3[4]+=1;
        else if(Q2==5) result[0].data3[5]+=1;
        else if(Q2==4) result[0].data3[6]+=1;
        else if(Q2==3) result[0].data3[7]+=1;
        else if(Q2==2) result[0].data3[8]+=1;
        else result[0].data3[9]+=1;
  
        if(Q3==10) result[0].data4[0]+=1;
        else if(Q3==9) result[0].data4[1]+=1;
        else if(Q3==8) result[0].data4[2]+=1;
        else if(Q3==7) result[0].data4[3]+=1;
        else if(Q3==6) result[0].data4[4]+=1;
        else if(Q3==5) result[0].data4[5]+=1;
        else if(Q3==4) result[0].data4[6]+=1;
        else if(Q3==3) result[0].data4[7]+=1;
        else if(Q3==2) result[0].data4[8]+=1;
        else result[0].data4[9]+=1;
  
  
        if(Q4==10) result[0].data5[0]+=1;
        else if(Q4==9) result[0].data5[1]+=1;
        else if(Q4==8) result[0].data5[2]+=1;
        else if(Q4==7) result[0].data5[3]+=1;
        else if(Q4==6) result[0].data5[4]+=1;
        else if(Q4==5) result[0].data5[5]+=1;
        else if(Q4==4) result[0].data5[6]+=1;
        else if(Q4==3) result[0].data5[7]+=1;
        else if(Q4==2) result[0].data5[8]+=1;
        else result[0].data5[9]+=1;
  
  
        if(Q5==10) result[0].data6[0]+=1;
        else if(Q5==7) result[0].data6[1]+=1;
        else result[0].data6[2]+=1;
  
        if(Q6==10) result[0].data7[0]+=1;
        else if(Q6==5) result[0].data7[1]+=1;
        else result[0].data7[2]+=1;
  
        if(Q7==10) result[0].data8[0]+=1;
        else result[0].data8[1]+=1;
  
        if(Q8==10) result[0].data9[0]+=1;
        else if(Q8==7) result[0].data9[1]+=1;
        else result[0].data6[2]+=1;
  
        if(Q10==10) result[0].data10[0]+=1;
        else if(Q10==5) result[0].data10[1]+=1;
        else result[0].data10[2]+=1;
  

      //console.log("dataArray:", dataArray);
      //console.log("docId: ",docId);
      const dRef = db.collection("charts").doc(docId);
      dRef.update({
        "data1" : result[0].data1,
        "data2" : result[0].data2,
        "data3" : result[0].data3,
        "data4" : result[0].data4,
        "data5" : result[0].data5,
        "data6" : result[0].data6,
        "data7" : result[0].data7,
        "data8" : result[0].data8,
        "data9" : result[0].data9,
        "data10" : result[0].data10,
        });          
        });
        //setReviews(resultReview)
      });
  };

  const updateReq = (uid, chart, comment, qDocNo, qDocName, qDocs) => {
    const reqRef = db.collection("reqs_min").doc(idReq);

    reqRef.get().then((response) => {
      const reqData = response.data();
      let currResCount = reqData.currResCount;
      const reqCreateBy = reqData.createBy;


        currResCount+=1;

      const chart_cus_label = reqData.chart_cus_label;
      const chart_cus_data = reqData.chart_cus_data;
      const chart_md_avg_label = reqData.chart_md_avg_label;
      const chart_md_avg_data = reqData.chart_md_avg_data;
      
      chart_cus_data.map((n)=>n*1);
      chart_md_avg_data.map((n)=>n*1);
      let data=chart.data.map((n)=>n*1); 


      if(currResCount==resCount){
        console.log("reqCreateBy", reqCreateBy);
        console.log("uid", uid);

        if(loginUserType == 1){
          console.log("------0------------");
          reqRef
          .update({
            currResCount: currResCount,
            state : 2,
            stateName : "진단완료",
            chart_cus_label : chart.label,
            chart_cus_data : data,
            currResCount: currResCount,
            qDocNo : qDocNo,
            qDocName : qDocName,
            isChart : true,
            reqMsg : comment||"",
            currResCount : currResCount
          })
          .then(() => {
            history.push({
              pathname: '/dashBoard'
              //search: '?query=abc',
              
      
            });
          });

        }else{
          console.log("------1------------");
          reqRef
          .update({
            currResCount: currResCount,
            state : 2,
            stateName : "진단완료",
            chart_md_avg_label : chart.label,
            chart_md_avg_data : data,
            currResCount: currResCount,
            isChart : true
          })
          .then(() => {
            history.push({
              pathname: '/dashBoard'
              //search: '?query=abc',
              
      
            });
          });

        }

      }else if(currResCount>resCount){
        alert('답변  불가','실시간 진단 진행이 마감되었습니다. 다른 요청 건을 확인해주세요.')
      }else{
        console.log("------2------------");


        if(reqCreateBy == uid){
        console.log("------3------------");
          //고객
          reqRef
            .update({
              chart_cus_label : chart.label,
              chart_cus_data : data,
              isChart : true,
              qDocNo : qDocNo,
              qDocName : qDocName,
              //qDocs : qDocs,
              reqMsg : comment||"",
              currResCount : currResCount

            })
            .then(() => {
              history.push({
                pathname: '/dashBoard'
                //search: '?query=abc',
                
        
              });
            });
    
          }else{
        console.log("------4------------");

          //전문가
          if((currResCount -1 )== 0){
        console.log("------5------------");

          
            //덮어씌우기
            reqRef
            .update({
              chart_md_avg_label : chart.label,
              chart_md_avg_data : data,
              currResCount: currResCount,
              isChart : true
            })
            .then(() => {
              history.push({
                pathname: '/dashBoard'
                //search: '?query=abc',
            
        
              });
       
            });

          }else{
        console.log("------6------------");

            //기존값 받고 계산 후 리턴
            let pre = chart_md_avg_data.map( (n,i)=> parseFloat((n+data[i])/(currResCount-1)));

            reqRef
              .update({
                chart_md_avg_data : pre,
                currResCount: currResCount,
                isChart : true
              })
              .then(() => {
                history.push({
                  pathname: '/dashBoard'
                  //search: '?query=abc',
                  
                });
     
              });
          }
    
          
          
          }
      }

      
    });
  };

  const onChange = (values) => {
    setSelected(values)
    //console.log("selected=>", values)
  }
 
  return (
    <div>
      {user ?
      (
        <Content>
        <MainContainer>
        {loginUserType==0 && <Heading>진단</Heading>}
        {loginUserType==1 && <Heading>자가진단</Heading>}
        {loginUserType==2 && <Heading>진단답변</Heading>}

        <SubHeading>1.상품정보</SubHeading>
      <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>

      {/* Q0 begin */}
      <div>
        <p><b>Q{questions[0].no}. {questions[0].title}</b></p>
        <p style={bodyTextStyle}> {questions[0].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[0].points.map((n) => (
          <span>
         <input
            key={`q0${n}`}
            id={`q0${n}`}
            //key={n}
            //id={n}
            type="radio"
            name="q0"
            value={n}
            onChange={(e) => handleChange(e, 0)}
            check={Q0 == n }
            //checked={Q0 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q0 end */}
      {/* Q1 begin */}
        <div>
        <p><b>Q{questions[1].no}. {questions[1].title}</b></p>
        <p style={bodyTextStyle}> {questions[1].body}</p>
        <p>&nbsp; </p>
        
        {questions[1].points.map((n, i) => (
        <label htmlFor={questions[1].label[i]}>
         <input
            key={`q1${n}`}
            id={`q1${n}`}
            type="radio"
            name="q1"
            value={n}
            onChange={(e) => handleChange(e, 1)}
            checked={Q1 == n}
            style={{
              height: 15,
              width: 20,
            }}
          />  &nbsp;
          {questions[1].label[i]}
          <br />
          </label>
      ))}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
        {/* Q1 end */}
        {/* Q2 begin */}
        <div>
        <p><b>Q{questions[2].no}. {questions[2].title}</b></p>
        <p style={bodyTextStyle}> {questions[2].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[2].points.map((n) => (
          <span>
         <input
            key={`q2${n}`}
            id={`q2${n}`}
            type="radio"
            name="q2"
            value={n}
            onChange={(e) => handleChange(e, 2)}
            checked={Q2 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q2 end */}
       {/* Q3 begin */}
       <div>
        <p><b>Q{questions[3].no}. {questions[3].title}</b></p>
        <p style={bodyTextStyle}> {questions[3].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[3].points.map((n) => (
          <span>
         <input
            key={`q3${n}`}
            id={`q3${n}`}
            type="radio"
            name="q3"
            value={n}
            onChange={(e) => handleChange(e, 3)}
            checked={Q3 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q3 end */}
      {/* Q4 begin */}
       <div>
        <p><b>Q{questions[4].no}. {questions[4].title}</b></p>
        <p style={bodyTextStyle}> {questions[4].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[4].points.map((n) => (
          <span>
         <input
            key={`q4${n}`}
            id={`q4${n}`}
            type="radio"
            name="q4"
            value={n}
            onChange={(e) => handleChange(e, 4)}
            checked={Q4 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q4 end */}
      {/* Q5 begin */}
      <div>
        <p><b>Q{questions[5].no}. {questions[5].title}</b></p>
        <p style={bodyTextStyle}> {questions[5].body}</p>
        <p>&nbsp; </p>
        {questions[5].points.map((n, i) => (
         <label htmlFor={questions[5].label[i]}>
         <input
            key={`q5${n}`}
            id={`q5${n}`}
            type="radio"
            name="q5"
            value={n}
            onChange={(e) => handleChange(e, 5)}
            checked={Q5 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          {questions[5].label[i]}
          <br />
          </label>
      ))}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q5 end */}
      {/* Q6 begin */}
      <div>
        <p><b>Q{questions[6].no}. {questions[6].title}</b></p>
        <p style={bodyTextStyle}> {loginUserType==1 ? questions[6].body : "품질 관련 서류는 모두 갖추고 있나요?"}</p>
        <p>&nbsp; </p>
        
        {questions[6].points.map((n, i) => (
         <label htmlFor={questions[6].label[i]}>
         <input
            key={`q6${n}`}
            id={`q6${n}`}
            type="radio"
            name="q6"
            value={n}
            onChange={(e) => handleChange(e, 6)}
            checked={Q6 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          {questions[6].label[i]}
          <br />
          </label>
      ))}
      {loginUserType==1 && allQDoc.length != 0 && 
             <ColumnSelect
        options={allQDoc}
        onChange={onChange}
        leftHeader='전체'
        rightHeader='선택된 품질서류'
        isSearchable={true}
        searchPlaceholder="검색..."
        theme={{
          headerBgColor: '#eee',
          columnBorderColor: '#ccc',
          textColor: '#000000',
          columnBgColor: '#ccc',
          buttonBgColor: '#ccc',
          optionSelectedBgColor: '#eee',
          optionHoverBgColor: '#eee',
          searchFocusBorderColor: '#ccc',
        }}
      />
      }
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q6 end */}

      {/* Q10 begin */}
      <div>
        <p><b>Q{questions[10].no}. {questions[10].title}</b></p>
        <p style={bodyTextStyle}> {questions[10].body}</p>
        <p>&nbsp; </p>
        {questions[10].points.map((n, i) => (
          <label htmlFor={questions[10].label[i]}>
         <input
            key={`q10${n}`}
            id={`q10${n}`}
            type="radio"
            name="q10"
            value={n}
            onChange={(e) => handleChange(e, 10)}
            checked={Q10 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          {questions[10].label[i]}
          <br />
          </label>
      ))}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q10 end */}
      {/* Q7 begin */}
      <div>
        <p><b>Q{questions[7].no}. {questions[7].title}</b></p>
        <p style={bodyTextStyle}> {questions[7].body}</p>
        <p>&nbsp; </p>
        {questions[7].points.map((n, i) => (
          <label htmlFor={questions[7].label[i]}>
         <input
            key={`q7${n}`}
            id={`q7${n}`}
            type="radio"
            name="q7"
            value={n}
            onChange={(e) => handleChange(e, 7)}
            checked={Q7 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
           {questions[7].label[i]}
          <br />
          </label>
      ))}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q7 end */}
        {/* Q8 begin */}
      <div>
        <p><b>Q{questions[8].no}. {questions[8].title}</b></p>
        <p style={bodyTextStyle}> {questions[8].body}</p>
        <p>&nbsp; </p>

        {questions[8].points.map((n, i) => (
          <label htmlFor={questions[8].label[i]}>
           <input
            key={`q8${n}`}
            id={`q8${n}`}
            type="radio"
            name="q8"
            value={n}
            onChange={(e) => handleChange(e, 8)}
            checked={Q8 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          {questions[8].label[i]}
          <br />
          </label>
      ))}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q8 end */}

      {/* Q9 begin */}
      {loginUserType==2 && 
      <div>
        <p><b>Q{questions[9].no}. {questions[9].title}</b></p>
        <p style={bodyTextStyle}> {questions[9].body}</p>
        <p>&nbsp; </p>
        <TextArea defaultValue="" placeholder="200자 내외로 입력해주세요." {...register("comment", { required: true })} rows={5}/>
             {errors.comment && <span style={errorStyle}>전문가 진단 의견을 입력하세요.</span>}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      }
      {/* Q9 end */}

      <SubmitButton type="submit">
        <span className="text">진단 완료</span>
      </SubmitButton>
           </form>
        </FormContainer>
           </MainContainer>
           </Content>

       )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
      } 
    </div>
  )
}

function NumberList(props) {
  const numbers = props.numbers;
  const listItems = numbers.map((number) =>
    <li key={number.toString()}>
      {number}
    </li>
  );
  return (
    <ul>{listItems}</ul>
  );
}

export default RespAdd;


/*
state: { 
              loginEmail: loginEmail,
              loginUid : user.uid,
              loginUserName : loginUserName,
              loginUserType : loginUserType,
              loginUserState : loginUserState,
              idReq: idReq,
              cateNo : cateNo,
            }
*/