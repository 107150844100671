import React, { useState, useEffect }  from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from "react-table";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import firebase, {auth} from "../../config/firebase";

const StyleImages = styled.div`
display:flex; 
height: auto;
width : 600px;
align-items: flex-start;
flex-wrap: wrap;
`;

const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #fff;
  color: #000;
  text-align: left;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(1) {
  font-weight: bold;  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;

.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;


const db=firebase.firestore();


const DetailCsatTable = ({ noticeId, aUType}) =>{ 
  const [csat, setCsat] = useState([]);
  const user = auth.currentUser;

  const getCsat = async(noticeId) => {
    const tempCsat=[];
    let csats=null;

    if(aUType==0){
      csats = await db.collection("csats")
    .where("idNotice", "==", noticeId)
    .get();

    }else{
      csats = await db.collection("csats")
      .where("idNotice", "==", noticeId)
      .where("idUser", "==", user.uid)
    .get();

    }


    
    csats.docs.map(csat => {       
      tempCsat.push(csat.data());

    })

    setCsat(tempCsat);
    //console.log("tempResp->",tempResp);
    //console.log(tempChartMdData);
  };


  useEffect(() => {
    //alert(resp);
    getCsat(noticeId);
    
    }, []);

return (

  <div>
  <StyledTable
    style={{ 
      border: 'solid 1px #fff',
      width: "500px",
    }}>

   <tbody>   

   <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>설문결과
     </StyledTd>
     <StyledTd colSpan="1" style={{width: "300px"}}>
       
       {csat.map(item => (
        <div key={item.id}>
          <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>{item.name}</span>
          <span style={{color: "#444", fontSize: 12}}> ( {item.email},</span>
          <span style={{color: "#444", fontSize: 12}}> {item.bizName} )</span><br/>
          <span>- 총 점수 : {item.rating.reduce(function(a, b){ return a + b; }, 0)}점</span><br/>
          <span>- 항목별 점수 : {item.rating.join(", ")}점</span><br/>          
          <span>- 의견 : {item.comment}</span><br/>

        <hr
        style={{
            color: "#ccc",
            backgroundColor: "#ccc",
            height: 0.5,
            marginTop : 10,
            marginBottom : 10
        }}
    />
        </div>
        
        ))}
     </StyledTd>
    </StyledTBodyTr>
   
     
   </tbody>
 </StyledTable>
  

</div>
);
}

export default DetailCsatTable;