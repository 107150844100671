import React, {useEffect, useState } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';
import firebase, {auth} from "../../config/firebase";


const db=firebase.firestore();
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;

const NoticeDetail = ({ match, location }) => {

  const [data, setData] = useState("");
  //const [createAt, setCreateAt] = useState(null);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");


  const handleReadData = (e) => {
    const id=e.data;
    getData(id);
  };

  const getData = async(id) => {
    const data = await db.collection("notices").doc(id).get()
    .then(snapshot => {
      const d=snapshot.data()
      setData(d);
      console.log(d);
      setTitle(d.title);
      setBody(d.body);

      //setCreateAt(new Date(d.createAt.seconds * 1000));
    })
  };

  const handleSendData = (e) => {
    window.ReactNativeWebView.postMessage("data");
  };



  useEffect(() => {

    if (isMobile && isIOS) {
      window.addEventListener('message', handleReadData);
      return () => {
        window.removeEventListener('message', handleReadData);
      };
      }
      if (isMobile && isAndroid) {
      document.addEventListener('message', handleReadData);
      return () => {
        document.removeEventListener('message', handleReadData);
      };  
      }
    //getDiag("JRfAyZkYYnUcm0492Xet")

  }, []);

return (
    
<div style={{paddingLeft: 50, paddingRight: 50}}> 
<h1 tw="text-xl font-semibold" style={{marginBottom:30}}>{title}</h1>
<p>{body}</p>
</div>
)    
} 

export default NoticeDetail;