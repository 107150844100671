import React, { useState, useEffect }  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import firebase, {auth} from "../config/firebase";
import Header from "../components/admin/Header";
import { useTable } from 'react-table'
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import DetailProdTable from "../components/admin/DetailProdTable";
import RadarChart from "../components/admin/RadarChart";
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;


const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(odd) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();


const Req = ({location:{state}}) =>{
    let { reqId } = useParams();
    const {
        prodName, 
        competProd, 
        brand,
        cateName,
        manufacturer,
        stateName,
        price,
        sellingPoint,
        unitItem,
        customer,
        chart_cus_data,
        chart_cus_label,
        images,
        createAt,
        createBy,
        sendTo,
        qDocName,
        loginEmail,
        loginUid,
        loginUserName,
        loginUserType,
        loginUserState,
        idReq,
        cateNo,
        allQDocs
    } = state;
    const [cusEmail, setCusEmail] = useState("");
    const [mdEmail, setMdEmail] = useState("");
    const [cusName, setCusName] = useState("");
    const [mdName, setMdName] = useState("");
    const [bizName, setBizName] = useState("");
    const [resp, setResp] = useState("");
    const [chartMdData, setChartMdData] = useState([]);
    const [mdComment, setMdCommnet] = useState("");

    
    


    const getEmail = async(cus, md) => {
    
      const cusUsers = await db.collection("users")
      .where('uid', 'in', [cus, md])
      //.where("uid", "==", cus)
      //.where("uid", "==", md)
      .get();
      cusUsers.docs.map(user => {
        //console.log("uid=>", user.data().uid);
        if(user.data().uid == cus){
          setCusEmail(user.data().email);
          setCusName(user.data().profile.name);
          setBizName(user.data().profile.bizInfo.bizName);
        }else{
          setMdEmail(user.data().email);
          setMdName(user.data().profile.name);

        }

      })
/*
      const mdUsers = await db.collection("users")
      .where("uid", "==", md)
      .get();
      mdUsers.docs.map(user => {
        setMdEmail(user.data().email);
        //console.log("email  =>",user.data().email)
      })
   */
    
    };

    const getResp = async(reqId) => {
    
      const resps = await db.collection("resp_min")
      .where("idReq", "==", reqId)
      .get();
      resps.docs.map(resp => {       
        //console.log(resp.data());
        //console.log(resp.data().nickName);
        setResp(resp.data());
        setChartMdData(resp.data().chart.data);
        setMdCommnet(resp.data().comment);



      })
    };

    useEffect(() => {
      //console.log(reqId);
      getResp(reqId);
      getEmail(createBy, sendTo);
             
      }, []);


      const data = React.useMemo(
        () => [
          {
            col1: '상품명',
            col2: prodName,
          },
          {
            col1: '브랜드',
            col2: brand,
          },
          {
            col1: '카테고리',
            col2: cateName,
          },
          {
            col1: '가격',
            col2: price,
          },
          {
            col1: '상품구성',
            col2: unitItem,
          },
        
          {
            col1: '제조사',
            col2: manufacturer,
          },
          {
            col1: '주요고객',
            col2: customer,
          },
          {
            col1: '상품특장점',
            col2: sellingPoint,
          },
          {
            col1: '경쟁상품',
            col2: competProd,
          },
          {
            col1: '품질서류',
            col2: chart_cus_data.length === 0 ? "자가진단 현재 미작성": qDocName.length === 0 ? "서류 미보유" : qDocName.join(", "),
          },

          
        ],
        []
      )
    
      const columns = React.useMemo(
        () => [
          {
            Header: '항목',
            accessor: 'col1', // accessor is the "key" in the data
          },
          {
            Header: '내용',
            accessor: 'col2',
          },
        ],
        []
      )

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data })

   

  return(

    <div tw="min-h-screen text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
      

    <div>
      <DetailProdTable columns={columns} data={data} images={images}/>
      {chart_cus_data.length===0 && loginUserType==1 &&
      (
      <div>
      <Link to={{ pathname:`/RespAdd/${idReq}`,
      state : {
        loginEmail: loginEmail,
        loginUid : loginUid,
        loginUserName : loginUserName,
        loginUserType : loginUserType,
        loginUserState : loginUserState,
        idReq: idReq,
        cateNo : cateNo,
        allQDocs : allQDocs
      }}}>
      <PrimaryButton buttonRounded={true} as="a">자가진단하기</PrimaryButton>
      </Link>
        </div> 
      )}
      {chartMdData.length===0 && loginUserType==2 &&
      (
      <div>
      <Link to={{ pathname:`/RespAdd/${idReq}`,
      state : {
        loginEmail: loginEmail,
        loginUid : loginUid,
        loginUserName : loginUserName,
        loginUserType : loginUserType,
        loginUserState : loginUserState,
        idReq: idReq,
        cateNo : cateNo,
        allQDocs : allQDocs
      }}}>
      <PrimaryButton buttonRounded={true} as="a">진단하기</PrimaryButton>
      </Link>
        </div> 
      )}
      {
        loginUserType == 0 && (
      <div>
      <br /><br /><br />
      <h1 tw="text-xl font-semibold">진단결과</h1>
      <RadarChart cusData={chart_cus_data} cusLabel={chart_cus_label} mdData={chartMdData} mdComment={mdComment} qDocName={qDocName}/>
      <br /><br /><br />
      <h1 tw="text-xl font-semibold">기본정보</h1>
      <StyledTable>
        <StyledTBodyTr>
          <StyledTd>사업자</StyledTd>
          <StyledTd>{bizName}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>담당자</StyledTd>
          <StyledTd>{cusName} ({cusEmail})</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>전문가</StyledTd>
          <StyledTd>{mdName} ({mdEmail})</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>진단요청일시</StyledTd>
          <StyledTd>{createAt.getFullYear()}.{createAt.getMonth() + 1}.{createAt.getDate()} {createAt.getHours()}:
              {createAt.getMinutes() < 10 ? "0" : ""}
              {createAt.getMinutes()}</StyledTd>
        </StyledTBodyTr>

      </StyledTable>
      </div>
      )}
        
    </div>
    </div>
      </main>
    </div>
  );
}
export default Req;

/*
          
  <h3>{chart_cus_data}</h3>
      <h3>{chart_cus_label}</h3>
*/


/*
<h1 tw="text-xl font-semibold">상품/역량진단 클리닉 보고서</h1>
      <h1 tw="text-xl font-semibold">{bizName}</h1>
      <h1 tw="text-xl font-semibold">{prodName}</h1>
      <br /><br /><br />
      
  */