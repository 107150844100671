import React, {useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth} from "../config/firebase";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { Container as ContainerBase } from "components/misc/Layouts";
import styled from "styled-components";
import ColumnSelect from 'react-column-select'

const db=firebase.firestore();

const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const errorStyle = {
  color : 'red',
  fontSize : "10px"

}

//const NoticeAdd = (history) =>{
const NoticeAdd = ({location:{state}, history }) =>{
  
const user = auth.currentUser;
/*
const {
  aUEmail,
  aUid,
  aUName,
  aUType,
  aUState
} = state; */
const {
  allUsers 
}=state;
const [aUEmail, setAuEmail] = useState("");
const [aUName, setAuName] = useState("");
const [aUType, setAuType] = useState("");
//const [allUsers, setAllUsers] = useState([]);



const getUserInfo = async(uid) => {
    
  const users = await db.collection("users")
  .where("uid", "==", uid)
  .get();
  users.docs.map(user => {

    setAuEmail(user.data().email);
    setAuName(user.data().profile.name);
    setAuType(user.data().userType);
    //console.log("email  =>",user.data().email);
  })
/*
  const getAllUsers = await db.collection("users")
  .get();
  const allUsers=[];

  getAllUsers.docs.map(user => { 
    allUsers.push({
      value : user.data().uid,      
     label : user.data().profile.name,
     //token : user.data().token,
     //state : user.data().state,
     //userType : user.data().userType,
     //email : user.data().email
  })
});

  setAllUsers(allUsers);
  console.log("allUsers=>",allUsers);
*/
};

const filterItems = (query) => {
  return allUsers.filter((el) =>
    el.userType.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
  );
}

const sendNotificationTo = async(tokens) => {
  console.log("token:", tokens);
  tokens.map(token => sendNotification(token))
};


const sendNotification=async()=>{ 
  console.log("-------ready to push");
  const message = {
    to: "ExponentPushToken[6WuFm9HgjUYbe7mbZcDDUZ]",
    sound: 'default',
    title: '상품/역량 진단 요청 알림',
    body: '(sss)',
    //data: { url: `${prefix}notices/${room.id}`,  },
    data: { url: '/account'  },

  };

  await fetch('https://cors-anywhere.herokuapp.com/https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
  console.log("------finish to push-----")
}

const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [result, setResult] = useState("");
  const [pushOption, setPushOption] = useState("");
  const [pushOptionValue, setPushOptionValue] = useState(""); 
  const [selected, setSelected] = useState([])  
  const [noticeType, setNoticeType] = useState(1);  
  const [allUserCopy, setAllUserCopy] = useState(allUsers);


  useEffect(() => {
    getUserInfo(user.uid);
    //console.log("filter=>",filterItems(2));
  }, []);

  const handleChange = (event) => {
    
    //label 가져오기 
    //console.log("label=>",event.target.selectedOptions[0].text);
    setPushOption(event.target.value);
    console.log("select value->",event.target.value);
    setSelected([]);
    if(event.target.value==="0"){
      setSelected(allUserCopy);
      setPushOptionValue(0);
      //setAllUserCopy([]);
      console.log(allUserCopy);
    }else if(event.target.value==="1"){
      setSelected(filterItems(1));
      setPushOptionValue(1);
      //setAllUserCopy(filterItems(2));
      console.log(filterItems(1));
    }else if(event.target.value==="2"){
      setSelected(filterItems(2));
      setPushOptionValue(2);
      //setAllUserCopy(filterItems(1));
      console.log(filterItems(2));
    }else if(event.target.value==="3"){
      setSelected(filterItems(3));
      setPushOptionValue(3);
      //setAllUserCopy(filterItems(1));
      console.log(filterItems(3));
    }else {
      event.preventDefault();
    }
    //console.log("selected=>",selected);

    //console.log("pushOption->",event.target.value);
  }

  const handleTypeChange = (e) => {
      setNoticeType(e.target.value);
  };

  const onChange = (values) => {
    setSelected(values)
    console.log("selected=>", values)
  }
  const onSubmit = async (data) => {
    //데이터 전달받기
    //console.log("title=>",data.title);    
    //setResult(JSON.stringify(data));
    //console.log(data);

    if(noticeType == 0 || noticeType > 2){
      alert("공지사항 종류를 선택해주세요.");
    }else if(selected.length==0){
    //if(pushOptionValue == 3 || (pushOption =="3" && selected.length==0)){
    //if(pushOption =="default" || pushOption !="0" || pushOption !="1" || pushOption!="2" || pushOption!="3"){
      alert("푸시 수신자를 선택해주세요.");
    }else{

      console.log(selected);
      let uid=[];
      let token=[];
      let company=[];
      let name=[];
      let email=[];
      let sendToValue = [];     
      for ( const item of selected){
        try{
          uid.push(item.value);
          token.push(item.token);
          company.push(item.company);
          name.push(item.name);
          email.push(item.email);
          sendToValue.push(
            {
            label : `${item.name} (${item.company}, ${item.email})`,
             value : item.value
            },
          );     
        }catch (error){
          console.log(error);
        }

      };
      

      await db.collection("notices").add({
        title : data.title,
        body :  data.body,
        email : aUEmail,
        uid : user.uid,
        name : aUName,
        userType : aUType,
        state : 1,
        stateName : "등록완료",
        createAt : new Date(),
        updateAt : new Date(),
        files : [],
        pushOptionNo : pushOptionValue,
        sendTo : uid,
        sendToToken : token,
        sendToInfo : {
          company : company,
          name : name,
          email : email,
          uid : uid,
          token : token
        },
        sendToValue : sendToValue,
        isVisible : 1,
        replyFrom : [],
        noticeType : Number(noticeType)
    })
    .then((docRef) => {
        //console.log("Document written with ID: ", docRef.id);
        history.push({
          pathname: '/notices',
          //search: '?query=abc',
      });
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
    });

    //sendNotificationTo(token);
    //sendNotification();
    }
  }

return (
    
        <div> 
            {user ? 
             (
             
              <Content>
                <MainContainer>
                <h1 tw="text-xl font-semibold">공지사항 등록</h1>
              <FormContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
             <div>
               작성자 : {aUName} ( {aUEmail} )
              </div>

              <div>
        <p>&nbsp;</p>
        <label htmlFor="공지사항">
         <input
            key="a"
            id="a"
            type="radio"
            name="a"
            value={1}
            onChange={(e) => handleTypeChange(e)}
            checked={noticeType== 1}
            style={{
              height: 15,
              width: 20,
            }}
          />
          공지사항&nbsp;&nbsp;&nbsp;
          </label>

          <label htmlFor="만족도조사">
         <input
            key="b"
            id="b"
            type="radio"
            name="b"
            value={2}
            onChange={(e) => handleTypeChange(e)}
            checked={noticeType == 2}
            style={{
              height: 15,
              width: 20,
            }}
          />
          만족도 조사
          </label>
          <p>&nbsp;</p>
              </div>
        


             <div>
               제목 <Input defaultValue="" {...register("title", { required: true })} />
               {errors.title && <span style={errorStyle}>제목을 입력하세요.</span>}
              </div>
              <div>
             본문 <TextArea defaultValue="" {...register("body", { required: true })} rows={10}/>
             {errors.body && <span style={errorStyle}>본문을 입력하세요.</span>}
             </div>
          
              <div>
               푸시 수신자 선택 
               <Select  onChange={handleChange}>
            <option value="default">그룹 선택</option>
            <option value="default">-------</option>
            <option value="0">전체 선택</option>
            <option value="1">중소기업 전체 선택</option>
            <option value="2">전문가 전체 선택</option>
            <option value="3">직접 선택</option>
          </Select>
          {pushOption =="3"  && 
             <ColumnSelect
        options={allUserCopy}
        onChange={onChange}
        leftHeader='전체'
        rightHeader='선택된 수신자'
        isSearchable={true}
        searchPlaceholder="검색..."
        theme={{
          headerBgColor: '#eee',
          columnBorderColor: '#ccc',
          textColor: '#000000',
          columnBgColor: '#ccc',
          buttonBgColor: '#ccc',
          optionSelectedBgColor: '#eee',
          optionHoverBgColor: '#eee',
          searchFocusBorderColor: '#ccc',
        }}
      />
      }
               </div>
            
  
             <SubmitButton type="submit">
                  <span className="text">등록</span>
                </SubmitButton>
           </form>
           </FormContainer>
           </MainContainer>
           </Content>
          
             )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default NoticeAdd;

/*

to={{ pathname:`/notices`, 
      state:{
        email: aUEmail,
        uid : aUid,
        userName : aUName,
        userType : aUType,
        userState : aUState

      }}}
*/