import React from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from "react-table";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line

const StyleImages = styled.div`
display:flex; 
height: auto;
width : 600px;
align-items: flex-start;
flex-wrap: wrap;
`;

const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #fff;
  color: #000;
  text-align: left;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(odd) {
  font-weight: bold;  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;

.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const DetailProdTableMtr = ({ columns, data}) =>{ 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })



return (

  <div>
  <StyledTable {...getTableProps()}
    style={{ 
      border: 'solid 1px #fff',
      width: "100%",
    }}>

   <tbody {...getTableBodyProps()}>
     {rows.map(row => {
       prepareRow(row)
       return (
         <StyledTBodyTr {...row.getRowProps()}>
           {row.cells.map(cell => {
             return (
               <StyledTd
                 {...cell.getCellProps()}
                 /*style={{
                   padding: '10px',
                   border: 'solid 1px #ccc',
                   background: '#fff',
                   
                   
                 }}*/
               >
                 {cell.render('Cell')}
               </StyledTd>
             )
           })}
         </StyledTBodyTr>
       )
     })}
   </tbody>
 </StyledTable>
  

</div>
);
}

export default DetailProdTableMtr;

/*
   <thead>
     {headerGroups.map(headerGroup => (
       <StyledTBodyTr {...headerGroup.getHeaderGroupProps()}>
         {headerGroup.headers.map(column => (
           <StyledTh
             {...column.getHeaderProps()}
             /*style={{
               borderBottom: 'solid 3px black',
               background: '#fff',
               color: 'black',
               fontWeight: 'bold',
             }}
             >
             {column.render('Header')}
           </StyledTh>
         ))}
       </StyledTBodyTr>
     ))}
   </thead>

*/