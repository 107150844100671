import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import firebase, {auth} from "../config/firebase";
import Select from 'react-select';

const db=firebase.firestore();
const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const MonoSelect = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;


const Join2 = ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "전문가 회원가입",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign In With Google",
      url: "https://google.com"
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign In With Twitter",
      url: "https://twitter.com"
    }
  ],
  submitButtonText = "전문가 회원가입",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "/resetPwd",
  signupUrl = "/admin",
  history
}) => {

  const [formData, setFormData] = useState(defaultFormValue());
  const [selectedCateOpt, setSelectedCateOpt] = useState([]);
  const [selectedChannelOpts, setSelectedChannelOpts] = useState(null);
  const [cateNo, setCateNo] = useState([]);
  const [cateName, setCateName] = useState([]);
  const [chNo, setChNo] = useState(null);
  const [chName, setChName] = useState(null);

  const cateOpts = [
    { value: '1', label: '생활/건강' },
    { value: '2', label: '식품' },
    { value: '3', label: '이미용' },
    { value: '4', label: '패션' },
    { value: '5', label: '가전' },
    { value: '6', label: '홈인테리어' },
    { value: '7', label: '유아동' },
    { value: '8', label: '스포츠/레저' },
    { value: '9', label: '기타' },
  ]
  const channelOpts = [
    { value: '100', label: '오픈마켓' },
    { value: '101', label: '홈쇼핑' },
    { value: '102', label: 'T커머스' },
    { value: '107', label: '라이브커머스' },
    { value: '108', label: '미디어커머스' },
    { value: '103', label: '종합몰' },
    { value: '104', label: '소셜커머스' },
    { value: '109', label: '모바일 쇼핑몰' },
    { value: '105', label: '폐쇄몰' },
    { value: '106', label: '해외수출' },
    { value: '200', label: '대형마트' },
    { value: '201', label: '백화점' },
    { value: '202', label: '전문점' },
    { value: '203', label: '면세점' },
    { value: '204', label: '쇼핑센터' },
    { value: '205', label: '편의점' },
    { value: '206', label: '카테고리 킬러' },

  ]

  const ccNos= (channel,categorys) => {
    let temp=[];
    temp=categorys.map(cate=>(channel*1000)+cate);
    //setCcno(temp);
    return temp;
  }

  const checkEmail=(str)=>{                                                 
     var reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
     if(!reg_email.test(str)) {return false;}
     else {return true;}
}
  const onSubmit=(e)=>{
    e.preventDefault();
    if (formData.email=="" || formData.password=="") {
      alert("이메일과 비밀번호를 필수 입력해주세요.");
    }else if(!checkEmail(formData.email)){
      alert("올바른 이메일 주소가 아닙니다.");
    }else if (formData.password !=formData.repeatPassword) {
      alert("입력한 비밀번호가 일치하지 않습니다.");
    } else if (formData.password.length < 6) {
      alert("비밀번호는 최소 6글자 이상이어야 합니다.");
    }else if(formData.bizName==""){
      alert("회사명을 입력해주세요.");
    }else if(formData.name==""){
      alert("전문가명을 입력해주세요.");
    }else if(formData.phone==""){
      alert("전화번호를 입력해주세요.");
    }else if(chNo==null){
      alert("유통채널을 입력해주세요.");
    }else if(cateNo.length==0){
      alert("담당 카테고리를 입력해주세요.");
    }
    
    else {

      const baselineRef = db.collection("baseline").doc("base");
        baselineRef.get().then((response) => {
          const baseData = response.data();
          const total_partner_count = baseData.total_partner_count + 1;
          baselineRef.update({
            total_partner_count : total_partner_count
          })
      

      auth
        .createUserWithEmailAndPassword(formData.email, formData.password)
        .then((u) => {
          db.collection('users').doc(u.user.uid).set({
            email : formData.email,
            uid : u.user.uid,
            avatar : "",
            displayName : formData.yearsOfCareer+"년차 전문가",
            creatAt : new Date(),
            updateAt : new Date(),
            argeeAt : new Date(),
            adAt : new Date(),
            privatAt : new Date(),
            approveAt : new Date(),
            userType : 2,
            userGroup : 100,
            state : 1,
            profile : {
              total_res_count : 0,
              total_view_count : 0,
              intro : "",
              name : formData.name,
              phone : formData.phone,
              view_count : 0,
              curr_view_count : 0,
              bizInfo : {
                bizNum : "",
                bizName: formData.bizName,
                name : "",
                licensePhoto : "",
                intro : "",
              },
              proInfo : {
                channelCountry : {
                  name : "국내",//filterQlabel(q1Value),
                  no : "0"//filterQvalue(q1Value)
                },
                channelType : {
                  name : Number(chNo.charAt(0))==1 ? "온라인":"오프라인",//q2Value[0].onOffName,
                  no : Number(chNo.charAt(0))==1 ? "1":"2",
              },
                channel : {
                  name : chName,
                    no : chNo
                },
                category : {
                  name : cateName,
                  no: cateNo,
                },
                ccNo : ccNos(chNo,cateNo),
                yearsOfCareer : Number(formData.yearsOfCareer),
                mdNo : total_partner_count,
              }
            },
            
            token : "webSignUpUser",
          })
          alert("전문가로 회원가입이 완료되었습니다. 영업일+2일 내에 담당자 확인 후 승인처리됩니다.");
          history.push({
            pathname: '/admin',
          });
        })
        .catch(() => {
          alert("이미 사용중인 이메일입니다. 다른 이메일을 입력해주세요.");
        });
      
      });
    }
  };
  
  const onChange = (e, type) => {
    if(type=="yearsOfCareer" || type=="phone"){
      const value = e.target.value.replace(/\+|-/ig, '');
      setFormData({ ...formData, [type]: value });
    }else{
      setFormData({ ...formData, [type]: e.target.value });
    }


  };

  const onSelectedChannelOpt = (e) => {
    //console.log(e);
    setChNo(e.value);
    setChName(e.label);
  };

  const onSelectedCateOpt = (e) => {
    console.log(e);
    if(e.length>=4){
      alert("3개까지만 선택 가능합니다.")
    }else{
      let tempV=[];
      let tempL=[];
      e.map(v=>{
        tempV.push(v.value);
        tempL.push(v.label);
      })
      setCateName(tempL);
      setCateNo(tempV);
      //console.log(tempL);
      //console.log(tempV);
    }

  };

  return (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              
              <Form>
                
                <Input 
                  type="text" 
                  placeholder="이메일"
                  onChange={(e) => onChange(e, "email")}
                  autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호" 
                onChange={(e) => onChange(e, "password")}
                autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호 재입력" 
                onChange={(e) => onChange(e, "repeatPassword")}
                autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="회사명 입력"
                  onChange={(e) => onChange(e, "bizName")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number" 
                  placeholder="경력년수 입력 (숫자만 입력)"
                  onChange={(e) => onChange(e, "yearsOfCareer")}
                  autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="전문가명 입력"
                  onChange={(e) => onChange(e, "name")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number" 
                  placeholder="전화번호 입력 (예: 01012345678)"
                  onChange={(e) => onChange(e, "phone")}
                  autoCapitalize='none'
                />
                <div style={{
                  margin:20
                }}></div>
                <Select 
                  options={channelOpts} 
                  defaultValue={selectedChannelOpts}
                  onChange={(e) => onSelectedChannelOpt(e)}
                  placeholder="유통채널 선택"
                  />
                <div style={{
                  margin:20
                }}></div>
                <Select 
                  options={cateOpts}
                  defaultValue={selectedCateOpt}
                  onChange={(e) => onSelectedCateOpt(e)}
                  isMulti={true}
                  placeholder="담당카테고리 선택"
                />



                <SubmitButton type="submit" onClick={onSubmit}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              </Form>
              <p tw="mt-6 text-xs text-gray-600 text-center">
                <a href={forgotPasswordUrl} tw="border-b border-gray-500 border-dotted">
                  비밀번호를 잊어버렸나요?
                </a>
              </p>
              <p tw="mt-8 text-sm text-gray-600 text-center">
                로그인하시겠어요?{" "}
                <a href={signupUrl} tw="border-b border-gray-500 border-dotted">
                  로그인
                </a>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
  );
}

function defaultFormValue() {
  return {
    email: "",
    password: "",
  };
}

export default Join2;