import React, {useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth, storage} from "../config/firebase";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { Container as ContainerBase } from "components/misc/Layouts";
import styled from "styled-components";
import ColumnSelect from 'react-column-select'
import { v4 as uuidv4 } from "uuid"
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";
import { map } from "lodash";
import Select from 'react-select';

const db=firebase.firestore();

const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select2 = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const SubHeading = tw.h1`text-xl xl:text-xl font-semibold`;

const FileUploadBtn = styled.button` background-color: black; border: 1px solid black; color: #fff;padding:8px;margin-top:16px;margin-bottom:32px;margin-right:16px;font-size:11px;`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const errorStyle = {
  color : 'red',
  fontSize : "10px"

}

const noticeStyle = {
    color : 'black',
    fontSize : "10px"
  
  }

  const bodyTextStyle = {
    color : 'black',
    fontSize : "13px"
  
  }

//const NoticeAdd = (history) =>{
const ReqAdd = ({location:{state}, history }) =>{
  
const user = auth.currentUser;
/*
const {
  aUEmail,
  aUid,
  aUName,
  aUType,
  aUState
} = state; */
const {
  allUsers 
}=state;
const [aUEmail, setAuEmail] = useState("");
const [aUName, setAuName] = useState("");
const [aUType, setAuType] = useState("");
const [aUState, setAuState] = useState("");
const [cateNo, setCateNo] = useState(null);
const [cateName, setCateName] = useState(""); 
const [selectedFiles, setSelectedFiles] = useState(null);
const [attachment, setAttachment] = useState([]);
const [file, setFile] = useState([]);
const [fileUrl, setFileUrl] = useState([]);


const resetFileInput=React.useRef();

const [ccNo, setCcno] = useState([]);
const [displayName, setDisplayName] = useState("");
const [userType, setUserType] = useState("");
const [userState, setUserState] = useState("");
const [respondent,setRespondent] = useState({});
const [token, setToken] = useState("");
const [isRespondent,setIsRespondent] = useState(false);
const [userEmail, setUserEmail] = useState("");
const [userCompany, setUserCompany] = useState("");
const [mdToken, setMdToken] = useState("");
const [mdUidMono, setMdUidMono] = useState("");
const [mdNo, setMdNo] = useState(-1);
const [mdUid, setMdUid] = useState("");
const [mdEmail, setMdEmail] = useState("");
const [mdName, setMdName] = useState("");
const [mdCompany, setMdCompany] = useState("");
const [mds, setMds] = useState([]);
const [items, setItems] = useState([]);
const [selectedMdNo, setSelectedMdNo] = useState(null);

const qDoc=[
  { 
    label: "인허가 서류>사업자등록증",
    value: 1000,
    name: "사업자등록증"
    },
    {
    label: "인허가 서류>제조/수입/판매업 신고(허가)증",
    value: 1001,
    name: "제조/수입/판매업 신고(허가)증"
    },
    {
    label: "인허가 서류>제조품목허가서",
    value: 1002,
    name: "제조품목허가서"
    },
    {
    label: "인허가 서류>의료기기 품질관리기준적합인정서(제조, 수입)",
    value: 1003,
    name: "의료기기 품질관리기준적합인정서(제조, 수입)"
    },
    {
    label: "인허가 서류>의료기기 광고사전심의",
    value: 1004,
    name: "의료기기 광고사전심의"
    },
    {
    label: "검사 성적서>원단시험성적서",
    value: 2000,
    name: "원단시험성적서"
    },
    {
    label: "검사 성적서>정수기 품질검사 성적서",
    value: 2001,
    name: "정수기 품질검사 성적서"
    },
    {
    label: "검사 성적서>재질 및 용출시험",
    value: 2002,
    name: "재질 및 용출시험"
    },
    {
    label: "검사 성적서>재질 성분시험",
    value: 2003,
    name: "재질 성분시험"
    },
    {
    label: "검사 성적서>완제품 시험성적서",
    value: 2004,
    name: "완제품 시험성적서"
    },
    {
    label: "검사 성적서>기타 시험성적서",
    value: 2005,
    name: "기타 시험성적서"
    },
    {
    label: "거래사실 확인서류>수입신고필증",
    value: 3000,
    name: "수입신고필증"
    },
    {
    label: "거래사실 확인서류>식품등의 수입신고필증",
    value: 3001,
    name: "식품등의 수입신고필증"
    },
    {
    label: "거래사실 확인서류>상품 거래 사실(OEM 포함)",
    value: 3002,
    name: "상품 거래 사실(OEM 포함)"
    },
    {
    label: "거래사실 확인서류>권한사용 계약서",
    value: 3003,
    name: "권한사용 계약서"
    },
    {
    label: "인증 서류>법 강제 인증서",
    value: 4000,
    name: "법 강제 인증서"
    },
    {
    label: "인증 서류>특허관련",
    value: 4001,
    name: "특허관련"
    },
    {
    label: "인증 서류>품질관련",
    value: 4002,
    name: "품질관련"
    },
    {
    label: "인증 서류>안전관련",
    value: 4003,
    name: "안전관련"
    },
    {
    label: "인증 서류>안정성 관련",
    value: 4004,
    name: "안정성 관련"
    },
    {
    label: "인증 서류>생산물책임보험",
    value: 4005,
    name: "생산물책임보험"
    },
    {
    label: "인증 서류>BSE 미감염증명서",
    value: 4006,
    name: "BSE 미감염증명서"
    },
    {
    label: "인증 서류>논문",
    value: 4007,
    name: "논문"
    },
    {
    label: "기타>제품 표준서",
    value: 5000,
    name: "제품 표준서"
    },
    {
    label: "기타>작업 지시(표준)서",
    value: 5001,
    name: "작업 지시(표준)서"
    },
    {
    label: "기타>등기부 등본",
    value: 5002,
    name: "등기부 등본"
    },
    {
    label: "기타>토지 대장",
    value: 5003,
    name: "토지 대장"
    },
    {
    label: "기타>비디오물 등급필증",
    value: 5004,
    name: "비디오물 등급필증"
    },
    {
    label: "기타>사용설명서 (주의사항)",
    value: 5005,
    name: "사용설명서 (주의사항)"
    },
    {
    label: "기타>표시사항",
    value: 5006,
    name: "표시사항"
    },
    {
    label: "기타>원산지 확약서",
    value: 5007,
    name: "원산지 확약서"
    }
];
const foodQDoc=[
  {
    label: "업체관련>영업허가(신고)증",
    value: 100,
    name: "영업허가(신고)증"
    },
    {
    label: "업체관련>건강진단결과서(건강진단서)",
    value: 101,
    name: "건강진단결과서(건강진단서)"
    },
    {
    label: "업체관련>수질검사 성적서(노로바이러스 검사성적서)",
    value: 102,
    name: "수질검사 성적서(노로바이러스 검사성적서)"
    },
    {
    label: "업체관련>제조물책임보험증권",
    value: 103,
    name: "제조물책임보험증권"
    },
    {
    label: "업체관련>HACCP,GMP 제품표준서 및 관리기준서",
    value: 104,
    name: "HACCP,GMP 제품표준서 및 관리기준서"
    },
    {
    label: "업체관련>HACCP,GMP 심사내역(AUDIT 점검내역)",
    value: 105,
    name: "HACCP,GMP 심사내역(AUDIT 점검내역)"
    },
    {
    label: "업체관련>방충,방서관리일지",
    value: 106,
    name: "방충,방서관리일지"
    },
    {
    label: "업체관련>차량점검일지(냉장,냉동 차량온도기록지)",
    value: 107,
    name: "차량점검일지(냉장,냉동 차량온도기록지)"
    },
    {
    label: "업체관련>위생교육 수료증 사원 위생교육일지",
    value: 108,
    name: "위생교육 수료증 사원 위생교육일지"
    },
    {
    label: "업체관련>협력사/제조원 거래계약서",
    value: 109,
    name: "협력사/제조원 거래계약서"
    },
    {
    label: "제품 및 공정>품목제조보고(신고)서",
    value: 200,
    name: "품목제조보고(신고)서"
    },
    {
    label: "제품 및 공정>자가품질검사성적서",
    value: 201,
    name: "자가품질검사성적서"
    },
    {
    label: "제품 및 공정>영양성분분석성적서(제품 공간 비율 성적서)",
    value: 202,
    name: "영양성분분석성적서(제품 공간 비율 성적서)"
    },
    {
    label: "제품 및 공정>수입원료,제품서류",
    value: 203,
    name: "수입원료,제품서류"
    },
    {
    label: "제품 및 공정>[해당시] 원부재료 원산지증명서(유기농 취급시 유기농 인증서류)",
    value: 204,
    name: "[해당시] 원부재료 원산지증명서(유기농 취급시 유기농 인증서류)"
    },
    {
    label: "제품 및 공정>원,부재료 수불일지",
    value: 205,
    name: "원,부재료 수불일지"
    },
    {
    label: "제품 및 공정>원,부재료 입고검사일지 원부재료 상품안전성 검사 성적서",
    value: 206,
    name: "원,부재료 입고검사일지 원부재료 상품안전성 검사 성적서"
    },
    {
    label: "제품 및 공정>생산일지 / 작업일지 / 제조기록서(공정별 생산일지 포함 및 배합일지)",
    value: 207,
    name: "생산일지 / 작업일지 / 제조기록서(공정별 생산일지 포함 및 배합일지)"
    },
    {
    label: "제품 및 공정>판매상품별 제조공정도 제품별 공정(관리)일지",
    value: 208,
    name: "판매상품별 제조공정도 제품별 공정(관리)일지"
    },
    {
    label: "제품 및 공정>생산별(공정별) 제품안전성 검사일지 기구별 검/교정일지",
    value: 209,
    name: "생산별(공정별) 제품안전성 검사일지 기구별 검/교정일지"
    },
    {
    label: "제품 및 공정>동일제품 유통 현황 [제품거래내역(3년)]",
    value: 210,
    name: "동일제품 유통 현황 [제품거래내역(3년)]"
    },
    {
    label: "제품 및 공정>완제품 출고 및 반품 관리내역",
    value: 211,
    name: "완제품 출고 및 반품 관리내역"
    },
    {
    label: "제품 및 공정>냉장냉동 온도관리일지",
    value: 212,
    name: "냉장냉동 온도관리일지"
    },
    {
    label: "제품 및 공정>CCP 점검일지",
    value: 213,
    name: "CCP 점검일지"
    },
    {
    label: "제품 및 공정>컴플레인 처리리포트",
    value: 214,
    name: "컴플레인 처리리포트"
    },
    {
    label: "원료관련>거래명세서",
    value: 300,
    name: "거래명세서"
    },
    {
    label: "원료관련>수입원료,제품서류",
    value: 301,
    name: "수입원료,제품서류"
    },
    {
    label: "원료관련>GMO증빙 및 검사성적서(대두,옥수수,면화,카놀라,사탕무,알팔파)",
    value: 302,
    name: "GMO증빙 및 검사성적서(대두,옥수수,면화,카놀라,사탕무,알팔파)"
    },
    {
    label: "원료관련>품목제조보고서",
    value: 303,
    name: "품목제조보고서"
    },
    {
    label: "원료관련>시험성적서(잔류농약시험성적서 - 해당상품 취급시)",
    value: 304,
    name: "시험성적서(잔류농약시험성적서 - 해당상품 취급시)"
    },
    {
    label: "원료관련>도축증명서",
    value: 305,
    name: "도축증명서"
    },
    {
    label: "원료관련>등급판정서",
    value: 306,
    name: "등급판정서"
    },
    {
    label: "원료관련>수매확인서",
    value: 307,
    name: "수매확인서"
    },
    {
    label: "원료관련>원료 표시사항",
    value: 308,
    name: "원료 표시사항"
    },
    {
    label: "포장 및 광고>거래명세서(포장지 업체 관련 신고서류)",
    value: 400,
    name: "거래명세서(포장지 업체 관련 신고서류)"
    },
    {
    label: "포장 및 광고>포장제 시험성적서",
    value: 401,
    name: "포장제 시험성적서"
    },
    {
    label: "포장 및 광고>광고사전심의필증",
    value: 402,
    name: "광고사전심의필증"
    },
    {
    label: "포장 및 광고>상품별 표시사항(포장지 한도견본)",
    value: 403,
    name: "상품별 표시사항(포장지 한도견본)"
    },
    {
    label: "포장 및 광고>외박스 표시사항",
    value: 404,
    name: "외박스 표시사항"
    },
    {
    label: "포장 및 광고>설명서,보증서 등",
    value: 405,
    name: "설명서,보증서 등"
    },
    {
    label: "포장 및 광고>각종 인증서[제품표시내용 등]",
    value: 406,
    name: "각종 인증서[제품표시내용 등]"
    },
    {
    label: "포장 및 광고>관련 논문 및 특허",
    value: 407,
    name: "관련 논문 및 특허"
    },
    {
    label: "기타(공통)>원산지확약서",
    value: 500,
    name: "원산지확약서"
    },
    {
    label: "기타(공통)>서류,현장 개선 계획서",
    value: 501,
    name: "서류,현장 개선 계획서"
    },
    {
    label: "기타(공통)>기타자료(원료,상품,언론이슈 자료)",
    value: 502,
    name: "기타자료(원료,상품,언론이슈 자료)"
    }
];


const getUserInfo = async(uid) => {
    
  const users = await db.collection("users")
  .where("uid", "==", uid)
  .get();
  users.docs.map(user => {

    setAuEmail(user.data().email);
    setAuName(user.data().profile.name);
    setAuType(user.data().userType);
    setAuState(user.data().state);
    //console.log("email  =>",user.data().email);
  })
};


const sendNotificationTo = async(tokens) => {
  //console.log("token:", tokens);
  tokens.map(token => sendNotification(token))
};


const sendNotification=async()=>{ 
  console.log("-------ready to push");
  const message = {
    to: "ExponentPushToken[6WuFm9HgjUYbe7mbZcDDUZ]",
    sound: 'default',
    title: '상품/역량 진단 요청 알림',
    body: '(sss)',
    //data: { url: `${prefix}notices/${room.id}`,  },
    data: { url: '/account'  },

  };

  await fetch('https://cors-anywhere.herokuapp.com/https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
  console.log("------finish to push-----")
}

const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [result, setResult] = useState("");
  const [pushOption, setPushOption] = useState("");
  const [pushOptionValue, setPushOptionValue] = useState(""); 
  const [selected, setSelected] = useState(null)  


  useEffect(() => {
    getUserInfo(user.uid);
    getToken();
    //console.log("allUsers=>",allUsers);
    //console.log("filter=>",filterItems(2));
  }, []);


  const onFileChange = (event) => {
    setSelectedFiles(event.target.files);
    for (let i = 0; i < selectedFiles.length; i++) {
      //formData.append("myfile", selectedFiles[i], selectedFiles[i].name);
      //console.log(selectedFiles[i]+" pdf =>"+selectedFiles[i].name)
    }

    const {target:{file, value}} = event;
    const theFile = file[0];
    const reader = new FileReader();
    setFile(value)
    reader.onloadend = (finishedEvent) => {
      const { currentTarget: {result}} = finishedEvent
      setAttachment(result)
    }
    reader.readAsDataURL(theFile);
  }
  const onClearAttachment = () => {
    setAttachment([]);
    setFile([]);
    resetFileInput.current.value="";
  };


  const onFileUpload = async (event) => {
    event.preventDefault();
    let attachmentUrl = "";
    if (attachment !== "") {
      const attachmentRef = storage.ref().child(`reqs/${uuidv4()}`);
      const response = await attachmentRef.putString(attachment, "data_url")
      attachmentUrl = await response.ref.getDownloadURL()
    }
    const postObj = {
        text: "aaa",
        createdAt: Date.now(),
        creatorId: user.uid,
        attachmentUrl,
    }
    await db.collection("reqs").add(postObj);
    setAttachment('');
    setFile('')
  };


  const handleFilesChange = (e) => {
    e.preventDefault();
    // files 배열 자체를 담을 것이므로 index 뺌.
    setSelectedFiles(e.target.files);

    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("myfile", e.target.files[i], e.target.files[i].name);
      console.log(e.target.files[i]+" img =>"+e.target.files[i].name)
    }
    setAttachment(formData.getAll("myfile"));
    //console.log(formData.getAll("myfile")[0].name);
    //console.log(formData.getAll("myfile")[1].name);
    
    //console.log(attachment[1].name);



  };

  const handleFilesUpload = async(event) => {
    event.preventDefault();
    //console.log(attachment[0].name);
    let fileName=[];
    let pdfBlob=[];
    for (let i = 0; i < attachment.length; i++) {
      let id=uuidv4();
      //console.log(attachment[i].name)

      //console.log(id);
      //console.log(attachment[i].name)

      
      try {
        await storage.ref("reqs").child(id).put(attachment[i])
        .then((result) => {
           storage
          .ref(`reqs/${id}`)
          .getDownloadURL()
          .then((fileUrl) => {
            pdfBlob.push(fileUrl);
          });
        });
      } catch (error) {
          console.log("error", error);
      }
    }
    setFileUrl(pdfBlob);
    //console.log(pdfBlob);
    alert("이미지가 성공적으로 업로드 되었습니다.");
    /*
    uploadFileStorage().then((response) => {
      setFileUrl(response);
      alert("파일이 성공적으로 업로드 되었습니다.");
    });

*/


  



  };

  const uploadFileStorage = async () => {
    const pdfBlob = [];

    await Promise.all(
      map(attachment, async (f) => {
        const response = await fetch(f);
        console.log(response);

        const blob = await response.blob();
        console.log("blob=>",blob);
        const ref = storage.ref("reqs").child(uuidv4());
        await ref.put(blob).then(async (result) => {
          await storage
            .ref(`reqs/${result.metadata.name}`)
            .getDownloadURL()
            .then((fileUrl) => {
              pdfBlob.push(fileUrl);
            });
        });
      })
    );

    return pdfBlob;
  };

  const uploadedFile = () => {
    const result = [];
 
    for (let i = 0; i < attachment.length; i++) {
      result.push(<span style={noticeStyle} key={i}>{attachment[i].name}<br/></span>);
    }
    return result;
  };

  const selectCateName=(value)=>{

    switch(value) {
      case '1':
        return "생활/건강";
      case '2':
        return "식품";
      case '3':
        return "이미용";
      case '4':
        return "패션";
      case '5':
        return "가전";
      case '6':
        return "홈인테리어";
      case '7':
        return "유아동";
      case '8':
        return "스포츠/레저";
      case '9':
        return "기타";
      default:
        return 'default';
    }

  }

  const handleCategoryChange = (event) => {
    
    //console.log(event.target.value);
    setCateNo(Number(event.target.value));
    const name=selectCateName(event.target.value);
    setCateName(name);
    //console.log(name);
    event.preventDefault();

  }

  const handleChange = (event) => {
    //console.log(event);
    //console.log(event.id);
    //console.log(event.value);
    //setPushOption(event.id);
    setSelected(event.value);

    //label 가져오기 
    //console.log("label=>",event.target.selectedOptions[0].text);
    //setPushOption(event.target.value);
    //console.log("select value->",event.target.value);
    //setSelected(event.target.value);
    //setSelected(event.target.selectedOptions[0].text);
    //event.preventDefault();
    
    //console.log("selected=>",selected);

    //console.log("pushOption->",event.target.value);
  }

  const onChange = (values) => {
    setSelected(values)
    //console.log("selected=>", values)
  }

  const ccNos= (cate,channels) => {
    let temp=[];
    temp=channels.map(ch=>(cate*1000)+ch);
    setCcno(temp);
    return temp;
  }

  const getPartners = async() => {
    //console.log("====2====");
    let item=[];

    const users = await db.collection("users")
    .where("profile.proInfo.mdNo", ">", -1)
    .orderBy("profile.proInfo.mdNo", "asc")
    .get();
    //console.log("====3====");
    //console.log("token", user.data().token);

    users.docs.map(user => {
      if(user.data().profile.proInfo.mdNo == 0){
        item.push({
          label: "홈쇼핑",
          value: user.data().uid,
          id: user.data().profile.proInfo.mdNo,
          email : user.data().email,
          token : user.data().token,
          uid : user.data().uid
          });

      }else{
        item.push({
          label: user.data().profile.proInfo.mdNo,
          value: user.data().uid,
          id: user.data().profile.proInfo.mdNo,
          email : user.data().email,
          token : user.data().token,
          uid : user.data().uid
          });

      }
      
    })
    //console.log("====4====");
    setMds(item);

    const items=[
      {
        title: '전문가 선택',
        id: -1,
        children: item
      },
    ]

    setItems(items);
    //items["child"](item);
    //console.log("get partners=>",item);
    //console.log("====5====");
  };

  

  const getToken = () => {
      const docRef = db.collection("users").doc(user.uid);
      docRef.get().then((doc) => {
          if (doc.exists) {
              //console.log("Document data:", doc.data());
              //console.log("token Data: ", doc.data().token);
              //Get 중소기업의 유저 정보
              setDisplayName(doc.data().displayName);
              setUserType(doc.data().userType);
              setUserState(doc.data().state);
              setRespondent(doc.data().profile.respondent);
              setToken(doc.data().token);

              setUserEmail(doc.data().email);
              setUserCompany(doc.data().profile.bizInfo.bizName);


              if(doc.data().profile.respondent.email !=""){
                setIsRespondent(true);
                //console.log("email not null");
                //email로 token 가져오기
                
                //Get 전문가의 유저 정보
                db.collection("users")
                .where("email", "==", doc.data().profile.respondent.email)
                .get()
                .then((users)=>{
                  users.forEach((doc)=>{
                    //console.log(doc.id, "=>", doc.data());
                    setMdToken(doc.data().token);
                    setMdUidMono(doc.id);
                    setMdNo(doc.data().profile.proInfo.mdNo);
                    setMdEmail(doc.data().email);
                    setMdName(doc.data().profile.name);
                    setMdCompany(doc.data().profile.bizInfo.bizName);


                    const docRef = db.collection("users").doc(user.uid);
                    docRef.update({
                    "profile.respondent.token" : doc.data().token,
                    "profile.respondent.uid" : doc.data().uid,
                    "profile.respondent.mdNo" : doc.data().profile.proInfo.mdNo,
                })
                  })

                })
                
                  
                //email로 token update 할 필요 있어?

              }else{
                //console.log("email=>",doc.data().profile.respondent.email);
                //전문가 리스트 데이터 가져오기 함수
                //console.log("isRespondent =>",isRespondent);
                setIsRespondent(false);
                //console.log("====1====");
                getPartners();
                //console.log("====6====");
              }

          } else { 
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });

  };
  

  const onSubmit = async (data) => {

    //console.log("cateNo=>", cateNo);
    //데이터 전달받기
    //console.log("title=>",data.title);    
    //setResult(JSON.stringify(data));
    //console.log(data);
      let sendToUid="";
      if(mdUidMono !=""){
      sendToUid=mdUidMono;
      }else{
        sendToUid=selected;
      }

      //console.log(selected);

      /*
      let uid=[];
      let token=[];
      let company=[];
      let name=[];
      let email=[];
      let sendToValue = [];     
      for ( const item of selected){
        try{
          uid.push(item.value);
          token.push(item.token);
          company.push(item.company);
          name.push(item.name);
          email.push(item.email);
          sendToValue.push(
            {
            label : `${item.name} (${item.company}, ${item.email})`,
             value : item.value
            },
          );     
        }catch (error){
          console.log(error);
        }

      };
      */
     if(cateNo==null || cateNo=="default"){

      alert("카테고리를 선택해주세요.");
     
     }else if(fileUrl.length==0){
      alert("상품 사진을 업로드해주세요.");

     }else{

      await db.collection("reqs_min").add({

        prodName : data.prodName,
        brand : data.brand,
        price : Number(data.price),
        unitItem : data.unitItem,
        manufacturer : data.manufacturer,
        customer: data.customer,
        sellingPoint : data.sellingPoint,
        competProd : data.competProd,
        ccNo : ccNos(cateNo,["101"]),
        cateNo: cateNo,
        cateName : cateName,
        channelNo : ["101"],//chNo,
        channelName : "홈쇼핑",
        url : data.url || "",
        revenue : "",
        chart_cus_label : [],
        chart_cus_data : [],
        chart_md_avg_label : [],
        chart_md_avg_data : [],
        predicChannels: [],
        wishChNo : [],
        wishChName : [],
        reqMsg: "",
        sortPredicChannels : {},
        disadvantage : "",
        images: fileUrl,
        resCount	: 2,
        currResCount : 0,
        state : 1,
        stateName : "진단진행중",
        createAt: new Date(),
        createBy: user.uid,
        sendTo: sendToUid,
        token : token,
        isChart : false,
        reqEmail : aUEmail,
        reqName : aUName,
        reqCompany : userCompany,
        resEmail : mdEmail,
        resName : mdName,
        resCompany : mdCompany,
        qDocNo : [],
        qDocName : []
    })
    .then((docRef) => {
        //console.log("Document written with ID: ", docRef.id);
      history.push({
        pathname: '/RespAdd',
        //search: '?query=abc',
        state: { 
          loginEmail: aUEmail,
          loginUid : user.uid,
          loginUserName : aUName,
          loginUserType : aUType,
          loginUserState : aUState,
          idReq: docRef.id,
          cateNo : cateNo,
          allQDocs :  cateNo==2 ? foodQDoc : qDoc
        }

    });
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
    });

     }

      

    //sendNotificationTo(token);
    //sendNotification();
    
  }

return (
    
        <div> 
            {user ? 
             (
             
              <Content>
                <MainContainer>
                <Heading>진단요청</Heading>
                <SubHeading>1.상품정보</SubHeading>
              <FormContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
             <div>
               작성자 : {aUName} ( {aUEmail} )
              </div>

              <div>
               카테고리
               <Select2 value={cateNo} onChange={handleCategoryChange}>
               <option value="default">카테고리 선택</option>
                  <option value="1" label="생활/건강">생활/건강</option>
                  <option value="2">식품</option>
                  <option value="3">이미용</option>
                  <option value="4">패션</option>
                  <option value="5">가전</option>
                  <option value="6">홈인테리어</option>
                  <option value="7">유아동</option>
                  <option value="8">스포츠/레저</option>
                  <option value="9">기타</option>
          </Select2>
              </div>
             <div>
               상품명 <Input defaultValue="" {...register("prodName", { required: true })} />
               {errors.prodName && <span style={errorStyle}>상품명을 입력하세요.</span>}
              </div>
              <div>
               브랜드 <Input defaultValue="" {...register("brand", { required: true })} />
               {errors.brand && <span style={errorStyle}>브랜드를 입력하세요.</span>}
              </div>

              <div>
               판매가 
               <span style={noticeStyle}> 숫자만 입력해주세요.</span>
               <Input type="number" defaultValue="" {...register("price", { required: true })} />
               {errors.price && <span style={errorStyle}>판매가를 입력하세요.</span>}
              </div>
              <div>
               상품구성 <Input defaultValue="" {...register("unitItem", { required: true })} />
               {errors.unitItem && <span style={errorStyle}>상품구성을 입력하세요.</span>}
              </div>
              <div>
               제조사 <Input defaultValue="" {...register("manufacturer", { required: true })} />
               {errors.manufacturer && <span style={errorStyle}>제조사를 입력하세요.</span>}
              </div>
              <div>
               상품정보 웹사이트 <Input defaultValue="https://" {...register("url", { required: true })} />
               {errors.url && <span style={errorStyle}>제조사를 입력하세요.</span>}
              </div>
              <div>
                상품 사진업로드
                <span style={noticeStyle}> 최대 4장까지 Ctrl 키로 파일 다중 선택이 가능합니다.</span>
                <Input type="file" onChange={handleFilesChange} accept="image/*" multiple ref={resetFileInput}/> 
                
                {uploadedFile()}
                <FileUploadBtn onClick={handleFilesUpload}>전체 업로드</FileUploadBtn>
                <FileUploadBtn onClick={onClearAttachment}>전체 삭제</FileUploadBtn>
            
              </div>
              <SubHeading>2.판매정보</SubHeading>
              <div>
              주요고객(예시 : 20대 초반 여자) <Input defaultValue="" {...register("customer", { required: true })} />
               {errors.customer && <span style={errorStyle}>주요고객을 입력하세요.</span>}
              </div>
              <div>
             셀링포인트 <TextArea defaultValue="" placeholder="셀링포인트를 입력해주세요. (상품의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요)" {...register("sellingPoint", { required: true })} rows={5}/>
             {errors.sellingPoint && <span style={errorStyle}>셀링포인트를 입력하세요.</span>}
             </div>
             <div>
             경쟁상품 입력<Input defaultValue="" {...register("competProd", { required: true })} />
               {errors.competProd && <span style={errorStyle}>경쟁상품을 입력하세요.</span>}
              </div>
              <p>&nbsp;</p>
              { isRespondent == false &&
              <SubHeading>3.전문가 선택</SubHeading>
              }
              { isRespondent == false &&
              <div>
               역량진단을 진행할 전문가 번호를 선택해주세요.
               <p style={bodyTextStyle}> 
            
            {`
(만약 전문가 번호를 부여받지 않았다면 "홈쇼핑"을 선택해주세요)
`}</p>
               <div style={{
                  margin:20
                }}></div>
                 <Select 
                  options={mds} 
                  defaultValue={selectedMdNo}
                  onChange={handleChange}
                  placeholder="전문가 선택"
                  />
             
               </div>
                }

            <p>&nbsp;</p>
              { isRespondent == false ? 
               <SubHeading>4.자가진단</SubHeading> :
               <SubHeading>3.자가진단</SubHeading> 
              }
               <div>
             [다음] 버튼을 눌러 자가진단을 진행해주세요.
             <p style={bodyTextStyle}> 
            
            {`
자가진단이란? 
내 상품의 시장성, 상품성, 사업성에 관해 직접 점검해보는 
체크리스트로 컨설팅 진행에 기초 자료로 사용됩니다.
(10문항, 5분 정도 소요)
`}</p>
              </div>
  
             <SubmitButton type="submit">
                  <span className="text">다음</span>
                </SubmitButton>
           </form>
           </FormContainer>
           </MainContainer>
           </Content>
          
             )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default ReqAdd;

/*

to={{ pathname:`/notices`, 
      state:{
        email: aUEmail,
        uid : aUid,
        userName : aUName,
        userType : aUType,
        userState : aUState

      }}}
*/