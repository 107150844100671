import React, {useRef, useState, useEffect, useCallback } from 'react';
import queryString from "query-string";
import firebase from "../../config/firebase";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import {StylesManager } from "survey-react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getFirestore, getDocs, where, onSnapshot, limit, query, orderBy, collection, addDoc, updateDoc, serverTimestamp, deleteDoc, deleteField, runTransaction } from "firebase/firestore";
import { getDatabase, ref, child, get, set, update } from "firebase/database";
import moment from "moment";
StylesManager.applyTheme("winterstone");
const db=firebase.firestore();
const auth = getAuth();
const WebAdd = ({ match, location }) => {
  const query = queryString.parse(location.search), [mode, setMode] = useState(query.mode), [type, setType] = useState({});
  //console.log("mode->>",mode);
  useEffect(() => { switch (mode) { case "rechallenge": setType(rechallenge); break; case "reqbiz": setType(reqbiz); break; case "req": setType(req); break; default: setType(req); } }, []);
  const rechallenge={ title: "기업실패 원인 및 재도전 진단", description: "", logo: "https://www.drmd.kr/static/media/logo.e46179e0.png", logoWidth: 60, logoHeight: 60,
  elements: [
    { type : "html", name: "info", html: "안녕하세요?<br/>저희는 중소벤처기업진흥공단에서 실시하는 “재도전 교육 및 컨설팅 사업”을 담당하고 있는 ㈜엠디글로벌넷 입니다.<br/>본 설문지는 기존 실패했던 기업에 대한 실패 원인 연구와 재도전 사업의 성공 요인에 대한 컨설팅 및 연구를 진행하기 위해 작성되었습니다.<br/>귀하께서 응답하신 내용은 중소벤처기업진흥공단 재도전 교육 및 컨설팅과 연구를 위한 통계적 자료로만 이용되며, 귀하의 개인 정보는 익명으로 보장됩니다.<br/>질문에 정답이 있는 것은 아니니 솔직하게 응답해 주시면 되겠습니다. 질문을 읽으신 후 가급적 오래 생각하시지 마시고 먼저 떠오르는 것에 응답해 주시기 바랍니다.<br/>본 연구에 참여하여 주셔서 감사합니다.<br/><br/>2023년 8월<br/>중소벤처기업진흥공단 재도약성장처<br/>㈜엠디글로벌넷 대표이사 이상발 드림<br/>☎ 02-6267-1100, E-mail : jalsago@naver.com",},
    { type : "html", name: "info", html: `<p style="font-size: 18px"><b>[1] 기본정보</b></p>`},    
    { name: "isStart", type: "radiogroup", isRequired: true, title: "귀하의 현재 재창업 상태는?", colCount: 2, choices: [ "예비재창업자(사업자 미등록)","기재창업자(사업자 등록)"]},
    { name: "sex", type: "radiogroup", isRequired: true, title: "귀하의 성별은?", colCount: 2, choices: [ "남성","여성"]},
    { name: "age", type: "radiogroup", isRequired: true, title: "귀하의 연령대는?", colCount: 3, choices: [ "20대","30대","40대","50대","60대 이상"]},
    { name: "edu", type: "radiogroup", isRequired: true, title: "귀하의 학력은?", colCount: 3, choices: [ "고졸","전문대졸","대졸","석사","박사 이상"]},

    { type : "html", name: "info", html: `<p style="font-size: 18px"><b>[2] 기존 실패기업 관련 설문</b></p><br/>귀하의 실패했던 사업이 2개 이상이라면 그중 가장 최근에 하셨던 사업으로 설문에 응답해 주시기 바라며, 아래의 질문을 읽고 자신의 생각에 가장 가깝다고 생각되는 번호에 체크(√)해 주시기 바랍니다.`},    
    { name: "q1", type: "radiogroup", isRequired: true, title: "귀하의 실패기업 소재지는?", colCount: 2, choices: [ "서울","인천, 경기도","대전, 세종, 충청","부산, 대구, 울산, 경상","광주, 전라도", "강원도", "제주도"]},
    { name: "q2", type: "radiogroup", isRequired: true, title: "실패했던 사업의 업종은?", colCount: 3, choices: [ "제조업","도소매업","건설업","IT업","서비스업", "기타"]},
    { name: "q3", type: "text", title: "제조업의 세부업종은?", placeHolder: "", isRequired: true,visibleIf: "{q2} == '제조업'"},
    { name: "q4", type: "text", title: "기타 업종은?", placeHolder: "", isRequired: true,visibleIf: "{q2} == '기타'"},
    { name: "q5", type: "comment", title: "실패기업 아이템은?", placeHolder: "", isRequired: true },
    { name: "q6", type: "radiogroup", isRequired: true, title: "실패기업 년간 매출액?", colCount: 2, choices: [ "1억원 미만","1억원~5억원 미만","5억원~10억원 미만","10억원~30억원 미만","30억원 이상"]},

    { name: "q7", type: "radiogroup", isRequired: true, title: "실패했던 기업은 기술 또는 제조기반 사업이었나요?", colCount: 3, choices: [ "기술기반", "제조기반", "해당없음"]},
    { name: "q8", type: "matrix", isRequired: true, title: "기업의 자원 확보", columns: [ {value: 1,text: "전혀그렇지않다"}, {value: 2,text: "그렇지않다"},{value: 3,text: "보통이다"},{value: 4,text: "그렇다"},{value: 5,text: "아주 그렇다"}], rows: [{value: "sq1",text: "실패했던 기업은 사업화(영업, 개발, 제조 등)에 필요한 인력을 확보했다."},{value: "sq2",text: "실패했던 기업은 재정적 자원(자본, 매출)이 적절했다."},{value: "sq3",text: "실패했던 기업은 네트워크(협력사 등)를 다양하게 확보했다."},{value: "sq4",text: "실패했던 기업은 기술적 자원(인력, 특허 등)을 확보했다.",visibleIf: "{q7} = '기술기반'"},{value: "sq5",text: "실패했던 기업은 적절한 규모의 생산설비를 확보했다.",visibleIf: "{q7} = '제조기반'"}]},  
    { name: "q9", type: "matrix", isRequired: true, title: "기업의 외부환경 변화 대응", columns: [ {value: 1,text: "전혀그렇지않다"}, {value: 2,text: "그렇지않다"},{value: 3,text: "보통이다"},{value: 4,text: "그렇다"},{value: 5,text: "아주 그렇다"}], rows: [{value: "sq1",text: "실패했던 기업의 고객(시장)에 대한 파악 및 변화 대응은 적절했다."},{value: "sq2",text: "실패했던 기업의 거래처(수요처, 공급처)에 대한 파악 및 변화 대응은 적절했다."},{value: "sq3",text: "실패했던 기업의 산업환경(트랜드 등) 파악 및 변화 대응은 적절했다."},{value: "sq4",text: "실패했던 기업의 정부 정책(제도, 규제 등) 파악 및 변화 대응은 적절했다."}]},  
    { name: "q10", type: "matrix", isRequired: true, title: "기업의 영업·마케팅", columns: [ {value: 1,text: "전혀그렇지않다"}, {value: 2,text: "그렇지않다"},{value: 3,text: "보통이다"},{value: 4,text: "그렇다"},{value: 5,text: "아주 그렇다"}], rows: [{value: "sq1",text: "실패했던 기업은 목표한 고객을 대상으로 제품 및 서비스를 판매하여 충분한 매출이 발생하였다."},{value: "sq2",text: "실패했던 기업은 고객의 핵심문제(해결되면 비용 지불의사가 있는 문제)를 해결한 제품 및 서비스를 구현했다."},{value: "sq3",text: "실패했던 기업은 시장진입 및 시장확장 전략이 적절했다."},{value: "sq4",text: "실패했던 기업은 제품 및 서비스는 경쟁사 대비 차별화(가격 및 기능, 성능 등) 요인을 확보했다."}]},  
    { name: "q11", type: "matrix", isRequired: true, title: "기업가 역량", columns: [ {value: 1,text: "전혀그렇지않다"}, {value: 2,text: "그렇지않다"},{value: 3,text: "보통이다"},{value: 4,text: "그렇다"},{value: 5,text: "아주 그렇다"}], rows: [{value: "sq1",text: "실패했던 기업에서 나는 제품 및 서비스 관련 전문성(지식, 경험, 품질정책, 가격정책 등)을 갖추었다."},{value: "sq2",text: "실패했던 기업에서 나는 다양한 사람의 의견을 수렴하여 의사결정을 했다."},{value: "sq3",text: "실패했던 기업에서 나는 조직원 간의 갈등에 대해 원활한 커뮤니케이션 활동을 하였다."},{value: "sq4",text: "실패했던 기업에서 나는 적절히 자금을 확보하고 관리했다."},{value: "sq5",text: "실패했던 기업에서 나는 사업확장 및 다각화 전략을 적절히 수립하고 실행했다."}]},  
    { name: "q12", type: "radiogroup", isRequired: true, title: "자신이 생각하기에 실패했던 기업에서 가장 핵심적인 실패 원인은 무엇이라고 생각하십니까?", colCount: 1, choices: [ "자원 확보 부족(인력 및 자금 등)", "시장 등의 외부환경 변화 대응 부족", "영업 및 마케팅 부족","기업가 역량 부족"]},
    { name: "q13", type: "comment", title: "자신이 생각하기에 기업 실패를 극복하는 과정에서 배운 점이나 고려할 점을 자유롭게 기술하여 주시기 바랍니다.", placeHolder: "", isRequired: true },
    
    
    { type : "html", name: "info", html: `<p style="font-size: 18px"><b>[3] 재도전 사업 설문</b></p><br/>귀하의 재도전 사업 관련 설문이며, 예비 창업자의 경우 계획되었거나 계획하려고 하는 사업에 대하여 설문에 응답해 주시기 바라며, 아래의 질문을 읽고 자신의 생각에 가장 가깝다고 생각되는 번호에 체크(√)해 주시기 바랍니다.`,},
    { name: "q21", type: "radiogroup", isRequired: true, title: "귀하의 소재지는?(예비 창업자는 희망지)", colCount: 2, choices: [ "서울","인천, 경기도","대전, 세종, 충청","부산, 대구, 울산, 경상","광주, 전라도", "강원도", "제주도"]},
    { name: "q22", type: "radiogroup", isRequired: true, title: "재도전 사업의 업종은?(예비 창업자는 계획 업종)", colCount: 3, choices: [ "제조업","도소매업","건설업","IT업","서비스업", "기타"]},
    { name: "q23", type: "text", title: "기타 업종은?", placeHolder: "", isRequired: true,visibleIf: "{q22} == '기타'"},
    { name: "q24", type: "comment", title: "재도전 사업아이템은?(예비 창업자는 계획 아이템)", placeHolder: "", isRequired: true },
    { name: "q25", type: "radiogroup", isRequired: true, title: "재도전 사업 년간 매출액?(예비 창업자는 목표 매출액)", colCount: 2, choices: [ "1억원 미만","1억원~5억원 미만","5억원~10억원 미만","10억원~30억원 미만","30억원 이상"]},
    { name: "q26", type: "matrix", isRequired: true, title: "사업의 자원 확보(예비 창업자는 계획)", columns: [ {value: 1,text: "전혀그렇지않다"}, {value: 2,text: "그렇지않다"},{value: 3,text: "보통이다"},{value: 4,text: "그렇다"},{value: 5,text: "아주 그렇다"}], rows: [{value: "sq1",text: "재도전 사업은 사업화(영업, 개발, 제조 등)에 필요한 인력을 확보하고 있다."},{value: "sq2",text: "재도전 사업은 재정적 자원(자본, 매출)이 적절하다."},{value: "sq3",text: "재도전 사업은 네트워크(협력사 등)를 다양하게 확보하고 있다."},{value: "sq4",text: "재도전 사업은 기술적 자원(인력, 특허 등)을 확보하고 있다.",visibleIf: "{q7} = '기술기반'"},{value: "sq5",text: "재도전 사업은 적절한 규모의 생산설비를 확보하고 있다.",visibleIf: "{q7} = '제조기반'"}]},  
    { name: "q27", type: "matrix", isRequired: true, title: "기업의 외부환경 변화 대응(예비 창업자는 계획)", columns: [ {value: 1,text: "전혀그렇지않다"}, {value: 2,text: "그렇지않다"},{value: 3,text: "보통이다"},{value: 4,text: "그렇다"},{value: 5,text: "아주 그렇다"}], rows: [{value: "sq1",text: "재도전 사업의 고객(시장)에 대한 파악 및 변화 대응은 적절하다."},{value: "sq2",text: "재도전 사업의 거래처(수요처, 공급처)에 대한 파악 및 변화 대응은 적절하다."},{value: "sq3",text: "재도전 사업의 산업환경(트랜드 등) 파악 및 변화 대응은 적절하다."},{value: "sq4",text: "재도전 사업의 정부 정책(제도, 규제 등) 파악 및 변화 대응은 적절하다."}]},
    { name: "q28", type: "matrix", isRequired: true, title: "기업의 영업·마케팅(예비 창업자는 계획)", columns: [ {value: 1,text: "전혀그렇지않다"}, {value: 2,text: "그렇지않다"},{value: 3,text: "보통이다"},{value: 4,text: "그렇다"},{value: 5,text: "아주 그렇다"}], rows: [{value: "sq1",text: "재도전 사업은(의) 목표한 고객을 대상으로 제품 및 서비스를 판매하여 충분한 매출이 발생하고 있다."},{value: "sq2",text: "재도전 사업은(의) 고객의 핵심문제(해결되면 비용 지불의사가 있는 문제)를 해결할 제품 및 서비스를 구현하고 있다."},{value: "sq3",text: "재도전 사업은(의) 시장진입 및 시장확장 전략은 적절하다."},{value: "sq4",text: "재도전 사업은(의) 제품 및 서비스는 경쟁사 대비 차별화(가격 및 기능, 성능 등) 요인을 확보하고 있다."}]},  
    { name: "q29", type: "matrix", isRequired: true, title: "기업가 역량(예비 창업자 포함)", columns: [ {value: 1,text: "전혀그렇지않다"}, {value: 2,text: "그렇지않다"},{value: 3,text: "보통이다"},{value: 4,text: "그렇다"},{value: 5,text: "아주 그렇다"}], rows: [{value: "sq1",text: "재도전 사업에서 나는 제품 및 서비스 관련 전문성(지식, 경험, 품질정책, 가격정책 등)이 충분하다."},{value: "sq2",text: "재도전 사업에서 나는 다양한 사람의 의견을 수렴하여 의사결정을 한다."},{value: "sq3",text: "재도전 사업에서 나는 조직원 간의 갈등에 대해 원활한 커뮤니케이션 활동을 한다."},{value: "sq4",text: "재도전 사업에서 나는 적절히 자금을 확보하고 관리한다."},{value: "sq5",text: "재도전 사업에서 나는 사업확장 및 다각화 전략을 적절히 실행한다."}]},  
    { name: "q30", type: "comment", title: "재도전 사업 관점에서 제품(서비스)의 강점을 자유롭게 기술하여 주십시오.", placeHolder: "강점은 기업 내부에서 제품(서비스)의 기능, 성능, 디자인, 고객 확보, 품질, 개발 및 생산, 판로 개척 등의 관점에서 기재하여 주시기 바랍니다.", isRequired: true },
    { name: "q31", type: "comment", title: "재도전 사업 관점에서 제품(서비스)의 약점을 자유롭게 기술하여 주십시오.", placeHolder: "약점은 기업 내부에서 제품(서비스)의 기능, 성능, 디자인, 고객 확보, 품질, 개발 및 생산, 판로 개척 등의 관점에서 기재하여 주시기 바랍니다.", isRequired: true },
    { name: "q32", type: "comment", title: "재도전 사업의 시장 환경의 기회 요인을 자유롭게 기술하여 주십시오.", placeHolder: "기회 요인은 기업 외부의 산업 환경, 기술 및 고객 트랜드, 경쟁사(강점, 약점, 상황) 등의 관점에서 기재하여 주시기 바랍니다.", isRequired: true },
    { name: "q33", type: "comment", title: "재도전 사업의 시장 환경의 위험 요인을 자유롭게 기술하여 주십시오.", placeHolder: "위험 요인은 기업 외부의 산업 환경, 기술 및 고객 트랜드, 경쟁사(강점, 약점, 상황) 등의 관점에서 기재하여 주시기 바랍니다.", isRequired: true },

  ]
  
  }
  const reqbiz={ title: "자가진단", description: "내 상품의 시장성, 상품성, 사업성에 관해 직접 점검해보는 체크리스트로 컨설팅 진행에 기초 자료로 사용됩니다.", logo: "https://www.drmd.kr/static/media/logo.e46179e0.png", logoWidth: 60, logoHeight: 60,
  elements: [
    //{ name: "mdNo", type: "dropdown", title: "전문가", isRequired: true, choices: ["1","2","3","4","5","6","7","8","9"]},
    //{ type : "html", name: "info", html: "<b>*현장형 컨설팅</b><br/>일시 : 2023년 9월 19일 (화) 10시~ 16시<br/>장소 : 서울특별시 마포구 백범로31길 21 창업허브 공덕<br/>*식사비 무료! (중식 제공), 주차공간은 협소하므로 대중교통 이용 권고합니다.",},
    { name: "bizName", type: "text", title: "회사명", placeHolder: "(예시: 엠디글로벌넷)", isRequired: true,},
    { name: "name", type: "text", title: "대표자명", placeHolder: "(예시: 홍길동)", isRequired: true,},
    { name: "bizNum", type: "text", title: "사업자번호", placeHolder: "(예시: 1009912345)", isRequired: true, validators: [ { type: "regex", text: "사업자번호는 10자리 숫자로 입력해주세요." }]},
    { name: "femaleDisabled", type: "matrix", isRequired: true, title: "여성기업/장애인기업 여부", columns: [ {value: 0,text: "해당없음"}, {value: 1,text: "해당함"},], rows: [{value: "sq1",text: "여성기업으로 인증된 회사입니까?"},{value: "sq2",text: "장애인기업으로 인증된 회사입니까?"},]},
    { name: "revenue", type: "text", title: "전년도 매출액", placeHolder: "(예시: 5억)", isRequired: true },
    { name: "channel", type: "dropdown", title: "주요매출처", isRequired: true, choices: ["오픈마켓", "홈쇼핑", "T커머스", "라이브커머스", "자사몰", "종합몰", "소셜커머스", "폐쇄몰", "대형마트", "백화점", "전문점", "면세점", "쇼핑센터", "편의점", "카테고리 킬러","기타"]},
    { name: "email", type: "text", title: "이메일", placeHolder: "(예시: user@drmd.kr)", isRequired: true, validators: [ { type: "email", text: "이메일형식(user@drmd.kr)으로 입력해주세요." }]},
    { type : "html", name: "info", html: "*자가진단 작성 완료 시 이메일(비밀번호 : 사업자번호 숫자10글자)로 닥터엠디 회원가입이 완료됩니다.",},
    { name: "phone", type: "text", title: "핸드폰번호", placeHolder: "(예시: 01012345678)", isRequired: true, validators: [ { type: "numeric", text: "대시(-)없이 핸드폰번호만 입력해주세요." }]},
    { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
    { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
    { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }]},
    { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
    { name: "unitItem", type: "comment", title: "상품구성", placeHolder: "(예시: 원피스 장식 포함)", isRequired: true },
    { name: "manufacturer", type: "text", title: "제조사", placeHolder: "", isRequired: true },
    { name: "url", type: "text", title: "상품정보 웹사이트", placeHolder: "https://", isRequired: false },
    { name: "customer", type: "text", title: "주요고객", placeHolder: "(예시: 20대 초반 여성)", isRequired: true },
    { name: "sellingPoint", type: "comment", title: "셀링포인트", placeHolder: "(상품의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요.)", isRequired: true },
    { name: "competProd", type: "comment", title: "경쟁상품 입력", placeHolder: "", isRequired: true },
    { type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(고객수요/시장성) 상품이 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품입니까?" },
    { type: "matrix", name: "q2", isRequired: true, title: "가격 경쟁력", columns: [ {value: 10,text: "경쟁사 대비 최저가"}, {value: 7,text: "경쟁사와 가격대 비슷함"}, {value: 5,text: "경쟁사보다 가격이 높음"},], rows: [{value: "sq1",text: "경쟁사 대비 가격 경쟁력이 있습니까?"}, ]},
    { type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(상품 디자인) 상품의 전반적인 디자인(상세페이지, BI/CI, 패키지 등)은 어떠합니까?" },
    { type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(상품 기능) 맛(식품) 또는 기능성(비식품)이 타상품과 차별화되어 있습니까?"},
    { type: "rating", name: "q5", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(브랜드 인지도) 상품의 현재 브랜드 인지도는 어느 정도라고 생각하십니까?"},
    { type: "matrix", name: "q6", isRequired: true, title: "제품 생산 책임성", columns: [{value: 10,text: "제품 직접 생산(공장보유)"}, {value: 7,text: "제품 OEM 생산"}, {value: 5,text: "제품 판권 보유 기업 (유통기업)"}, ],rows: [{value: "sq1",text: "제품을 직접 생산합니까?"},]},
    {type: "matrix", name: "q8", isRequired: true, title: "온라인 대응 역량", columns: [{value: 10,text: "대응가능인원 보유"}, {value: 7,text: "현재 대응 불가능 하나 인력충원계획 있음"}, {value: 5,text: "대응 불가능 및 향후 충원계획 없음"}, ],rows: [{value: "sq1",text: "온라인 판매 진행 시 대응 가능한 담당자가 있습니까?"},]},      
    { type: "matrix", name: "q9", isRequired: true, title: "품질관리 대응 역량", columns: [{value: 10,text: "대응가능인원 보유"}, {value: 5,text: "현재 대응 불가능 하나 인력충원계획 있음"}, {value: 1,text: "대응 불가능 및 향후 충원계획 없음"}, ],rows: [{value: "sq1",text: "품질관리 및 고객응대 시 대응 가능한 담당자가 있습니까?"},]},
    { name: "participants", type: "matrixdynamic", title: "추가 참여하는 인원이 있다면 인원수만큼 성함과 핸드폰번호를 입력해주세요.", columns: [{name: "name", title: "성함", cellType: "text", placeHolder: "(예시: 홍길동)"},{ name: "phone", cellType: "text", title: "핸드폰번호", placeHolder: "(예시: 01012345678)", validators: [ { type: "numeric", text: "대시(-)없이 핸드폰번호만 입력해주세요." }]}], detailPanelMode: "underRow", rowCount: 1, addRowText: "인원 추가", removeRowText: "삭제" },
    //{ type : "html", name: "info", html: "*중식으로 제공되는 도시락은 사전에 인원 확정되어 주문되므로 실제 참여할 인원을 정확하게 적어주세요.",},
    //{ name: "carsParked", type: "matrixdynamic", title: "주차 필요 시 필요한 차량수만큼 차량번호를 입력해주세요.", columns: [{name: "carNo", title: "차량번호", cellType: "text", placeHolder: "(예시: 01가1234)"}], detailPanelMode: "underRow", rowCount: 1, addRowText: "차량 추가", removeRowText: "삭제" }  
  ]
}

const req={ title: "자가진단", description: "내 상품/서비스의 시장성, 상품성, 사업성에 관해 직접 점검해보는 체크리스트로 컨설팅 진행에 기초 자료로 사용됩니다.", logo: "https://www.drmd.kr/static/media/logo.e46179e0.png", logoWidth: 60, logoHeight: 60,
  elements: [
    //{ name: "mdNo", type: "dropdown", title: "전문가", isRequired: true, choices: ["1","2","3","4","5","6","7","8","9"]},
    //{ type : "html", name: "info", html: "<b>*현장형 컨설팅</b><br/>일시 : 2023년 9월 19일 (화) 10시~ 16시<br/>장소 : 서울특별시 마포구 백범로31길 21 창업허브 공덕<br/>*식사비 무료! (중식 제공), 주차공간은 협소하므로 대중교통 이용 권고합니다.",},
    { name: "bizName", type: "text", title: "회사명", placeHolder: "(예시: 엠디글로벌넷)", isRequired: true,},
    { name: "name", type: "text", title: "대표자명", placeHolder: "(예시: 홍길동)", isRequired: true,},
    { name: "bizNum", type: "text", title: "사업자번호", placeHolder: "(예시: 1009912345)", isRequired: true, validators: [ { type: "regex", text: "사업자번호는 10자리 숫자로 입력해주세요." }]},
    { name: "femaleDisabled", type: "matrix", isRequired: true, title: "여성기업/장애인기업 여부", columns: [ {value: 0,text: "해당없음"}, {value: 1,text: "해당함"},], rows: [{value: "sq1",text: "여성기업으로 인증된 회사입니까?"},{value: "sq2",text: "장애인기업으로 인증된 회사입니까?"},]},
    { name: "revenue", type: "text", title: "전년도 매출액", placeHolder: "(예시: 5억)", isRequired: true },
    { name: "channel", type: "dropdown", title: "주요매출처", isRequired: true, choices: ["오픈마켓", "홈쇼핑", "T커머스", "라이브커머스", "자사몰", "종합몰", "소셜커머스", "폐쇄몰", "대형마트", "백화점", "전문점", "면세점", "쇼핑센터", "편의점", "카테고리 킬러","기타"]},
    { name: "email", type: "text", title: "이메일", placeHolder: "(예시: user@drmd.kr)", isRequired: true, validators: [ { type: "email", text: "이메일형식(user@drmd.kr)으로 입력해주세요." }]},
    { type : "html", name: "info", html: "*자가진단 작성 완료 시 이메일(비밀번호 : 사업자번호 숫자10글자)로 닥터엠디 회원가입이 완료됩니다.",},
    { name: "phone", type: "text", title: "핸드폰번호", placeHolder: "(예시: 01012345678)", isRequired: true, validators: [ { type: "numeric", text: "대시(-)없이 핸드폰번호만 입력해주세요." }]},
    { name: "prodName", type: "text", title: "상품/서비스명", placeHolder: "(예시: 미니원피스)", isRequired: true },
    { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
    { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }]},
    { name: "cateName", type: "dropdown", title: "상품/서비스카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
    { name: "unitItem", type: "comment", title: "상품/서비스구성", placeHolder: "(예시: 원피스 장식 포함)", isRequired: true },
    { name: "manufacturer", type: "text", title: "제조사", placeHolder: "", isRequired: true },
    { name: "url", type: "text", title: "상품/서비스정보 웹사이트", placeHolder: "https://", isRequired: false },
    { name: "customer", type: "text", title: "주요고객", placeHolder: "(예시: 20대 초반 여성)", isRequired: true },
    { name: "sellingPoint", type: "comment", title: "셀링포인트", placeHolder: "(상품/서비스의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요.)", isRequired: true },
    { name: "competProd", type: "comment", title: "경쟁상품/서비스 입력", placeHolder: "", isRequired: true },
    { type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(고객수요/시장성) 상품/서비스가 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품/서비스입니까?" },
    { type: "matrix", name: "q2", isRequired: true, title: "가격 경쟁력", columns: [ {value: 10,text: "경쟁사 대비 최저가"}, {value: 7,text: "경쟁사와 가격대 비슷함"}, {value: 5,text: "경쟁사보다 가격이 높음"},], rows: [{value: "sq1",text: "경쟁사 대비 가격 경쟁력이 있습니까?"}, ]},
    { type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(상품/서비스 디자인) 상품/서비스의 전반적인 디자인(상세페이지, BI/CI, 패키지 등)은 어떠합니까?" },
    { type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(상품/서비스 기능) 맛(식품) 또는 기능성(비식품)이 타상품/서비스와 차별화되어 있습니까?"},
    { type: "rating", name: "q5", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(브랜드 인지도) 상품/서비스의 현재 브랜드 인지도는 어느 정도라고 생각하십니까?"},
    { type: "matrix", name: "q6", isRequired: true, title: "제품 생산 책임성", columns: [{value: 10,text: "제품/서비스 직접 생산(공장보유)/구축"}, {value: 7,text: "제품/서비스 OEM 생산/구축"}, {value: 5,text: "제품/서비스 판권 보유 기업 (유통기업)"}, ],rows: [{value: "sq1",text: "제품/서비스를 직접 생산/구축합니까?"},]},
    {type: "matrix", name: "q8", isRequired: true, title: "온라인 대응 역량", columns: [{value: 10,text: "대응가능인원 보유"}, {value: 7,text: "현재 대응 불가능 하나 인력충원계획 있음"}, {value: 5,text: "대응 불가능 및 향후 충원계획 없음"}, ],rows: [{value: "sq1",text: "온라인 판매 진행 시 대응 가능한 담당자가 있습니까?"},]},      
    { type: "matrix", name: "q9", isRequired: true, title: "품질관리 대응 역량", columns: [{value: 10,text: "대응가능인원 보유"}, {value: 5,text: "현재 대응 불가능 하나 인력충원계획 있음"}, {value: 1,text: "대응 불가능 및 향후 충원계획 없음"}, ],rows: [{value: "sq1",text: "품질관리 및 고객응대 시 대응 가능한 담당자가 있습니까?"},]},
    { visible: false, name: "participants", type: "matrixdynamic", title: "추가 참여하는 인원이 있다면 인원수만큼 성함과 핸드폰번호를 입력해주세요.", columns: [{name: "name", title: "성함", cellType: "text", placeHolder: "(예시: 홍길동)"},{ name: "phone", cellType: "text", title: "핸드폰번호", placeHolder: "(예시: 01012345678)", validators: [ { type: "numeric", text: "대시(-)없이 핸드폰번호만 입력해주세요." }]}], detailPanelMode: "underRow", rowCount: 1, addRowText: "인원 추가", removeRowText: "삭제" },
    //{ type : "html", name: "info", html: "*중식으로 제공되는 도시락은 사전에 인원 확정되어 주문되므로 실제 참여할 인원을 정확하게 적어주세요.",},
    //{ name: "carsParked", type: "matrixdynamic", title: "주차 필요 시 필요한 차량수만큼 차량번호를 입력해주세요.", columns: [{name: "carNo", title: "차량번호", cellType: "text", placeHolder: "(예시: 01가1234)"}], detailPanelMode: "underRow", rowCount: 1, addRowText: "차량 추가", removeRowText: "삭제" }  
  ]
}


const onComplete=(survey, options)=>{
const obj=survey.data;
window.scrollTo(0,0);
createUserWithEmailAndPassword(auth, obj.email, obj.bizNum)
  .then(async(userCredential) => {
    // Signed in
    let tempRespondent={ company: "엠디글로벌넷", name: "이상발" ,email : "balsang@naver.com", uid : "fQupou8K6UWjrvM9uUimpLetqvG2", token : "ExponentPushToken[Yt616_O4yKfz_Sg3VQizwV]"};
    const user = userCredential.user;
    console.log(user);
    let userData={
      adAt : new Date(),
      approveAt : new Date(),
      argeeAt : new Date(),
      avatar : "",
      creatAt : new Date(),
      displayName : obj.name,
      email : obj.email,
      privatAt : new Date(),
      profile : {
        total_req_count : 0,
        name : obj.name,
        phone : obj.phone,
        bizInfo : {
          bizNum : obj.bizNum,
          bizName: obj.bizName,
          name : obj.name,
          licensePhoto : "",
          profit : 0
        },
        purpose : {
          name : "중소기업역량진단",
            no : 13
        },
        respondent : tempRespondent
      },
      state : 2,
      token : "notyet",      
      uid : userCredential.user.uid,
      updateAt : new Date(),
      userGroup : 13,
      userType : 1,      
    }
    await setDoc(doc(db, "users", userCredential.user.uid), userData);    
    tempRespondent={ company: "엠디글로벌넷", name: "이상발" ,email : "balsang@naver.com", uid : "fQupou8K6UWjrvM9uUimpLetqvG2", token : "ExponentPushToken[Yt616_O4yKfz_Sg3VQizwV]"};
    const q1=obj.q1,q2=obj.q2.sq1,q3=obj.q3,q4=obj.q4,q5=obj.q5,q6=obj.q6.sq1,q8=obj.q8.sq1,q9=obj.q9.sq1;
    const q1Sum=q1,q2Sum=q2,q3Sum=q3+q4,q5Sum=q5,q6Sum=q6,q7Sum=q8,q9Sum=q9,totalSum=q1Sum+q2Sum+q3Sum+q5Sum+q6Sum+q7Sum+q9Sum;
    const chartData=[q1,q2,q3,q4,q5,q6,q8,q9];
    const cusTitleChartData=[q1Sum,q2Sum,q3Sum,q5Sum,q6Sum,q7Sum,q9Sum];
    const chartLabel=[
      '고객수요/시장성', '가격 경쟁력', '상품디자인',
      '상품 기능','브랜드 인지도','제품 생산 책임성',
      '온라인 대응역량','품질관리 대응 역량'
    ];
    const titleLabel=[
      '고객수요/시장성', '가격 경쟁력', '상품성',
      '브랜드 인지도','제품 생산 책임성','품질관리'
    ];
    const reqData={
      bizName : obj?.bizName||"",
      //cusMenCates : obj?.q10,
      displayName : "",
      brand : obj?.brand||"",
      cateName : obj?.cateName||"",
      channelName : "온라인",
      competProd : obj?.competProd||"",
      customer: obj?.customer||"",
      images: [],
      manufacturer : obj?.manufacturer||"",
      price : parseInt(obj?.price)||0,
      prodName : obj?.prodName||"",
      sellingPoint : obj?.sellingPoint||"",
      url : obj?.url||"",
      unitItem : obj?.unitItem||"",
      token : "",
      reqEmail : obj?.email||"",
      reqName : obj?.name||"",
      reqCompany : obj?.bizName||"",
      resName : tempRespondent?.name||"", //name이 없음
      resCompany : tempRespondent?.company||"", //company 없음
      resEmail : tempRespondent?.email||"", 
      itemName : "중소기업역량진단",
      type: "reqbiz",
      isReplied: false,
      state : 1,
      stateName : mode=="req"?"심화컨설팅자가진단완료":"자가진단완료",
      createAt: new Date(),
      dash: moment().format('YYYY-MM-DD'),
      createBy: userCredential.user.uid,
      repliedBy : tempRespondent?.uid||"", 
      repliedToekn : tempRespondent?.token||"", 
      repliedAt : new Date(),
      cusData: obj,
      cusChartData: chartData,
      cusChartLabel: chartLabel,        
      cusTitleLabel: titleLabel,
      cusTitleChartData:cusTitleChartData,
      cusTotalSum:totalSum,
      mdData:{},
      mdChartData: [],
      mdChartLabel: [],
      mdTitleLabel: [],
      mdTitleChartData:[],
      mdTotalSum:0,
    };
    await addDoc(collection(db, "data"), reqData);
  })
  .catch(async(error) => {
    const errorCode = error.code, errorMessage = error.message;
    console.log(errorMessage);

    let tempRespondent={ company: "엠디글로벌넷", name: "이상발" ,email : "balsang@naver.com", uid : "fQupou8K6UWjrvM9uUimpLetqvG2", token : "ExponentPushToken[Yt616_O4yKfz_Sg3VQizwV]"};
    const q1=obj.q1,q2=obj.q2.sq1,q3=obj.q3,q4=obj.q4,q5=obj.q5,q6=obj.q6.sq1,q8=obj.q8.sq1,q9=obj.q9.sq1;
    const q1Sum=q1,q2Sum=q2,q3Sum=q3+q4,q5Sum=q5,q6Sum=q6,q7Sum=q8,q9Sum=q9,totalSum=q1Sum+q2Sum+q3Sum+q5Sum+q6Sum+q7Sum+q9Sum;
    const chartData=[q1,q2,q3,q4,q5,q6,q8,q9];
    const cusTitleChartData=[q1Sum,q2Sum,q3Sum,q5Sum,q6Sum,q7Sum,q9Sum];
    const chartLabel=[
      '고객수요/시장성', '가격 경쟁력', '상품디자인',
      '상품 기능','브랜드 인지도','제품 생산 책임성',
      '온라인 대응역량','품질관리 대응 역량'
    ];
    const titleLabel=[
      '고객수요/시장성', '가격 경쟁력', '상품성',
      '브랜드 인지도','제품 생산 책임성','품질관리'
    ];
    const reqData={
      bizName : obj?.bizName||"",
      //cusMenCates : obj?.q10,
      displayName : "",
      brand : obj?.brand||"",
      cateName : obj?.cateName||"",
      channelName : "온라인",
      competProd : obj?.competProd||"",
      customer: obj?.customer||"",
      images: [],
      manufacturer : obj?.manufacturer||"",
      price : parseInt(obj?.price)||0,
      prodName : obj?.prodName||"",
      sellingPoint : obj?.sellingPoint||"",
      url : obj?.url||"",
      unitItem : obj?.unitItem||"",
      token : "",
      reqEmail : obj?.email||"",
      reqName : obj?.name||"",
      reqCompany : obj?.bizName||"",
      resName : tempRespondent?.name||"", //name이 없음
      resCompany : tempRespondent?.company||"", //company 없음
      resEmail : tempRespondent?.email||"", 
      itemName : "중소기업역량진단",
      type: "reqbiz",
      isReplied: false,
      state : 1,
      stateName : mode=="req"?"심화컨설팅자가진단완료":"자가진단완료",
      createAt: new Date(),
      dash: moment().format('YYYY-MM-DD'),
      createBy: obj.email+"-"+obj.bizNum,
      repliedBy : tempRespondent?.uid||"", 
      repliedToekn : tempRespondent?.token||"", 
      repliedAt : new Date(),
      cusData: obj,
      cusChartData: chartData,
      cusChartLabel: chartLabel,        
      cusTitleLabel: titleLabel,
      cusTitleChartData:cusTitleChartData,
      cusTotalSum:totalSum,
      mdData:{},
      mdChartData: [],
      mdChartLabel: [],
      mdTitleLabel: [],
      mdTitleChartData:[],
      mdTotalSum:0,
    };
    await addDoc(collection(db, "data"), reqData);
  });
  
  }

  const model = new Survey.Model(type);
   model.onValidateQuestion.add((survey, options) => {
    switch(options?.name){    
      case "bizNum": if(typeof parseInt(options?.value) == "number" && options?.value?.length!=10){ options.error = '숫자 10글자만 입력 가능합니다.' } break;
      case "email": 
      const regExp=/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/
      if(!regExp.test(options?.value)){ options.error = '숫자 10글자만 입력 가능합니다.'; } break;     
    }
  });
  return (<Survey.Survey model={model} completeText={'자가진단 등록'} completedHtml={'<html><head><title></title></head><body><p><b>자가진단 등록이 정상적으로 완료되었습니다.</b></p><br><span>입력해주신 이메일(비밀번호는 사업자번호 숫자10글자)로<br>닥터엠디 회원가입이 완료되었습니다.<br><br>닥터엠디 앱에서 로그인하시면 추후 진행되는 전문가의 진단 결과를 보실 수 있습니다.<br>참여해주셔서 감사합니다.<br><br>*앱다운받기 : <a href="https://play.google.com/store/apps/details?id=com.connectm.drmd" target="_blank">안드로이드</a> / <a href="https://apps.apple.com/us/app/dr-md-%EB%8B%A5%ED%84%B0%EC%97%A0%EB%94%94/id1575160430" target="_blank">아이폰</a></span></body></html>'} onComplete={onComplete} />);
}
export default WebAdd;