import React, { useState, useEffect }  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import firebase, {auth} from "../config/firebase";
import Header from "../components/admin/Header";
import { useTable } from 'react-table'
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import DetailProdTableMtr from "../components/admin/DetailProdTableMtr";
import RadarChartMtr from "../components/admin/RadarChartMtr";
import YoutubeEmbed from "../components/admin/YoutubeEmbed";
import "../styles/youtubeEmbed.css";
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";



const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;


const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(odd) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();


function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Mtr = ({location:{state}, history}) =>{
    let { mtrId } = useParams();
    const {
      prodName,
      brand,
      cateName,
      cateNo,
      reqCompany,
      reqEmail,
      reqName,
      video,
      files,
      price,
      unit,
      revenue,
      stock,
      channel,
      salesAt,
      salesTime,
      email,
      uid,
      name,
      userType,
      stateName,
      createAt,
      createBy,
      sendTo,
      sendToInfo,
      chart_md_avg_label,
      chart_md_avg_data,
      cjart_md_sum_data,
      resCount,
      currResCount,
      isChart,
      totalSum,
      totalAvg,
      totalSumAvg,
      pushOptionNo,
      sendToValue,
      type3Users,
      loginEmail,
      loginUid,
      loginUserName,
      loginUserType,
      loginUserState,
      loginUserGroup,
      idReq,
      replyFrom,
      recomCh
    } = state;

    const user = auth.currentUser;
    const [aUEmail, setAuEmail] = useState("");
    const [aUName, setAuName] = useState("");
    const [aUType, setAuType] = useState("");
    const [aUUid, setAuUid] = useState("");
    const [allUsers, setAllUsers] = useState([]);
    const [replyFromMe, setReplyFromMe]= useState(replyFrom.find(e=>e==user.uid));
    const [respFromMe, setRespFromMe] = useState([]);
    const [chartMdDataFromMe, setChartMdDataFromMe] = useState([]);
    const [mdComment, setMdCommnet] = useState("");   
    const [recomChs, setRecomChs] = useState([]);


    const handleTop3Ch=(ch)=>{
      let topWishCh=[];
      let top3Chs=[];
  
      topWishCh = ch.sort(function (a, b) {
        return b.count - a.count;
      });
      topWishCh.slice(0,3).map(v=>{
        //console.log(v.count);
        if(v.count != 0){
        top3Chs.push(v.chName);
        }
      });
      setRecomChs(top3Chs);
  
    }
    const getUserInfo = async(uid) => {
    
      const users = await db.collection("users")
      .where("uid", "==", uid)
      .get(); 
      users.docs.map(user => {
        setAuEmail(user.data().email);
        setAuName(user.data().profile.name);
        setAuType(user.data().userType);
        setAuUid(user.data().uid);
    
    
        //console.log("email  =>",user.data().email);
    
      })
  
      const getAllUsers = await db.collection("users")
      .where("userGroup", "==", 102)
      .get();
      const allUsers=[];
    
      getAllUsers.docs.map(user => { 
        allUsers.push({
          value : user.data().uid, 
         label : `${user.data().profile.name} (${user.data().profile.bizInfo.bizName}, ${user.data().email})`,
         name : user.data().profile.name,
         token : user.data().token,
         state : user.data().state,
         userType : user.data().userType,
         email : user.data().email
      })
    });
    
      setAllUsers(allUsers);
  
  
    
    };

    const getRespFromMe = async(mtrId) => {
      //const tempResp=[];
      const tempChartMdData=[];
      //const tempMdComment=[];
      //const tempMdDisadvantage=[];


      const resps = await db.collection("resp_mentoring")
      .where("idReq", "==", mtrId)
      .where("idUser", "==", user.uid)
      .get();
      resps.docs.map(resp => {       
        //tempResp.push(resp.data());
        tempChartMdData.push(resp.data().chart.data);
        //tempMdComment.push(resp.data().comment);
        //tempMdDisadvantage.push(resp.data().disadvantage);

      })

      //setRespFromMe(tempResp);
      setChartMdDataFromMe(tempChartMdData);
      //setMdCommnet(tempMdComment);
      //console.log(tempResp);
      //console.log(tempChartMdData);
      //console.log(tempMdComment);
    };

    useEffect(() => {
      //console.log("uid=>",user.uid);
      //console.log("replyFromMe=>",replyFromMe);
      //console.log(reqId);
      getRespFromMe(mtrId);
      handleTop3Ch(recomCh);
      getUserInfo(user.uid);
      
      
      }, []);

      const handleOnGotoList = (e)=>{
        
          history.push({
            pathname: '/mentorings'
            //search: '?query=abc',     
        });
    

          e.preventDefault();
        
      }


      const handleOnDel = (e)=>{
        const message="정말로 삭제하시겠습니까?"
        if (window.confirm(message)) {
          //onDel();
          history.push({
            pathname: '/mentoringDel',
            //search: '?query=abc',
            state: { 
            mtrId: mtrId
            }        
        });
    
        }else{
          e.preventDefault();
        }
      }

      const data = React.useMemo(
        () => [
          {
            col1: '상품명',
            col2: prodName,
          },
          {
            col1: '브랜드',
            col2: brand,
          },
          {
            col1: '카테고리',
            col2: cateName,
          },
          {
            col1: '가격',
            col2: numberWithCommas(price),
          },
          {
            col1: '판매수량',
            col2: numberWithCommas(unit),
          },
          {
            col1: '매출액',
            col2: numberWithCommas(revenue),
          },
          {
            col1: '잔여재고',
            col2: numberWithCommas(stock),
          },
          {
            col1: '방송사',
            col2: channel,
          },
          {
            col1: '방송일',
            col2: salesAt,
          },
          {
            col1: '방송시간',
            col2: salesTime,
          },
          
        ],
        []
      )
    
      const columns = React.useMemo(
        () => [
          {
            Header: '항목',
            accessor: 'col1', // accessor is the "key" in the data
          },
          {
            Header: '내용',
            accessor: 'col2',
          },
        ],
        []
      )

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data })

   
      const pdfViewBtn = files.map((file, i) => {
        return (
        <PrimaryButton key={i} buttonRounded={true} as="a" href={file} target="_blank">상품상세보기#{i+1}</PrimaryButton>
        )
      });

  return(

    <div tw="min-h-screen text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
      

    <div>
    <h1 tw="text-xl font-semibold">방송분석 상세</h1>

    {video !== "" &&
    (
      <div>
    <YoutubeEmbed embedId={video}/>
    </div>
    )}
    {files.length != 0 &&
      (
        <div>
      {pdfViewBtn}
      </div>
      )}
      <DetailProdTableMtr columns={columns} data={data} />    
      <br /><br /><br />
      <h1 tw="text-xl font-semibold">진단결과</h1>
      <RadarChartMtr recomChs={recomChs} chartMdDataFromMe={chartMdDataFromMe} mdAvgData={chart_md_avg_data} mdAvgLabel={chart_md_avg_label} totalAvg={totalAvg} totalSum={totalSum} mdComment={mdComment} mtrId={mtrId} loginUserType={loginUserType}/>
      <div>
      {loginUserType!=0 && replyFromMe!=user.uid &&
      (
      <Link to={{ pathname:`/mentoringReply/${idReq}`,
      state : {
        loginEmail: loginEmail,
        loginUid : loginUid,
        loginUserName : loginUserName,
        loginUserType : loginUserType,
        loginUserState : loginUserState,
        loginUserGroup : loginUserGroup,
        idReq: idReq,
      }}}>
      <PrimaryButton buttonRounded={true} as="a">분석하기</PrimaryButton>
      </Link>
      )}
      {loginUserType!=0 && 
      (
      <PrimaryButton buttonRounded={true} as="a" href="#" onClick={handleOnGotoList}>리스트</PrimaryButton>
      )} 
      </div> 
      <br /><br /><br />
      {
        loginUserType == 0 && (
      <div>
      <h1 tw="text-xl font-semibold">기본정보</h1>
      <StyledTable>
        <StyledTBodyTr>
          <StyledTd>사업자</StyledTd>
          <StyledTd>{reqCompany}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>담당자</StyledTd>
          <StyledTd>{reqName} ({reqEmail})</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>전문가</StyledTd>
          <StyledTd>전체 {sendTo.length}명 중 {currResCount}명 분석 답변 완료</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>분석요청일시</StyledTd>
          <StyledTd>{createAt.getFullYear()}.{createAt.getMonth() + 1}.{createAt.getDate()} {createAt.getHours()}:
              {createAt.getMinutes() < 10 ? "0" : ""}
              {createAt.getMinutes()}</StyledTd>
        </StyledTBodyTr>

      </StyledTable>
      </div>
      )}
    </div>

    {
        loginUserType == 0 && (
    <div>
    <Link to={{ pathname:"/mentoringEdit",
      state : {
        mtrId,
        prodName,
        brand,
        cateName,
        cateNo,
        reqCompany,
        reqEmail,
        reqName,
        video,
        files,
        price,
        unit,
        revenue,
        stock,
        channel,
        salesAt,
        salesTime,
        email,
        uid,
        name,
        userType,
        stateName,
        createAt,
        createBy,
        sendTo,
        sendToInfo,
        chart_md_avg_label,
        chart_md_avg_data,
        cjart_md_sum_data,
        resCount,
        currResCount,
        isChart,
        totalSum,
        totalAvg,
        pushOptionNo,
        sendToValue,
        allUsers,
        type3Users
      }}}>
      <PrimaryButton buttonRounded={true} as="a">수정</PrimaryButton>
      </Link>
      <PrimaryButton buttonRounded={true} as="a" href="#" onClick={handleOnDel}>삭제</PrimaryButton>

      <PrimaryButton buttonRounded={true} as="a" href="#" onClick={handleOnGotoList}>리스트</PrimaryButton>

    </div>
    )}
        
         
    </div>
      </main>
    </div>
  );
}
export default Mtr;

/*
          
  <h3>{chart_cus_data}</h3>
      <h3>{chart_cus_label}</h3>
*/


/*
<h1 tw="text-xl font-semibold">상품/역량진단 클리닉 보고서</h1>
      <h1 tw="text-xl font-semibold">{bizName}</h1>
      <h1 tw="text-xl font-semibold">{prodName}</h1>
      <br /><br /><br />
      
  */