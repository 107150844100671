import React, { useState, useEffect }  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import firebase, {auth} from "../config/firebase";
import DetailCsatTable from "../components/admin/DetailCsatTable";
import Header from "../components/admin/Header";
import { useTable } from 'react-table'
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { filter } from "lodash";
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";

const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(odd) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();


const Notice = ({location:{state}, history}) =>{
/*const Notice = ({location:{state}, history}) =>{*/
    let { noticeId } = useParams();
    const {
      title,
      body,
      createAt,
      files,
      sendTo,
      //allUsers,
      pushOptionNo,
      sendToInfo,
      sendToValue,
      noticeType,
      replyFrom
  } = state;
  const user = auth.currentUser;
  const [aUEmail, setAuEmail] = useState("");
  const [aUName, setAuName] = useState("");
  const [aUType, setAuType] = useState("");
  const [aUUid, setAuUid] = useState("");
  const [noReplyUsers, setNoReplyUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const getUserInfo = async(uid) => {
    
    const users = await db.collection("users")
    .where("uid", "==", uid)
    .get();
    users.docs.map(user => {
      setAuEmail(user.data().email);
      setAuName(user.data().profile.name);
      setAuType(user.data().userType);
      setAuUid(user.data().uid);
  
  
      //console.log("email  =>",user.data().email);
  
    })

    const getAllUsers = await db.collection("users")
    .get();
    const allUsers=[];
  
    getAllUsers.docs.map(user => { 
      allUsers.push({
        value : user.data().uid, 
       label : `${user.data().profile.name} (${user.data().profile.bizInfo.bizName}, ${user.data().email})`,
       name : user.data().profile.name,
       token : user.data().token,
       state : user.data().state,
       userType : user.data().userType,
       email : user.data().email
    })
  });
    
    console.log("allUsers=>", allUsers)
    setAllUsers(allUsers);



  
  };
  useEffect(() => {
    let tempSendToValue=sendToValue;
    let noReplyUserInfo=[];
    const replyedUid=findUniqElem(sendTo, replyFrom);
    //console.log(a);
    
    replyedUid.map(uid =>{
      let n=null;
      tempSendToValue.indexOf(); // 출력 : 1
      n=tempSendToValue.findIndex(i => i.value == uid); 
      noReplyUserInfo.push(tempSendToValue[n]);
    })
    setNoReplyUsers(noReplyUserInfo);
    console.log("noReplyUserInfo", noReplyUserInfo);

    //console.log("allUsers=>", allUsers)
    getUserInfo(user.uid);
    //console.log("sendToValue=>",sendToValue)



  }, []);

/*
  const {
    title,
    body,
    name,
    uid,
    email,
    userType,
    userState,
    stateName,
    createAt,
    files,
    sendTo
} = state; */

  const handleOnDel = (e)=>{
    const message="정말로 삭제하시겠습니까?"
    if (window.confirm(message)) {
      //onDel();
      history.push({
        pathname: '/noticeDel',
        //search: '?query=abc',
        state: { 
        noticeId: noticeId
        }        
    });

    }else{
      e.preventDefault();
    }
  }
  const onDel = () =>{
    
    db.collection("notices").doc(noticeId).delete()
  .then(() => {
      window.location.replace("/notices");
      alert("삭제 완료되었습니다.")
      /*
      history.push({
        pathname: '/notices',
        //search: '?query=abc',
        state: { 
          
        aUEmail : email,
        aUid : uid,
        aUName : name,
        aUType : userType,
        aUState : userState
        }        
    });*/

    //history.push({
      //pathname: '/notices',
    //});
    //history.go(-2);
  })
  .catch((error) => {
      console.error("Error to del document: ", error);
  });

    

  }
/*
    const getEmail = async(cus, md) => {
    
      const cusUsers = await db.collection("users")
      .where('uid', 'in', [cus, md])
      //.where("uid", "==", cus)
      //.where("uid", "==", md)
      .get();
      cusUsers.docs.map(user => {
        //console.log("uid=>", user.data().uid);
        if(user.data().uid == cus){
          setCusEmail(user.data().email);
          setCusName(user.data().profile.name);
          setBizName(user.data().profile.bizInfo.bizName);
        }else{
          setMdEmail(user.data().email);
          setMdName(user.data().profile.name);

        }
      })
    
    };

    useEffect(() => {

      //getEmail(createBy, sendTo);

             
      }, []);

*/
  return(

    <div tw="min-h-screen text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
      

    <div>
      <h1 tw="text-xl font-semibold">공지사항 상세</h1>
      <StyledTable>
      <StyledTBodyTr>
          <StyledTd>제목</StyledTd>
          <StyledTd>{title}</StyledTd>
        </StyledTBodyTr>
      <StyledTBodyTr>
          <StyledTd>본문</StyledTd>
          <StyledTd>{body}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>푸시 수신자 그룹</StyledTd>
          <StyledTd>{pushOptionNo==0 && "중소기업, 전문가 전체" } { pushOptionNo==1 && "중소기업 전체"} {pushOptionNo==2 && "전문가 전체" } {pushOptionNo==3 && "직접 선택" } ({sendTo.length}명)</StyledTd>
        </StyledTBodyTr>
        {noticeType==2 &&
          (
        <StyledTBodyTr>
          <StyledTd>미답변 유저</StyledTd>
          <StyledTd>
          <NumberList n={noReplyUsers} noticeId={noticeId}/>

          </StyledTd>
        </StyledTBodyTr>
        )}
        
        <StyledTBodyTr>
          <StyledTd>등록일시</StyledTd>
          <StyledTd>{createAt.getFullYear()}.{createAt.getMonth() + 1}.{createAt.getDate()} {createAt.getHours()}:
              {createAt.getMinutes() < 10 ? "0" : ""}
              {createAt.getMinutes()}</StyledTd>
        </StyledTBodyTr>

      </StyledTable>


      { noticeType == 2 && replyFrom.length !=0 &&
     ( <DetailCsatTable noticeId={noticeId} aUType={aUType}/>
     )}    
    </div>
    <div>
    <Link to={{ pathname:"/noticeEdit",
      state : {
        noticeId: noticeId,
        title : title,
        body : body,
        email : aUEmail,
        uid : user.uid,
        name : aUName,
        userType : aUType,
        files : files,
        sendTo : sendTo,
        allUsers : allUsers,
        pushOptionNo : pushOptionNo,
        sendToValue : sendToValue,
        noticeType : noticeType
      }}}>
      <PrimaryButton buttonRounded={true} as="a">수정</PrimaryButton>
      </Link>
      <PrimaryButton buttonRounded={true} as="a" href="#" onClick={handleOnDel}>삭제</PrimaryButton>

      <PrimaryButton buttonRounded={true} as="a" href="../notices">리스트</PrimaryButton>

    </div>
    
    </div>
      </main>
    </div>
  );
}

function findUniqElem(arr1, arr2) {
  return arr1.concat(arr2)
    	 .filter(item => !arr1.includes(item) || !arr2.includes(item));
}

function ListItem(props) {
  return <li><Link to={`../survey/${props.noticeId}/${props.value}`} target="_blank" rel="noopener noreferrer">{props.label}</Link></li>;
}

function NumberList(props) {
  const n = props.n;
  return (
    <ul>
      {n.map((i) =>
        <ListItem noticeId={props.noticeId} key={n.toString()}
                  label={i["label"]} value={i["value"]}/>
      )}
    </ul>
  );
}
export default Notice;



/*
          
  <h3>{chart_cus_data}</h3>
      <h3>{chart_cus_label}</h3>

      <Link to={{ pathname:"/noticeDel",
      state : {
        noticeId: noticeId,
      }}}>
      <PrimaryButton buttonRounded={true} as="a">삭제</PrimaryButton>
      </Link>

*/