import React, {useRef, useState, useEffect, useCallback } from 'react';
import queryString from "query-string";
import firebase from "../../config/firebase";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import {StylesManager } from "survey-react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getFirestore, getDocs, where, onSnapshot, limit, query, orderBy, collection, addDoc, updateDoc, serverTimestamp, deleteDoc, deleteField, runTransaction } from "firebase/firestore";
import { getDatabase, ref, child, get, set, update } from "firebase/database";
import moment from "moment";
StylesManager.applyTheme("winterstone");
const db=firebase.firestore();
const auth = getAuth();
const Rechallenge = ({ match, location }) => {
  const query = queryString.parse(location.search), [mode, setMode] = useState(query.mode), [type, setType] = useState({});
  //console.log("mode->>",mode);
  useEffect(() => { switch (mode) { case "reqbiz": setType(reqbiz); break;  default: setType(req); } }, []);
  const req={};
  const reqbiz={ title: "자가진단111", description: "내 상품의 시장성, 상품성, 사업성에 관해 직접 점검해보는 체크리스트로 컨설팅 진행에 기초 자료로 사용됩니다.", logo: "https://www.drmd.kr/static/media/logo.e46179e0.png", logoWidth: 60, logoHeight: 60,
  elements: [
    //{ name: "mdNo", type: "dropdown", title: "전문가", isRequired: true, choices: ["1","2","3","4","5","6","7","8","9"]},
    { type : "html", name: "info", html: "<b>*현장형 컨설팅</b><br/>일시 : 2023년 7월 5일 (수) 10시~ 16시<br/>장소 : 강원도 춘천시 후석로420번길 7 3층 소담스퀘어 강원 308호<br/>*식사비 무료! (중식 제공), 주차비 무료! (주차시설 완비)",},
    { name: "bizName", type: "text", title: "회사명", placeHolder: "(예시: 엠디글로벌넷)", isRequired: true,},
    { name: "name", type: "text", title: "대표자명", placeHolder: "(예시: 홍길동)", isRequired: true,},
    { name: "bizNum", type: "text", title: "사업자번호", placeHolder: "(예시: 1009912345)", isRequired: true, validators: [ { type: "regex", text: "사업자번호는 10자리 숫자로 입력해주세요." }]},
    { name: "femaleDisabled", type: "matrix", isRequired: true, title: "여성기업/장애인기업 여부", columns: [ {value: 0,text: "해당없음"}, {value: 1,text: "해당함"},], rows: [{value: "sq1",text: "여성기업으로 인증된 회사입니까?"},{value: "sq2",text: "장애인기업으로 인증된 회사입니까?"},]},
    { name: "revenue", type: "text", title: "전년도 매출액", placeHolder: "(예시: 5억)", isRequired: true },
    { name: "channel", type: "dropdown", title: "주요매출처", isRequired: true, choices: ["오픈마켓", "홈쇼핑", "T커머스", "라이브커머스", "자사몰", "종합몰", "소셜커머스", "폐쇄몰", "대형마트", "백화점", "전문점", "면세점", "쇼핑센터", "편의점", "카테고리 킬러","기타"]},
    { name: "email", type: "text", title: "이메일", placeHolder: "(예시: user@drmd.kr)", isRequired: true, validators: [ { type: "email", text: "이메일형식(user@drmd.kr)으로 입력해주세요." }]},
    { type : "html", name: "info", html: "*자가진단 작성 완료 시 이메일(비밀번호 : 사업자번호 숫자10글자)로 닥터엠디 회원가입이 완료됩니다.",},
    { name: "phone", type: "text", title: "핸드폰번호", placeHolder: "(예시: 01012345678)", isRequired: true, validators: [ { type: "numeric", text: "대시(-)없이 핸드폰번호만 입력해주세요." }]},
    { name: "prodName", type: "text", title: "상품명", placeHolder: "(예시: 미니원피스)", isRequired: true },
    { name: "brand", type: "text", title: "브랜드", placeHolder: "(예시: 로즈블리)", isRequired: true },
    { name: "price", type: "text", inputType: "number", title: "판매가", placeHolder: "(숫자만입력)", isRequired: true, validators: [ { type: "numeric", text: "숫자로만 입력해주세요." }]},
    { name: "cateName", type: "dropdown", title: "상품카테고리", isRequired: true, choices: ["생활/건강","식품","이미용","패션","가전","홈인테리어","유아동","스포츠/레저","기타"]},
    { name: "unitItem", type: "comment", title: "상품구성", placeHolder: "(예시: 원피스 장식 포함)", isRequired: true },
    { name: "manufacturer", type: "text", title: "제조사", placeHolder: "", isRequired: true },
    { name: "url", type: "text", title: "상품정보 웹사이트", placeHolder: "https://", isRequired: false },
    { name: "customer", type: "text", title: "주요고객", placeHolder: "(예시: 20대 초반 여성)", isRequired: true },
    { name: "sellingPoint", type: "comment", title: "셀링포인트", placeHolder: "(상품의 특장점과 같이 소비자에게 어필할 수 있는 특징을 입력해주세요.)", isRequired: true },
    { name: "competProd", type: "comment", title: "경쟁상품 입력", placeHolder: "", isRequired: true },
    { type: "rating", name: "q1", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(고객수요/시장성) 상품이 현재 시장의 트렌드에 부합하거나 수요가 큰 시장규모 또는 호감도가 높은 상품입니까?" },
    { type: "matrix", name: "q2", isRequired: true, title: "가격 경쟁력", columns: [ {value: 10,text: "경쟁사 대비 최저가"}, {value: 7,text: "경쟁사와 가격대 비슷함"}, {value: 5,text: "경쟁사보다 가격이 높음"},], rows: [{value: "sq1",text: "경쟁사 대비 가격 경쟁력이 있습니까?"}, ]},
    { type: "rating", name: "q3", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(상품 디자인) 상품의 전반적인 디자인(상세페이지, BI/CI, 패키지 등)은 어떠합니까?" },
    { type: "rating", name: "q4", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(상품 기능) 맛(식품) 또는 기능성(비식품)이 타상품과 차별화되어 있습니까?"},
    { type: "rating", name: "q5", isRequired: true, rateMin: 0, rateMax: 10, minRateDescription: "매우 불만족", maxRateDescription: "매우 만족", title: "(브랜드 인지도) 상품의 현재 브랜드 인지도는 어느 정도라고 생각하십니까?"},
    { type: "matrix", name: "q6", isRequired: true, title: "제품 생산 책임성", columns: [{value: 10,text: "제품 직접 생산(공장보유)"}, {value: 7,text: "제품 OEM 생산"}, {value: 5,text: "제품 판권 보유 기업 (유통기업)"}, ],rows: [{value: "sq1",text: "제품을 직접 생산합니까?"},]},
    {type: "matrix", name: "q8", isRequired: true, title: "온라인 대응 역량", columns: [{value: 10,text: "대응가능인원 보유"}, {value: 7,text: "현재 대응 불가능 하나 인력충원계획 있음"}, {value: 5,text: "대응 불가능 및 향후 충원계획 없음"}, ],rows: [{value: "sq1",text: "온라인 판매 진행 시 대응 가능한 담당자가 있습니까?"},]},      
    { type: "matrix", name: "q9", isRequired: true, title: "품질관리 대응 역량", columns: [{value: 10,text: "대응가능인원 보유"}, {value: 5,text: "현재 대응 불가능 하나 인력충원계획 있음"}, {value: 1,text: "대응 불가능 및 향후 충원계획 없음"}, ],rows: [{value: "sq1",text: "품질관리 및 고객응대 시 대응 가능한 담당자가 있습니까?"},]},
    { name: "participants", type: "matrixdynamic", title: "추가 참여하는 인원이 있다면 인원수만큼 성함과 핸드폰번호를 입력해주세요.", columns: [{name: "name", title: "성함", cellType: "text", placeHolder: "(예시: 홍길동)"},{ name: "phone", cellType: "text", title: "핸드폰번호", placeHolder: "(예시: 01012345678)", validators: [ { type: "numeric", text: "대시(-)없이 핸드폰번호만 입력해주세요." }]}], detailPanelMode: "underRow", rowCount: 1, addRowText: "인원 추가", removeRowText: "삭제" },
    { name: "carsParked", type: "matrixdynamic", title: "주차 필요 시 필요한 차량수만큼 차량번호를 입력해주세요.", columns: [{name: "carNo", title: "차량번호", cellType: "text", placeHolder: "(예시: 01가1234)"}], detailPanelMode: "underRow", rowCount: 1, addRowText: "차량 추가", removeRowText: "삭제" }  
  ]
}


const onComplete=(survey, options)=>{
const obj=survey.data;
window.scrollTo(0,0);
createUserWithEmailAndPassword(auth, obj.email, obj.bizNum)
  .then(async(userCredential) => {
    // Signed in
    let tempRespondent={ company: "엠디글로벌넷", name: "이상발" ,email : "balsang@naver.com", uid : "fQupou8K6UWjrvM9uUimpLetqvG2", token : "ExponentPushToken[Yt616_O4yKfz_Sg3VQizwV]"};
    const user = userCredential.user;
    console.log(user);
    let userData={
      adAt : new Date(),
      approveAt : new Date(),
      argeeAt : new Date(),
      avatar : "",
      creatAt : new Date(),
      displayName : obj.name,
      email : obj.email,
      privatAt : new Date(),
      profile : {
        total_req_count : 0,
        name : obj.name,
        phone : obj.phone,
        bizInfo : {
          bizNum : obj.bizNum,
          bizName: obj.bizName,
          name : obj.name,
          licensePhoto : "",
          profit : 0
        },
        purpose : {
          name : "중소기업역량진단",
            no : 13
        },
        respondent : tempRespondent
      },
      state : 2,
      token : "notyet",      
      uid : userCredential.user.uid,
      updateAt : new Date(),
      userGroup : 13,
      userType : 1,      
    }
    await setDoc(doc(db, "users", userCredential.user.uid), userData);    
    tempRespondent={ company: "엠디글로벌넷", name: "이상발" ,email : "balsang@naver.com", uid : "fQupou8K6UWjrvM9uUimpLetqvG2", token : "ExponentPushToken[Yt616_O4yKfz_Sg3VQizwV]"};
    const q1=obj.q1,q2=obj.q2.sq1,q3=obj.q3,q4=obj.q4,q5=obj.q5,q6=obj.q6.sq1,q8=obj.q8.sq1,q9=obj.q9.sq1;
    const q1Sum=q1,q2Sum=q2,q3Sum=q3+q4,q5Sum=q5,q6Sum=q6,q7Sum=q8,q9Sum=q9,totalSum=q1Sum+q2Sum+q3Sum+q5Sum+q6Sum+q7Sum+q9Sum;
    const chartData=[q1,q2,q3,q4,q5,q6,q8,q9];
    const cusTitleChartData=[q1Sum,q2Sum,q3Sum,q5Sum,q6Sum,q7Sum,q9Sum];
    const chartLabel=[
      '고객수요/시장성', '가격 경쟁력', '상품디자인',
      '상품 기능','브랜드 인지도','제품 생산 책임성',
      '온라인 대응역량','품질관리 대응 역량'
    ];
    const titleLabel=[
      '고객수요/시장성', '가격 경쟁력', '상품성',
      '브랜드 인지도','제품 생산 책임성','품질관리'
    ];
    const reqData={
      bizName : obj?.bizName||"",
      //cusMenCates : obj?.q10,
      displayName : "",
      brand : obj?.brand||"",
      cateName : obj?.cateName||"",
      channelName : "온라인",
      competProd : obj?.competProd||"",
      customer: obj?.customer||"",
      images: [],
      manufacturer : obj?.manufacturer||"",
      price : parseInt(obj?.price)||0,
      prodName : obj?.prodName||"",
      sellingPoint : obj?.sellingPoint||"",
      url : obj?.url||"",
      unitItem : obj?.unitItem||"",
      token : "",
      reqEmail : obj?.email||"",
      reqName : obj?.name||"",
      reqCompany : obj?.bizName||"",
      resName : tempRespondent?.name||"", //name이 없음
      resCompany : tempRespondent?.company||"", //company 없음
      resEmail : tempRespondent?.email||"", 
      itemName : "중소기업역량진단",
      type: "reqbiz",
      isReplied: false,
      state : 1,
      stateName : "자가진단완료",
      createAt: new Date(),
      dash: moment().format('YYYY-MM-DD'),
      createBy: userCredential.user.uid,
      repliedBy : tempRespondent?.uid||"", 
      repliedToekn : tempRespondent?.token||"", 
      repliedAt : new Date(),
      cusData: obj,
      cusChartData: chartData,
      cusChartLabel: chartLabel,        
      cusTitleLabel: titleLabel,
      cusTitleChartData:cusTitleChartData,
      cusTotalSum:totalSum,
      mdData:{},
      mdChartData: [],
      mdChartLabel: [],
      mdTitleLabel: [],
      mdTitleChartData:[],
      mdTotalSum:0,
    };
    await addDoc(collection(db, "data"), reqData);
  })
  .catch(async(error) => {
    const errorCode = error.code, errorMessage = error.message;
    console.log(errorMessage);

    let tempRespondent={ company: "엠디글로벌넷", name: "이상발" ,email : "balsang@naver.com", uid : "fQupou8K6UWjrvM9uUimpLetqvG2", token : "ExponentPushToken[Yt616_O4yKfz_Sg3VQizwV]"};
    const q1=obj.q1,q2=obj.q2.sq1,q3=obj.q3,q4=obj.q4,q5=obj.q5,q6=obj.q6.sq1,q8=obj.q8.sq1,q9=obj.q9.sq1;
    const q1Sum=q1,q2Sum=q2,q3Sum=q3+q4,q5Sum=q5,q6Sum=q6,q7Sum=q8,q9Sum=q9,totalSum=q1Sum+q2Sum+q3Sum+q5Sum+q6Sum+q7Sum+q9Sum;
    const chartData=[q1,q2,q3,q4,q5,q6,q8,q9];
    const cusTitleChartData=[q1Sum,q2Sum,q3Sum,q5Sum,q6Sum,q7Sum,q9Sum];
    const chartLabel=[
      '고객수요/시장성', '가격 경쟁력', '상품디자인',
      '상품 기능','브랜드 인지도','제품 생산 책임성',
      '온라인 대응역량','품질관리 대응 역량'
    ];
    const titleLabel=[
      '고객수요/시장성', '가격 경쟁력', '상품성',
      '브랜드 인지도','제품 생산 책임성','품질관리'
    ];
    const reqData={
      bizName : obj?.bizName||"",
      //cusMenCates : obj?.q10,
      displayName : "",
      brand : obj?.brand||"",
      cateName : obj?.cateName||"",
      channelName : "온라인",
      competProd : obj?.competProd||"",
      customer: obj?.customer||"",
      images: [],
      manufacturer : obj?.manufacturer||"",
      price : parseInt(obj?.price)||0,
      prodName : obj?.prodName||"",
      sellingPoint : obj?.sellingPoint||"",
      url : obj?.url||"",
      unitItem : obj?.unitItem||"",
      token : "",
      reqEmail : obj?.email||"",
      reqName : obj?.name||"",
      reqCompany : obj?.bizName||"",
      resName : tempRespondent?.name||"", //name이 없음
      resCompany : tempRespondent?.company||"", //company 없음
      resEmail : tempRespondent?.email||"", 
      itemName : "중소기업역량진단",
      type: "reqbiz",
      isReplied: false,
      state : 1,
      stateName : "자가진단완료",
      createAt: new Date(),
      dash: moment().format('YYYY-MM-DD'),
      createBy: obj.email+"-"+obj.bizNum,
      repliedBy : tempRespondent?.uid||"", 
      repliedToekn : tempRespondent?.token||"", 
      repliedAt : new Date(),
      cusData: obj,
      cusChartData: chartData,
      cusChartLabel: chartLabel,        
      cusTitleLabel: titleLabel,
      cusTitleChartData:cusTitleChartData,
      cusTotalSum:totalSum,
      mdData:{},
      mdChartData: [],
      mdChartLabel: [],
      mdTitleLabel: [],
      mdTitleChartData:[],
      mdTotalSum:0,
    };
    await addDoc(collection(db, "data"), reqData);
  });
  
  }

  const model = new Survey.Model(type);
   model.onValidateQuestion.add((survey, options) => {
    switch(options?.name){    
      case "bizNum": if(typeof parseInt(options?.value) == "number" && options?.value?.length!=10){ options.error = '숫자 10글자만 입력 가능합니다.' } break;
      case "email": 
      const regExp=/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/
      if(!regExp.test(options?.value)){ options.error = '숫자 10글자만 입력 가능합니다.'; } break;     
    }
  });
  return (<Survey.Survey model={model} completeText={'자가진단 등록'} completedHtml={'<html><head><title></title></head><body><p><b>자가진단 등록이 정상적으로 완료되었습니다.</b></p><br><span>입력해주신 이메일(비밀번호는 사업자번호 숫자10글자)로<br>닥터엠디 회원가입이 완료되었습니다.<br><br>닥터엠디 앱에서 로그인하시면 추후 진행되는 전문가의 진단 결과를 보실 수 있습니다.<br>참여해주셔서 감사합니다.<br><br>*앱다운받기 : <a href="https://play.google.com/store/apps/details?id=com.connectm.drmd" target="_blank">안드로이드</a> / <a href="https://apps.apple.com/us/app/dr-md-%EB%8B%A5%ED%84%B0%EC%97%A0%EB%94%94/id1575160430" target="_blank">아이폰</a></span></body></html>'} onComplete={onComplete} />);
}
export default Rechallenge;