import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "자주 찾는 질문 FAQ",
  description = "",
  faqs = [
    {
      question: "회원가입 절차는 어떻게 되나요?",
      answer:
        "닥터엠디 메인화면 우측 상단의 회원가입 메뉴를 클릭하여 회원가입을 진행하면 됩니다. 가입 이후 2영업일 내에 담당자 확인 후 승인처리 됩니다."
    },
    {
      question: "기본서비스, SNS 홍보 서비스 일괄 구매 가능한가요?",
      answer:
        "일괄 구매 가능합니다. 개별 쿠폰 구매 후 마이페이지 > 쿠폰에서 등록된 개별 쿠폰을 사용하기를 누르면 됩니다. "
    },
    {
      question: "서비스 신청은 어떻게 하나요?",
      answer:
        "로그인 후 닥터엠디 메인화면에서 서비스 신청 버튼을 눌러 원하는 서비스 쿠폰을 결제합니다. 그 후 쿠폰 사용하기를 누르면 서비스 신청이 완료됩니다."
    },
    {
      question: "구매한 쿠폰은 언제까지 사용 가능한가요?",
      answer:
        "쿠폰 구매 이후 미사용한 쿠폰은 24개월 내 사용해야 합니다. 만약 구매일로부터 24개월 경과 시점에서는 사용이 불가합니다. 보다 자세한 내용은 취소 및 환불규정을 확인해 주세요."
    },
    {
      question: "비밀번호를 잊어버렸어요.",
      answer:
        "로그인 화면 하단 > 비밀번호 찾기로 이동한 뒤 이메일 입력 후 [비밀번호 초기화] 버튼을 누르면 해당 메일로 비밀번호 초기화할 수 있도록 이메일이 전송됩니다."
    },
    {
      question: "상품 등록, 자가진단이 진행되지 않아요.",
      answer:
        "필수입력 항목 중에 입력되지 않은 것은 없을까요? 모든 항목이 입력되었음에도 진행이 되지 않는다면 닥터엠디 카카오톡 채널 채팅으로 문의 주세요.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
