import React, { useState, useEffect }  from 'react';
import Header from "../components/admin/Header";
import Navbar from "../components/admin/Navbar";

import ListResps, {SelectColumnFilter} from "../components/admin/ListResps";
import firebase, {auth} from "../config/firebase";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;


const db=firebase.firestore();



const RespList = (props) => {
  const resultResps = [];
  const [resps, setResps] = useState([]);
  const { userInfo } = props;



  useEffect(() => {


  db.collection("resp_min")
    .orderBy("createAt", "desc")
    .get()
    .then((response) => {
   
      response.forEach((doc) => {
        const resps = doc.data();
        /*
        const category="";
        if(typeof doc.data().profile.proInfo.category.name == undefined){
          category="";
        }else{
          category = doc.data().profile.proInfo.category.name;
        }
        const channel="";
        const channelCountry="";
        const channelType="";
        const yearsOfCareer="";
*/
      


        const data={
          id: doc.id,
          comment : doc.data().comment,
          chart_label : doc.data().chart.label,
          chart_data : doc.data().chart.data,
          idReq : doc.data().idReq,
          idUser : doc.data().idUser,
          nickName : doc.data().nickName,
          createAt : new Date(doc.data().createAt.seconds * 1000),
        };
        //console.log("user no=>",doc.id);
        
        //req.id = doc.id;
        resultResps.push(data);
      });
      setResps(resultResps);
    });

    //console.log(resultReqs);
 
  }, []);

    const columns = React.useMemo(
        () => [
          {
            Header: "번호",
            //accessor: d => d.id,
            //accessor: "prodName",
            Cell: ({row})  => row.id
          },
          {
            Header: "전문가 의견",
            accessor: "comment",
          },
          {
            Header: "등록일",
            accessor: d => `${d.createAt.getFullYear()}.${d.createAt.getMonth()+1}.${d.createAt.getDate()}`,
            //accessor: "prodName",
          },
          {
            Header: "상세보기",
            accessor: d => <Link to={{ pathname:`/respList/${d.id}`, 
            state:{
                
                comment : d.comment,
                chart_label : d.chart_label,
                chart_data : d.chart_data,
                idReq : d.idReq,
                idUser : d.idUser,
                createAt : d.createAt,
                nickName : d.nickName,
            }}}>상세보기</Link>
            //accessor: d => <Link to={`/reqs/${d.id}`}>{d.prodName}</Link>,
            //accessor: d => <a href={`https://connectmd12.web.app/req/${d.id}`}>{d.id}</a>,
            //accessor: "prodName",
            //Cell: (props)  => <a href={`http://www.${props.prodName}!`}>{props.prodName}</a>
          },
          /*{
            Header: "진단요청 새창보기",
            accessor: d => <Link to={{ pathname:`/reqs/${d.idReq}`, 
            }} target="_blank" rel="noopener noreferrer">상세보기</Link>
            //accessor: d => <Link to={`/reqs/${d.id}`}>{d.prodName}</Link>,
            //accessor: d => <a href={`https://connectmd12.web.app/req/${d.id}`}>{d.id}</a>,
            //accessor: "prodName",
            //Cell: (props)  => <a href={`http://www.${props.prodName}!`}>{props.prodName}</a>
          },*/
        
        ],[]);

    //const data = React.useMemo(() => getData(), []);
    
    return (

      <div>
      <Navbar uuserInfo={ 
          userInfo.loginEmail,
          userInfo.loginUid,
          userInfo.loginUserName,
          userInfo.loginUserType,
          userInfo.loginUserState
        } />
      <div tw="min-h-screen bg-gray-100 text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
        
        <h1 tw="text-xl font-semibold">전문가 진단리스트</h1>
        </div>
        <div tw="mt-4">
        <ListResps 
          columns={columns} 
          data={resps}
          
        
        />
      </div>
      </main>
    </div>
    </div>
);
}

export default RespList;

/*


        const userEmail = [];

        const user= await db.collection("users")
        .where("uid", "==", doc.data().createBy)
        .get()
        .then((user) => {


          user.forEach((u)=>{
            //console.log(u.id, "=>", u.data());
            //console.log(u.id, "=>", u.data().email);

            userEmail.push(u.data().email);

          })

          console.log("email=>",userEmail[0]);
          data.email=userEmail[0];
          //const mail = doc.data().email;
          //userEmail.push(mail);
          //console.log("email=>", mail)
        });

*/