import React from 'react';
import firebase, {auth} from "../../config/firebase";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { BsCartPlus, BsCartDash } from 'react-icons/bs';

const db=firebase.firestore();

export default function CanCelBasket(props) {
  const { cartItems, onAdd, onRemove, setMode, products } = props;
  const itemsPrice = cartItems.reduce((a, c) => a + c.salesVol * c.salesPrice, 0);
  
  /*
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * Math.ceil(c.puchasPrice/(1-c.margin)/100) * 100, 0);
  const taxPrice = itemsPrice * 0.14;
  const shippingPrice = itemsPrice > 2000 ? 0 : 20;
  //const totalPrice = itemsPrice + taxPrice + shippingPrice;
  */
  const totalPrice = itemsPrice;
  return (
<div>
  {cartItems.length === 0 ? (<div><span style={{fontSize: "0.875em"}}>주문취소할 내역이 없습니다.</span></div>):
  (
    <>
    {cartItems.map((prod) => {
    //console.log(prod);
    //console.log(products);
    
    /*const p = products.find(function(item, index, arr){
      return item.value == prod.value
    });
    console.log(p);
    */
    return(
  <div className="Post" key={prod.id}>
    <div>
    <Row >
          <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>
          <>
          <b><span style={{fontSize: "0.875em"}}>{prod.prodName }</span></b><br/>
          <span style={{fontSize: "0.875em"}}>{prod.salesPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원&nbsp;x&nbsp;{prod.salesVol}&nbsp;=&nbsp;{prod.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
          </>
          </Col>

          <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>
          <span style={{fontSize: "0.875em"}}>{prod.deliDateDash}</span>
          <Button type="button" onClick={() => onRemove(prod)} size="sm" variant="outline-dark">삭제</Button>
          </Col>

      </Row>

    </div>
  </div>   
  )
  })}
  <div  className="Post" style={{alignContent:"right"}}>
    <Row>
  <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>
  <span style={{marginTop: "30px", fontWeight: "bold", fontSize: "0.875em"}}>최종 취소예정 금액:</span>
  </Col>
  <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>  <span style={{fontSize: "0.875em"}}>
   {totalPrice? totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0} 원</span>
   </Col>
   </Row>

  </div>
  </>
)}

</div>
  );
}


/*

    <div key={item.id} className="grid-container">
      <div>
      <span style={{fontWeight: "bold"}}>{item.label}</span> <br />
      <span style={{fontSize: "13px", color:"#aaa"}}>({item.qty} x {item.puchasPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원)</span>
      </div>
      <div>
      <button onClick={() => onAdd(item)} className="add">+</button>{' '}
      <button onClick={() => onRemove(item)} className="remove">-</button>
      </div>
    </div>

*/