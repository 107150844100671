import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "img/sns_review_blog1.png",
      title: "소형 무전원 정수기",
      channel: "블로그",
      btnUrl: "https://blog.naver.com/jjarang88/222359737488",
    },
    {
      imageSrc: "img/sns_review_sns1.png",
      title: "천연 물티슈 세트",
      channel: "인스타그램",
      btnUrl: "https://www.instagram.com/p/CJ8-FeFg36t/",
    },
    {
      imageSrc: "img/sns_review_youtube1.png",
      title: "영아 / 유아 분유",
      channel: "유튜브",
      btnUrl: "https://www.youtube.com/watch?v=TI9-al-q9_s",
    },
    {
      imageSrc: "img/sns_review_blog2.png",
      title: "베이지 폴더 매트",
      channel: "블로그",
      btnUrl: "https://blog.naver.com/summersweets/222441198302",
    },
    {
      imageSrc: "img/sns_review_sns2.png",
      title: "순면 유아 이불",
      channel: "인스타그램",
      btnUrl: "https://www.instagram.com/p/CQ-CVb-KYsl/",
    },
    {
      imageSrc: "img/sns_review_youtube2.png",
      title: "아이스크림 장난감",
      channel: "유튜브",
      btnUrl: "https://www.youtube.com/watch?v=tEQSH6nGAyE",
    },
    {
      imageSrc: "img/sns_review_blog3.png",
      title: "천연 원목 블럭 교구",
      channel: "블로그",
      btnUrl: "https://blog.naver.com/hellow0/222444966460",
    },
    {
      imageSrc: "img/sns_review_sns3.png",
      title: "짜먹는 퓨레 간식",
      channel: "인스타그램",
      btnUrl: "https://www.instagram.com/p/CHoxqHflq44/",
    },
    {
      imageSrc: "img/sns_review_youtube3.png",
      title: "천연 원목 블럭 교구",
      channel: "유튜브",
      btnUrl: "https://www.youtube.com/watch?v=7U-2jvyZXKw",
    },
    
  ]

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>SNS 홍보 개선 사례</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  <RatingsInfo>
                    <Rating>{card.channel}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
              </TextInfo>
              <CardImage imageSrc={card.imageSrc} />
              
              <PrimaryButton onClick={()=> window.open(card.btnUrl, "_blank")}>자세히 보기</PrimaryButton>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
