import React, {useState, useEffect, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth, storage} from "../config/firebase";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { Container as ContainerBase } from "components/misc/Layouts";
import styled from "styled-components";
import ColumnSelect from 'react-column-select'
import { v4 as uuidv4 } from "uuid"
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";
import { map } from "lodash";

const db=firebase.firestore();
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const SubHeading = tw.h1`text-xl xl:text-xl font-semibold`;

const FileUploadBtn = styled.button` background-color: black; border: 1px solid black; color: #fff;padding:8px;margin-top:16px;margin-bottom:32px;margin-right:16px;font-size:11px;`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const errorStyle = {
  color : 'red',
  fontSize : "10px"

}

const noticeStyle = {
  color : 'black',
  fontSize : "10px"

}

//const NoticeAdd = (history) =>{
const MtrAdd = ({location:{state}, history }) =>{
  
const user = auth.currentUser;
/*
const {
  aUEmail,
  aUid,
  aUName,
  aUType,
  aUState
} = state; */
const {
  allUsers 
}=state;
const [aUEmail, setAuEmail] = useState("");
const [aUName, setAuName] = useState("");
const [aUType, setAuType] = useState("");
const [displayName, setDisplayNmae] = useState("");


const resetFileInput=React.useRef();
//const [allUsers, setAllUsers] = useState([]);
const label=['매우 만족', '만족', '보통', '부족', '매우 부족'];
const questions=[ 
  { 
    no: 1,
    title: "방송 편리성",
    body : "상품 사용이 편리합니까?",
    type : "radio"
  },
  {
    no: 2,
    title: "방송 혁신성",
    body : "상품이 지금까지와는 달리 새로운 기능이나 타 상품과의 차별화된 우월성을 표현할 수 있습니까?",
    type : "radio"
  },
  {
    no: 3,
    title: "방송 안전성",
    body : "방송심의등 표현 및 사용이 안전합니까?",
    type : "radio"
  },
  {
    no: 4,
    title: "방송 시연성",
    body : "방송을 방송 중에 알기 쉽게 매력적으로 시연해 보일수 있습니까?",
    type : "radio"
  },
  {
    no: 5,
    title: "상품특성상 1차적으로 진출 공략해야할 시장은 어디라고 생각합니까?",
    body : "(최대 3개 다중선택 가능)",
    type : "select"
  },
  {
    no: 6,
    title: "상품 가격",
    body : "이 제품의 상품가격을 어떻게 생각하십니까?",
    type : "radio"
  },
  {
    no: 7,
    title: "상품 기능",
    body : "상품기능이 우수합니까?",
    type : "radio"
  },
  {
    no: 8,
    title: "상품 디자인",
    body : "고객이 상품디자인을 보고 관심을 가질만 합니까?",
    type : "radio"
  },
  {
    no: 9,
    title: "상품 인지성",
    body : "상품의 브랜드를 알고있습니까 ? (브랜드인지도, 상품인지도 또는 서비스 인지도)",
    type : "radio"
  },
  {
    no: 10,
    title: "상품 전문성",
    body : "이 상품에 대한 회사의 전문성은 어떠합니까?",
    type : "radio"
  },
  {
    no: 11,
    title: "상품성",
    body : "회사의 이 상품에 대한 상품성은 어떠합니까?",
    type : "radio"
  },
  {
    no: 12,
    title: "상품 보완성",
    body : "상품성, 전문성, 인지성(3개 중 택1)에서 보완해야할 부분은 무엇입니까? (200자 이내로 기술해주세요)",
    type : "input"
  },
  {
    no: 13,
    title: "시장호감도",
    body : "이 상품에 좋은 감정을 불러일으키는 정도는 어떠합니까?",
    type : "radio"
  },
  {
    no: 14,
    title: "시장트랜드",
    body : "경향, 동향, 추세, 유행에 부합하는 상품입니까?",
    type : "radio"
  },
  {
    no: 15,
    title: "시장경쟁력",
    body : "이 상품이 갖는 시장 경쟁력에 대해 어떻게 생각하십니까?",
    type : "radio"
  },
  {
    no: 16,
    title: "전문가 분석의견",
    body : "전문가로써 마지막으로 상품에 대한 분석 및 의견을 500자 이내로 기술해주세요.",
    body1 : "- 상품에 대한 시장 경쟁력 확보 가능성",
    body2 : "- 상품의 구매 니즈 확보 및 구매 소구점 파악",
    body3 : "- 시장 재진입에 따른 마케팅 및 판매 전략",
    type : "input"
  },
  {
    no: 17,
    title: "MD분석 점수",
    body : "본인의 의견을 한 점수로 나타내면?",
    type : "radio"
  },
  
]


const getUserInfo = async(uid) => {
    
  const users = await db.collection("users")
  .where("uid", "==", uid)
  .get();
  users.docs.map(user => {

    setAuEmail(user.data().email);
    setAuName(user.data().profile.name);
    setAuType(user.data().userType);
    setToken(user.data().token);
    //console.log("email  =>",user.data().email);
  })
/*
  const getAllUsers = await db.collection("users")
  .get();
  const allUsers=[];

  getAllUsers.docs.map(user => { 
    allUsers.push({
      value : user.data().uid,      
     label : user.data().profile.name,
     //token : user.data().token,
     //state : user.data().state,
     //userType : user.data().userType,
     //email : user.data().email
  })
});

  setAllUsers(allUsers);
  console.log("allUsers=>",allUsers);
*/
};

const filterItems = (query) => {
  return allUsers.filter((el) =>
    el.userType.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
  );
}

const sendNotificationTo = async(prodName, brand, tokens) => {
  console.log("token:", tokens);
  tokens.map(token => sendNotification(prodName, brand, token))
};


const sendNotification=async(prodName, brand, token)=>{ 
  console.log("-------ready to push");
  const message = {
    to: "ExponentPushToken[6WuFm9HgjUYbe7mbZcDDUZ]",
    sound: 'default',
    title: '방송 상품 분석 요청 알림',
    body: prodName+'('+brand+')',
    //data: { url: `${prefix}notices/${room.id}`,  },
    data: { url: '/account'  },

  };

  await fetch('https://cors-anywhere.herokuapp.com/https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
  console.log("------finish to push-----")
}

const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [result, setResult] = useState("");
  const [pushOption, setPushOption] = useState("");
  const [pushOptionValue, setPushOptionValue] = useState(""); 
  const [cateNo, setCateNo] = useState(null);
  const [cateName, setCateName] = useState(""); 
  const [selected, setSelected] = useState([])  
  const [allUserCopy, setAllUserCopy] = useState(allUsers);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [file, setFile] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);

  const [token, setToken] = useState("");







  useEffect(() => {
    getUserInfo(user.uid);
    //console.log("filter=>",filterItems(2));
  }, []);

  const initChart = (idReq) => {

    const paylod = {
      idReq: idReq,
      data1 : [0,0,0,0,0],
      data2 : [0,0,0,0,0],
      data3 : [0,0,0,0,0],
      data4 : [0,0,0,0,0],
      data5 : [0,0,0,0,0],
      data6 : [0,0,0,0,0],
      data7 : [0,0,0,0,0],
      data8 : [0,0,0,0,0],
      data9 : [0,0,0,0,0],
      data10 : [0,0,0,0,0],
      data11 : [0,0,0,0,0],
      data12 : [0,0,0,0,0],
      data13 : [0,0,0,0,0],
      data14 : [0,0,0,0,0],

      chartsData : [
        {
          no : 1,
          title : questions[0].title,
          body : questions[0].body,
          label : label,
        },
        {
          no : 2,
          title : questions[1].title,
          body : questions[1].body,
          label : label,
        },
        {
          no : 3,
          title : questions[2].title,
          body : questions[2].body,
          label : label,
        },
        {
          no : 4,
          title : questions[3].title,
          body : questions[3].body,
          label : label,
        },
        {
          no : 6,
          title : questions[5].title,
          body : questions[5].body,
          label : label,
        },
        {
          no : 7,
          title : questions[6].title,
          body : questions[6].body,
          label : label,
        },
        {
          no : 8,
          title : questions[7].title,
          body : questions[7].body,
          label : label,
        },
        {
          no : 9,
          title : questions[8].title,
          body : questions[8].body,
          label : label,
        },
        {
          no : 10,
          title : questions[9].title,
          body : questions[9].body,
          label : label,
        },
        {
          no : 11,
          title : questions[10].title,
          body : questions[10].body,
          label : label,
        },
        {
          no : 13,
          title : questions[12].title,
          body : questions[12].body,
          label : label,
        },
        {
          no : 14,
          title : questions[13].title,
          body : questions[13].body,
          label : label,
        },
        {
          no : 15,
          title : questions[14].title,
          body : questions[14].body,
          label : label,
        },
        {
          no : 17,
          title : questions[16].title,
          body : questions[16].body,
          label : label,
        },

      ]
    };

    db.collection("charts_mentoring")
    .add(paylod)
    .then(() => {
      //updateReq(user.uid, chart);
        sendNotification(token, displayName);
      
    })
    .catch(() => {
      alert("답변 등록이 실패하였습니다. 다시 시도해 주세요.");
    });

  };

  const onFileChange = (event) => {
    setSelectedFiles(event.target.files);
    for (let i = 0; i < selectedFiles.length; i++) {
      //formData.append("myfile", selectedFiles[i], selectedFiles[i].name);
      console.log(selectedFiles[i]+" pdf =>"+selectedFiles[i].name)
    }

    const {target:{file, value}} = event;
    const theFile = file[0];
    const reader = new FileReader();
    setFile(value)
    reader.onloadend = (finishedEvent) => {
      const { currentTarget: {result}} = finishedEvent
      setAttachment(result)
    }
    reader.readAsDataURL(theFile);
  }
  const onClearAttachment = () => {
    setAttachment([]);
    setFile([]);
    resetFileInput.current.value="";
  };


  const onFileUpload = async (event) => {
    event.preventDefault();
    let attachmentUrl = "";
    if (attachment !== "") {
      const attachmentRef = storage.ref().child(`mentorings/${uuidv4()}`);
      const response = await attachmentRef.putString(attachment, "data_url")
      attachmentUrl = await response.ref.getDownloadURL()
    }
    const postObj = {
        text: "aaa",
        createdAt: Date.now(),
        creatorId: user.uid,
        attachmentUrl,
    }
    await db.collection("mentorings").add(postObj);
    setAttachment('');
    setFile('')
  };


  const handleFilesChange = (e) => {
    e.preventDefault();
    // files 배열 자체를 담을 것이므로 index 뺌.
    setSelectedFiles(e.target.files);

    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("myfile", e.target.files[i], e.target.files[i].name);
      console.log(e.target.files[i]+" pdf =>"+e.target.files[i].name)
    }
    setAttachment(formData.getAll("myfile"));
    //console.log(formData.getAll("myfile")[0].name);
    //console.log(formData.getAll("myfile")[1].name);
    
    //console.log(attachment[1].name);



  };

  const handleFilesUpload = async(event) => {
    event.preventDefault();
    //console.log(attachment[0].name);
    let fileName=[];
    let pdfBlob=[];
    for (let i = 0; i < attachment.length; i++) {
      let id=uuidv4();
      console.log(attachment[i].name)

      console.log(id);
      //console.log(attachment[i].name)

      
      try {
        await storage.ref("mentorings").child(id).put(attachment[i])
        .then((result) => {
           storage
          .ref(`mentorings/${id}`)
          .getDownloadURL()
          .then((fileUrl) => {
            pdfBlob.push(fileUrl);
          });
        });
      } catch (error) {
          console.log("error", error);
      }
    }
    setFileUrl(pdfBlob);
    console.log(pdfBlob);
    alert("파일이 성공적으로 업로드 되었습니다.");
    /*
    uploadFileStorage().then((response) => {
      setFileUrl(response);
      alert("파일이 성공적으로 업로드 되었습니다.");
    });

*/


  



  };

  const uploadFileStorage = async () => {
    const pdfBlob = [];

    await Promise.all(
      map(attachment, async (f) => {
        const response = await fetch(f);
        console.log(response);

        const blob = await response.blob();
        console.log("blob=>",blob);
        const ref = storage.ref("mentorings").child(uuidv4());
        await ref.put(blob).then(async (result) => {
          await storage
            .ref(`mentorings/${result.metadata.name}`)
            .getDownloadURL()
            .then((fileUrl) => {
              pdfBlob.push(fileUrl);
            });
        });
      })
    );

    return pdfBlob;
  };

  const selectCateName=(value)=>{

    switch(value) {
      case '1':
        return "생활/건강";
      case '2':
        return "식품";
      case '3':
        return "이미용";
      case '4':
        return "패션";
      case '5':
        return "가전";
      case '6':
        return "홈인테리어";
      case '7':
        return "유아동";
      case '8':
        return "스포츠/레저";
      case '9':
        return "기타";
      default:
        return 'default';
    }

  }

  const handleCategoryChange = (event) => {
    
    //console.log(event.target.value);
    setCateNo(Number(event.target.value));
    const name=selectCateName(event.target.value);
    setCateName(name);
    //console.log(name);
    event.preventDefault();

  }

 
  const handleChange = (event) => {
    setPushOption(event.target.value);
    setSelected([]);
    if(event.target.value==="0"){
      setSelected(allUserCopy);
      setPushOptionValue(0);
      //setAllUserCopy([]);
      console.log(allUserCopy);
    }else if(event.target.value==="1"){
      setSelected(filterItems(1));
      setPushOptionValue(1);
      //setAllUserCopy(filterItems(2));
      console.log(filterItems(1));
    }else if(event.target.value==="2"){
      setSelected(filterItems(2));
      setPushOptionValue(2);
      //setAllUserCopy(filterItems(1));
      console.log(filterItems(2));
    }else if(event.target.value==="3"){
      setSelected(filterItems(3));
      setPushOptionValue(3);
      //setAllUserCopy(filterItems(1));
      console.log(filterItems(3));
    }else {
      event.preventDefault();
    }
    //console.log("selected=>",selected);

    //console.log("pushOption->",event.target.value);
  }

  const onChange = (values) => {
    setSelected(values)
    console.log("selected=>", values)
  }
  const onSubmit = async (data) => {
    //데이터 전달받기
    //console.log("title=>",data.title);    
    //setResult(JSON.stringify(data));
    //console.log(data);
    if(selected.length==0){
    //if(pushOptionValue == 3 || (pushOption =="3" && selected.length==0)){
    //if(pushOption =="default " || pushOption !="0" || pushOption !="1" || pushOption!="2" || pushOption!="3"){
      alert("푸시 수신자를 선택해주세요.");
    }else{

      console.log(selected);
      let uid=[];
      let token=[];
      let company=[];
      let name=[];
      let email=[];
      let sendToValue = [];
     
      for ( const item of selected){
        try{
          uid.push(item.value);
          token.push(item.token);
          company.push(item.company);
          name.push(item.name);
          email.push(item.email);
          sendToValue.push(
            {
            label : `${item.name} (${item.company}, ${item.email})`,
             value : item.value
            },
          )
        }catch (error){
          console.log(error);
        }

      };
      

      await db.collection("reqs_mentoring").add({
        prodName : data.prodName,
        brand : data.brand,
        cateName : cateName,
        cateNo : cateNo,
        reqCompany : data.reqCompany,
        reqEmail : data.reqEmail,
        reqName : data.reqName,
        video : data.youtubeCode,
        files : fileUrl,
        price : data.price,
        unit : data.unit,
        revenue : data.revenue,
        stock : data.stock,
        channel : data.channel,
        salesAt : data.salesAt,
        salesTime : data.salesTime,
        email : aUEmail,
        uid : user.uid,
        name : aUName,
        userType : aUType,
        state : 1,
        stateName : "분석요청",
        createAt : new Date(),
        createBy : user.uid,
        updateAt : new Date(),
        pushOptionNo : pushOptionValue,
        sendTo : uid,
        sendToToken : token,
        sendToInfo : {
          company : company,
          name : name,
          email : email,
          uid : uid,
          token : token
        },
        sendToValue : sendToValue,
        chart_md_avg_label : [],
        chart_md_avg_data : [],
        chart_md_sum_data : [],
        resCount	: uid.length,
        currResCount : 0,
        isChart : false,
        totalSum : 0,
        totalAvg : 0,
        replyFrom : [],
        recomCh : [
          { "chNo" : "100", "chName" : "오픈마켓", "count" : 0, "index" : 0 },
          { "chNo" : "101", "chName" : "홈쇼핑", "count" : 0, "index" : 1},
          { "chNo" : "102", "chName" : "T커머스", "count" : 0, "index" : 2 },
          { "chNo" : "103", "chName" : "종합몰", "count" : 0, "index" : 3 },
          { "chNo" : "104", "chName" : "소셜커머스", "count" : 0, "index" : 4 },
          { "chNo" : "105", "chName" : "폐쇄몰", "count" : 0, "index" : 5 },
          { "chNo" : "106", "chName" : "해외수출", "count" : 0, "index" : 6 },
          { "chNo" : "200", "chName" : "대형마트", "count" : 0, "index" : 7 },
          { "chNo" : "201", "chName" : "백화점", "count" : 0, "index" : 8 },
          { "chNo" : "202", "chName" : "전문점", "count" : 0, "index" : 9 },
          { "chNo" : "203", "chName" : "면세점", "count" : 0, "index" : 10 },
          { "chNo" : "204", "chName" : "쇼핑센터", "count" : 0, "index" : 11 },
          { "chNo" : "107", "chName" : "라이브커머스", "count" : 0, "index" : 12 },
          { "chNo" : "108", "chName" : "미디어커머스", "count" : 0, "index" : 13 },
          { "chNo" : "109", "chName" : "모바일 쇼핑몰", "count" : 0, "index" : 14 },
          { "chNo" : "205", "chName" : "편의점", "count" : 0, "index" : 15 },
          { "chNo" : "206", "chName" : "카테고리 킬러", "count" : 0, "index" : 16 },
          
        ],
    })
    .then((docRef) => {
      
      initChart(docRef.id);
      //sendNotificationTo(data.prodName, data.brand, token);
      //sendNotification(data.prodName, data.brand, token);
        //console.log("Document written with ID: ", docRef.id);
        history.push({
          pathname: '/mentorings',
          //search: '?query=abc',
      });
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
    });

    
    }
  }

  const uploadedFile = () => {
    const result = [];
 
    for (let i = 0; i < attachment.length; i++) {
      result.push(<span style={noticeStyle} key={i}>{attachment[i].name}<br/></span>);
    }
    return result;
  };

return (
    
        <div> 
            {user ? 
             (
             
              <Content>
                <MainContainer>
                <Heading>방송분석 등록</Heading>
                <SubHeading>기본정보</SubHeading>
              <FormContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
             <div>
               작성자 : {aUName} ( {aUEmail} )
              </div>

              <div>
               카테고리
               <Select value={cateNo} onChange={handleCategoryChange}>
               <option value="default">카테고리 선택</option>
                  <option value="1" label="생활/건강">생활/건강</option>
                  <option value="2">식품</option>
                  <option value="3">이미용</option>
                  <option value="4">패션</option>
                  <option value="5">가전</option>
                  <option value="6">홈인테리어</option>
                  <option value="7">유아동</option>
                  <option value="8">스포츠/레저</option>
                  <option value="9">기타</option>
          </Select>
              </div>
             <div>
               상품명 <Input defaultValue="" {...register("prodName", { required: true })} />
               {errors.title && <span style={errorStyle}>상품명을 입력하세요.</span>}
              </div>
              <div>
               브랜드 <Input defaultValue="" {...register("brand", { required: true })} />
               {errors.title && <span style={errorStyle}>브랜드를 입력하세요.</span>}
              </div>
              
              <div>
               중소기업 회사명 <Input defaultValue="" {...register("reqCompany", { required: true })} />
               {errors.title && <span style={errorStyle}>중소기업의 회사명을 입력하세요.</span>}
              </div>
              <div>
               중소기업 이메일 <Input defaultValue="" {...register("reqEmail", { required: true })} />
               {errors.title && <span style={errorStyle}>중소기업의 이메일주소를 입력하세요.</span>}
              </div>
              <div>
               중소기업 담당자명 <Input defaultValue="" {...register("reqName", { required: true })} />
               {errors.title && <span style={errorStyle}>중소기업의 담당자를 입력하세요.</span>}
              </div>

              <div>
                파일 업로드
                <span style={noticeStyle}> Ctrl 키로 파일 다중 선택이 가능합니다.</span>
                <Input type="file" onChange={handleFilesChange} accept="application/pdf, application/vnd.ms-excel" multiple ref={resetFileInput}/> 
                
                {uploadedFile()}
                <FileUploadBtn onClick={handleFilesUpload}>전체 업로드</FileUploadBtn>
                <FileUploadBtn onClick={onClearAttachment}>전체 삭제</FileUploadBtn>
            
              </div>
              <div>
               유튜브 영상코드
               <span style={noticeStyle}> 공유&#62;퍼가기&#62;소스 내 11자리의 동영상 코드를 입력해주세요.</span>
               <Input defaultValue="" {...register("youtubeCode", { required: false })} />
               {errors.title && <span style={errorStyle}>유튜브 영상코드를 입력하세요.</span>}
              </div>
          
              <div>
               푸시 수신자 선택 
               
             <ColumnSelect
        options={allUserCopy}
        onChange={onChange}
        leftHeader='전체'
        rightHeader='선택된 수신자'
        isSearchable={true}
        searchPlaceholder="검색..."
        theme={{
          headerBgColor: '#eee',
          columnBorderColor: '#ccc',
          textColor: '#000000',
          columnBgColor: '#ccc',
          buttonBgColor: '#ccc',
          optionSelectedBgColor: '#eee',
          optionHoverBgColor: '#eee',
          searchFocusBorderColor: '#ccc',
        }}
      />
      
               </div>
            
               <SubHeading>판매정보</SubHeading>
               <div>
               판매가 <Input type="number" defaultValue="" {...register("price", { required: true })} />
               {errors.title && <span style={errorStyle}>판매가를 입력하세요.</span>}
              </div>
              <div>
               판매수량 <Input type="number" defaultValue="" {...register("unit", { required: true })} />
               {errors.title && <span style={errorStyle}>판매수량을 입력하세요.</span>}
              </div>
              <div>
               매출액 <Input type="number" defaultValue="" {...register("revenue", { required: true })} />
               {errors.title && <span style={errorStyle}>매출금액을 입력하세요.</span>}
              </div>
              <div>
               잔여재고 <Input type="number" defaultValue="" {...register("stock", { required: true })} />
               {errors.title && <span style={errorStyle}>잔여재고를 입력하세요.</span>}
              </div>
              <div>
               방송사 <Input defaultValue="" {...register("channel", { required: true })} />
               {errors.title && <span style={errorStyle}>방송사를 입력하세요.</span>}
              </div>
              <div>
               방송일 <Input defaultValue="" {...register("salesAt", { required: true })} />
               {errors.title && <span style={errorStyle}>방송일을 입력하세요.</span>}
              </div>
              <div>
               방송시간대 <Input defaultValue="" {...register("salesTime", { required: true })} />
               {errors.title && <span style={errorStyle}>방송시간대를 입력하세요.</span>}
              </div>



             <SubmitButton type="submit">
                  <span className="text">등록</span>
                </SubmitButton>
           </form>
           </FormContainer>
           </MainContainer>
           </Content>
          
             )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default MtrAdd;

/*

to={{ pathname:`/notices`, 
      state:{
        email: aUEmail,
        uid : aUid,
        userName : aUName,
        userType : aUType,
        userState : aUState

      }}}
*/

/*
<Select value={pushOption} onChange={handleChange}>
            <option value="default">그룹 선택</option>
            <option value="default">-------</option>
            <option value="2">방송분석 전문가 전체 선택</option>
            <option value="3">직접 선택</option>
          </Select>

*/