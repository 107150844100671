import React from 'react';
import { Radar } from 'react-chartjs-2';

import Table from "react-bootstrap/Table";

import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 500px;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;

:nth-of-type(1) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;


const ReqDetail = ({ cusData, cusLabel, mdData}) =>{ 
  
  const chartData6 = {
    labels: cusLabel,
    datasets: [
      {
        label: '전문가진단',
        data: mdData,
        backgroundColor: 'rgba(255, 0, 59, 0.2)',
        borderColor: 'rgba(255, 0, 59, 1)',
        borderWidth: 1,
      },
      {
        label: '자가진단',
        data: cusData,
        backgroundColor: 'rgba(0, 59, 255, 0.2)',
        borderColor: 'rgba(0, 59, 255, 1)',
        borderWidth: 1,
      },
    ],
  };
  
  
  const options = {
    scales: {
      r: {
          angleLines: {
              display: false
          },
          suggestedMin: 0,
          suggestedMax: 8
      }
  }
  };

  const chart={
    width: isBrowser ? "500px": "100%",
  }


return (

    <div style={chart}>
      <div>
       <h1 tw="text-xl font-semibold" style={{marginBottom:30}}>전체결과</h1>
      
       <Radar data={chartData6} options={options} />  
       
       </div>
    </div>
)
}

export default ReqDetail;
