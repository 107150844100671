import React, {useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth} from "../config/firebase";
import { useForm } from "react-hook-form";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { Rating,  RatingView} from 'react-simple-star-rating'

const db=firebase.firestore();
const FormContainer = tw.div`w-full flex-1 mt-8`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({ match }) => {
  const [rating1, setRating1] = useState(5);
  const [rating2, setRating2] = useState(5);
  const [rating3, setRating3] = useState(5);
  const [rating4, setRating4] = useState(5);
  const [rating5, setRating5] = useState(5);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const handleRating = (e, n) => {
    switch(n) {
      case 1: return setRating1(e);
      case 2: return setRating2(e);
      case 3: return setRating3(e);
      case 4: return setRating4(e);
      case 5: return setRating5(e);
    }
  }

  const onSubmit = async (data) => {
    const uid=match.params.uid;
    const noticeId=match.params.noticeId;
    
    //데이터 전달받기
    //console.log("title=>",data.title);    
    //setResult(JSON.stringify(data));
    //console.log(data);
    if(rating1 <= 0 || rating2 <= 0 || rating3 <= 0 || rating4 <=0 || rating5 <= 0){
      alert("만족도를 선택해주세요.");
    }else{   
      
      const docRef = db.collection("users").doc(uid);
      docRef.get().then((doc) => {
          if (doc.exists) {

              const paylod = {
                idUser: uid,
                avatarUser: null,
                idNotice: noticeId,
                createAt: new Date(),
                updateAt: new Date(),
                email : doc.data().email,
                bizName : doc.data().profile.bizInfo.bizName,
                name : doc.data().profile.name,
                nickName: doc.data().displayName,
                comment : data.comment||"",
                rating: [
                  rating1,
                  rating2,
                  rating3,
                  rating4,
                  rating5
                ]
              };

                db.collection("csats")
                .add(paylod)
                .then(() => {
                  updateNotice(uid);
                
                })
                .catch(() => {
                  alert("답변 등록이 실패하였습니다. 다시 시도해 주세요.");
                });              

          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });


    
    }
  }

  const updateNotice = (uid) => {
    const noticeId=match.params.noticeId;
    const noticeRef = db.collection("notices").doc(noticeId);

    noticeRef.get().then((response) => {
      const noticeData = response.data();
      let replyFrom = noticeData.replyFrom;
      replyFrom.push(uid);

      noticeRef
        .update({
          replyFrom : replyFrom
        })
        .then(() => {
          alert("만족도 설문에 참여해주셔서 감사드립니다.");
        });
    });
  };
  return (
    <AnimationRevealPage>
      <Header />
      <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <Container>
        <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        
          <HeadingRow>
            <Heading>만족도 설문</Heading>
          </HeadingRow>
          <div style={{
            marginBottom:48
          }}>
          <Text style={{
            marginBottom:16
          }}>1.<span style={{fontWeight: 'bold'}}>과업1</span>에 대해 얼마나 만족하셨나요?</Text>
          <Rating onClick={(e) => handleRating(e, 1)} size={40} ratingValue={rating1}/>
          </div>
          <div style={{
            marginBottom:48
          }}>
          <Text style={{
            marginBottom:16
          }}>2.<span style={{fontWeight: 'bold'}}>과업2</span>에 대해 얼마나 만족하셨나요?</Text>
          <Rating onClick={(e) => handleRating(e, 2)} size={40} ratingValue={rating2}/>
          </div>
          <div style={{
            marginBottom:48
          }}>
          <Text style={{
            marginBottom:16
          }}>3.<span style={{fontWeight: 'bold'}}>과업3</span>에 대해 얼마나 만족하셨나요?</Text>
          <Rating onClick={(e) => handleRating(e, 3)} size={40} ratingValue={rating3}/>
          </div>
          <div style={{
            marginBottom:48
          }}>
          <Text style={{
            marginBottom:16
          }}>4.<span style={{fontWeight: 'bold'}}>과업4</span>에 대해 얼마나 만족하셨나요?</Text>
          <Rating onClick={(e) => handleRating(e, 4)} size={40} ratingValue={rating4}/>
          </div>
          <div style={{
            marginBottom:48
          }}>
          <Text style={{
            marginBottom:16
          }}>5.<span style={{fontWeight: 'bold'}}>과업5</span>에 대해 얼마나 만족하셨나요?</Text>
          <Rating onClick={(e) => handleRating(e, 5)} size={40} ratingValue={rating5}/>
          </div>
          <div>
          <Text style={{
            marginBottom:16
          }}>6.추가로 하실말씀은?</Text>
          <TextArea defaultValue="" placeholder="200자 내외로 입력해주세요." {...register("comment", { required: false })} rows={5}/>
          </div>
          
          <SubmitButton type="submit">
                  <span className="text">설문 완료</span>
                </SubmitButton>
                </form>
        </FormContainer>
        </Container>
      </div>
      
    </AnimationRevealPage>
  );
};
