import React, {useEffect, useState } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';
import firebase, {auth} from "../config/firebase";
import BarChart from "../components/admin/BarChart";

const db=firebase.firestore();
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;

const DiagChart3 = () =>{


  const [data, setData] = useState("");
  const [cusChartData, setCusChartData] = useState([]);
  const [cusChartLabel, setCusChartLabel] = useState([]);
  const [mdChartData, setMdChartData] = useState([]);
  const [mdChartLabel, setMdChartLabel] = useState([]);
  

  const handleReadData = (e) => {
    const id=e.data;
    getDiag(id);
  };

  const getDiag = async(id) => {
    const data = await db.collection("diags").doc(id).get()
    .then(snapshot => {
      const d=snapshot.data()
      setData(d);
      //const obj = JSON.parse(snapshot.data());
      console.log(d);
      setCusChartData(d.cusChartData);
      setCusChartLabel(d.cusChartLabel);
      setMdChartData(d.mdChartData);
      setMdChartLabel(d.mdChartLabel);


      //alert(obj);
    })
  };

  const handleSendData = (e) => {
    window.ReactNativeWebView.postMessage("data");
  };



  useEffect(() => {
    if (isMobile && isIOS) {
      window.addEventListener('message', handleReadData);
      return () => {
        window.removeEventListener('message', handleReadData);
      };
      }
      if (isMobile && isAndroid) {
      document.addEventListener('message', handleReadData);
      return () => {
        document.removeEventListener('message', handleReadData);
      };  
      }
    //getDiag("JRfAyZkYYnUcm0492Xet")

  }, []);

return (
    
        <div style={{paddingLeft: 50, paddingRight: 50}}> 
          <BarChart cusData={cusChartData} cusLabel={cusChartLabel} mdData={mdChartData} />

        </div>
)    
} 

export default DiagChart3;

/*
  useEffect(() => {
    window.addEventListener('message', handleReadData);
    // cleanup this component
    return () => {
      window.removeEventListener('message', handleReadData);
    };
  }, []);
*/