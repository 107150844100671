import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
          닥터엠디 이용약관<br />
        [1장 총칙]<br />
        제 1 조 (목적)<br />
        본 약관은 이용고객이 닥터엠디(이하 “회사” 혹은 “Dr.MD”이라 합니다)이 제공하는 “서비스”를 이용함에 있어 회사와 이용고객간의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.<br /><br />

        제 2 조 (정의)<br />
        본 약관에서 사용하는 주요 용어는 다음과 같습니다.<br />
        ① “Dr.MD”라 함은 회사가 온라인 협업 클라우드 서비스를 이용고객에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화, 용역을 거래할 수 있도록 설정한 가상의 영업장 또는 회사가 운영하는 웹사이트를 말하며, 아울러 ”Dr.MD”을 운영하는 사업자의 의미로도 사용합니다<br />
        ② “서비스”라 함은, 구현되는 단말기(PC, 태블릿, 휴대용 단말기 등의 각 종 유무선 장치)와 상관없이 브랜드명 ‘Dr.MD”을 사용하여 “회사”가 제공하는 제반 서비스를 말합니다.<br />
        ③ “이용고객” 이라 함은, 회사의 “서비스”에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 받는 회원과 비회원을 말합니다.<br />
        ④ “게시물” 이라 함은, “이용고객”이 “서비스”를 이용함에 있어 “서비스상”에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.<br />
        ⑤ “주문거래”라 함은 이용고객이 서비스를 이용함에 있어 결제서비스를 이용하여 매매거래가 이루어지는 일체를 말합니다.<br />
        ⑥ “포인트”라 함은 회원이 서비스 이용을 함으로써 회사가 회원에 제공하는 사이버 화폐를 말합니다.<br /><br />

        제 3 조 (약관의 게시, 효력 및 개정)<br />
        ① 회사는 본 약관의 내용을 이용고객이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다. 다만, 약관의 내용은 “이용자”가 연결화면을 통하여 보도록 할 수 있습니다.<br />
        ② 회사는 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.<br />
        ③ 회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자로부터 최소 칠(7)일 전부터 (이용자의 소중한 권리 또는 의무에 중요한 내용 변경은 최소 30일 전부터) 일정기간 서비스 내 공지하고, 로그인시 동의 창 등의 전자적 수단을 통해 고지합니다.<br />
        ④ 회사가 전항에 따라 이용고객에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 않으면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도, 이용고객이 명시적으로 거부의 의사 표시를 하지 않은 경우에는 변경된 약관을 승인한 것으로 봅니다.<br />
        ⑤ 이용고객이 개정약관에 동의하지 않을 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이용고객은 제26조 제1항의 규정에 따라 이용계약을 해지할 수 있습니다.<br />
        ⑥ 본 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.<br /><br />
        
        [2장 서비스의 이용]<br />
        제 4 조 (서비스 이용)<br />
        ① 이용계약의 성립 : 닥터엠디로부터 사전에 로그인 아이디와 비밀번호를 발급받은 회원은 "서비스" 이용 신청 및 개인정보처리방침에 동의하는 것으로 간주됩니다.<br />
        ② 서비스 이용은 회사의 서비스 사용 승낙 직후부터 가능합니다.<br />
        ③ 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로 합니다. 다만, 서비스 설비의 정기점검 등의 사유로 회사가 서비스를 특정범위로 분할하여 별도로 날짜와 시간을 정할 수 있습니다.<br />
    
        제 5 조 (서비스 내용 변경통지 등)<br />
        회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 서비스의 공지사항, SMS 등의 전자적 방식을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있습니다.<br />
    
        제 6 조 (서비스 이용의 제한 및 중지)<br />
        ① 회사는 다음 사유가 발생한 경우에는 이용고객의 서비스 이용을 제한하거나 중지시킬 수 있습니다.<br />
        1. 이용고객이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우<br />
        2. 이용고객이 제8조의 의무를 위반한 경우<br />
        3. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우<br />
        4. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br />
        5. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때<br />
        6. 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우<br />
        ② 회사는 “주민등록법"을 위반한 명의도용 및 결제도용, 전화번호 도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 서비스 이용을 통해 획득한 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.<br />
        ③ 회사는 본 조에 따라 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한 기간 등을 제5조 [서비스 내용 변경통지 등]에 따라 이용고객에게 통지합니다.<br />
        ④ 이용고객은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.<br /><br />
     
        제 7조 (권리의 귀속 및 저작물의 이용)<br />
        ① 회사가 이용고객에게 제공하는 각종 서비스에 대한 저작권을 포함한 일체의 권리는 회사에 귀속되며 이용고객이 서비스를 이용하는 과정에서 작성한 게시물에 대한 저작권을 포함한 일체에 관한 권리는 별도의 의사표시가 없는 한 해당 이용고객에게 귀속됩니다.<br />
        ② 회사 는 서비스의 운영, 전시, 전송, 배포, 홍보 의 목적으로 이용고객의 별도의 허락 없이 무상으로 저작권법에 규정하는 공정한 관행에 합치되게 합리적인 범위 내에서 다음과 같이 이용고객이 등록한 게시물을 사용할 수 있습니다.<br />
        ③ 이용고객이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램저작권 등을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 이용고객은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우 이용고객은 그로 인해 "회사"에 발생한 모든 손해를 부담하여야 합니다.<br /><br />
        
        – 다 음 –<br />
        서비스 내에서 이용고객의 게시물의 복제, 수정, 개조, 전시, 전송, 배포 및 저작물성을 해치지 않는 범위 내에서의 편집 저작물 작성 미디어 , 통신사 등 서비스 제휴 파트너에게 회원의 게시물 내용을 제공, 전시 혹은 홍보하게 하는 것.<br /><br />
     
        제 8 조 (회원 및 비회원 이용고객의 의무)<br />
        ① 회원 및 비회원 이용고객은 다음 각 호의 행위를 하여서는 안 됩니다.<br />
        1. 회원정보에 허위 내용을 등록하는 행위<br />
        2. 회사의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 영리 또는 비영리의 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공하는 행위<br />
        3. 회사 기타 제3자의 명예를 훼손하거나 지적재산권을 침해하는 등 회사나 제3자의 권리를 침해하는 행위<br />
        4. 다른 회원의 회원 정보 또는 이용고객의 정보를 도용하여 부당하게 서비스를 이용한 경우<br />
        5. 정크메일(junk mail), 스팸메일(spam mail), 행운의 편지(chain letters), 피라미드 조직에 가입할 것을 권유하는 메일, 외설 또는 폭력적인 메시지 ·화상·음성 등이 담긴 메일을 보내거나 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위<br />
        6. 정보통신망이용촉진및정보보호등에관한법률 등 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)를 전송하거나 게시하는 행위<br />
        7. 공공질서 또는 미풍양속에 위배되는 내용의 정보, 문장, 도형, 음성 등을 유포하는 행위<br />
        8. 회사의 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 모용하여 글을 게시하거나 메시지를 발송하는 행위<br />
        9. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편, SMS로 발송하는 행위<br />
        10. 스토킹(stalking) 등 다른 이용고객의 서비스 이용을 방해하는 행위<br />
        11. 다른 이용고객의 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위<br />
        12. 불특정 다수의 이용고객을 대상으로 하여 광고 또는 선전을 게시하거나 스팸메일을 전송하는 등의 방법으로 회사에서 제공하는 서비스를 이용하여 영리목적의 활동을 하는 행위<br />
        13. 현행 법령, 회사가 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한 규정을 위반하는 행위<br />
        ② 회사는 회원 또는 비회원 이용고객이 제1항의 행위를 하는 경우 서비스의 이용을 제한하거나 일방적으로 본 계약을 해지할 수 있으며, 이에 대해 회사는 어떠한 책임도 지지 않습니다.<br /><br />
   
        제 9 조 (회사의 의무)<br />
        ① 회사는 회사의 서비스 제공 및 보안과 관련된 설비를 지속적이고 안정적인 서비스 제공에 적합하도록 유지, 점검 또는 복구 등의 조치를 성실히 이행하여야 합니다.<br />
        ② 회사는 이용고객이 원하지 아니하는 영리 목적의 광고성 전자우편이나 SMS를 발송하지 않습니다.<br />
        ③ 회사는 서비스의 제공과 관련하여 알게 된 이용고객의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관한 기타의 사항은 정보통신망 이용촉진 및 정보보호등에 관한 법률 등 관계법령 및 회사가 별도로 정한 “개인정보취급방침”에 따릅니다.<br /><br />
   
        제 10조 (상품진단와 연결 웹사이트 간의 관계)<br />
        ① 상품진단와 연결된 웹사이트란 하이퍼 링크(예: 하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우를 말합니다.<br />
        ② 회사는 연결된 웹사이트가 독자적으로 제공하는 재화, 용역으로 이용고객과 해당 웹사이트가 행하는 거래에 대해서 보증책임을 포함하여 일체의 책임을 지지 않습니다<br /><br />
  
        제 11 조 (양도금지)<br />
        이용고객의 서비스 받을 권리는 이를 양도 내지 증여하거나 질권의 목적으로 제공 할 수 없습니다<br /><br />
  
        제 12 조 (포인트서비스)<br />
        ① “포인트”는 회사의 정책에 따라 회원에게 부여가 되며, 사용기간은 적립일로부터 3년이며, 적립기준, 사용조건 및 제한에 관한 사항은 서비스 화면에 별도로 게시하거나 통지합니다. “포인트”의 사용조건에 관한 사항은 회사의 정책에 따라 달라질 수 있습니다.<br />
        ② 회원의 탈퇴 및 자격상실이 될 경우 “포인트”는 자동 소멸됩니다.<br /><br />
   
        제 13 조 (주문거래 기록의 보관)<br />
        “주문거래”의 거래기록은 전자상거래등에서의 소비자보호에 관한 법률에 규정에 의해 일정기간 동안 보존 할 수 있습니다.<br /><br />
   
        제 14 조 (주문거래 시 가능한 결제 수단)<br />
        회사에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 하나로 할 수 있습니다.<br /><br />
        1. 신용카드 결제<br />
        2. 가상계좌 결제, 포인트<br />
        3.기타 회사가 추가 지정하는 결제수단<br /><br />
  
        제 15 조 (주문거래 시 구매결정 및 송금)<br />
        이용고객은 본 약관 및 회사가 정한 규정에 따라 다음 각 호의 절차를 거쳐 “주문거래”를 할 수 있습니다.<br />
        1. 재화 또는 용역의 선택<br />
        2.성명, 예약일자, 연락처의 입력<br />
        3. 재화 또는 용역 금액의 확인<br />
        4. 결제방법의 선택<br /><br />
    
        제 16 조 (주문거래 시 취소 및 환불)<br />
        ① 회사의 “주문거래”시 취소 및 환불 규정은 전자상거래등의 소비자보호에 관한 법률 등 관련 법령을 준수합니다.<br />
        ② “주문거래”시 상품진단 서비스 성격에 따라 회사는 별도의 취소 및 환불 관련 규정을 정할 수 있으며, 이 경우 별도 계약 및 이용조건 상의 취소 및 환불 규정이 우선 적용됩니다.<br />
        ③ “주문거래”시 상품진단 서비스의 특성상 취소와 환불은 해당 서비스 제공 업체와 확인 후 처리 하게 되며, 신용카드결제는 신용카드사의 환불기준에 의거하여 시일이 소요될 수 있습니다.<br /><br />
        
        [3장 위치기반서비스]<br />
        제17조 (위치기반서비스의 내용)<br />
        회사는 위치정보사업자로부터 제공받은 위치정보수집대상의 위치정보를 이용하여 다음 각 호와 같은 내용의 위치기반서비스를 제공합니다<br />
        1. 현재 위치를 활용한 검색결과 제공: 개인위치정보주체의 현 위치를 기준으로 근거리 및 방문이 가능한 업체 등의 검색결과를 제공합니다.<br /><br />
   
        제18조 (위치정보 수집방법)<br />
        회사는 다음과 같은 방식으로 개인위치정보를 수집합니다.<br />
        1. 휴대폰 단말기를 이용한 기지국 기반(Cell ID방식)의 실시간 위치정보 수집<br />
        2. GPS칩이 내장된 전용 단말기를 통해 수집되는 GPS 정보를 통한 위치정보 수집<br />
        3. Wi-Fi칩이 내장된 전용 단말기를 통해 수집되는 WPS 정보를 통한 위치정보 수집<br />
        4. 기타 위치를 식별할 수 있는 장치를 통해 수집되는 위치정보 수집<br /><br />
   
        제19조 (위치기반서비스의 이용)<br />
        ① 위치기반서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상이나 기술상의 이유로 서비스가 일지 중지될 수 있고, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.<br />
        ② 회사는 위치기반서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 공지합니다.<br />
        회사는 전시·사변·천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 위치기반서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.<br />
        ④ 회사는 제4항의 규정에 의하여 위치기반서비스의 이용을 제한하거나 정지한 때에는 그 사유 및 제한기간 등을 지체 없이 이용고객에게 알리도록 노력해야 합니다.<br />
        ⑤ 제3항에 의한 장애로 인하여 위치기반서비스가 제한 또는 정지된 경우에 회사는 일체의 책임을 지지 않습니다.<br /><br />

        제20조 (개인위치정보의 이용 또는 제공)<br />
        ① 개인위치정보를 이용하는 회사는 아래와 같습니다.<br />
        가. 상호 : 닥터엠디<br />
        나. 대표자: 이상발<br />
        다. 주소 : 서울특별시 강서구 공항대로 212, 문영퀸즈파크 11, A동 1133호<br /><br />
        
        ② 회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 합니다.<br />
        ③ 이용고객 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용고객의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용고객의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할 법원에 제기합니다.<br />
        ④ 회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보주체에 대한 위치정보 수집∙이용∙제공사실 확인자료를 위치정보시스템에 자동으로 기록하며, 1년간 보관합니다.<br />
        ⑤ 회사는 개인위치정보를 이용고객이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 이용고객에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. 단, 다음 각 호의 어느 하나에 해당하는 경우에는 이용고객이 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보합니다.<br />
        1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우<br />
        2. 이용고객이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우<br /><br />
       
        제21조 (개인위치정보주체의 권리)<br />
        ① 이용고객은 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치 정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.<br />
        ② 이용고객은 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.<br />
        ③ 이용고객은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 이용고객의 요구를 거절할 수 없습니다.<br />
        1. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료<br />
        2. 본인의 개인위치 정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제 3자에게 제공된 이유 및 내용<br />
        ④ 이용고객은 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.<br /><br />
       
        제22조 (법정대리인의 권리)<br />
        ① 회사는 14세 미만의 이용고객에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제 3자 제공에 대한 동의를 당해 이용고객과 당해 이용고객의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 제7조에 의한 이용고객의 권리를 모두 가집니다.<br />
        ② 회사는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용∙제공사실 확인자료를 이용 약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세미만의 아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.<br />
        1. 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용, 제공사실 확인자료가 필요한 경우<br />
        2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우<br />
          </Text>
        </ContentWithPaddingXl>
      </Container>
      
    </AnimationRevealPage>
  );
};
