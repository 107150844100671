import React, { useState, useEffect }  from 'react';
import Header from "../components/admin/Header";
import Navbar from "../components/admin/Navbar";

import ListUsers, {SelectColumnFilter} from "../components/admin/ListUsers";
import firebase, {auth} from "../config/firebase";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import Navbarmenu from '../components/menu/Navbarmenu';
import '../styles/navbarmenu.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;


const db=firebase.firestore();



const UserList = (props) => {
  const resultUsers = [];
  const [users, setUsers] = useState([]);

  useEffect(() => {


  db.collection("users")
    .orderBy("creatAt", "desc")
    .get()
    .then((response) => {
   
      response.forEach((doc) => {
        const user = doc.data();
        const userType = doc.data().userType;
        /*
        const category="";
        if(typeof doc.data().profile.proInfo.category.name == undefined){
          category="";
        }else{
          category = doc.data().profile.proInfo.category.name;
        }
        const channel="";
        const channelCountry="";
        const channelType="";
        const yearsOfCareer="";
*/
      


        const data={
          id: doc.id,
          email: doc.data().email,
          name : doc.data().profile.name,          
          bizName : doc.data().profile.bizInfo.bizName,
          phone : doc.data().profile.phone,
          userState : doc.data().state,
          userType : doc.data().userType,
          userGroup: doc.data().userGroup,
          creatAt : new Date(doc.data().creatAt.seconds * 1000),
          displayName : doc.data().displayName,
          
          //category : category,
          //channel : channel,
          //channelCountry : channelCountry,
          //channelType : channelType,
          //yearsOfCareer : yearsOfCareer,
        };
        //console.log("user no=>",doc.id);
        
        //req.id = doc.id;
        resultUsers.push(data);
      });
      setUsers(resultUsers);
    });

    //console.log(resultReqs);
 
  }, []);

    const columns = React.useMemo(
        () => [
          {
            Header: "번호",
            //accessor: d => d.id,
            //accessor: "prodName",
            Cell: ({row})  => row.id
          },
          {
            Header: "이름",
            accessor: "name",
          },
          {
            Header: "이메일",
            accessor: "email",
            show: window.innerWidth > 750 ? true : false
          },
          {
            Header: "회사명",
            accessor: "bizName",
          },
          
          {
            Header: "분류",
            accessor: d => `${d.userType==0 ? "관리자" : (d.userType==1 ? "중소기업" : "전문가")}`, 
            show: window.innerWidth > 750 ? true : false
          },
          
          {
            Header: "상태",
            accessor: d => `${d.userState==1 ? "승인대기중" : "승인완료"}`, 
            Filter: SelectColumnFilter, 
            filter: 'includes', 
          },
          {
            Header: "가입일",
            accessor: d => `${d.creatAt.getFullYear()}.${d.creatAt.getMonth()+1}.${d.creatAt.getDate()}`,
            show: window.innerWidth > 750 ? true : false
            //accessor: "prodName",
          },
          {
            Header: "상세보기",
            accessor: d => <Link to={{ pathname:`/userList/${d.id}`, 
            state:{
                email: d.email,
                name : d.name,          
                bizName : d.bizName,
                phone : d.phone,
                userState : d.userState,
                userType : d.userType,
                userGroup: d.userGroup,
                creatAt : d.creatAt,
                displayName : d.displayName,
                //category : d.category,
                //channel : d.channel,
                //channelCountry : d.channelCountry,
                //channelType : d.channelType,
                //yearsOfCareer : d.yearsOfCareer,
            }}}>상세보기</Link>
            //accessor: d => <Link to={`/reqs/${d.id}`}>{d.prodName}</Link>,
            //accessor: d => <a href={`https://connectmd12.web.app/req/${d.id}`}>{d.id}</a>,
            //accessor: "prodName",
            //Cell: (props)  => <a href={`http://www.${props.prodName}!`}>{props.prodName}</a>
          },
        



          
               
        ],[]);

    //const data = React.useMemo(() => getData(), []);
    
    return (

      <div>
     <Navbarmenu />
      <div tw="min-h-screen bg-gray-100 text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
        
        <h1 tw="text-xl font-semibold">회원리스트</h1>
        </div>
        <div tw="mt-4">
        <ListUsers 
          columns={columns} 
          data={users}
          
        
        />
      </div>
      </main>
    </div>
    </div>
);
}

export default UserList;

/*


        const userEmail = [];

        const user= await db.collection("users")
        .where("uid", "==", doc.data().createBy)
        .get()
        .then((user) => {


          user.forEach((u)=>{
            //console.log(u.id, "=>", u.data());
            //console.log(u.id, "=>", u.data().email);

            userEmail.push(u.data().email);

          })

          console.log("email=>",userEmail[0]);
          data.email=userEmail[0];
          //const mail = doc.data().email;
          //userEmail.push(mail);
          //console.log("email=>", mail)
        });

*/