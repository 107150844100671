import React from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from "react-table";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line


const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  table-layout: auto;
  width: 100%
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;

`;
/*
:nth-of-type(2) { font-weight: bold; width: 200px;}
:nth-of-type(1) { width: 80px;}
:nth-of-type(3) { width: 120px;}
:nth-of-type(4) { width: 180px;}
:nth-of-type(5) { width: 120px;}
:nth-of-type(6) { width: 120px;}
:nth-of-type(7) { width: 120px;}
:nth-of-type(8) { width: 120px;}
*/

const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;
:nth-of-type(even) {
  background-color: #f3f3f3;
}
:last-of-type {
  border-bottom: 2px solid #182987;
}
.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

export const SelectColumnFilter=({
    column: { filterValue, setFilter, preFilteredRows, id },
  })=>{
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);
  
    // Render a multi-select box
    return (
      <select
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }


const GlobalFilter= ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <span>
        검색:{' '}
        <input
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`검색결과 ${count} 건`}
        />
      </span>
    )
  }



const ListDiags = ({ columns, data }) =>{ 

    const { 
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        rows, 
        prepareRow, 

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable({
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map(column => {
            if (column.show === false) return column.accessor || column.id;
        }),
    },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
    );



return (

    <>
    <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />

        {headerGroups.map((headerGroup) =>
        headerGroup.headers.map((column) =>
          column.Filter ? (
            <div key={column.id}>
              <label for={column.id}>{column.render("Header")}: </label>
              {column.render("Filter")}
            </div>
          ) : null
        )
      )}
    
    <div>
        <div>
          <div>
            <div>
    <StyledTable {...getTableProps()} border="1"  className="table"> 
      <thead
  
        //style={{backgroundColor:"#f9dfafb"}}
        //className="bg-gray-50"
        //style="background-color: rgba(249, 250, 251, 1);"
      >
        {headerGroups.map((headerGroup) => (
          <StyledTHeadTr {...headerGroup.getHeaderGroupProps()}  className="tr">
            {headerGroup.headers.map((column) => (
              <StyledTh 
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                /*
                  style={{
                    borderBottom: 'solid 3px red',
                    background: 'aliceblue',
                    color: 'black',
                    fontWeight: 'bold',
                  }}*/
                  className="th">
                  {column.render('Header')}
                     
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ▼'
                        : ' ▲'
                      : ''}
                  </span>
              
              
              </StyledTh>

            ))}
          </StyledTHeadTr>
        ))}
      </thead>



      
      <tbody {...getTableBodyProps()}
      >
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <StyledTBodyTr {...row.getRowProps()}  className="tr">
              {row.cells.map((cell) => {
                return <StyledTd {...cell.getCellProps()}
                className="td"
                >{cell.render("Cell")}</StyledTd>;
              })}
            </StyledTBodyTr>
          );
        })}
      </tbody>
    </StyledTable>
    </div>
          </div>
        </div>
      </div>

    <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {state.pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={state.pageSize}
          onChange={e => {
              setPageSize(Number(e.target.value))
          }}
        >
          {[5, 10, 20].map(pageSize => (
              <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
);
}

export default ListDiags;