import React, { useState, useEffect }  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import firebase, {auth} from "../config/firebase";
import Header from "../components/admin/Header";
import { useTable } from 'react-table'
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import DetailDiagTable from "../components/admin/DetailDiagTable";
import BarChart from "../components/admin/BarChart";
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";



const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;


const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(1) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();


function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Diag = ({location:{state}, history}) =>{
    let { diagId } = useParams();
    const {
      bizName,
      itemName,
      cusData,
      cusChartData,
      cusChartLabel,
      cusTitleLabel,
      cusQ1Sum,
      cusQ2Sum,
      cusQ3Sum,
      cusQ4Sum,
      cusQ5Sum,
      cusQ6,
      cusQ7,
      cusQ8,
      cusTotalSum,
      mdData,
      mdChartData,
      mdChartLabel,
      mdTitleLabel,
      mdQ1Sum,
      mdQ2Sum,
      mdQ3Sum,
      mdQ4Sum,
      mdQ5Sum,
      mdTotalSum,
      mdQ6,
      mdQ7,
      mdQ8,
      isReplied,
      stateName,
      createAt,
      createBy,
      repliedBy,
      repliedAt,
    } = state;

    const user = auth.currentUser;
    const [aUEmail, setAuEmail] = useState("");
    const [aUName, setAuName] = useState("");
    const [aUType, setAuType] = useState("");
    const [aUUid, setAuUid] = useState("");
    const [chartMdDataFromMe, setChartMdDataFromMe] = useState([]);
    const [mdComment, setMdCommnet] = useState("");   

    const getUserInfo = async(uid) => {
    
      const users = await db.collection("users")
      .where("uid", "==", uid)
      .get(); 
      users.docs.map(user => {
        setAuEmail(user.data().email);
        setAuName(user.data().profile.name);
        setAuType(user.data().userType);
        setAuUid(user.data().uid);
            //console.log("email  =>",user.data().email);
      })    
    };



    useEffect(() => {
      getUserInfo(user.uid);
      
      }, []);

      const handleOnGotoList = (e)=>{
        
          history.push({
            pathname: '/diags'
            //search: '?query=abc',     
        });
          e.preventDefault();
      }


      const handleOnDel = (e)=>{
        const message="정말로 삭제하시겠습니까?"
        if (window.confirm(message)) {
          //onDel();
          history.push({
            pathname: '/diagDel',
            //search: '?query=abc',
            state: { 
            diagId: diagId
            }        
        });
    
        }else{
          e.preventDefault();
        }
      }

      const data = React.useMemo(
        () => [
          {
            col1: '',
            col2: '자가진단',
            col3: '코디진단',
          },          
        ],
        []
      )
    
      const columns = React.useMemo(
        () => [
          {
            Header: '항목',
            accessor: 'col1', // accessor is the "key" in the data
          },
          {
            Header: '내용',
            accessor: 'col2',
          },
          {
            Header: '내용',
            accessor: 'col3',
          },
        ],
        []
      )

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data })


  return(

    <div tw="min-h-screen text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
      

    <div>
    <h1 tw="text-xl font-semibold">창업역량진단 상세</h1>

      <DetailDiagTable 
        columns={columns} 
        data={data}
        cusQ1Sum={cusQ1Sum} 
        cusQ2Sum={cusQ2Sum} 
        cusQ3Sum={cusQ3Sum} 
        cusQ4Sum={cusQ4Sum}
        cusQ5Sum={cusQ5Sum}
        cusQ6={cusQ6}
        cusQ7={cusQ7}
        cusQ8={cusQ8}
        cusTotalSum={cusTotalSum}
        mdQ1Sum={mdQ1Sum} 
        mdQ2Sum={mdQ2Sum} 
        mdQ3Sum={mdQ3Sum} 
        mdQ4Sum={mdQ4Sum}
        mdQ5Sum={mdQ5Sum}
        mdQ6={mdQ6}
        mdQ7={mdQ7}
        mdQ8={mdQ8}
        mdTotalSum={mdTotalSum}
      />    
      <br /><br /><br />
      <BarChart 
        cusData={cusChartData} 
        cusLabel={cusChartLabel} 
        mdData={mdChartData}
        mode="diag"
      />
      <br /><br /><br />
      

      <div>
      <h1 tw="text-xl font-semibold">기본정보</h1>
      <StyledTable>
        <StyledTBodyTr>
          <StyledTd>사업자</StyledTd>
          <StyledTd>{bizName}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>진단요청일시</StyledTd>
          <StyledTd>{createAt.getFullYear()}.{createAt.getMonth() + 1}.{createAt.getDate()} {createAt.getHours()}:
              {createAt.getMinutes() < 10 ? "0" : ""}
              {createAt.getMinutes()}</StyledTd>
        </StyledTBodyTr>

      </StyledTable>
      </div>
      
    </div>

    <div>
      {aUType==0 && (
      <PrimaryButton buttonRounded={true} as="a" href="#" onClick={handleOnDel}>삭제</PrimaryButton>
      )}
      <PrimaryButton buttonRounded={true} as="a" href="#" onClick={handleOnGotoList}>리스트</PrimaryButton>

    </div>
  
        
         
    </div>
      </main>
    </div>
  );
}
export default Diag;

/*
          
  <h3>{chart_cus_data}</h3>
      <h3>{chart_cus_label}</h3>
*/


/*
<h1 tw="text-xl font-semibold">상품/역량진단 클리닉 보고서</h1>
      <h1 tw="text-xl font-semibold">{bizName}</h1>
      <h1 tw="text-xl font-semibold">{prodName}</h1>
      <br /><br /><br />
      
  */