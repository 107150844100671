import React, { useState, useEffect }  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import firebase, {auth} from "../config/firebase";
import Header from "../components/admin/Header";
import { useTable } from 'react-table'
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons.js";

const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #182987;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(odd) {
  font-weight: bold;
  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;


.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;


const Select = tw.select`px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const db=firebase.firestore();


const User = ({location:{state}, history}) =>{
    let { userId } = useParams();
    const {
        email,
        name,      
        bizName,
        phone,
        userType,
        userGroup,
        creatAt,
        userState,
        displayName,
        //category,
        //channel,
        //channelCountry,
        //channelType,
        //yearsOfCareer,
    } = state;

    const user = auth.currentUser;

    const [userTypeNo, setUserTypeNo] = useState(userType);


    const handleUserTypeNoChange = (event) => {
      
      //console.log(event.target.value);
      setUserTypeNo(Number(event.target.value));
      event.preventDefault();
  
    }

    const handleOnUpdate = (e)=>{


      if(userTypeNo== null || userTypeNo=="default"){
        alert("유저타입을 선택해주세요.")
        e.preventDefault();
      }else{

        const message="회원을 승인하시겠습니까?"
      if (window.confirm(message)) {
        //onDel();
        history.push({
          //pathname: '/userApprove',
          pathname: `/userApprove/${userId}`,
          //search: '?query=abc',
          state: { 
            userId: userId,
            //userTypeNo : userTypeNo
          }        
      });
  
      }else{
        e.preventDefault();
      }
        
      }
      
    }


/*
    const getEmail = async(cus, md) => {
    
      const cusUsers = await db.collection("users")
      .where('uid', 'in', [cus, md])
      //.where("uid", "==", cus)
      //.where("uid", "==", md)
      .get();
      cusUsers.docs.map(user => {
        //console.log("uid=>", user.data().uid);
        if(user.data().uid == cus){
          setCusEmail(user.data().email);
          setCusName(user.data().profile.name);
          setBizName(user.data().profile.bizInfo.bizName);
        }else{
          setMdEmail(user.data().email);
          setMdName(user.data().profile.name);

        }
      })
    
    };

    useEffect(() => {

      //getEmail(createBy, sendTo);

             
      }, []);

*/
  return(

    <div tw="min-h-screen text-gray-900">           
      <main tw="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
      <div tw="">
      

    <div>
      
      <h1 tw="text-xl font-semibold">회원정보</h1>
      <StyledTable>
        <StyledTBodyTr>
          <StyledTd>사업자</StyledTd>
          <StyledTd>{bizName}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>담당자</StyledTd>
          <StyledTd>{name} ({email})</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>연락처</StyledTd>
          <StyledTd>{phone}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>분류</StyledTd>
          <StyledTd>{userType==0 ? "관리자" : (userType==1 ? "중소기업" : "전문가")}</StyledTd>
        </StyledTBodyTr>
        <StyledTBodyTr>
          <StyledTd>상태</StyledTd>
          <StyledTd>{userState==1 ? "승인대기중" : "승인완료"}</StyledTd>
        </StyledTBodyTr>
        
        <StyledTBodyTr>
          <StyledTd>가입일시</StyledTd>
          <StyledTd>{creatAt.getFullYear()}.{creatAt.getMonth() + 1}.{creatAt.getDate()} {creatAt.getHours()}:
              {creatAt.getMinutes() < 10 ? "0" : ""}
              {creatAt.getMinutes()}</StyledTd>
        </StyledTBodyTr>
  

      </StyledTable>
      <div>
        {(userType==2 || userType==3 ) && userState==1 && (
               <Select value={userTypeNo} onChange={handleUserTypeNoChange}>
               <option value="default">유저타입 선택</option>
                  <option value="2">역량진단 전문가</option>
                  <option value="3">방송분석 전문가</option>
          </Select>
        )}
          {(userType==2 || userType==3) && userState==1 && (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )}
          {userState==1 && (
          <PrimaryButton buttonRounded={true} as="a" href="#" onClick={handleOnUpdate}>승인</PrimaryButton>
          )}
              </div>
    </div>
    </div>
      </main>
    </div>
  );
}
export default User;

/*
          
  <h3>{chart_cus_data}</h3>
      <h3>{chart_cus_label}</h3>
*/