import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import firebase, {auth} from "../config/firebase";
import Select from 'react-select'
import { ListGroup } from "react-bootstrap";

const db=firebase.firestore();
const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const MonoSelect = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Select2 = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;


const SignUpWebView = ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  SubmitButtonIcon = LoginIcon,
  history
}) => {

  const [formData, setFormData] = useState(defaultFormValue());
  const [mode, setMode] = useState("userType");
  const [selectedCateOpt, setSelectedCateOpt] = useState([]);
  const [selectedChannelOpts, setSelectedChannelOpts] = useState(null);
  const [selectedPurposeOpts, setSelectedPurposeOpts] = useState(null);

  const [cateNo, setCateNo] = useState([]);
  const [cateName, setCateName] = useState([]);
  const [chNo, setChNo] = useState(null);
  const [chName, setChName] = useState(null);
  const [purposeNo, setPurposeNo] = useState(null);
  const [purposeName, setPurposeName] = useState(null);

  const cateOpts = [
    { value: '1', label: '생활/건강' },
    { value: '2', label: '식품' },
    { value: '3', label: '이미용' },
    { value: '4', label: '패션' },
    { value: '5', label: '가전' },
    { value: '6', label: '홈인테리어' },
    { value: '7', label: '유아동' },
    { value: '8', label: '스포츠/레저' },
    { value: '9', label: '기타' },
  ]
  const channelOpts = [
    { value: '100', label: '오픈마켓' },
    { value: '101', label: '홈쇼핑' },
    { value: '102', label: 'T커머스' },
    { value: '107', label: '라이브커머스' },
    { value: '108', label: '미디어커머스' },
    { value: '103', label: '종합몰' },
    { value: '104', label: '소셜커머스' },
    { value: '109', label: '모바일 쇼핑몰' },
    { value: '105', label: '폐쇄몰' },
    { value: '106', label: '해외수출' },
    { value: '200', label: '대형마트' },
    { value: '201', label: '백화점' },
    { value: '202', label: '전문점' },
    { value: '203', label: '면세점' },
    { value: '204', label: '쇼핑센터' },
    { value: '205', label: '편의점' },
    { value: '206', label: '카테고리 킬러' },
  ]

  const purpose = [
    { value: '1', label: '[중소기업유통센터] 소상공인 TV홈쇼핑,T커머스 멘토링' },
    { value: '2', label: '[중소기업유통센터] 22년 소상공인 홈쇼핑 방송준비(역량강화) 멘토링' },
    { value: '3', label: '[청년창업사관학교] 창업성공패키지 특화코칭' },
  ]

  const division = [
    { value: '1', label: '1분과' },
    { value: '2', label: '2분과' },
  ]

  const selectList = ["apple", "banana", "grape"];

  const [purposeSelected, setPurposeSelected] = useState("default");
  const [divisionSelected, setDivisionSelected] = useState("default");


  const purposeHandleSelect = (e) => {    
    setPurposeSelected(e.target.value);
    setPurposeNo(e.target.value);
    setPurposeName(e.target.label);
  };

  const divisionSelect = (e) => {    
    setDivisionSelected(e.target.label);
  };

  const ccNos= (channel,categorys) => {
    let temp=[];
    temp=categorys.map(cate=>(channel*1000)+cate);
    //setCcno(temp);
    return temp;
  }
  const checkEmail=(str)=>{                                                 
    var reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if(!reg_email.test(str)) {return false;}
    else {return true;}
}

  const onSubmit=(e)=>{
    e.preventDefault();
    if (formData.email=="" || formData.password=="") {
      alert("이메일과 비밀번호를 필수 입력해주세요.");
    }else if(!checkEmail(formData.email)){
      alert("올바른 이메일 주소가 아닙니다.");
    }else if (formData.password !=formData.repeatPassword) {
      alert("입력한 비밀번호가 일치하지 않습니다.");
    } else if (formData.password.length < 6) {
      alert("비밀번호는 최소 6글자 이상이어야 합니다.");
    }else if(formData.bizName==""){
      alert("회사명을 입력해주세요.");
    }else if(formData.bizNum==""){
      alert("사업자번호를 입력해주세요.");
    }else if(formData.profit==""){
      alert("전년도 매출액을 입력해주세요.");
    }else if(formData.name==""){
      alert("담당자명을 입력해주세요.");
    }else if(formData.phone==""){
      alert("전화번호를 입력해주세요.");
    }else if(mode=="cusSignUp" && purposeNo==null){
      alert("가입목적을 선택해주세요.");
    }else if(mode=="cusSignUp" && purposeNo==3 && divisionSelected=="default"){
      alert("분과를 선택해주세요.");
    }else if(mode=="mdSignUp" && chNo==null){
      alert("유통채널을 입력해주세요.");
    }else if(mode=="mdSignUp" && cateNo.length==0){
      alert("담당 카테고리를 입력해주세요.");
    
    }else {
      console.log("---------1------");
      const baselineRef = db.collection("baseline").doc("base");
      baselineRef.get().then((response) => {
        const baseData = response.data();
        const total_partner_count = baseData.total_partner_count + 1;
        baselineRef.update({
          total_partner_count : total_partner_count
        })

      auth
      .createUserWithEmailAndPassword(formData.email, formData.password)
      .then((u) => {
        console.log("---------2------");
        let data={};
        if(mode=="cusSignUp"){
        console.log("---------2-1------");

          let tempRespondent={
            email : "balsang@naver.com",
            uid : "fQupou8K6UWjrvM9uUimpLetqvG2",
            token : "ExponentPushToken[Yt616_O4yKfz_Sg3VQizwV]"
          };

          if(divisionSelected!="default"){
            if(divisionSelected=="1분과"){
              tempRespondent={
                email : "mindpark@daum.net",
                uid : "VxBaLu6VQYONa6SujjsRIP5whND3",
                token : "ExponentPushToken[L4Vr_WLoXQYrEwFhcmHvv3]"
              };
            }else{
              tempRespondent={
                email : "sonatajung01@naver.com",
                uid : "0ZkXHqvGOpQ5psZl7MfNiPC6nhy1",
                token : "ExponentPushToken[fJ8hdbL6iojZBsgsMuHnsZ]"
              };
            }
          }

          data={
            email : formData.email,
            uid : u.user.uid,
            avatar : "",
            displayName : formData.name,
            userType : 1,
            userGroup : purposeNo==1 || purposeNo==2? 11 : 12,
            state : 1,
            profile : {
              total_req_count : 0,
              name : divisionSelected=="default"? formData.name : divisionSelected,
              phone : formData.phone,
              bizInfo : {
                bizNum : formData.bizNum,
                bizName: formData.bizName,
                name : "",
                licensePhoto : "",
                profit : formData.profit
              },
              purpose : {
                name : purposeName,
                  no : purposeNo
              },
              respondent : tempRespondent
            },
          }
        }else if(mode=="mdSignUp"){
        console.log("---------2-2------");

          data={
            email : formData.email,
            uid : u.user.uid,
            avatar : "",
            displayName : formData.yearsOfCareer+"년차 전문가",
            userType : 2,
            userGroup : 100,
            state : 1,
            profile : {
              total_res_count : 0,
              total_view_count : 0,
              intro : "",
              name : formData.name,
              phone : formData.phone,
              view_count : 0,
              curr_view_count : 0,
              bizInfo : {
                bizNum : "",
                bizName: formData.bizName,
                name : "",
                licensePhoto : "",
                intro : "",
              },
              proInfo : {
                channelCountry : {
                  name : "국내",//filterQlabel(q1Value),
                  no : "0"//filterQvalue(q1Value)
                },
                channelType : {
                  name : Number(chNo.charAt(0))==1 ? "온라인":"오프라인",//q2Value[0].onOffName,
                  no : Number(chNo.charAt(0))==1 ? "1":"2",
              },
                channel : {
                  name : chName,
                    no : chNo
                },
                category : {
                  name : cateName,
                  no: cateNo,
                },
                ccNo : ccNos(chNo,cateNo),
                yearsOfCareer : Number(formData.yearsOfCareer),
                mdNo : total_partner_count,
              }
            },
          }
          
        }else{
        console.log("---------2-3-----");
          
          data={
            email : formData.email,
            uid : u.user.uid,
            avatar : "",
            displayName : formData.name.charAt(0)+"*"+formData.name.charAt(formData.name.length-1)+" 코디네이터",
            userType : 2,
            userGroup : 104,
            state : 1,
            profile : {
              total_res_count : 0,
              total_view_count : 0,
              intro : "",
              name : formData.name,
              phone : formData.phone,
              view_count : 0,
              curr_view_count : 0,
              bizInfo : {
                bizNum : "",
                bizName: formData.bizName,
                name : "",
                licensePhoto : "",
                intro : "",
              },
              proInfo : {
                channelCountry : {
                  name : "국내",//filterQlabel(q1Value),
                  no : "0"//filterQvalue(q1Value)
                },
                channelType : {
                  name : "온라인",//q2Value[0].onOffName,
                  no : "100",
              },
                channel : {
                  name : "패션",
                    no : "4"
                },
                category : {
                  name : cateName,
                  no: cateNo,
                },
                ccNo : "",
                yearsOfCareer : "",
                mdNo : total_partner_count,
              }
            },
          }



        }

        console.log("---------3------");
        
        const data2=JSON.stringify(data);
        if(window.ReactNativeWebView) {
        console.log("---------3-1------");

          myFunction(data2);
        console.log("---------3-2------");

        }
      
      })
      .catch(() => {
        console.log("---------4------");

        alert("이미 사용중인 이메일입니다. 다른 이메일을 입력해주세요.");
      });
      
      
      
    });
     
    }
    
  };
  
  const onChange = (e, type) => {
    if(type=="bizNum" || type=="phone" || type=="yearsOfCareer" ){
      const value = e.target.value.replace(/\+|-/ig, '');
      setFormData({ ...formData, [type]: value });
    }else{
      setFormData({ ...formData, [type]: e.target.value });
    }
  };

 const onSelectedChannelOpt = (e) => {
    //console.log(e);
    setChNo(e.value);
    setChName(e.label);
  };

  const onSelectedPurposeOpts = (e) => {
    //console.log(e);
    setChNo(e.value);
    setChName(e.label);
    setSelectedPurposeOpts()
  };
  const onSelectedCateOpt = (e) => {
    console.log(e);
    if(e.length>=4){
      alert("3개까지만 선택 가능합니다.")
    }else{
      let tempV=[];
      let tempL=[];
      e.map(v=>{
        tempV.push(v.value);
        tempL.push(v.label);
      })
      setCateName(tempL);
      setCateNo(tempV);
      //console.log(tempL);
      //console.log(tempV);
    }

  };


  async function handleUserType(event, userType) {
    event.preventDefault();
    //updateUser("group", userType);
    switch (userType) {
      case 1:
        setMode("cusSignUp");
        break;
      case 10:
        setMode("mdSignUp");
        break;
      case 11:
        setMode("codiSignUp");
      break;
      default:
        setMode("cusSignUp");
    }
  }

  const myFunction = async(data) => {
    
    window.ReactNativeWebView.postMessage(data)
  }


  const render = {
    userType : (
      <MainContent>
      <Heading>회원유형선택</Heading>
      <div style={{ paddingBottom: 50}}/>
        <ListGroup size="lg">
          <ListGroup.Item size="lg"  onClick={ (e) => handleUserType(e,1)} >
            중소기업(소상공인 / 예비창업가) 회원가입
          </ListGroup.Item>
          <ListGroup.Item size="lg" onClick={ (e) => handleUserType(e,10)} >
            전문가 회원가입
          </ListGroup.Item>
          <ListGroup.Item size="lg" onClick={ (e) => handleUserType(e,11)} >
            코디네이터 회원가입
          </ListGroup.Item>
          
        </ListGroup>
      </MainContent>
    ),
    cusSignUp : (
      <MainContent>
      <Heading>중소기업 회원가입</Heading>
      <FormContainer>
              
              <Form>
                
                <Input 
                  type="text" 
                  placeholder="이메일"
                  onChange={(e) => onChange(e, "email")}
                  autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호" 
                onChange={(e) => onChange(e, "password")}
                autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호 재입력" 
                onChange={(e) => onChange(e, "repeatPassword")}
                autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="회사명 입력"
                  onChange={(e) => onChange(e, "bizName")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number"
                  placeholder="사업자번호 입력 (숫자만 입력)"
                  onChange={(e) => onChange(e, "bizNum")}
                  autoCapitalize='none'
                />
                <Input 
                  type="text"
                  placeholder="전년도 매출액 (숫자, 한글 모두 가능)"
                  onChange={(e) => onChange(e, "profit")}
                  autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="담당자명"
                  onChange={(e) => onChange(e, "name")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number" 
                  placeholder="전화번호 입력 (예: 01012345678)"
                  onChange={(e) => onChange(e, "phone")}
                  autoCapitalize='none'
                />
                
                <div style={{
                  margin:20
                }}></div>

                  <div style={{minHeight:"50px",height:"50px"}}>
                    <Select2 onChange={purposeHandleSelect}value={purposeSelected} style={{width:"100%", height:"100%"}}>
                    <option value="default" disabled hidden>
                    가입목적 선택
                    </option>
                      {purpose.map((item) => (
                        <option value={item.value} key={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select2>
              
                  </div>
                  <div style={{
                  margin:20
                }}></div>
                  {purposeNo==3 && (
                    <div style={{minHeight:"50px",height:"50px"}}>
                    <Select2 onChange={divisionSelect}value={divisionSelected} style={{width:"100%", height:"100%"}}>
                    <option value="default" disabled hidden>
                    분과 선택
                    </option>
                      {division.map((item) => (
                        <option value={item.value} key={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select2>
              
                  </div>
                  )}
                <div style={{
                  margin:20
                }}></div>
                <SubmitButton type="submit" onClick={onSubmit}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">회원가입</span>
                </SubmitButton>
              </Form>
            </FormContainer>
      </MainContent>
    ),
    mdSignUp : (
      <MainContent>
            <Heading>전문가 회원가입</Heading>
            <FormContainer>
              
              <Form>
                
                <Input 
                  type="text" 
                  placeholder="이메일"
                  onChange={(e) => onChange(e, "email")}
                  autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호" 
                onChange={(e) => onChange(e, "password")}
                autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호 재입력" 
                onChange={(e) => onChange(e, "repeatPassword")}
                autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="회사명 입력"
                  onChange={(e) => onChange(e, "bizName")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number" 
                  placeholder="경력년수 입력 (숫자만 입력)"
                  onChange={(e) => onChange(e, "yearsOfCareer")}
                  autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="전문가명 입력"
                  onChange={(e) => onChange(e, "name")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number" 
                  placeholder="전화번호 입력 (예: 01012345678)"
                  onChange={(e) => onChange(e, "phone")}
                  autoCapitalize='none'
                />
                <div style={{
                  margin:20
                }}></div>
                <Select 
                  options={channelOpts} 
                  defaultValue={selectedChannelOpts}
                  onChange={(e) => onSelectedChannelOpt(e)}
                  placeholder="유통채널 선택"
                  />
                <div style={{
                  margin:20
                }}></div>
                <Select 
                  options={cateOpts}
                  defaultValue={selectedCateOpt}
                  onChange={(e) => onSelectedCateOpt(e)}
                  isMulti={true}
                  placeholder="담당카테고리 선택"
                />
                <SubmitButton type="submit" onClick={onSubmit}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">회원가입</span>
                </SubmitButton>
              </Form>
            </FormContainer>
          </MainContent>
    ),
    codiSignUp : (
      <MainContent>
            <Heading>코디네이터 회원가입</Heading>
            <FormContainer>
              
              <Form>
                
                <Input 
                  type="text" 
                  placeholder="이메일"
                  onChange={(e) => onChange(e, "email")}
                  autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호" 
                onChange={(e) => onChange(e, "password")}
                autoCapitalize='none'
                />
                <Input type="password"
                placeholder="비밀번호 재입력" 
                onChange={(e) => onChange(e, "repeatPassword")}
                autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="회사명 입력"
                  onChange={(e) => onChange(e, "bizName")}
                  autoCapitalize='none'
                />
                <Input 
                  type="text" 
                  placeholder="담당자명 입력"
                  onChange={(e) => onChange(e, "name")}
                  autoCapitalize='none'
                />
                <Input 
                  type="number" 
                  placeholder="전화번호 입력 (예: 01012345678)"
                  onChange={(e) => onChange(e, "phone")}
                  autoCapitalize='none'
                />
                <div style={{
                  margin:20
                }}></div>
                <SubmitButton type="submit" onClick={onSubmit}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">회원가입</span>
                </SubmitButton>
              </Form>
              
            </FormContainer>
          </MainContent>
    ),
  }

  return (
    <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          {render[mode]}         
      </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
  );
}

function defaultFormValue() {
  return {
    email: "",
    password: "",
  };
}

export default SignUpWebView;