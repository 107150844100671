import React, {useState, useEffect} from 'react';
import FlatList from 'flatlist-react';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { BsCartPlus, BsCartDash } from 'react-icons/bs';
import moment from "moment";
export default function OrdList(props) {
  //const { products, onAdd, setMode, SetCurProd, cartItems } = props;
  //const { prods } = props;
  const { category, hideCate, products, onAdd, onAddGroup, onRemove, setMode, SetCurProd, cartItems, searchTerm, setSearchTerm, uid, user} = props;
/*
  const onLinkClick = (mode) => {
    SetCurProd(prod);
    setMode("detail");
    goToTop();  
};*/

useEffect(() =>{
}, [products]);



  const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
  };

  const onSearchInput = e =>{
    setSearchTerm(e.target.value);
  }
  
  

  async function onBtAddRow(){

  }
  const PostWrapper = ({children}) => {
    return (
        <div className="PostWrapper">
            {children}
        </div>
    );
};

  const renderProd = (prod, idx) => {

    //let isSale=prod.isSales=="일시중지" ||prod.isSales=="품절"||prod.isSales=="사용안함"||(prod.isSales=="판매"&&prod.curStock<=0)?false:true
    //let salesPrice= Math.ceil(prod.puchasPrice/(1-prod.margin)/100) * 100;

    return (
       
  <div className="Post" key={idx}>
  <div>
  <Row >
          <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>
          <>
          <b><span style={{fontSize: "0.875em"}}>{prod.prodName }</span></b><br/>
          <span style={{fontSize: "0.875em"}}>{prod.salesPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원&nbsp;x&nbsp;{prod.salesVol}&nbsp;=&nbsp;{prod.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
          </>
          </Col>

          <Col xs="auto" style={{paddingRight:0, paddingLeft :0 }}>
          {prod.state=="주문접수" && (
          <Button type="button" onClick={() => onAdd(prod)} size="sm" variant="outline-dark">주문취소 신청</Button>
          )}
          </Col>

      </Row>
  </div>
</div>
    );
  }
 const groupSeparator = (group, idx, groupLabel) => <div className="group-separator"><b><span style={{fontSize: "0.875em"}}>{groupLabel}</span></b></div>;
/*
 const groupSeparator = (group, idx, groupLabel) => (
    <Row className="group-separator1">
    <Col xs="auto">
   <b><span style={{fontSize: "0.875em"}}>{groupLabel}</span></b>
   </Col>
   <Col xs="auto">
   {category=="배송예정" && (
    <Button type="button" onClick={() => onAddGroup(groupLabel)} size="sm" variant="link">전체 주문취소 신청</Button>
    )}
   </Col>   
   </Row>
  );*/

  return (
      <div>
        <input style={{ width:"100%", marginBottom:"30px", fontSize: "0.875em" }} placeholder="품목명 또는 배송일(yyyy-mm-dd)을 입력해주세요." type="text" value={searchTerm} onChange={onSearchInput} className={`form-control`}  />
    <FlatList
      list={products}
      renderItem={renderProd}
      renderWhenEmpty={() => <div><span style={{fontSize: "0.875em"}}>내역이 없습니다.</span>
          <Button type="button" onClick={() => window.location.reload()} size="sm" variant="outline-dark">새로고침</Button>
      </div>}
      sortBy={["deliDateDash"]}
      filterBy={(item, index) => {
        if(category=="배송예정" && item.state =="주문접수"){
          return true
        }else if(category=="배송완료" && item.state =="매출완료"){
          return true
        }else if(category=="주문취소" && item.state =="주문취소"){
          return true
        }else{
          //주문취소
          return false
        }
      }}
      /*
      filterBy={(item, index) => {
        //console.log(item.cateName); //|| category!=hideCate
        //console.log(hideCate); //|| category!=hideCate
        //console.log(category); //현재 선택된 카테고리

        if(hideCate!="전체" && item.cateName != hideCate){
          
        if(item.isSales!="사용안함"){

          if(category=="전체"){
            return true
          }else if(category=="my"){
            return item.puchasedCus[user?.bizName.value]
          }
          else{
            return item.cateName===category
          }
        }else{
          return false
        }
      }

      }
      }*/

      //filterBy={item => item.type.includes('완')}
      //searchCaseInsensitive
      searchTerm={searchTerm}
      searchBy={["prodName","deliDateDash"]}
      searchMinCharactersCount={1}
      groupOf={2}
      groupSeparator={groupSeparator}
      group={{
        by: (item) => item.deliDateDash,
        separator: groupSeparator,
      }}
    />
</div>
  );
}


/*

          {prod.state=="매출완료" && prod.deliDateDash == moment().add(3, 'days').format('YYYY-MM-DD') && (
            <ButtonGroup>
          <Button type="button" onClick={() => onAdd(prod)} size="sm" variant="outline-dark">반품요청</Button>
          <Button type="button" onClick={() => onAdd(prod)} size="sm" variant="dark">환불요청</Button>
          </ButtonGroup>
          )}
*/