import React, {useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase, {auth} from "../config/firebase";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import styled from "styled-components";
import ColumnSelect from 'react-column-select'
import { sum, find } from "lodash";

const db=firebase.firestore();
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const SubHeading = tw.h1`text-xl xl:text-xl font-semibold`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

//const RespAdd = () => (
const MtrReply = ({location:{state}, history }) =>{
  const user = auth.currentUser;
  const {
    loginEmail,
    loginUid,
    loginUserName,
    loginUserType,
    loginUserState,
    loginUserGroup,
    idReq,         
  }=state;


  const [resCount, setResCount] = useState(0);
  const [currResCount, setCurrResCount] = useState(0);
  const [respState, setRespState] = useState(0);
  const [allQDoc, setAllQDoc] = useState([
    {
      label: '오픈마켓',
      value: '100',
      onOffName: '온라인',
      onOffNo :'1'
      },
      {
        label: '홈쇼핑',
        value: '101',
      onOffName: '온라인',
      onOffNo :'1'
      },
      {
        label: 'T커머스',
        value: '102',
        onOffName: '온라인',
        onOffNo :'1'
        },
        {
          label: '라이브커머스',
          value: '107',
          onOffName: '온라인',
          onOffNo :'1'
          },
          {
            label: '미디어커머스',
            value: '108',
            onOffName: '온라인',
            onOffNo :'1'
            },    
        {
          label: '종합몰',
          value: '103',
          onOffName: '온라인',
          onOffNo :'1'
          },
          {
            label: '소셜커머스',
            value: '104',
            onOffName: '온라인',
            onOffNo :'1'
            },
            {
              label: '모바일 쇼핑몰',
              value: '109',
              onOffName: '온라인',
              onOffNo :'1'
              },
            {
              label: '폐쇄몰',
              value: '105',
              onOffName: '온라인',
              onOffNo :'1'
              },
              {
                label: '해외수출',
                value: '106',
                onOffName: '온라인',
                onOffNo :'1'
                },
                {
                  label: '대형마트',
                  value: '200',
                  onOffName: '오프라인',
                  onOffNo :'2'
                  },
                  {
                    label: '백화점',
                  value: '201',
                  onOffName: '오프라인',
                  onOffNo :'2'
                  },
                  {
                    label: '전문점',
                    value: '202',
                    onOffName: '오프라인',
                    onOffNo :'2'
                    },
                    {
                      label: '면세점',
                      value: '203',
                      onOffName: '오프라인',
                      onOffNo :'2'
                      },
                      {
                        label: '쇼핑센터',
                        value: '204',
                        onOffName: '오프라인',
                        onOffNo :'2'
                        },
                        {
                          label: '편의점',
                          value: '205',
                          onOffName: '오프라인',
                          onOffNo :'2'
                          },
                          {
                            label: '카테고리 킬러',
                            value: '206',
                            onOffName: '오프라인',
                            onOffNo :'2'
                            },

  ]);
  const [selected, setSelected] = useState([]);

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
  const bodyTextStyle = {
    color : 'black',
    fontSize : "15px"
  }
  const bodyTextStyle1 = {
    color : 'black',
    fontSize : "13px",
    marginTop : 20,
  }
  const errorStyle = {
    color : 'red',
    fontSize : "10px"
  }

  const questions=[ 
    { 
      no: 1,
      title: "방송 편리성",
      body : "상품 사용이 편리합니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 2,
      title: "방송 혁신성",
      body : "상품이 지금까지와는 달리 새로운 기능이나 타 상품과의 차별화된 우월성을 표현할 수 있습니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 3,
      title: "방송 안전성",
      body : "방송심의등 표현 및 사용이 안전합니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 4,
      title: "방송 시연성",
      body : "방송을 방송 중에 알기 쉽게 매력적으로 시연해 보일수 있습니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 5,
      title: "상품특성상 1차적으로 진출 공략해야할 시장은 어디라고 생각합니까?",
      body : "(최대 3개 다중선택 가능)",
      type : "select",
    },
    {
      no: 6,
      title: "상품 가격",
      body : "이 제품의 상품가격을 어떻게 생각하십니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 7,
      title: "상품 기능",
      body : "상품기능이 우수합니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 8,
      title: "상품 디자인",
      body : "고객이 상품디자인을 보고 관심을 가질만 합니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 9,
      title: "상품 인지성",
      body : "상품의 브랜드를 알고있습니까 ? (브랜드인지도, 상품인지도 또는 서비스 인지도)",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 10,
      title: "상품 전문성",
      body : "이 상품에 대한 회사의 전문성은 어떠합니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 12,
      title: "상품 보완성",
      body : "상품성, 전문성, 인지성(3개 중 택1)에서 보완해야할 부분은 무엇입니까? (200자 이내로 기술해주세요)",
      type : "input",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 11,
      title: "상품성",
      body : "회사의 이 상품에 대한 상품성은 어떠합니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 13,
      title: "시장호감도",
      body : "이 상품에 좋은 감정을 불러일으키는 정도는 어떠합니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 16,
      title: "홈쇼핑 방송 분석의견",
      body : "아래 필수 포함내용 8가지 항목에 대한 분석 및 의견을 500자 이내로 기술해주세요.",
      body1 : "※ 필수 포함 내용 : 인서트영상, 쇼호스트(멘트), Display, 게스트 출연, 자막, 상품구성, 가격판매정책, 프로모션",
      body2 : "(단, 홈쇼핑 영상이 없는 건은 상품구성, 가격판매정책, 프로모션 등 3가지 항목만 작성해도 됩니다)",
      type : "input",
    },
    {
      no: 14,
      title: "시장트랜드",
      body : "경향, 동향, 추세, 유행에 부합하는 상품입니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 15,
      title: "시장경쟁력",
      body : "이 상품이 갖는 시장 경쟁력에 대해 어떻게 생각하십니까?",
      type : "radio",
      points : [5, 4, 3, 2, 1]
    },
    {
      no: 17,
      title: "MD분석 점수",
      body : "본인의 의견을 한 점수로 나타내면?",
      type : "radio",
      label : ["5점", "4점", "3점", "2점", "1점"],
      points : [5, 4, 3, 2, 1]
    },
    
  ]

  const [Q0, setQ0] = useState(""); 
  const [Q1, setQ1] = useState(""); 
  const [Q2, setQ2] = useState(""); 
  const [Q3, setQ3] = useState(""); 
  const [Q5, setQ5] = useState(""); 
  const [Q6, setQ6] = useState(""); 
  const [Q7, setQ7] = useState(""); 
  const [Q8, setQ8] = useState("");
  const [Q9, setQ9] = useState("");
  const [Q11, setQ11] = useState(""); 
  const [Q12, setQ12] = useState(""); 
  const [Q14, setQ14] = useState(""); 
  const [Q15, setQ15] = useState("");
  const [Q16, setQ16] = useState("");
   

  useEffect(() => {
    
    const reqRef = db.collection("reqs_mentoring").doc(idReq);
      reqRef.get().then((doc) => {
      //reqRef.onSnapshot((doc) => {
        setResCount(doc.data().resCount);
        setCurrResCount(doc.data().currResCount);
        setRespState(doc.data().state);
      });
   
  }, []);

  const handleChange = (e, q) => {
    switch(q) {
      case 0: return setQ0(e.target.value);
      case 1: return setQ1(e.target.value);
      case 2: return setQ2(e.target.value);
      case 3: return setQ3(e.target.value);
      case 5: return setQ5(e.target.value);
      case 6: return setQ6(e.target.value);
      case 7: return setQ7(e.target.value);
      case 8: return setQ8(e.target.value);
      case 9: return setQ9(e.target.value);
      case 11: return setQ11(e.target.value);
      case 12: return setQ12(e.target.value);
      case 14: return setQ14(e.target.value);
      case 15: return setQ15(e.target.value);
      case 16: return setQ16(e.target.value);
    }
  };

  const onSubmit = async (data) => {

   
    if(Q0==""){
        alert("1번 질문에 답변을 선택해주세요.");
    }else if(Q1==""){
      alert("2번 질문에 답변을 선택해주세요.");
    }else if(Q2==""){
      alert("3번 질문에 답변을 선택해주세요.");
    }else if(Q3==""){
      alert("4번 질문에 답변을 선택해주세요.");
    }else if( selected.length==0){
        //if(pushOptionValue == 3 || (pushOption =="3" && selected.length==0)){
        //if(pushOption =="default" || pushOption !="0" || pushOption !="1" || pushOption!="2" || pushOption!="3"){
      alert("5번 질문에 답변을 선택해주세요.");
    }else if(selected.length>3){
      alert("5번 질문에 답변은 최대 3개까지 선택 가능합니다."); 
    }else if(Q5==""){
      alert("6번 질문에 답변을 선택해주세요.");
    }else if(Q6==""){
      alert("7번 질문에 답변을 선택해주세요.");
    }else if(Q7==""){
      alert("8번 질문에 답변을 선택해주세요.");
    }else if(Q8==""){
      alert("9번 질문에 답변을 선택해주세요.");
    }else if(Q9==""){
      alert("10번 질문에 답변을 선택해주세요.");
    }else if(Q11==""){
      alert("11번 질문에 답변을 선택해주세요.");
    }else if(Q12==""){
      alert("13번 질문에 답변을 선택해주세요.");
    }else if(Q14==""){
      alert("14번 질문에 답변을 선택해주세요.");
    }else if(Q15==""){
      alert("15번 질문에 답변을 선택해주세요.");
    }else if(Q16==""){
      alert("17번 질문에 답변을 선택해주세요.");
   
    }else{
      let wishChName=[];
      let wishChNo=[];


      for ( const item of selected){
        try{
          wishChNo.push(item.value);
          wishChName.push(item.label);     
        }catch (error){
          console.log(error);
        }

      };

    if(resCount==currResCount){
      //alert("실시간 진단 진행 마감되어 답변이 불가능합니다.");
      alert("실시간 진단 진행 마감되어 답변 이 불가능합니다. 뒤로가기를 눌러 다른 요청 건을 확인해주세요.");
    } else {
      
      const chart = {
          label : [
            questions[0].title,
            questions[1].title,
            questions[2].title,
            questions[3].title,
            questions[5].title,
            questions[6].title,
            questions[7].title,
            questions[8].title,
            questions[9].title,
            questions[11].title,
            questions[12].title,
            questions[14].title,
            questions[15].title,
            questions[16].title,

          ],
          data : [
            parseFloat(Q0),
            parseFloat(Q1),
            parseFloat(Q2),
            parseFloat(Q3),
            parseFloat(Q5),
            parseFloat(Q6),
            parseFloat(Q7),
            parseFloat(Q8),
            parseFloat(Q9),
            parseFloat(Q11),
            parseFloat(Q12),
            parseFloat(Q14),
            parseFloat(Q15),
            parseFloat(Q16)
          ],
        };
        //console.log("user_uid: ", user.uid);
      const docRef = db.collection("users").doc(user.uid);
      await docRef.get().then((doc) => {
          if (doc.exists) {
              //console.log("Document data:", doc.data());
              //setDisplayName(doc.data().displayName);
              //setIntro(doc.data().profile.intro);
              //console.log("profile Phone:", doc.data().profile.phone);
              //console.log("nickName Phone:", doc.data().displyName);

              const paylod = {
                idUser: user.uid,
                avatarUser: user.photoURL,
                idReq: idReq,
                createAt: new Date(),
                updateAt: new Date(),
                email : doc.data().email,
                bizName : doc.data().profile.bizInfo.bizName,
                name : doc.data().profile.name,
                nickName: doc.data().displayName,
                channelName : doc.data().profile.proInfo.channel.name,
                channelNo : doc.data().profile.proInfo.channel.no,
                chart : chart, 
                wishChNo : wishChNo,
                wishChName : wishChName,
                disadvantage : data.disadvantage,
                comment : data.comment,
             };
             
                db.collection("resp_mentoring")
                .add(paylod)
                .then((docRef) => {
                  //updateReq(user.uid, chart, docRef);
                  updateReq(user.uid, chart, wishChNo);
                  updateChartsData(idReq);
                })
                .catch(() => {
                  alert("답변 등록이 실패하였습니다. 다시 시도해 주세요.");
                });

          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });

    
      
    }
  }
  };

  const updateChartsData = (idReq) => {

    let docId="";
    db.collection("charts_mentoring")
      .where("idReq", "==", idReq)
      .get()
      .then((response) => {
        response.forEach((doc) => {
          //console.log("Document data:", doc.data());
          const result = [];
          result.push(doc.data());
          //console.log("result=>", result[0].chartsData.data);
          const dataArray = [];
          docId=doc.id;

          result[0].chartsData.map((d)=>{
            //console.log("d data: ",d.data);
            dataArray.push(d.data);
        });


        if(Q0==5) result[0].data1[0]+=1;
        else if(Q0==4) result[0].data1[1]+=1;
        else if(Q0==3) result[0].data1[2]+=1;
        else if(Q0==2) result[0].data1[3]+=1;
        else result[0].data1[4]+=1;

        if(Q1==5) result[0].data2[0]+=1;
        else if(Q1==4) result[0].data2[1]+=1;
        else if(Q1==3) result[0].data2[2]+=1;
        else if(Q1==2) result[0].data2[3]+=1;
        else result[0].data2[4]+=1;
  
        if(Q2==5) result[0].data3[0]+=1;
        else if(Q2==4) result[0].data3[1]+=1;
        else if(Q2==3) result[0].data3[2]+=1;
        else if(Q2==2) result[0].data3[3]+=1;
        else result[0].data3[4]+=1;

        if(Q3==5) result[0].data4[0]+=1;
        else if(Q3==4) result[0].data4[1]+=1;
        else if(Q3==3) result[0].data4[2]+=1;
        else if(Q3==2) result[0].data4[3]+=1;
        else result[0].data4[4]+=1;

        if(Q5==5) result[0].data5[0]+=1;
        else if(Q5==4) result[0].data5[1]+=1;
        else if(Q5==3) result[0].data5[2]+=1;
        else if(Q5==2) result[0].data5[3]+=1;
        else result[0].data5[4]+=1;

        if(Q6==5) result[0].data6[0]+=1;
        else if(Q6==4) result[0].data6[1]+=1;
        else if(Q6==3) result[0].data6[2]+=1;
        else if(Q6==2) result[0].data6[3]+=1;
        else result[0].data6[4]+=1;

        if(Q7==5) result[0].data7[0]+=1;
        else if(Q7==4) result[0].data7[1]+=1;
        else if(Q7==3) result[0].data7[2]+=1;
        else if(Q7==2) result[0].data7[3]+=1;
        else result[0].data7[4]+=1;

        if(Q8==5) result[0].data8[0]+=1;
        else if(Q8==4) result[0].data8[1]+=1;
        else if(Q8==3) result[0].data8[2]+=1;
        else if(Q8==2) result[0].data8[3]+=1;
        else result[0].data8[4]+=1;

        if(Q9==5) result[0].data9[0]+=1;
        else if(Q9==4) result[0].data9[1]+=1;
        else if(Q9==3) result[0].data9[2]+=1;
        else if(Q9==2) result[0].data9[3]+=1;
        else result[0].data9[4]+=1;

        if(Q11==5) result[0].data10[0]+=1;
        else if(Q11==4) result[0].data10[1]+=1;
        else if(Q11==3) result[0].data10[2]+=1;
        else if(Q11==2) result[0].data10[3]+=1;
        else result[0].data10[4]+=1;

        if(Q12==5) result[0].data11[0]+=1;
        else if(Q12==4) result[0].data11[1]+=1;
        else if(Q12==3) result[0].data11[2]+=1;
        else if(Q12==2) result[0].data11[3]+=1;
        else result[0].data11[4]+=1;

        if(Q14==5) result[0].data12[0]+=1;
        else if(Q14==4) result[0].data12[1]+=1;
        else if(Q14==3) result[0].data12[2]+=1;
        else if(Q14==2) result[0].data12[3]+=1;
        else result[0].data12[4]+=1;

        if(Q15==5) result[0].data13[0]+=1;
        else if(Q15==4) result[0].data13[1]+=1;
        else if(Q15==3) result[0].data13[2]+=1;
        else if(Q15==2) result[0].data13[3]+=1;
        else result[0].data13[4]+=1;

        if(Q16==5) result[0].data14[0]+=1;
        else if(Q16==4) result[0].data14[1]+=1;
        else if(Q16==3) result[0].data14[2]+=1;
        else if(Q16==2) result[0].data14[3]+=1;
        else result[0].data14[4]+=1;   

      //console.log("dataArray:", dataArray);
      //console.log("docId: ",docId);
      const dRef = db.collection("charts_mentoring").doc(docId);
      dRef.update({
        "data1" : result[0].data1,
        "data2" : result[0].data2,
        "data3" : result[0].data3,
        "data4" : result[0].data4,
        "data5" : result[0].data5,
        "data6" : result[0].data6,
        "data7" : result[0].data7,
        "data8" : result[0].data8,
        "data9" : result[0].data9,
        "data10" : result[0].data10,
        "data11" : result[0].data11,
        "data12" : result[0].data12,
        "data13" : result[0].data13,
        "data14" : result[0].data14,
        });          
        });
        //setReviews(resultReview)
      });
  };

  const updateReq = (uid, chart, chNo) => {


    /*
    read db reqs data
    기존 데이터 읽어오기
    - recomCh
    - currResCount
    - replyFrom
    - chart_md_avg_label
    - totalSum
    - createBy


    */

    let updateObj = {};
    let replyFrom=[];
    let tempChart_md_avg_data=[];
    let tempChart_md_sum_data=[];
    let mdPoint=chart.data[13];
    let resultMdPoint=0;
    
    if(mdPoint==1){
      resultMdPoint=15;
    }else if(mdPoint==2){
      resultMdPoint=20;
    }else if(mdPoint==3){
      resultMdPoint=25;
    }else if(mdPoint==4){
      resultMdPoint=30;
    }else {
      resultMdPoint=35;
    }
    //합계, 평균 등을 구하기 위해서 13번째 값 제거
    let tempMdData=chart.data;
    tempMdData.pop();
    
    //console.log("tempMdData=>",tempMdData);
    const reqRef = db.collection("reqs_mentoring").doc(idReq);

    reqRef.get().then((response) => {
      const reqData = response.data();
      let recomCh=reqData.recomCh;
      chNo.map(v=>{
        let temp=find(recomCh, {"chNo" : v});
        console.log("temp->", temp);
        let index=temp.index;
        recomCh[index].count+=1;
      });
      //console.log("after recomCh",recomCh);
      let increaseCurrResCount = reqData.currResCount + 1;
      let tempTotalSum=reqData.totalSum+sum(tempMdData)+resultMdPoint;
      let tempTotalAvg=0;

      if(tempTotalSum==0 ){
        tempTotalAvg = tempTotalSum;
        console.log("tempTotalAvg->",tempTotalAvg);
      }else{
        tempTotalAvg = parseFloat(tempTotalSum / increaseCurrResCount);
        console.log("tempTotalAvg->",tempTotalAvg);
      }

      //다시 항목별 점수를 넣기 위해 13번째 값 추가
      tempMdData.push(mdPoint);

      if(reqData.replyFrom.length!=0){
        replyFrom=reqData.replyFrom;
      }
      replyFrom.push(uid);
      //console.log("replyFrom",replyFrom);

      let chart_md_avg_label = reqData.chart_md_avg_label;
      let chart_md_avg_data = reqData.chart_md_avg_data;
      let chart_md_sum_data = reqData.chart_md_sum_data;

 
      //평균점수 배열이 기 등록된 경우
      if(reqData.chart_md_sum_data.length!=0){
        chart_md_sum_data.map((n,i)=>{
          let sumTemp=n+tempMdData[i];
          let avgTemp=parseFloat((n+tempMdData[i])/increaseCurrResCount);
          //console.log("-------", i, "----------");
          //console.log(sumTemp," / ", increaseCurrResCount, " = ", avgTemp);
          tempChart_md_sum_data.push(sumTemp);
          tempChart_md_avg_data.push(avgTemp);
        });
      }else{
        //평균점수 배열이 빈 배열인 경우 
        tempChart_md_avg_data=tempMdData;
        tempChart_md_sum_data=tempMdData;
      }
      //console.log("chart.label=>",chart.label);
      //console.log("tempChart_md_sum_data=>",tempChart_md_sum_data);
      //console.log("tempChart_md_avg_data=>",tempChart_md_avg_data);
      //console.log("replyFrom=>",replyFrom);
      //console.log("tempTotalSum=>",tempTotalSum);
      //console.log("tempTotalAvg=>",tempTotalAvg);
      //console.log("recomCh=>",recomCh);


      //case01. res 1, +1 curr 1
      //case02. res 2, +1 curr 1
      //case03. res 2, +1 curr 2

      if(resCount==increaseCurrResCount){
        updateObj={
          state : 2,
          stateName : "분석완료",
          chart_md_avg_label : chart.label,
          chart_md_avg_data : tempChart_md_avg_data,
          chart_md_sum_data : tempChart_md_sum_data,
          currResCount: increaseCurrResCount,
          isChart : true,
          replyFrom : replyFrom,
          totalSum : tempTotalSum,
          totalAvg : tempTotalAvg,
          recomCh : recomCh
        };  
      }else if(resCount<increaseCurrResCount){
        alert('답변  불가','실시간 방송분석 진행이 마감되었습니다. 다른 요청 건을 확인해주세요.');
      }else if(resCount>increaseCurrResCount){
        updateObj={
          chart_md_avg_label : chart.label,
          chart_md_avg_data : tempChart_md_avg_data,
          chart_md_sum_data : tempChart_md_sum_data,
          currResCount: increaseCurrResCount,
          isChart : true,
          replyFrom : replyFrom,
          totalSum : tempTotalSum,
          totalAvg : tempTotalAvg,
          recomCh : recomCh
        };
      }


      reqRef.update(updateObj)
      .then(() => {
        history.push({
          pathname: '/mentorings'
          //search: '?query=abc',     
      });
      });

    });



  };

  const onChange = (values) => {
    setSelected(values)
    //console.log("selected=>", values)
  }
 
  return (
    <div>
      {user ?
      (
        <Content>
        <MainContainer>
        <Heading>방송분석 답변</Heading>
      <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>

      {/* Q0 begin */}
      <div>
        <p><b>Q{questions[0].no}. {questions[0].title}</b></p>
        <p style={bodyTextStyle}> {questions[0].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[0].points.map((n) => (
          <span>
         <input
            key={`q0${n}`}
            id={`q0${n}`}
            //key={n}
            //id={n}
            type="radio"
            name="q0"
            value={n}
            onChange={(e) => handleChange(e, 0)}
            check={Q0 == n }
            //checked={Q0 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q0 end */}
      {/* Q1 begin */}
        <div>
        <p><b>Q{questions[1].no}. {questions[1].title}</b></p>
        <p style={bodyTextStyle}> {questions[1].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[1].points.map((n) => (
          <span>
         <input
            key={`q1${n}`}
            id={`q1${n}`}
            //key={n}
            //id={n}
            type="radio"
            name="q1"
            value={n}
            onChange={(e) => handleChange(e, 1)}
            check={Q1 == n }
            //checked={Q0 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
        {/* Q1 end */}
        {/* Q2 begin */}
        <div>
        <p><b>Q{questions[2].no}. {questions[2].title}</b></p>
        <p style={bodyTextStyle}> {questions[2].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[2].points.map((n) => (
          <span>
         <input
            key={`q2${n}`}
            id={`q2${n}`}
            type="radio"
            name="q2"
            value={n}
            onChange={(e) => handleChange(e, 2)}
            checked={Q2 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q2 end */}
       {/* Q3 begin */}
       <div>
        <p><b>Q{questions[3].no}. {questions[3].title}</b></p>
        <p style={bodyTextStyle}> {questions[3].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[3].points.map((n) => (
          <span>
         <input
            key={`q3${n}`}
            id={`q3${n}`}
            type="radio"
            name="q3"
            value={n}
            onChange={(e) => handleChange(e, 3)}
            checked={Q3 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q3 end */}
      {/* Q4 begin */}
       <div>
        <p><b>Q{questions[4].no}. {questions[4].title}</b></p>
        <p style={bodyTextStyle}> {questions[4].body}</p>
        <p>&nbsp; </p>
        {allQDoc.length != 0 && 
             <ColumnSelect
        options={allQDoc}
        onChange={onChange}
        leftHeader='전체'
        rightHeader='선택'
        isSearchable={true}
        searchPlaceholder="검색..."
        theme={{
          headerBgColor: '#eee',
          columnBorderColor: '#ccc',
          textColor: '#000000',
          columnBgColor: '#ccc',
          buttonBgColor: '#ccc',
          optionSelectedBgColor: '#eee',
          optionHoverBgColor: '#eee',
          searchFocusBorderColor: '#ccc',
        }}
      />
      }
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q4 end */}
      {/* Q5 begin */}
      <div>
        <p><b>Q{questions[5].no}. {questions[5].title}</b></p>
        <p style={bodyTextStyle}> {questions[5].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[5].points.map((n) => (
          <span>
         <input
            key={`q5${n}`}
            id={`q5${n}`}
            type="radio"
            name="q5"
            value={n}
            onChange={(e) => handleChange(e, 5)}
            checked={Q5 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q5 end */}
      {/* Q6 begin */}
      <div>
        <p><b>Q{questions[6].no}. {questions[6].title}</b></p>
        <p style={bodyTextStyle}> {questions[6].body}</p>
        <p>&nbsp; </p>
        
        <b>매우만족</b>
        {questions[6].points.map((n) => (
          <span>
         <input
            key={`q6${n}`}
            id={`q6${n}`}
            type="radio"
            name="q6"
            value={n}
            onChange={(e) => handleChange(e, 6)}
            checked={Q6 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q6 end */}
      {/* Q7 begin */}
      <div>
        <p><b>Q{questions[7].no}. {questions[7].title}</b></p>
        <p style={bodyTextStyle}> {questions[7].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[7].points.map((n) => (
          <span>
         <input
            key={`q7${n}`}
            id={`q7${n}`}
            type="radio"
            name="q7"
            value={n}
            onChange={(e) => handleChange(e, 7)}
            checked={Q7 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q7 end */}
        {/* Q8 begin */}
      <div>
        <p><b>Q{questions[8].no}. {questions[8].title}</b></p>
        <p style={bodyTextStyle}> {questions[8].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[8].points.map((n) => (
          <span>
         <input
            key={`q8${n}`}
            id={`q8${n}`}
            type="radio"
            name="q8"
            value={n}
            onChange={(e) => handleChange(e, 8)}
            checked={Q8 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q8 end */}

      {/* Q9 begin */}
      <div>
        <p><b>Q{questions[9].no}. {questions[9].title}</b></p>
        <p style={bodyTextStyle}> {questions[9].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[9].points.map((n) => (
          <span>
         <input
            key={`q9${n}`}
            id={`q9${n}`}
            type="radio"
            name="q9"
            value={n}
            onChange={(e) => handleChange(e, 9)}
            checked={Q9 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q9 end */}
      {/* Q11 begin */}
      <div>
        <p><b>Q{questions[11].no}. {questions[11].title}</b></p>
        <p style={bodyTextStyle}> {questions[11].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[11].points.map((n) => (
          <span>
         <input
            key={`q11${n}`}
            id={`q11${n}`}
            type="radio"
            name="q11"
            value={n}
            onChange={(e) => handleChange(e, 11)}
            checked={Q11 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q11 end */}
          {/* Q10 begin */}
      <div>
        <p><b>Q{questions[10].no}. {questions[10].title}</b></p>
        <p style={bodyTextStyle}> {questions[10].body}</p>
        <p>&nbsp; </p>
        <TextArea defaultValue="" placeholder="200자 내외로 입력해주세요." {...register("disadvantage", { required: true })} rows={5}/>
             {errors.comment && <span style={errorStyle}>상품에서 보완해야 할 부분을 입력하세요.</span>}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q10 end */}
      {/* Q12 begin */}
      <div>
        <p><b>Q{questions[12].no}. {questions[12].title}</b></p>
        <p style={bodyTextStyle}> {questions[12].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[12].points.map((n) => (
          <span>
         <input
            key={`q12${n}`}
            id={`q12${n}`}
            type="radio"
            name="q12"
            value={n}
            onChange={(e) => handleChange(e, 12)}
            checked={Q12 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q12 end */}
      {/* Q14 begin */}
      <div>
        <p><b>Q{questions[14].no}. {questions[14].title}</b></p>
        <p style={bodyTextStyle}> {questions[14].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[14].points.map((n) => (
          <span>
         <input
            key={`q14${n}`}
            id={`q14${n}`}
            type="radio"
            name="q14"
            value={n}
            onChange={(e) => handleChange(e, 14)}
            checked={Q14 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q14 end */}
      {/* Q15 begin */}
      <div>
        <p><b>Q{questions[15].no}. {questions[15].title}</b></p>
        <p style={bodyTextStyle}> {questions[15].body}</p>
        <p>&nbsp; </p>
        <b>매우만족</b>
        {questions[15].points.map((n) => (
          <span>
         <input
            key={`q15${n}`}
            id={`q15${n}`}
            type="radio"
            name="q15"
            value={n}
            onChange={(e) => handleChange(e, 15)}
            checked={Q15 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          </span>
      ))}
    매우부족
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q15 end */}
      {/* Q13 begin */}
      <div>
        <p><b>Q{questions[13].no}. {questions[13].title}</b></p>
        <p style={bodyTextStyle}> {questions[13].body}</p>
        <p style={bodyTextStyle1}> {questions[13].body1}</p>
        <p style={bodyTextStyle1}> {questions[13].body2}</p>
        <p>&nbsp; </p>
        <TextArea defaultValue="" placeholder="500자 내외로 입력해주세요." {...register("comment", { required: true })} rows={5}/>
             {errors.comment && <span style={errorStyle}>홈쇼핑 방송 분석 의견을 입력하세요.</span>}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q13 end */}
      {/* Q16 begin */}
      <div>
        <p><b>Q{questions[16].no}. {questions[16].title}</b></p>
        <p style={bodyTextStyle}> {questions[16].body}</p>
        <p>&nbsp; </p>

        {questions[16].points.map((n, i) => (
        <label htmlFor={questions[16].label[i]}>
         <input
            key={`q16${n}`}
            id={`q16${n}`}
            type="radio"
            name="q16"
            value={n}
            onChange={(e) => handleChange(e, 16)}
            checked={Q16 == n}
            style={{
              height: 15,
              width: 20,
            }}
          /> &nbsp;
          {questions[16].label[i]}
          <br />
          </label>
          
      ))}
        <hr
        style={{
            color: "#eee",
            backgroundColor: "#eee",
            height: 1,
            marginTop : 20,
            marginBottom : 20
        }}
        />
        </div>
      {/* Q16 end */}



      <SubmitButton type="submit">
        <span className="text">분석 답변</span>
      </SubmitButton>
           </form>
        </FormContainer>
           </MainContainer>
           </Content>

       )
             : 
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
      } 
    </div>
  )
}

function NumberList(props) {
  const numbers = props.numbers;
  const listItems = numbers.map((number) =>
    <li key={number.toString()}>
      {number}
    </li>
  );
  return (
    <ul>{listItems}</ul>
  );
}

export default MtrReply;


/*
state: { 
              loginEmail: loginEmail,
              loginUid : user.uid,
              loginUserName : loginUserName,
              loginUserType : loginUserType,
              loginUserState : loginUserState,
              idReq: idReq,
              cateNo : cateNo,
            }
*/