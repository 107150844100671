import React from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from "react-table";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line

const StyleImages = styled.div`
display:flex; 
height: auto;
width : 600px;
align-items: flex-start;
flex-wrap: wrap;
`;

const StyledTable = styled.table`
border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  table-layout: auto;
  width: 100%;
`;

const StyledTHeadTr = styled.tr`
background-color: #fff;
  color: #000;
  text-align: left;
  
`;

const StyledTh = styled.th`
padding: 12px 15px;
`;

const StyledTd = styled.td`
padding: 12px 15px;
:nth-of-type(1) {
  font-weight: bold;  
}
`;


const StyledTBodyTr = styled.tr`
border-bottom: 1px solid #dddddd;

.active-row {
  font-weight: bold;
  color: #182987;
}
  
`;

const DetailDiagTable = (
{ 
  columns, 
  data,
  cusQ1Sum,
  cusQ2Sum,
  cusQ3Sum,
  cusQ4Sum,
  cusQ5Sum,
  cusQ6,
  cusQ7,
  cusQ8,
  cusTotalSum,
  mdQ1Sum,
  mdQ2Sum,
  mdQ3Sum,
  mdQ4Sum,
  mdQ5Sum,
  mdTotalSum,
  mdQ6,
  mdQ7,
  mdQ8,
}) =>{ 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })



return (

  <div>
  <StyledTable {...getTableProps()}
    style={{ 
      border: 'solid 1px #fff',
      width: "100%",
    }}>

   <tbody {...getTableBodyProps()}>
     {rows.map(row => {
       prepareRow(row)
       return (
         <StyledTBodyTr {...row.getRowProps()}>
           {row.cells.map(cell => {
             return (
               <StyledTd
                 {...cell.getCellProps()}
                 /*style={{
                   padding: '10px',
                   border: 'solid 1px #ccc',
                   background: '#fff',
                   
                   
                 }}*/
               >
                 {cell.render('Cell')}
               </StyledTd>
             )
           })}
         </StyledTBodyTr>
       )
     })}

    <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>총 점수(100점)
     </StyledTd>

     <StyledTd style={{width: "300px"}}>{cusTotalSum > 0 ? cusTotalSum+"점" : "미작성"}
     </StyledTd>
     <StyledTd style={{width: "300px"}}>{mdTotalSum > 0 ? mdTotalSum+"점" : "미작성"}
     </StyledTd>
    </StyledTBodyTr>
    <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>상세 점수
     </StyledTd>
     <StyledTd style={{width: "300px"}}> 
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>창업목표 : </span>
      <span>{cusQ1Sum > 0 ? cusQ1Sum+"점" : "미작성"} </span><br/>
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>창업아이템 : </span>
      <span>{cusQ2Sum > 0 ? cusQ2Sum+"점" : "미작성"}</span><br/>
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>창업자역량 : </span>
      <span>{cusQ3Sum > 0 ? cusQ3Sum+"점" : "미작성"}</span><br/>
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>창업자금 : </span>
      <span>{cusQ4Sum > 0 ? cusQ4Sum+"점":"미작성"}</span><br/>
      <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>창업팀 : </span>
      <span>{cusQ5Sum > 0 ? cusQ5Sum+"점": "미작성"}</span><br/>
     </StyledTd>
     <StyledTd style={{width: "300px"}}> 
      <span>{mdQ1Sum > 0 ? mdQ1Sum+"점" : "미작성"} </span><br/>
      <span>{mdQ2Sum > 0 ? mdQ2Sum+"점" : "미작성"}</span><br/>
      <span>{mdQ3Sum > 0 ? mdQ3Sum+"점" : "미작성"}</span><br/>
      <span>{mdQ4Sum > 0 ? mdQ4Sum+"점":"미작성"}</span><br/>
      <span>{mdQ5Sum > 0 ? mdQ5Sum+"점": "미작성"}</span><br/>

     </StyledTd>
    </StyledTBodyTr>
    <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>코칭 희망 분야
     </StyledTd>
     <StyledTd style={{width: "300px"}}> 
     <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>1순위 : </span>
      <span>{cusQ7.length > 0 ? cusQ7 : "미작성"} </span><br/>
      <span>{cusQ6.length > 0 ? cusQ6.join(", ") : "미작성"} </span><br/>
     </StyledTd>
     <StyledTd style={{width: "300px"}}> 
     <span style={{color: "#000", fontWeight: 'bold',fontSize: 14}}>1순위 : </span>
      <span>{mdQ7.length > 0 ? mdQ7 : "미작성"} </span><br/>
      <span>{mdQ6.length > 0 ? mdQ6.join(", ") : "미작성"} </span><br/>
     </StyledTd>
    </StyledTBodyTr>
    <StyledTBodyTr  style={{backgroundColor: "#eee"}}>
     <StyledTd>바라는점(코멘트)
     </StyledTd>
     <StyledTd style={{width: "300px"}}> 
      <span>{cusQ8.length > 0 ? cusQ8 : "미작성"} </span><br/>
     </StyledTd>
     <StyledTd style={{width: "300px"}}> 
      <span>{mdQ8.length > 0 ? mdQ8 : "미작성"} </span><br/>
     </StyledTd>
    </StyledTBodyTr>
   </tbody>
 </StyledTable>
  

</div>
);
}

export default DetailDiagTable;

/*
   <thead>
     {headerGroups.map(headerGroup => (
       <StyledTBodyTr {...headerGroup.getHeaderGroupProps()}>
         {headerGroup.headers.map(column => (
           <StyledTh
             {...column.getHeaderProps()}
             /*style={{
               borderBottom: 'solid 3px black',
               background: '#fff',
               color: 'black',
               fontWeight: 'bold',
             }}
             >
             {column.render('Header')}
           </StyledTh>
         ))}
       </StyledTBodyTr>
     ))}
   </thead>

*/