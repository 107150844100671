import React from 'react';
import Prod from './Prod';

export default function Prods(props) {
  const { products, onAdd, setMode, SetCurProd, cartItems } = props;
  return (
      <div className="wrapper">
        {products.map((product) => (
          <Prod SetCurProd={SetCurProd} setMode={setMode} key={product.id} product={product} onAdd={onAdd} cartItems={cartItems}/>
        ))}
      </div>
  );
}