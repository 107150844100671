import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
          닥터엠디 개인정보처리방침<br />
        닥터엠디<br />
        개 인 정 보 처 리 방 침<br />
        -목차-<br />
        1. 개인정보 보호와 취급방침<br />
        2. 개인정보 수집 항목 및 방법<br />
        3. 개인정보 이용 목적<br />
        4. 개인정보 보유 및 이용기간<br />
        5. 개인정보 취급위탁<br />
        6. 개인정보 제 3자 제공<br />
        7. 개인정보 파기<br />
        8. 이용자 및 법정대리인의 권리·의무 및 행사방법<br />
        9. 쿠키(Cookie)의 설치, 운영 및 그 거부에 관한 사항<br />
        10. 링크 사이트에 대한 책임<br />
        11. 개인정보의 기술적/관리적 보호대책<br />
        12. 개인정보 관리 책임자 및 담당부서<br />
        13. 고지의 의무<br /><br />

        1. 개인정보 보호와 취급방침<br />
        ㄱ. “닥터엠디 개인정보 취급방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 닥터엠디 (이하 “회사" 혹은 “Dr.MD”이라 함)를 운영함에 있어 준수해야 할 지침을 의미하며, 닥터엠디은 대한민국의 개인정보보호 규정 및 가이드라인을 준수하고 있습니다.<br />
        ㄴ. 닥터엠디은 이용자의 ‘동의를 기반으로 개인정보를 수집·이용 및 제공’ 하고 있으며, ‘이용자의 권리(개인정보 자기결정권)를 적극적으로 보장’ 합니다.<br />
        ㄷ. 본 개인정보취급방침은 닥터엠디이 운영하는 서비스에서 적용되는 것을 원칙으로 하며 본 개인정보취급방침은 대한민국 법령에 의하여 규정되고 해석됩니다.<br /><br />

        2. 개인정보 수집 항목 및 방법<br />
        모든 이용자는 닥터엠디이 제공하는 서비스를 이용할 수 있고, Dr.MD의 다양한 서비스를 제공받을 수 있습니다. 그리고 이때 닥터엠디은 다음의 원칙 하에 이용자의 개인정보를 수집하고 있습니다.<br />
        ㄱ. 닥터엠디은 서비스 제공에 필요한 최소한의 개인 정보를 수집하고 있습니다. 각 서비스 제공에 필요한 최소한의 정보 외의 정보, 즉 선택적 정보 수집에는 동의하지 아니하실 수 있습니다. 각 서비스 제공에 따른 구체적인 개인정보 수집 항목은 다음과 같습니다.<br /><br />

        개인정보 수집 항목<br />
        진단 서비스<br />
        위치정보, 성명, 주소, 휴대폰번호<br />
        이벤트 참가<br />
        성명,휴대폰번호,주소<br />
        서비스 중 자동 수집<br />
        쿠키,IP Address,방문일시,이용자의 사용 OS, 기기 고유번호, 서비스 이용 기록, 불만 및 분쟁처리 기록<br /><br />
        ㄴ. 닥터엠디은 홈페이지 또는 모바일 애플리케이션 내 회원의 개인정보 입력, 각 서비스의 이용 과정 중 , 전화 또는 고객센터 이용 등의 방법으로 개인정보를 수집 합니다.<br />
        ㄷ. 닥터엠디은 민감 정보를 수집하지 않습니다.<br />
        닥터엠디은 이용자의 소중한 인권을 침해할 우려가 있는 민감한 정보(인종, 사상 및 신조, 정치적 성향 이나 범죄기록, 의료정보 등)는 어떠한 경우에도 수집하지 않으며, 만약 법령에서 정한 의무에 따라 불가피하게 수집하는 경우에는 반드시 이용자에게 사전 동의를 거치겠습니다.<br />
        다만, 해당 정보는 이용자가 확인한 시점을 기준으로 한 정보이며, 이용자의 개인정보를 추가 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 거치겠습니다.<br /><br />

        3. 개인정보 이용 목적<br />
        닥터엠디은 이용자의 소중한 개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는 사전에 이용자의 동의를 구하도록 하겠습니다.<br />
        ㄱ. 기기의 고유한 번호로 이용자를 식별하고, 불량 회원의 부정한 이용을 방지하기 위하여 사용합니다.<br />
        ㄴ. 이용자에게 닥터엠디의 다양한 서비스를 제공하고 서비스 이용 과정에서 이용자의 문의 사항이나 불만을 처리하고 공지사항 등을 전달하기 위해 사용합니다.<br />
        ㄷ. 이용자와 약속한 서비스를 제공하고 유료 서비스 구매 및 이용이 이루어지는 경우 이에 따른 요금 정산을 위해 사용됩니다.<br />
        ㄹ. 신규 서비스가 개발되거나 이벤트 행사 시 참여 기회를 알리기 위한 정보 전달 및 마케팅, 광고 등에도 사용됩니다.<br />
        ㅁ. 이용자의 이용 패턴과 접속 빈도 분석 및 서비스 이용에 대한 통계 및 이를 통한 맞춤형 서비스 제공 및 서비스 개선에도 사용됩니다.<br />
        ㅂ. google 등 외부 애드서버 제휴업체에서 사용자나 유사 잠재고객에게 인터넷 탐색 시 방문하는 웹사이트에 광고를 개제하는 등의 적절한 광고메세지를 전달할 수 있습니다.<br /><br />

        4. 개인정보 보유 및 기간<br />
        닥터엠디은 이용자의 개인정보를 서비스 이용 시작일부터 서비스를 제공하는 기간 동안에만 제한적으로 이용하고 있습니다. 이용자가 개인정보 삭제를 요청하거나 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 이용자의 개인정보는 지체 없이 파기됩니다.<br />
        그리고 단, 이용자의 개인정보 삭제요청 또는 개인정보 이용에 대한 동의를 철회하는 경우에도 관계 법령의 규정에 따라 일정한 기간 동안 회원정보를 보관하며 자세한 내용은 아래와 같습니다.<br /><br />

        소비자의 불만 및 분쟁처리에 관한 기록<br />
        보존근거 : 전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 시행령 제6조<br />
        보존기간 : 3년<br />
        대금결제 및 재화 등의 공급에 관한 기록<br />
        보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 시행령 제6조<br />
        보존 기간 : 5년<br />
        계약 또는 청약철회 등에 관한 기록<br />
        보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 시행령 제6조<br />
        보존 기간 : 5년<br />
        표시 또는 광고에 관한 기록<br />
        보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 시행령 제6조<br />
        보존 기간 : 6개월<br />
        접속에 관한 기록<br />
        보존 이유 : 통신비밀보호법 제15조의2 및 시행령 제41조<br />
        보존기간 : 3개월<br />
        부정거래기록<br />
        보존 이유 : 부정거래의 배제 등 회사 방침에 의한 보존<br />
        보존 기간 : 5년<br /><br />

        5. 개인정보 취급위탁<br />
        ㄱ.회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.<br />
        ㄴ.회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같으며, 개인정보의 보유 및 이용기간은 위탁 계약 만료 시 까지입니다.<br /><br />

        6. 개인정보 제 3자 제공<br />
        ㄱ. 닥터엠디은 원칙적으로 이용자의 동의 없이 개인정보를 제3자에게 제공하지 않으며, 개인정보를 제3자에게 제공해야 하는 경우 법령에 따른 동의를 받고 있습니다. 다만 다음의 경우는 예외로 하고 있습니다.<br />
        ① 이용자가 사전에 공개하거나 또는 제3자 제공에 동의한 경우입니다.<br />
        ② 법원 등 국가기관이 법령에 근거하여 이용자의 개인정보를 제공하도록 강제한 경우입니다.<br />
        ㄴ. 닥터엠디은 서비스 제공을 위해서 아래와 같이 이용자의 동의를 받아 제3자에게 개인정보를 제공하는 경우,업무를 위탁 또는 제휴하는 경우, 관계 법령에 따라 위탁 및 제휴계약 시 제공하는 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.<br />
        ㄷ.다만, 이용자는 관련법에 따라 수집한 개인정보의 업무위탁에 따른 제공 동의를 거부할 권리가 있으며, 미 동의시 서비스 이행을 위한 위탁업체 제공이 불가능하여 서비스 제한을 받으실 수 있습니다.<br /><br />

        7. 개인정보 파기<br />
        닥터엠디은 이용자의 개인정보에 대해 “개인정보의 수집·이용 목적이 달성”된 후에는 해당 정보를 재생할 수 없는 기술적인 방법을 이용하여 완전하게 삭제하게 되고, 종이로 출력된 개인정보는 분쇄하거나 소각하여 지체 없이 파기함을 약속합니다.<br /><br />

        8. 이용자 및 법정대리인의 권리·의무 및 행사방법<br />
        닥터엠디은 서비스 이용에 대해, 이용자(만 14세 미만인 경우에는 법정대리인을 말함, 이하 같음)의 권리를 다음과 같이 보호하고 있습니다.<br />
        ㄱ. 이용자는 언제든지 등록되어 있는 자신의 개인정보를 열람, 조회, 오류 등이 있을 경우 오류의 정정, 및 처리 정지를 요구하고 삭제할 수 있습니다. 다만, 그러한 경우 해당 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.<br />
        ㄴ. 언제든지 개인정보 제공에 관한 동의 철회를 요청할 수 있습니다.<br />
        ㄷ. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br />
        ㄹ. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br />
        ㅁ. 이용자는 개인정보 열람, 조회, 정정, 처리정지 및 삭제 등 요청 시, 회사에 대해 서면, 전화, 전자우편, 모사전송(fax)등을 통하여 하실 수 있고, 회사는 이에 대해 지체없이 조치할 것입니다.<br /><br />

        9. 쿠키(Cookie)의 설치, 운영 및 그 거부에 관한 사항<br />
        ㄱ. 쿠키(Cookie)의 정의<br />
        쿠키(Cookie)는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터로 전송하는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장되고 있습니다. 따라서 여러분 스스로가 쿠키의 설치 및 수집에 대해 자율적으로 선택할 수 있으므로 수집을 거부할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스의 이용에 제한이 생길 수 있습니다.<br />
        ㄴ. 쿠키 설정 거부 방법<br />
        -Internet Explorer의 경우<br />
        웹 브라우저 상단의 도구 - 인터넷 옵션 - 개인정보 탭에서 직접 설정<br />
        -Chrome의 경우<br />
        웹 브라우저 우측 상단의 아이콘 선택 - 설정 선택 - 화면 하단의 “고급 설정 표시” 선택 - 개인정보 섹션의 “콘텐츠 설정” 버튼 - 쿠키 섹션에서 직접 설정<br /><br />

        10. 링크 사이트에 대한 책임<br />
        회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 다만, 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "개인정보취급방침"이 적용되지 않습니다.<br />

        11. 개인정보의 기술적/관리적 보호 대책<br />
        회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br /><br />

        ㄱ. 비밀번호 암호화<br />
        회원 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.<br />
        ㄴ. 해킹 등에 대비한 대책<br />
        회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.<br />
        개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.<br />
        ㄷ. 취급 직원의 최소화 및 교육<br />
        회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보취급방침의 준수를 항상 강조하고 있습니다.<br />
        ㄹ. 개인정보보호 전담기구의 운영<br />
        사내 개인정보보호 전담기구 등을 통하여 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.<br /><br />

        12. 개인정보 관리 책임자 및 담당부서<br />
        책임자: 이상발<br />
        담당부서: 경영기획실<br />
        문의: connectmdceo@gmail.com<br />
        (개인정보 열람청구에 대한 접수 또한 위 책임자 및 담당부서에 하실 수 있습니다.)<br />
        또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.<br />
        - 개인분쟁조정위원회 ( http://www.1336.or.kr / 1336 )<br />
        - 정보보호마크인증위원회 ( http://www.eprivacy.or.kr / 02-580-0533~4 )<br />
        - 대검찰청 인터넷범죄수사센터 ( http://icic.sppo.go.kr / 02-3480-3600 )<br />
        - 경찰청 사이버테러대응센터 ( http://www.ctrc.go.kr / 02-392-0330 )<br /><br />

        13. 고지의 의무<br />
        닥터엠디은 위 내용에 대한 추가, 삭제 및 수정이 있을 경우에는 시행하는 날로부터 최소 7일전에 각 서비스의 공지사항을 통해 이용자에게 설명 드리겠습니다. 다만 이용자의 소중한 권리 또는 의무에 중요한 내용 변경은 최소 30일 전에 알려 드리겠습니다.<br />
        - 개인정보취급방침 시행일자: 2021년 5월 26일<br />
        - 개인정보취급방침 변경공고일자: 2021년 5월 26일<br />
          </Text>
        </ContentWithPaddingXl>
      </Container>
     
    </AnimationRevealPage>
  );
};
