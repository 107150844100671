import React, { useState, useEffect }  from 'react';
import Reqs from "./Reqs";
import { Redirect, Route, useLocation } from 'react-router-dom';

import firebase, {auth} from "../config/firebase";


//const DashBoard = () =>{

const db=firebase.firestore();
const DashBoard = ({location:{state}, history }) =>{
const user = auth.currentUser;
const location = useLocation();
const [loginEmail, setLoginEmail] = useState("");
const [loginUid, setLoginUid] = useState("");
const [loginUserName, setLoginUserName]= useState("");
const [loginUserType, setLoginUserType]=useState("");
const [loginUserState, setLoginUserState]=useState("");
const [loginUserGroup, setLoginUserGroup]=useState("");
const getLoginUserInfo = async(uid) => {
    


  db.collection('users').doc(uid).get().then((response) => {
    const user = response.data();
    //console.log("userEmail", user.email);
    setLoginEmail(user.email);
    setLoginUid(user.uid);
    setLoginUserName(user.profile.name);
    setLoginUserType(user.userType);
    setLoginUserState(user.state);
    setLoginUserGroup(user.userGroup);

    history.push({
      pathname: '/mtrz',
      //search: '?query=abc',
      state: { 
        loginEmail: user.email,
        loginUid : user.uid,
        loginUserName : user.profile.name,
        loginUserType : user.userType,
        loginUserState : user.state,
        loginUserGroup : user.userGroup
      }
  });
  });
};

useEffect(() => {
  getLoginUserInfo(user.uid)
}, []);

/*
const {
  loginEmail,
  loginUid,
  loginUserName,
  loginUserType,
  loginUserState
}=state;
*/
return (
    
        <div> 

            {!user &&
             <Redirect to={{
                pathname: '/admin', 
              }}
            />
            }
        </div>
)    
} 

export default DashBoard;